import React from 'react';
import { Button, Icon } from '@avtjs/react-components';
import { USER_EVENT_ACTIVITY, USER_EVENT_STATUSES } from '../../../../constants';
import { getGroupedUserEventOptions } from '../utils';

const quickFilterTitle = (props) => {
  return (
    <div className="quick-filter-title">
      <label> {props.title}</label>
      <Button
        design="text"
        slim
        icon={
          <Icon
            icon="abb-information-circle-2"
            size="s"
          />
        }
        tooltip={
          "Selecting 'Quick Filter' resets all other filters to default and applies the filter to all events."
        }
        onClick={(e) => e.preventDefault()}
      ></Button>
    </div>
  );
};
const getUserEventFiltersSchema = (userEventTypes, tags, users) => {
  const groupedOptions = getGroupedUserEventOptions(userEventTypes);

  const includesTaskTypeEvent = userEventTypes.find((eventType) => !!eventType.task);

  const schema = {
    type: 'object',
    properties: {
      quickFilterTitle: {
        type: 'object',
        title: 'Quick Filter',
      },
      quickFilter: {
        type: 'string',
        default: 'none',
        anyOf: [
          { const: 'none', title: 'None' },
          { const: 'overdue', title: 'All overdue' },
          { const: 'todo', title: 'All To-do' },
        ],
      },
      assignee: {
        type: 'string',
        title: 'Assignee',
        isMulti: true,
        uniqueItems: true,
        placeholder: 'Select assignee...',
        anyOf: [...users.map((user) => ({ const: user.id, title: user.name }))],
      },
      creator: {
        type: 'string',
        title: 'Creator',
        isMulti: true,
        uniqueItems: true,
        placeholder: 'Select creator...',
        anyOf: [...users.map((user) => ({ const: user.id, title: user.name }))],
      },
      type: {
        type: 'string',
        title: 'Event Type',
        isMulti: true,
        uniqueItems: true,
        placeholder: 'Select types...',
        groupedOptions,
        anyOf: [...userEventTypes.map((type) => ({ const: type.id, title: type.name }))],
      },
      activity: {
        type: 'string',
        title: 'Activity',
        placeholder: 'Select activity...',
        default: 'all',
        anyOf: [
          ...USER_EVENT_ACTIVITY.map((activity) => ({
            const: activity.toLowerCase(),
            title: activity,
          })),
        ],
      },
      status: {
        type: 'string',
        title: 'Status',
        placeholder: 'Select status...',
        default: 'all',
        anyOf: [
          ...USER_EVENT_STATUSES.map((status) => ({
            const: status.toLowerCase(),
            title: status,
          })),
        ],
      },
      tags: {
        title: 'Tags',
        type: 'array',
        isMulti: true,
        uniqueItems: true,
        placeholder: 'Select tags...',
        items: {
          type: 'string',
          anyOf: tags.map((tag) => ({
            const: tag.id,
            title: tag.name,
          })),
        },
      },
    },
  };

  if (!includesTaskTypeEvent) {
    delete schema.properties.activity;
  }

  return schema;
};

const iotEventFiltersSchmema = (iotEventTypes) => {
  return {
    type: 'object',
    properties: {
      eventType: {
        type: 'string',
        title: 'Event Type',
        isMulti: true,
        uniqueItems: true,
        anyOf: [
          ...iotEventTypes.map((iotEventType) => ({
            const: iotEventType.id,
            title: iotEventType.name,
          })),
        ],
      },
    },
  };
};

const getUserEventFiltersUiSchema = (formData) => {
  return {
    quickFilterTitle: {
      'ui:TitleFieldTemplate': quickFilterTitle,
    },
    quickFilter: {
      'ui:options': { label: false },
    },
    status: {
      'ui:disabled': formData.user?.quickFilter !== 'none',
    },
  };
};

export { getUserEventFiltersSchema, getUserEventFiltersUiSchema, iotEventFiltersSchmema };
