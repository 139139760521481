import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon, Label } from '@avtjs/react-components';

import SelectRootFile from '../SelectRootFile';

const ModelUpload = ({ onDropAccepted, file, showSelector = false, onSelect, onInvalidFile }) => {
  const [fileRejections, setFileRejections] = useState([]);

  const handleAccept = (cb) => (files) => {
    setFileRejections([]);
    return cb(files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      '.zip',
      '.nwd',
      '.rvt',
      '.pdf',
      '.dgn',
      '.dwg',
      '.dxf',
      '.stp',
      '.step',
      '.sldasm',
      '.sldprt',
      '.iam',
      '.ipt',
      '.obj',
      '.ifc',
    ].join(','),
    multiple: false,
    onDropAccepted: handleAccept(onDropAccepted),
    onDropRejected: setFileRejections,
  });

  const errorMessages = () => {
    if (fileRejections.length > 1) {
      return [true, false];
    }
    if (fileRejections.length > 0) {
      return [false, true];
    }
    return [false, false];
  };

  const [tooManyFilesError, wrongFileFormatError] = errorMessages();

  return (
    <>
      <div
        {...getRootProps()}
        className="model-upload__drop-zone"
      >
        <input {...getInputProps({ type: 'file' })} />
        <Icon
          icon="abb-upload"
          size="l"
        />
        <p>{'Drag & drop a model here, or click to select a file'}</p>
      </div>

      {tooManyFilesError && (
        <div className="model-upload-file-rejection">
          Too many files added. You can only add 1 file.
        </div>
      )}
      {wrongFileFormatError && (
        <div className="model-upload-file-rejection">File format error.</div>
      )}

      {file ? (
        <>
          <Label htmlFor="model-filename">Model</Label>
          <div className="model-upload-file-name">{(file || {}).name}</div>
        </>
      ) : null}

      {file && file.type === 'application/pdf' ? (
        <div className="model-file-info">
          <Icon
            icon="abb-information-circle-1"
            size="m"
          />
          <p>Converting .pdf to .dwg can increase clarity in the spatial viewer.</p>
        </div>
      ) : null}

      <div className="model-upload-file-wrapper">
        {showSelector ? (
          <SelectRootFile
            onSelect={onSelect}
            file={file}
            onInvalidFile={onInvalidFile}
          />
        ) : null}
      </div>
    </>
  );
};

export default ModelUpload;
