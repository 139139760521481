/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon } from '@avtjs/react-components';
import { SignalViewer } from '@avtjs/comtrade-viewer';
import '@avtjs/comtrade-viewer/dist/index.css';

import { updateTfrTemplate, fetchTfrTemplates } from '../../bundles/tfr-templates';
import NewTemplateModel from './components/NewTemplateModel';
import Loader from '../Loader';
import { fetchExtendedSeriesData } from '../../bundles/variables';
import { getSiteVariables, getSources } from '../../bundles/sources';
import services from '../../services';
import { checkIsOnline, displayNotification } from '../../bundles/notifications';
import getNotification from '../../bundles/notification-defaults';
import SelectPollingDateRange from '../SelectPollingDateRange';
import { SIGNAL_VIEWER_DATETIME, DEFAULT_AGGREGATION_OPTIONS } from '../../constants';
import ConfirmationDialog from '../ConfirmationDialog';
import {
  useDateSubscription,
  setPollingDateRange,
  getRangeFromLabel,
  getRangeFromPartial,
} from '../../bundles/application';
import { exportTimeSeriesToFile } from '../../utils';
import { getBestFitGranularity } from '../../datetimeUtils';
import { useEventModal } from '../EventModal';
import { getUserEventTypes } from '../../bundles/events';

const SignalViewerContainer = React.memo(
  ({
    selectedVisualizations,
    pollingDateRange: panelPollingRange,
    onFormReset,
    theme,
    tz,
    site,
  }) => {
    const dispatch = useDispatch();
    const tfrTemplates = useSelector(fetchTfrTemplates);
    const [showNewTemplate, setShowNewTemplate] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const [signalData, setSignalData] = useState({});
    const [currentRequestDetails, setCurrentRequestDetails] = useState({});
    const [initialActiveSignals, setInitialActiveSignals] = useState([]);

    const formattedInitialSignals = selectedVisualizations.map((visualization) =>
      visualization.variables
        .map((variable) => ({
          ...variable,
          visualization_name: visualization.name,
          // Use the first variable's aggregate as default for rest of them in a visualization
          aggregate: visualization.variables[0].aggregate,
        }))
        .slice(0, 10)
    );

    const formattedSignalData = useMemo(() => {
      if (Object.keys(signalData).length) {
        return Object.entries(signalData)
          .map(([signalId, aggs]) =>
            Object.keys(aggs).map((aggregate) => ({
              id: signalId,
              aggregate,
            }))
          )
          .flat();
      }
      return [];
    }, [JSON.stringify(signalData)]);

    const pollingDateRange = useDateSubscription(SIGNAL_VIEWER_DATETIME);
    const variables = useSelector(getSiteVariables);
    const sources = useSelector(getSources);

    const variablesWithSourceType = useMemo(
      () =>
        variables.map((variable) => {
          const variableSource = sources.find((source) => source.id === variable.source_id);
          return {
            ...variable,
            sourceType: variableSource.type || 'unknown',
          };
        }),
      [variables, sources]
    );

    useEffect(() => {
      const body = document.querySelector('body');
      body.classList.add('no-scroll');

      return () => body.classList.remove('no-scroll');
    }, []);

    useEffect(() => {
      if (panelPollingRange) {
        const relativeAdjustedRange = panelPollingRange?.label
          ? getRangeFromLabel(panelPollingRange.label)
          : getRangeFromPartial(panelPollingRange);

        dispatch(setPollingDateRange(relativeAdjustedRange, SIGNAL_VIEWER_DATETIME));
      }
    }, [panelPollingRange]);

    const mergeData = (prevData, newData) => {
      const merged = { ...prevData };
      newData.forEach((dataSet) => {
        merged[dataSet.variable] = prevData[dataSet.variable] || {};
        if (DEFAULT_AGGREGATION_OPTIONS.find((f) => f.value === dataSet.aggregate?.toLowerCase()))
          merged[dataSet.variable][dataSet.aggregate.toLowerCase()] = dataSet.values;
        else merged[dataSet.variable].avg = dataSet.values;
      });
      return merged;
    };

    useEffect(() => {
      const reqVars = formattedSignalData.length
        ? formattedSignalData
        : formattedInitialSignals.flat();
      const req = {
        startDate: pollingDateRange.startDate,
        endDate: pollingDateRange.endDate,
        granularity: getBestFitGranularity(
          (pollingDateRange.endDate - pollingDateRange.startDate) / 1000,
          2500
        ),
        variables: reqVars,
      };
      setCurrentRequestDetails(req);
      setInitialActiveSignals(formattedInitialSignals);
      if (reqVars.length > 0) {
        setShowLoader(true);
        dispatch(
          fetchExtendedSeriesData(req, (data) => {
            if (data.hasError) {
              console.error('Error retrieving data');
              setShowLoader(false);
            } else {
              setSignalData((prevData) => mergeData(prevData, data));
              setShowLoader(false);
            }
          })
        );
      } else {
        setSignalData({});
      }
    }, [pollingDateRange]);

    const onTimelineChange = ([from, to], aggregation, activeVariables, callback) => {
      const req = {
        startDate: from,
        endDate: to,
        aggregation,
        granularity: getBestFitGranularity((to - from) / 1000, 2500),
        variables: activeVariables,
      };
      setCurrentRequestDetails(req);
      setShowLoader(true);
      dispatch(
        fetchExtendedSeriesData(req, (data) => {
          if (!data || data.hasError) {
            console.error('Error retrieving data');
            setShowLoader(false);
          } else {
            setSignalData((prevData) => mergeData(prevData, data));
            setShowLoader(false);
            callback();
          }
        })
      );
    };

    const onCloseModal = useCallback(() => {
      setShowNewTemplate(false);
    });

    const onTemplateActions = useCallback((action) => {
      setTemplateData(action);
      if (action.actionType === 'save') {
        setShowLoader(true);
        const data = {
          site: site.id,
          name: action.name,
          value: { template: action.template },
          org: site.org,
          templateType: 'signal',
        };
        dispatch(
          updateTfrTemplate(action.id, data, () => {
            setShowLoader(false);
          })
        );
      } else {
        setShowNewTemplate(true);
      }
    }, []);

    const [eventDetail, onEventDetail] = useEventModal();

    const userEventTypes = useSelector(getUserEventTypes);
    const onOpenEvent = useCallback(
      (event) => {
        onEventDetail({
          modalType: 'iot',
          event,
          eventId: event.id,
          userEventTypes,
          site,
        });
      },
      [userEventTypes, site]
    );

    const getEvents = useCallback(
      async ({ from, to }) => {
        const events = await services.data
          .getEventBuckets({
            site: site.id,
            from,
            to,
            interval: Math.round((to - from) / 400), // 400 event buckets
            aggregationType: 'time_severity_lists',
          })
          .then(
            (res) => res,
            (e) => {
              console.error('Unable to fetch iot events: ', e);
              dispatch(checkIsOnline());
              dispatch(displayNotification(getNotification('getIotEvents', 'error')()));
              return { values: [] };
            }
          );
        return events.values;
      },
      [site]
    );

    const onDownloadSignalViewer = (activeSignals) => {
      const reqDetails = currentRequestDetails;
      delete reqDetails.variables;
      const seriesData = Object.values(activeSignals).flatMap((x) => {
        return x.map((y) => {
          const variableOfSeries = variables.find((v) => v.id === y[0]);
          return {
            variableName: variableOfSeries.name,
            values: signalData[y[0]][y[1]],
            aggregate: y[1],
            variable: y[0],
          };
        });
      });
      exportTimeSeriesToFile({
        ...reqDetails,
        siteName: site.name,
        seriesData,
        variables,
        exportType: 'xlsx',
        onExportCompleted: () => {},
      });
    };

    return (
      <>
        {selectedVisualizations.length <= 6 && (
          <div className="tfr-view">
            <div className="tfr-view-details">
              {showLoader && (
                <div className="page-loader">
                  <Loader
                    text={'Loading...'}
                    overlay
                  />
                </div>
              )}
              {((selectedVisualizations.length > 0 && initialActiveSignals.length > 0) ||
                selectedVisualizations.length === 0) && (
                <>
                  <SignalViewer
                    initialActiveSignals={initialActiveSignals}
                    signalData={signalData}
                    siteVariables={variablesWithSourceType}
                    initialChartCount={Math.max(selectedVisualizations.length, 3)}
                    pollingDateRange={pollingDateRange}
                    getEventsCallback={getEvents}
                    selectEventCallback={onOpenEvent}
                    onTimelineChange={onTimelineChange}
                    theme={theme}
                    onTemplateActions={onTemplateActions}
                    templateList={tfrTemplates}
                    onDownloadSignalViewer={onDownloadSignalViewer}
                    tz={tz}
                    DateTimePickerComponent={
                      <SelectPollingDateRange
                        enableGlobalDates={false}
                        panelId={SIGNAL_VIEWER_DATETIME}
                      />
                    }
                    aggregationOptions={DEFAULT_AGGREGATION_OPTIONS}
                    loading={showLoader}
                  >
                    <Button
                      icon={<Icon icon="back" />}
                      onClick={onFormReset}
                      activity="secondary"
                      slim
                    >
                      Close
                    </Button>
                  </SignalViewer>
                  {eventDetail}
                </>
              )}
              {showNewTemplate && (
                <NewTemplateModel
                  templateData={templateData}
                  onCloseModal={onCloseModal}
                  tfrTemplates={tfrTemplates}
                  site={site}
                  setShowLoader={setShowLoader}
                />
              )}
            </div>
          </div>
        )}
        {selectedVisualizations.length > 6 && (
          <ConfirmationDialog
            modal={true}
            onCancel={onFormReset}
            onConfirm={onFormReset}
            title="Warning"
            body={
              <>
                <p>
                  Selected visualizations exceeds the supported count. Please select up to 6
                  visualizations.
                </p>
              </>
            }
            cancelText="Dismiss"
            confirmText="Close"
            confirmType="info"
          />
        )}
      </>
    );
  }
);

export default SignalViewerContainer;
