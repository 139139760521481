import { Button, Icon, Spinner, useAuth } from '@avtjs/react-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleModal from '../../../../SimpleModal';
import PermissionLegend from '../../../../PermissionLegend';
import Heading from '../../../../Heading';
import ListItem from '../../../../ListItem';

import {
  createMemberAndConnection,
  getMemberTypes,
  getMembers,
  requestMemberTypes,
  requestMembers,
  deleteMember,
} from '../../../../../bundles/auth';

import CustomRolesModal from './CustomRolesModal';

const RolesTab = () => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const memberTypes = useSelector(getMemberTypes);
  const objectId = `org/${realm}`;
  const scope = { org: realm };
  const members = useSelector((state) => getMembers(state, objectId));
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedRole, setSelectedRole] = useState();

  const admin = memberTypes.find((val) => val.title === 'Site Manager');
  const viewer = memberTypes.find((val) => val.title === 'Viewer');

  const allPermissions = [];

  admin.permissions.forEach((permission) => {
    const basePermission = permission.split('/')[0];
    if (!allPermissions.includes(basePermission)) {
      allPermissions.push(basePermission);
    }
  });

  useEffect(() => {
    dispatch(requestMemberTypes());
    dispatch(requestMembers(`org/${realm}`));
  }, []);

  useEffect(() => {
    if (!members.length) {
      const adminData = {
        type: admin.title,
        objectId,
        permissions: admin.permissions,
        scope,
      };

      const viewerData = { scope, permissions: viewer.permissions, objectId, type: viewer.title };
      dispatch(createMemberAndConnection(adminData));
      dispatch(createMemberAndConnection(viewerData));
    }
  }, [members]);

  const onClose = () => setShowCreateRoleModal(false);

  const onClosePreviewModal = useCallback(() => {
    setShowPreview(false);
    onClose();
  }, [setShowPreview, onClose]);

  const onDeleteRole = useCallback((id) => {
    dispatch(deleteMember(id));
  }, []);

  const headerColumns = ['User role', 'Role type'];

  const getExtraMenuActions = (memberType) => [
    {
      text: 'View',
      action: () => {
        setSelectedRole(memberType);
        setShowPreview(true);
      },
      placement: 'before',
    },
  ];

  const roleList = useMemo(() => {
    if (!members) {
      return (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      );
    }

    return members
      .sort((a, b) => a.custom - b.custom)
      .map((member, i) => (
        <ListItem
          key={member.id}
          itemIndex={i}
          entity={member.type}
          item={member}
          extraMenuActions={getExtraMenuActions(member.type)}
          disableDefaultActions={!!(member.type === 'Site Manager' || member.type === 'Viewer')}
          CustomEditModal={
            <CustomRolesModal
              members={members}
              allPermissions={allPermissions}
              scope={scope}
              objectId={objectId}
              member={member}
              onCloseModal={onClose}
            />
          }
          onDelete={() => onDeleteRole(member.id)}
          confirmationDialogTitle={'Remove integration'}
          columns={[
            <div key={`row-${i}-role`}>{member.type}</div>,
            <div key={`row-${i}-role-type`}>{member.custom ? 'Custom' : 'System'}</div>,
          ]}
          confimationDialogBody={
            <>
              <p style={{ paddingTop: '1.5rem' }}>Are you sure you want to remove this role?</p>
            </>
          }
        />
      ));
  }, [members]);

  const selectedMember = members.find((member) => member.type === selectedRole);

  return (
    <>
      <Heading
        contentRight={
          <Button
            onClick={() => setShowCreateRoleModal(true)}
            className="button-create"
            icon={<Icon icon="abb-plus" />}
          >
            Add role
          </Button>
        }
      />

      {showCreateRoleModal && (
        <CustomRolesModal
          onCloseModal={onClose}
          members={members}
          allPermissions={allPermissions}
          scope={scope}
          objectId={objectId}
        />
      )}

      {showPreview && (
        <SimpleModal
          title={selectedMember && selectedMember.type}
          className="role-permissions-modal"
          size="s"
          onClose={onClosePreviewModal}
        >
          <PermissionLegend selectedMember={selectedMember} />
        </SimpleModal>
      )}

      <div className="list-container">
        <ListItem
          isHeader
          columns={headerColumns}
        />
        {roleList}
      </div>
    </>
  );
};

export default RolesTab;
