import React from 'react';

const notifications = {
  example: {
    /*
     * The following are notification fields which include sample values,
     * as well as any options which they should use (commented after), and a short explanation.
     */
    type: 'persistent', // ['persistent', 'transient(default)', 'actionable']
    status: 'error', // ['info(default)', success', 'warning', 'error'] error status reserved for system critical errors
    title: 'some bold title', // title which shows most prominently
    message: 'some message', // main message text
    extra: 'some extra text', // some sub text, displayed smaller
    id: 'some-error', // if defined, will replace previous notifications with same id, ootherwise random unique is generated
    groupId: 'some-common-id', // if defined will cause all notifications with same groupId to be displayed as a group
  },

  /*
   * QUESTIONS / COMMENTS
   * -----------------------------------------------------------------------------------------------
   * - Many notifications do not have defined messages. We should NOT present the user with the full
   *   error, but how/what should we relay to them? Do we need to give details about the error?
   *   Should we go through endpoints and ensure that we have a standardized Error stucture that we
   *   can get a message from (e.g. Error.message)?
   *
   * - Some messages have been removed as after the action the notification should not need much
   *   info (i.e. it's apparent what entity the notification is for, so a message is not necessary).
   *
   * - In general, notifications for entities that can have bulk actions performed have been given
   *   groupIds, others that might be recurring but are not specific have been given ids based on
   *   the action, while notifications for specific entities use the entity's id.
   */

  // bundles/ad
  // -----------------------------------------------------------------------------------------------

  getOneUser: {
    success: () => ({}), // none - used internally / displayed as badge (events)
    error: () => ({
      title: 'Failed to retrieve user',
      status: 'warning',
      groupId: 'get-user',
    }),
  },

  getUsers: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to retrieve users',
      type: 'persistent',
      status: 'warning',
      groupId: 'get-users',
    }),
  },

  // bundles/algorithms
  // -----------------------------------------------------------------------------------------------

  getAlgorithms: {
    success: () => ({}), // none - displayed as list
    error: () => ({
      title: 'Failed to fetch algorithms',
      type: 'persistent',
      status: 'warning',
    }),
  },

  createAlgorithm: {
    success: (algoName) => ({
      title: 'Algorithm created successfully',
      message: `Created ${algoName}.`,
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to create algorithm',
      type: 'persistent',
      status: 'warning',
      id: 'create-algorithm',
    }),
  },

  updateAlgorithm: {
    success: (algoName) => ({
      title: 'Algorithm updated successfully',
      message: `Updated ${algoName}.`,
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to update algorithm',
      type: 'persistent',
      status: 'warning',
      id: 'update-algorithm',
    }),
  },

  deleteAlgorithm: {
    success: () => ({
      title: 'Algorithm deleted successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to delete algorithm',
      type: 'persistent',
      status: 'warning',
      id: 'delete-algorithm',
    }),
  },

  // bundles/auth
  // -----------------------------------------------------------------------------------------------

  getGrants: {
    success: () => ({}), // none - internally used
    error: () => ({}), // none - should redirect to NoGrantsView
  },

  getMemberTypes: {
    success: () => ({}), // none - internally used
    error: () => ({
      title: 'Failed to fetch member types',
      type: 'persistent',
      message: 'Updating member permissions will not be possible',
      status: 'warning',
      id: 'get-member-types',
    }),
  },

  getMembers: {
    success: () => ({}), // none - rendered as list
    error: () => ({
      title: 'Failed to fetch members',
      type: 'persistent',
      message: 'Site members will not be available',
      status: 'warning',
      id: 'get-members',
    }),
  },

  createMember: {
    success: () => ({}), // none - added member is displayed on creation
    error: () => ({
      title: 'Failed to add site member',
      type: 'persistent',
      status: 'warning',
      id: 'add-member',
    }),
  },

  updateMember: {
    success: (userId) => ({
      title: 'Permissions successfully updated',
      status: 'success',
      id: userId,
    }),
    error: () => ({
      title: 'Failed to update permissions',
      type: 'persistent',
      status: 'warning',
      id: 'update-member',
    }),
  },

  deleteMember: {
    success: (memberId) => ({
      title: 'Role successfully removed',
      status: 'success',
      id: memberId,
    }),
    error: () => ({
      title: 'Failed to remove role',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member',
    }),
  },

  deleteMemberConnection: {
    success: () => ({}), // none - member/role is removed from display
    error: () => ({
      title: 'Failed to remove role',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member-connection',
    }),
  },

  deleteUser: {
    success: (userId) => ({
      title: 'User successfully removed',
      status: 'success',
      id: userId,
    }),
    error: () => ({
      title: 'Failed to remove user',
      type: 'persistent',
      status: 'warning',
      id: 'remove-member',
    }),
  },

  createInvitations: {
    success: (count) => ({
      title: `${count} invitation(s) successfully created`,
      status: 'success',
      id: 'add-invitations',
    }),
    error: () => ({
      title: 'Failed to invite user',
      type: 'persistent',
      status: 'warning',
      id: 'add-invitations',
    }),
  },

  updateInvitation: {
    success: (invitationId) => ({
      title: 'Invitation successfully updated',
      status: 'success',
      id: invitationId,
    }),
    error: () => ({
      title: 'Failed to update invitation',
      type: 'persistent',
      status: 'warning',
      id: 'update-invitation',
    }),
  },

  updateInvitations: {
    success: () => ({
      title: 'Invitations successfully updated',
      status: 'success',
      id: 'update-invitations',
    }),
    error: () => ({
      title: 'Failed to update invitations',
      type: 'persistent',
      status: 'warning',
      id: 'update-invitations',
    }),
  },

  deleteInvitation: {
    success: () => ({}), // none - invitation is removed from display
    error: () => ({
      title: 'Failed to remove invitation',
      type: 'persistent',
      status: 'warning',
      id: 'remove-invitation',
    }),
  },

  authorizeUser: {
    success: () => ({}), // none - user receives permissions, enters site
    error: () => ({
      title: 'Failed to authorize user',
      type: 'persistent',
      status: 'error',
      id: 'authorize-user',
    }),
  },

  getServiceAccounts: {
    success: () => ({}), // none - rendered as list
    error: () => ({
      title: 'Failed to fetch service accounts',
      type: 'persistent',
      status: 'warning',
      id: 'get-service-accounts',
    }),
  },

  transferServiceAccounts: {
    success: () => ({
      title: `Service account successfully transferred`,
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to transfer service account',
      type: 'persistent',
      status: 'warning',
      id: 'transfer-service-accounts',
    }),
  },

  // bundles/bookmarks
  // -----------------------------------------------------------------------------------------------

  deleteBookmark: {
    success: (fileName, bookmarkId) => ({
      title: 'Bookmark deleted successfully',
      message: fileName,
      status: 'success',
      id: bookmarkId,
    }),
    error: (fileName, bookmarkId) => ({
      title: 'Failed to delete bookmark',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: bookmarkId,
    }),
  },

  createBookmark: {
    success: (fileName, bookmarkId) => ({
      title: 'Bookmark created successfully',
      message: fileName,
      status: 'success',
      id: bookmarkId,
    }),
    error: () => ({
      title: 'Failed to create bookmark',
      type: 'persistent',
      status: 'warning',
      id: 'create-bookmark',
    }),
  },

  updateBookmark: {
    success: (fileName, bookmarkId) => ({
      title: 'Bookmark updated successfully',
      message: fileName,
      status: 'success',
      id: bookmarkId,
    }),
    error: () => ({
      title: 'Failed to update bookmark',
      type: 'persistent',
      status: 'warning',
      id: 'update-bookmark',
    }),
  },

  // bundles/components
  // -----------------------------------------------------------------------------------------------

  getComponentsStatus: {
    success: () => ({}), // none - used internally / displayed as badge
    error: () => ({
      title: 'Failed to fetch component health',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-component-health',
    }),
  },

  integratingComponents: {
    success: () => ({
      title: 'Components integrated',
      mesage: 'Dummy message',
      type: 'persistent',
      status: 'success',
    }),
    error: () => ({
      title: 'Components integration failed',
      type: 'persistent',
      status: 'error',
      id: 'integrating-components',
    }),
  },

  getComponents: {
    success: () => ({}), // none - used internally
    error: () => ({
      // caught in requestComps together with getSiteComponentTree
      title: 'Failed to fetch components',
      type: 'persistent',
      status: 'error',
      id: 'fetch-components',
    }),
  },

  createComponent: {
    success: (name, itemDesignation) => ({
      title: 'Component created successfully',
      message: `${name} - ${itemDesignation}`,
      status: 'success',
    }),
    error: (name) => ({
      title: 'Failed to create component',
      message: name,
      type: 'persistent',
      status: 'warning',
      id: 'create-component',
    }),
  },

  updateComponent: {
    success: (name, itemDesignation, compId) => ({
      title: 'Component updated successfully',
      message: `${name} - ${itemDesignation}`,
      status: 'success',
      id: compId,
    }),
    error: (name, itemDesignation, compId) => ({
      title: 'Failed to update component',
      message: 'Check your internet connection and try again.',
      extra: `${name} - ${itemDesignation}`,
      type: 'persistent',
      status: 'warning',
      id: compId,
    }),
  },

  deleteComponent: {
    success: (name, itemDesignation, compId) => ({
      title: 'Component deleted successfully',
      message: `${name} - ${itemDesignation}`,
      status: 'success',
      id: compId,
    }),
    error: (name, itemDesignation, compId) => ({
      title: 'Failed to delete component',
      message: `${name} - ${itemDesignation}`,
      type: 'persistent',
      status: 'warning',
      id: compId,
    }),
  },

  deleteNonSiteComponents: {
    success: () => ({
      title: 'Components deleted successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to delete components',
      type: 'persistent',
      status: 'warning',
    }),
  },

  createVariable: {
    success: (varName) => ({
      title: 'Signal created successfully',
      message: varName,
      status: 'success',
    }),
    error: (varName) => ({
      title: 'Failed to create signal',
      message: varName,
      type: 'persistent',
      status: 'warning',
      id: 'create-variable',
    }),
  },

  updateVariable: {
    success: (varName, varId) => ({
      title: 'Signal updated successfully',
      message: varName,
      status: 'success',
      id: varId,
    }),
    error: (varName, varId) => ({
      title: 'Failed to update signal',
      message: varName,
      type: 'persistent',
      status: 'warning',
      id: varId,
    }),
  },

  deleteVariable: {
    success: (varId) => ({
      title: 'Signal deleted successfully',
      status: 'success',
      id: varId,
    }),
    error: (varId) => ({
      title: 'Failed to delete signal',
      type: 'persistent',
      status: 'warning',
      id: varId,
    }),
  },

  uploadCompExcel: {
    success: () => ({
      title: 'Components created successfully',
      status: 'success',
      id: 'comps-import-success',
    }),
    error: () => ({
      title: 'Failed to import components',
      message: 'Make sure template is used when adding components.',
      status: 'warning',
      type: 'persistent',
      id: 'comps-import-failure',
    }),
  },

  downloadCompExcel: {
    success: () => ({}), // none - downloads...
    error: () => ({
      title: 'Failed to download components template',
      status: 'warning',
      type: 'persistent',
      id: 'comps-export-failure',
    }),
  },

  // bundles/events
  // -----------------------------------------------------------------------------------------------

  getEventTypes: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to fetch user event types',
      type: 'persistent',
      status: 'warning',
      id: 'get-user-events',
    }),
  },

  injectEvents: {
    pre: (fileName) => ({
      title: 'File upload started',
      message: fileName,
    }),
    partialSuccess: (numEvents, errors) => {
      const moreErrors = errors.length > 1 ? ` ...${errors.length - 1} more` : '';
      return {
        title: 'Encountered errors while adding events',
        message: `Successfully added ${numEvents} events, with ${errors.length} errors`,
        extra: `${errors[0].message}${moreErrors}`,
        type: 'persistent',
        status: 'warning',
      };
    },
    success: (numEvents) => ({
      title: 'File upload completed',
      message: `Successfully added ${numEvents} events`,
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to add events',
      type: 'persistent',
      status: 'warning',
    }),
  },

  exportEvents: {
    success: (exportType) => ({
      title: `${exportType} exported successfully`,
      message: '',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to export events',
      type: 'persistent',
      status: 'warning',
      id: 'export-events',
    }),
  },

  // bundles/files
  // -----------------------------------------------------------------------------------------------

  deleteFile: {
    success: (fileName) => ({
      title: 'File deleted successfully',
      message: fileName,
      status: 'success',
    }),
    error: (fileName, fileId) => ({
      title: 'Failed to delete file',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  getFiles: {
    success: () => ({}), // none - used internally / files displayed
    error: () => ({
      title: 'Failed to fetch files',
      type: 'persistent',
      status: 'warning',
      id: 'get-files',
    }),
  },

  createFile: {
    pre: (description, fileId) => ({
      title: description,
      message: '',
      id: fileId,
    }),
    success: (fileName, fileId) => ({
      title: 'File upload completed',
      message: fileName,
      status: 'success',
      id: fileId,
    }),
    size: (uploaded, total) => ({
      title: `${uploaded} out of ${total} documents have been uploaded successfully`,
      message: '',
      status: 'success',
    }),
    error: (fileName, fileId, existingFile) => ({
      title: 'File upload failed',
      message: existingFile ? `File with name ${fileName} already exists` : fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  // called when creating/updating files
  filesCreateTag: {
    success: () => ({}), // none - part of file create/update flow
    error: (operation) => ({
      title: `Failed to ${operation} files`,
      message: 'Tag creation failed.',
      type: 'persistent',
      status: 'warning',
      id: `${operation}-files-tag-creation`,
    }),
  },

  updateFile: {
    pre: (fileName, fileId) => ({
      title: 'File update in progress',
      message: fileName,
      id: fileId,
    }),
    success: (fileName, fileId) => ({
      title: 'File updated successfully',
      message: fileName,
      status: 'success',
      id: fileId,
    }),
    error: (fileName, fileId) => ({
      title: 'Failed to update file',
      message: fileName,
      type: 'persistent',
      status: 'warning',
      id: fileId,
    }),
  },

  getZippedFiles: {
    success: () => ({}), // none - download starts
    partialSuccess: (numNotFound, listNotFound) => ({
      // some files missing
      title: 'Failed to download all files',
      message: `The following file${numNotFound > 1 ? 's were' : ' was'} not found`,
      extra: listNotFound,
      type: 'persistent',
      status: 'warning',
      id: 'file-download',
    }),
    error: () => ({
      title: 'Failed to download files',
      type: 'persistent',
      status: 'warning',
      id: 'file-download',
    }),
  },

  // bundles/geometry-mappings
  // -----------------------------------------------------------------------------------------------

  getMappings: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to fetch geometry mapping data',
      type: 'persistent',
      status: 'warning',
      id: 'request-mappings',
    }),
  },

  createMapping: {
    success: (modelId) => ({
      title: 'Geometry mapping created successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to create geometry mapping',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  clearMappings: {
    success: (modelId) => ({
      title: 'Geometry mappings cleared successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to clear geometry mappings',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  updateMapping: {
    success: (modelId) => ({
      title: 'Geometry mapping updated successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to update geometry mapping',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  deleteMapping: {
    success: (modelId) => ({
      title: 'Geometry mapping deleted successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to delete geometry mapping',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  getGeometries: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to fetch geometry data',
      type: 'persistent',
      status: 'warning',
      id: 'request-mappings',
    }),
  },

  createGeometry: {
    success: (modelId) => ({
      title: 'Geometries updated successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to update geometries',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  deleteGeometry: {
    success: (modelId) => ({
      title: 'Geometry deleted successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to delete geometry',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },
  // bundles/integrations
  // --------------------------------------------------------------------------------------------

  getIntegrations: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to fetch integrations',
      type: 'persistent',
      status: 'warning',
      id: 'request-integrations',
    }),
  },

  createIntegration: {
    success: (message, integrationId, template = false) => ({
      title: 'Integration created successfully',
      type: template ? 'transient' : 'persistent',
      message,
      status: 'success',
      id: integrationId,
    }),
    error: (integrationName) => ({
      title: 'Failed to create integration',
      message: integrationName,
      type: 'persistent',
      status: 'warning',
      id: 'create-integration',
    }),
  },

  updateIntegration: {
    success: (message, integrationId, template = false) => ({
      title: 'Integration updated successfully',
      type: template ? 'transient' : 'persistent',
      message,
      status: 'success',
      id: integrationId,
    }),
    error: (integrationName, integrationId) => ({
      title: 'Failed to update integration',
      message: integrationName,
      type: 'persistent',
      status: 'warning',
      id: integrationId,
    }),
  },

  deleteIntegration: {
    success: (integrationId) => ({
      title: 'Integration deleted successfully',
      status: 'success',
      id: integrationId,
    }),
    error: (integrationId) => ({
      title: 'Failed to delete integration',
      type: 'persistent',
      status: 'warning',
      id: integrationId,
    }),
  },

  syncAssets: {
    success: (message, integrationId) => ({
      title: 'Integrated assets synced successfully',
      message,
      status: 'success',
      id: integrationId,
    }),
    error: (integrationId) => ({
      title: 'Failed to sync integrated assets',
      message: 'Resync to try again or contact your tenant administrator.',
      type: 'persistent',
      status: 'warning',
      id: integrationId,
    }),
  },

  createRemoteEvent: {
    success: (name, remoteId) => ({
      title: 'Remote event created successfully',
      message: remoteId,
      status: 'success',
      id: remoteId,
    }),
    error: (name) => ({
      title: 'Failed to create remote event',
      type: 'persistent',
      status: 'warning',
      id: `create-${name}`,
    }),
  },

  // bundles/layouts
  // --------------------------------------------------------------------------------------------

  saveLayout: {
    success: (layoutId) => ({
      title: 'Layout updated successfully',
      status: 'success',
      id: layoutId,
    }),
    error: (layoutId) => ({
      title: 'Failed to update layout',
      type: 'persistent',
      status: 'warning',
      id: layoutId,
    }),
  },

  // bundles/models
  // --------------------------------------------------------------------------------------------

  getModels: {
    success: () => ({}), // none - used internally / list display
    error: () => ({
      title: 'Failed to fetch models',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-models',
    }),
  },

  createModel: {
    success: (modelName) => ({
      title: 'Model created successfully',
      message: modelName,
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to create model',
      type: 'persistent',
      status: 'warning',
      id: 'create-model',
    }),
  },

  addModelVersion: {
    success: () => ({
      title: 'Model version added successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to add model version',
      type: 'persistent',
      status: 'warning',
      id: 'create-model-version',
    }),
  },

  updateModel: {
    success: (modelName, modelId) => ({
      title: 'Model updated successfully',
      message: modelName,
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to update model',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  deleteModel: {
    success: (modelName, modelId) => ({
      title: 'Model deleted successfully',
      message: modelName,
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to delete model',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  updateSceneConfig: {
    success: (modelId) => ({
      title: 'Scene config updated successfully',
      status: 'success',
      id: modelId,
    }),
    error: (modelId) => ({
      title: 'Failed to update scene config',
      type: 'persistent',
      status: 'warning',
      id: modelId,
    }),
  },

  // bundles/sites
  // -----------------------------------------------------------------------------------------------

  getSites: {
    success: () => ({}), // none - used internally / list display
    error: () => ({
      title: 'Failed to fetch sites',
      type: 'persistent',
      status: 'warning',
    }),
  },

  getThumbnail: {
    success: () => ({}), // none - displayed
    error: () => ({}), // none - silent fail, default letter used
  },

  createSite: {
    success: () => ({}), // none - redirects to created site
    error: () => ({
      title: 'Encountered an error while creating site',
      type: 'persistent',
      status: 'warning',
    }),
  },

  updateSite: {
    success: (siteId) => ({
      title: 'Site updated successfully',
      status: 'success',
      id: siteId,
    }),
    error: (siteId) => ({
      title: 'Failed to update site',
      type: 'persistent',
      status: 'warning',
      id: siteId,
    }),
  },

  deleteSite: {
    success: (siteId) => ({
      title: 'Site deleted successfully',
      status: 'success',
      id: siteId,
    }),
    error: (siteId) => ({
      title: 'Failed to delete site',
      type: 'persistent',
      status: 'warning',
      id: siteId,
    }),
  },

  importSites: {
    success: () => ({
      title: 'Sites created successfully',
      status: 'success',
      id: 'sites-import-success',
    }),
    error: () => ({
      title: 'Failed to import sites',
      message: 'Make sure template is used and filled in correctly when adding sites.',
      type: 'persistent',
      status: 'warning',
      id: 'sites-import-error',
    }),
  },

  exportSites: {
    success: () => ({}), // none - template downloaded
    error: () => ({
      title: 'Failed to download sites template',
      type: 'persistent',
      status: 'warning',
      id: 'sites-export-error',
    }),
  },

  requestTfrTemplates: {
    success: () => ({}),
    error: () => ({
      title: 'Failed to fetch templates',
      type: 'persistent',
      status: 'warning',
    }),
  },

  addTfrTemplates: {
    success: () => ({
      title: 'Template added successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to add template',
      type: 'persistent',
      status: 'warning',
    }),
  },

  updateTfrTemplates: {
    success: () => ({
      title: 'Template updated successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to update template',
      type: 'persistent',
      status: 'warning',
    }),
  },

  deleteTfrTemplate: {
    success: () => ({
      title: 'Template deleted successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to delete template',
      type: 'persistent',
      status: 'warning',
    }),
  },
  // bundles/sources
  // -----------------------------------------------------------------------------------------------

  getSources: {
    success: () => ({}), // none - list display
    error: () => ({
      title: 'Failed to fetch sources',
      type: 'persistent',
      status: 'warning',
      id: 'get-sources',
    }),
  },

  deleteSource: {
    success: (sourceId) => ({
      title: 'Source deleted successfully',
      status: 'success',
      id: sourceId,
    }),
    error: (sourceId) => ({
      title: 'Failed to delete source',
      type: 'persistent',
      status: 'warning',
      id: sourceId,
    }),
  },

  createSource: {
    success: () => ({
      title: 'Source created successfully',
      status: 'success',
    }),
    error: (message) => ({
      title: 'Failed to create source',
      message,
      type: 'persistent',
      status: 'warning',
      id: 'create-source',
    }),
  },

  importSourceVars: {
    success: (message) => ({
      title: 'Variables imported successfully',
      message,
      status: 'success',
      id: 'import-source-components',
    }),
    error: (message) => ({
      title: 'Failed to import source signals',
      message,
      type: 'persistent',
      status: 'warning',
      id: 'create-source',
    }),
  },

  updateSource: {
    success: (sourceId) => ({
      title: 'Source updated successfully',
      status: 'success',
      id: sourceId,
    }),
    error: (sourceId) => ({
      title: 'Failed to update source',
      type: 'persistent',
      status: 'warning',
      id: sourceId,
    }),
  },

  getSourceSyncHealth: {
    success: () => ({}), // none - used internally / displayed
    error: () => ({
      title: 'Failed to fetch source sync health',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-source-sync-health',
    }),
  },

  connectivity: {
    success: () => ({}), // none - used internally / displayed
    error: () => ({
      title: 'Failed to fetch source connectivity',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-source-connectivity',
    }),
  },

  // bundles/statesets
  // -----------------------------------------------------------------------------------------------

  getStateSets: {
    success: () => ({}), // none - list display
    error: () => ({
      title: 'Failed to fetch state sets',
      type: 'persistent',
      status: 'warning',
      id: 'get-stateset',
    }),
  },

  saveStateSet: {
    success: () => ({
      title: 'State set created successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to create stateset',
      type: 'persistent',
      status: 'warning',
      id: 'create-state',
    }),
  },

  updateStateSet: {
    success: (statesetId) => ({
      title: 'Stateset updated successfully',
      status: 'success',
      id: statesetId,
    }),
    error: (statesetId) => ({
      title: 'Failed to update stateset',
      type: 'persistent',
      status: 'warning',
      id: statesetId,
    }),
  },

  deleteStateSet: {
    success: (statesetId) => ({
      title: 'Stateset deleted successfully',
      status: 'success',
      id: statesetId,
    }),
    error: (statesetId) => ({
      title: 'Failed to delete stateset',
      type: 'persistent',
      status: 'warning',
      id: statesetId,
    }),
  },

  // bundles/tags
  // -----------------------------------------------------------------------------------------------

  getTags: {
    success: () => ({}), // none - used internally / displayed
    error: () => ({
      title: 'Failed to fetch tags',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-tags',
    }),
  },

  createTag: {
    success: () => ({}), // none - displayed (selected)
    excluded: (tagName) => ({
      title: 'Failed to create tag',
      message: `${tagName} already exists.`,
      type: 'persistent',
      status: 'warning',
      id: 'create-tag',
    }),
    error: () => ({
      title: 'Failed to create tag',
      type: 'persistent',
      status: 'warning',
      id: 'create-tag',
    }),
  },

  updateTag: {
    success: (tagId) => ({
      title: 'Tag updated successfully',
      status: 'success',
      id: tagId,
    }),
    error: (tagId) => ({
      title: 'Failed to update tag',
      type: 'persistent',
      status: 'warning',
      id: tagId,
    }),
  },

  deleteTag: {
    success: (tagId) => ({
      title: 'Tag deleted successfully',
      status: 'success',
      id: tagId,
    }),
    error: (tagId) => ({
      title: 'Failed to delete tag',
      type: 'persistent',
      status: 'warning',
      id: tagId,
    }),
  },

  // bundles/tasks
  // -----------------------------------------------------------------------------------------------

  getJobs: {
    success: () => ({}), // none - list display
    error: () => ({
      title: 'Failed to fetch jobs',
      type: 'persistent',
      status: 'warning',
      id: 'get-jobs',
    }),
  },

  getTasks: {
    success: () => ({}), // none - list display
    error: () => ({
      title: 'Failed to fetch tasks',
      type: 'persistent',
      status: 'warning',
      id: 'get-tasks',
    }),
  },

  createTask: {
    success: () => ({
      title: 'Task created successfully',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to create task',
      type: 'persistent',
      status: 'warning',
      id: 'create-task',
    }),
  },

  updateTask: {
    success: (taskId) => ({
      title: 'Task updated successfully',
      status: 'success',
      id: taskId,
    }),
    error: (taskId) => ({
      title: 'Failed to update task',
      type: 'persistent',
      status: 'warning',
      id: taskId,
    }),
  },

  deleteTask: {
    success: (taskId) => ({
      title: 'Task deleted successfully',
      status: 'success',
      id: taskId,
    }),
    error: (taskId) => ({
      title: 'Failed to delete task',
      type: 'persistent',
      status: 'warning',
      id: taskId,
    }),
  },

  // bundles/types
  // -----------------------------------------------------------------------------------------------

  getTypes: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to fetch types',
      type: 'persistent',
      status: 'warning',
      id: 'get-types',
    }),
  },

  // bundles/uploads
  // -----------------------------------------------------------------------------------------------

  uploadModelVersion: {
    success: () => ({
      title: 'New model version uploaded successfully',
      status: 'success',
      id: 'model-version-upload',
    }),
    error: () => ({
      title: 'Failed to upload new model version',
      type: 'persistent',
      status: 'warning',
      id: 'model-version-upload',
    }),
  },

  // bundles/variables
  // -----------------------------------------------------------------------------------------------

  getLastVarValues: {
    success: () => ({}), // none - used internally / displays
    error: (message) => ({
      title: 'Failed to fetch component signal data',
      message,
      type: 'persistent',
      status: 'warning',
      id: 'fetch-variable-data',
    }),
  },

  getExtendedSeries: {
    success: () => ({}), // none - used internally / displays
    error: (message) => ({
      title: 'Failed to fetch extended signal data',
      message,
      type: 'persistent',
      status: 'warning',
      id: 'get-extended-variable-data',
    }),
  },

  getVariables: {
    success: () => ({}), // none - used internally / displays
    error: () => ({
      title: 'Failed to fetch signal data',
      type: 'persistent',
      status: 'warning',
      id: 'fetch-variables',
    }),
  },

  uploadVarsExcel: {
    success: () => ({
      title: 'Signals created successfully',
      status: 'success',
      id: 'vars-import-success',
    }),
    error: () => ({
      title: 'Failed to import signals',
      message: 'Make sure template is used when adding components.',
      status: 'warning',
      type: 'persistent',
      id: 'vars-import-failure',
    }),
  },

  downloadVarsExcel: {
    success: () => ({}), // none - downloads...
    error: () => ({
      title: 'Failed to download signals template',
      status: 'warning',
      type: 'persistent',
      id: 'vars-export-failure',
    }),
  },

  // bundles/visualizations
  // -----------------------------------------------------------------------------------------------

  saveVisualization: {
    success: () => ({
      title: 'Visualization saved successfully',
      extra: 'Due to search filters, it may not be visible',
      status: 'success',
    }),
    error: () => ({
      title: 'Failed to save visualization',
      type: 'persistent',
      status: 'warning',
      id: 'save-visualization',
    }),
  },

  deleteVisualization: {
    success: (vizId) => ({
      title: 'Visualization deleted successfully',
      status: 'success',
      id: vizId,
    }),
    error: (vizId) => ({
      title: 'Failed to delete visualization',
      type: 'persistent',
      status: 'warning',
      id: vizId,
    }),
  },

  getVisualizations: {
    success: () => ({}), // none - used internally / list display
    error: (message) => ({
      title: 'Failed to fetch visualizations',
      message,
      type: 'persistent',
      status: 'warning',
      id: 'fetch-visualizations',
    }),
  },

  getVisualization: {
    success: () => ({}), // none - used internally / displays
    error: (vizId, message) => ({
      title: 'Failed to fetch visualization',
      type: 'persistent',
      status: 'warning',
      message,
      id: vizId,
    }),
  },

  // Shared
  // -----------------------------------------------------------------------------------------------

  getComments: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch event comments',
      type: 'persistent',
      status: 'warning',
      id: 'get-event-comments',
    }),
  },

  // Gets comment creator details. Errors handled silently.
  getOneUserLimited: {
    success: () => ({}),
    error: () => ({}),
  },

  addEventFile: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to add file',
      type: 'persistent',
      status: 'warning',
      id: 'add-event-file',
    }),
  },

  // NotFound errors are handled silently, others trigger a notification but are ignored
  getEventFile: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch file',
      type: 'transient',
      status: 'warning',
      groupId: 'get-event-file',
    }),
  },

  getIotEvents: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch IoT event data',
      type: 'persistent',
      status: 'warning',
      groupId: 'get-iot-events',
    }),
  },

  // components/EventModal/CommentForm/CommentForm.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getComments, addEventFile, getOneUserLimited

  /*
   * This call handles including event files as related files in file preview.
   * NotFound errors are handled silently
   */
  getCommentFiles: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch comment files',
      type: 'persistent',
      status: 'warning',
      id: 'get-comment-files',
    }),
  },

  /*
   * Save comment chain: create > save added files > update
   * services calls to: createComment, createFile, updateComment
   * Single notification to handle any errors during save.
   */
  saveComment: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to save comment',
      type: 'persistent',
      status: 'warning',
      id: 'save-event-comment',
    }),
  },

  deleteComment: {
    success: () => ({}), // none - removed
    error: () => ({
      title: 'Failed to delete comment',
      type: 'persistent',
      status: 'warning',
      id: 'delete-event-comment',
    }),
  },

  // components/EventModal/EditUserEventModal.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getEventFiles

  // Update files chain: create and/or delete > update (remove context) > update (add context)
  updateEventFiles: {
    success: () => ({}), // none - used internally
    error: () => ({
      title: 'Failed to update files',
      type: 'transient',
      status: 'warning',
      id: 'update-event-files',
    }),
  },

  saveEvent: {
    success: (operator, id, descriptor = '') => ({
      title: `Event${descriptor ? ' ' : ''}${descriptor} ${operator}${
        operator.endsWith('e') ? 'd' : 'ed'
      } successfully`,
      status: 'success',
      id,
    }),
    error: (operator, id, descriptor = '') => ({
      title: `Failed to ${operator} event${descriptor ? ' ' : ''}${descriptor}`,
      type: 'persistent',
      status: 'warning',
      id,
    }),
  },

  // components/EventModal/UserEventModal.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getEventFiles, getOneUserLimited, getComments

  // requestEvent, gets and updates event details if clicking on related event
  getEvent: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch event',
      type: 'transient',
      status: 'warning',
      id: 'get-event',
    }),
  },

  eventState: {
    eventNotification: ({ todoCount, overdueCount, timeStamp }) => {
      const todoMessage = todoCount
        ? ` ${todoCount} To-Do event${todoCount > 1 ? 's' : ''} in event timeline.`
        : '';
      const overdueMessage = overdueCount
        ? ` ${overdueCount} Overdue event${overdueCount > 1 ? 's' : ''} in event timeline.`
        : '';

      return {
        title: (
          <div>
            <div>{todoMessage}</div>
            <div>{overdueMessage}</div>
          </div>
        ),
        message: timeStamp,
        status: `${overdueCount ? 'event-warning' : 'event-info'}`,
        type: 'persistent',
        id: timeStamp,
      };
    },
    error: () => ({
      title: 'Failed to fetch event',
      type: 'transient',
      status: 'warning',
      id: 'get-event-count',
    }),
  },

  deleteEvent: {
    success: (eventId) => ({
      title: 'Event deleted successfully',
      status: 'success',
      eventId,
    }),
    error: (eventId) => ({
      title: 'Failed to delete event',
      type: 'persistent',
      status: 'warning',
      eventId,
    }),
  },

  // NotFound errors are handled silently, others trigger a notification but are ignored
  getCommentFile: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch comment file',
      type: 'transient',
      status: 'warning',
      groupId: 'get-comment-file',
    }),
  },

  // components/ModelView/components/ModelEditModal/ModelEditModal.js,
  // components/panels/ViewerPanel/ViewerPanel.js
  // -----------------------------------------------------------------------------------------------

  getServiceToken: {
    success: () => ({}), // none
    error: () => ({}), // none - handled in viewer
  },

  // components/panels/EventTimelinePanel/EventTimelineState.js
  // --------------------------------------------------------
  // From shared (above): getIotEvents

  getUserEvents: {
    success: () => ({}), // none - displayed
    error: () => ({
      title: 'Failed to fetch user event data',
      type: 'persistent',
      status: 'warning',
      groupId: 'get-user-events',
    }),
  },

  // components/panels/EventTimelinePanel/UserTimeline.js,
  // components/panels/EventTimelinePanel/UserEventStatus.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getComments

  // components/panels/TFRPanel/TFRPanel.js
  // -----------------------------------------------------------------------------------------------
  // From shared (above): getIotEvents

  // components/TagSelect/index.js
  // -----------------------------------------------------------------------------------------------
  // From bundles/tags (above): createTag

  // components/FilePreviewModal/FilePreviewModal.js
  // -----------------------------------------------------------------------------------------------

  filePreviewDownload: {
    success: () => ({}), // none - downloaded
    error: () => ({
      title: 'Failed to download file',
      type: 'persistent',
      status: 'warning',
      id: 'file-preview-download',
    }),
  },

  // components/ModelView/components/VersionColumnItem/VersionColumnItem.js
  // -----------------------------------------------------------------------------------------------
  // From bundles/uploads (above): uploadModel

  // model version upload (notfication handled outside bundle)
  modelVersionUpload: {
    success: () => ({
      title: 'New model version uploaded successfully',
      status: 'success',
      id: 'model-version-upload',
    }),
    error: () => ({
      title: 'Failed to upload new model version',
      type: 'persistent',
      status: 'warning',
      id: 'model-version-upload',
    }),
  },

  uploadAOExcel: {
    success: () => ({
      title: 'Imported Event Analyzer file successfully',
      status: 'success',
      id: 'ao-import-success',
    }),
    warning: () => ({
      title: 'File has invalid columns. Please check and upload again!',
      status: 'warning',
      type: 'persistent',
      id: 'ao-import-warning',
    }),
    error: () => ({
      title: 'Failed to import Event Analyzer file',
      status: 'warning',
      type: 'persistent',
      id: 'ao-import-failure',
    }),
  },
};

const getNotification = (type, status) => notifications[type][status];

export default getNotification;
