import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button, Icon, Spinner } from '@avtjs/react-components';

import {
  downloadVariablesExcel,
  uploadVariablesExcel,
  setImportErrors,
  getImportErrors,
  getVariablesImportedAt,
} from '../../../../../../../bundles/variables';
import Heading from '../../../../../../Heading';
import ConfirmationDialog from '../../../../../../ConfirmationDialog';
import InstructionalModal from '../../../../../../InstructionalModal';

const ImportStep = ({ site, onNext }) => {
  const dispatch = useDispatch();
  const varsImported = useSelector(getVariablesImportedAt);
  const importErrors = useSelector(getImportErrors);
  const fullScreenRef = useRef(null);

  const [importing, setImporting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);

  const onDropAccepted = ([file]) => {
    setImporting(true);
    dispatch(uploadVariablesExcel(file, site.id, site.org, site.template));
  };

  const onDropRejected = () => {
    dispatch(setImportErrors(['Imports should be from a single Excel file.']));
  };

  const { getInputProps, getRootProps } = useDropzone({
    disabled: importing,
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDropAccepted,
    onDropRejected,
  });

  useEffect(() => {
    if (importing && varsImported) {
      setImporting(false);
      onNext();
    }
  }, [importing, varsImported]);

  useEffect(() => {
    if (importErrors.length) {
      setImporting(false);
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [importErrors]);

  return (
    <>
      <div className="variable-import-step">
        <Heading
          contentLeft={<div className="title">Create signals and visualizations from an Excel</div>}
          large
        />
        <div className="variable-import-step__button-row">
          <Button
            onClick={() => dispatch(downloadVariablesExcel(site))}
            activity="secondary"
            design="text"
            icon={
              <div className="icon-green">
                <Icon icon="abb-document-excel" />
              </div>
            }
          >
            Export signal & visualization template
          </Button>
          <div className="variable-import-step__info-trigger">
            <Icon icon="abb-help-circle-1" />
            Excel info
          </div>
          <div className="variable-import-step__info-tooltip">
            <p>
              {'The downloaded excel allows for the creation of new signals ' +
                'and will not overwrite any existing signals.'}
            </p>
            <p>
              {'Signal visualizations can be optionally configured, and signals ' +
                'may be repeated in order to add different visualization configurations.'}
            </p>
            <p>{'Similarly, different signals can be added to the same visualization (name).'}</p>
          </div>
        </div>
        <div
          className="variable-import-step__drop-zone"
          {...getRootProps()}
        >
          <input
            name="files"
            {...getInputProps()}
          />
          {importing ? (
            <Spinner />
          ) : (
            <div>
              <Icon
                icon="abb-upload"
                size="l"
              />
              <p>{'Drag & drop signals excel here, or click to select files'}</p>
            </div>
          )}
        </div>
        <Button
          onClick={() => setShowInstruction(true)}
          activity="secondary"
          design="text"
          icon={
            <div className="icon-wiz-color">
              <Icon icon="abb-information-circle-1" />
            </div>
          }
        >
          How to prepare a signals excel
        </Button>
        {showInstruction && (
          <InstructionalModal
            fullScreenRef={fullScreenRef}
            onClose={() => setShowInstruction(false)}
            identifier="wiz-var-import"
            title="How to prepare a signals excel"
          />
        )}
      </div>
      {showErrors ? (
        <ConfirmationDialog
          onConfirm={() => dispatch(setImportErrors([]))}
          onCancel={() => dispatch(setImportErrors([]))}
          showCancel={false}
          title="Import errors"
          subtitle="The following errors occurred while attempting import:"
          body={importErrors.map((e, i) => (
            <p key={`err-${i}`}>{e}</p>
          ))}
          className="setup-wizard-errors"
        />
      ) : null}
    </>
  );
};

export default ImportStep;
