import colors from '../../../../colors';
import { THRESHOLD_TYPES } from '../../../../constants';

const thresholdOptions = {
  [THRESHOLD_TYPES.linear.value]: {
    value: {
      type: 'integer',
      title: 'Value',
      description: 'Value where the threshold should end.',
      default: 100,
    },
  },
  [THRESHOLD_TYPES.area.value]: {
    min: {
      type: 'integer',
      title: 'Min value',
      description: 'Value where the threshold should start.',
      default: 1,
    },
    max: {
      type: 'integer',
      title: 'Max value',
      description: 'Value where the threshold should end.',
      default: 50,
    },
  },
};

const thresholdSchema = {
  thresholds: {
    title: 'Thresholds',
    type: 'object',
    properties: {
      thresholdType: {
        type: 'string',
        title: 'Thresholds type',
        default: THRESHOLD_TYPES.linear.value,
        oneOf: Object.values(THRESHOLD_TYPES).map((item) => ({
          const: item.value,
          title: item.name,
        })),
      },
    },
    dependencies: {
      thresholdType: {
        // It doens't work if properties.x is them same x for all THRESHOLD_TYPES.
        // So prefix property with thresholdType. eg linearItems, areaItems, etc.
        oneOf: Object.values(THRESHOLD_TYPES).map(({ key, value: type }) => ({
          properties: {
            thresholdType: { enum: [type] },
            [key]: {
              type: 'array',
              title: '',
              default: [],
              items: {
                type: 'object',
                properties: {
                  fill: {
                    type: 'string',
                    title: 'Fill',
                    default: colors[0].value,
                    oneOf: colors.map((color) => ({
                      const: color.value,
                      title: color.label,
                    })),
                  },
                  description: {
                    type: 'string',
                    title: 'Description',
                    description: 'Description for the threshold.',
                    default: '',
                  },
                  ...thresholdOptions[type],
                },
              },
            },
          },
        })),
      },
    },
  },
};

export default thresholdSchema;
