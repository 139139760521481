import { units } from '../../../units';
import { uniqueValues } from '../../../utils';

const buildDefs = (sourceVars, components) => {
  const sortedComps = components.sort((a, b) => {
    const { itemDesignation: desA } = a;
    const { itemDesignation: desB } = b;
    return desA.localeCompare(desB);
  });
  const itemDesignations = sortedComps.map((c) => c.itemDesignation).filter(uniqueValues);

  return {
    itemDesignation: {
      type: ['string', 'null'],
      title: 'Item designation',
      placeholder: 'Select component',
      anyOf: [
        { const: null, title: 'None (required to display)' },
        ...itemDesignations.map((itemDes) => ({
          const: itemDes,
          title: itemDes,
        })),
      ],
    },
    variables: {
      type: 'array',
      title: 'Signals',
      default: [],
      items: {
        type: 'object',
        properties: {
          variableId: {
            type: ['string', 'null'],
            title: 'Signal',
            placeholder: 'Select signal',
            noOptionsMessage: 'No signals found.',
            default: (sourceVars[0] || {}).id || null,
            anyOf: sourceVars.length
              ? sourceVars.map((v) => ({
                  const: v.id,
                  title: `[ ${v.sourceName || '-'} ] [ ${v.itemDesignation || '-'} ] ${v.name}`,
                }))
              : [{ const: null, title: 'None available' }],
          },
          unit: {
            type: 'string',
            title: 'Display unit',
            default: units[0].id,
            anyOf: units.map((u) => ({
              const: u.id,
              title: `${u.category}: ${u.name}`,
            })),
          },
          name: {
            type: 'string',
            title: 'Display name',
            placeholder: 'Default to item designation',
            default: '',
          },
          maxDecimals: {
            type: 'integer',
            title: 'Max decimals',
            minimum: 0,
            default: 3,
          },
        },
      },
    },
  };
};

const getPushPinSchema = (variables = [], components = []) => ({
  type: 'object',
  definitions: buildDefs(variables, components),
  required: ['itemDesignation'],
  properties: {
    itemDesignation: {
      $ref: '#/definitions/itemDesignation',
    },
    variables: {
      $ref: '#/definitions/variables',
    },
  },
});

const getViewerPanelSchema = (panelSchema, variables = [], components = []) => {
  const activeOptions = [
    { const: 0, title: 'None' },
    { const: 1, title: 'All' },
    { const: 2, title: 'Active' },
    { const: 3, title: 'Active or All' },
  ];

  return {
    type: 'object',
    definitions: buildDefs(variables, components),
    properties: {
      display: {
        type: 'boolean',
        title: 'Display viewer',
        default: true,
      },
      showFullHeight: {
        type: 'boolean',
        title: 'Show viewer in full height',
        default: false,
      },
      tooltipOnHover: {
        type: 'boolean',
        title: 'Show tooltip on component hover',
        default: false,
      },
      showAreaObjects: {
        type: 'boolean',
        title: 'Show component area-type objects',
        default: false,
      },
      focusActiveComponent: {
        type: 'boolean',
        title: 'Hide all inactive components on select outside viewer',
        default: false,
      },
      isolate: {
        type: 'boolean',
        title: 'Hide non-relevant components (isolate)',
        default: true,
      },
      forceErrorPins: {
        type: 'boolean',
        title: 'Display push pins for components with errors',
        default: false,
      },
      showActivePushpinTooltip: {
        type: 'boolean',
        title: 'Display push pins tooltip of active component if pushpins enabled',
        default: false,
      },
      selectionType: {
        type: 'number',
        title: 'Selection (highlight) type',
        default: 1,
        anyOf: [
          { const: 1, title: 'Mixed' },
          { const: 2, title: 'Regular' },
          { const: 3, title: 'Overlay' },
        ],
      },
      renderComponents: {
        type: 'number',
        title: 'Render mapped components',
        default: 0,
        anyOf: activeOptions,
      },
      highlightComponents: {
        type: 'number',
        title: 'Highlight components (takes precedence over overlays)',
        default: 0,
        anyOf: activeOptions,
      },
      overlayComponents: {
        type: 'number',
        title: 'Overlay components (status color)',
        default: 0,
        anyOf: activeOptions,
      },
      showPushPins: {
        type: 'number',
        title: 'Display push pins',
        default: 0,
        anyOf: activeOptions,
      },
      toolbarOptions: {
        isMulti: true,
        type: 'array',
        title: 'Toolbar options (highlighted options will be displayed)',
        uniqueItems: true,
        default: [],
        items: {
          type: 'string',
          anyOf: [
            { const: 'measure', title: 'Measure tool' },
            { const: 'firstperson', title: 'First person tool' },
            { const: 'settings', title: 'Settings tool' },
          ],
        },
      },
      pushPins: {
        type: 'array',
        title: 'Push pins',
        hasMoveDown: false,
        hasMoveUp: false,
        items: {
          type: 'object',
          required: ['itemDesignation'],
          properties: {
            itemDesignation: {
              $ref: '#/definitions/itemDesignation',
            },
            variables: {
              $ref: '#/definitions/variables',
            },
          },
        },
      },
      componentTooltips: {
        type: 'array',
        title: 'Component Tooltips',
        hasMoveDown: false,
        hasMoveUp: false,
        items: {
          type: 'object',
          required: ['itemDesignation'],
          properties: {
            itemDesignation: {
              $ref: '#/definitions/itemDesignation',
            },
            displayName: {
              type: ['string', 'null'],
              title: 'Display name',
            },
            displayValue: {
              type: ['string', 'null'],
              title: 'Display value',
            },
            freeText: {
              type: ['string', 'null'],
              title: 'Free text',
            },
            variables: {
              $ref: '#/definitions/variables',
            },
          },
        },
      },
      panels: {
        type: 'array',
        items: {
          ...panelSchema,
        },
      },
    },
  };
};

const getViewerPanelAdminSchema = (variables = [], components = []) => ({
  type: 'object',
  definitions: buildDefs(variables, components),
  properties: {
    // push pins handled as collapsible item list
  },
});

const viewerPanelUiSchema = {};

export { getPushPinSchema, getViewerPanelSchema, getViewerPanelAdminSchema, viewerPanelUiSchema };
