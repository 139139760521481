.page-list-item.styled-item .title {
  font-size: 1.1rem;
  font-weight: 500;
}
.page-list-item.styled-item .path {
  opacity: 0.7;
  margin-left: 1rem;
  font-family: monospace;
  font-size: 0.9rem;
}
.page-list-item.styled-item .move-item-component {
  margin: 0 1rem 0 auto;
}

.dark-theme .page-list-item.styled-item {
  background: rgba(255, 255, 255, 0.05);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvUGFnZUl0ZW0iLCJzb3VyY2VzIjpbIlBhZ2VJdGVtLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7O0FBS0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ0BhdnRqcy9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3MnO1xuXG4ucGFnZS1saXN0LWl0ZW0uc3R5bGVkLWl0ZW0ge1xuICAudGl0bGUge1xuICAgIGZvbnQtc2l6ZTogMS4xcmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gIH1cblxuICAucGF0aCB7XG4gICAgb3BhY2l0eTogMC43O1xuICAgIG1hcmdpbi1sZWZ0OiAxcmVtO1xuICAgIGZvbnQtZmFtaWx5OiBtb25vc3BhY2U7XG4gICAgZm9udC1zaXplOiAwLjlyZW07XG4gIH1cblxuICAubW92ZS1pdGVtLWNvbXBvbmVudCB7XG4gICAgbWFyZ2luOiAwIDFyZW0gMCBhdXRvO1xuICB9XG59XG5cbi5kYXJrLXRoZW1lIHtcbiAgLnBhZ2UtbGlzdC1pdGVtLnN0eWxlZC1pdGVtIHtcbiAgICBiYWNrZ3JvdW5kOiByZ2JhKCNmZmYsIDAuMDUpO1xuICB9XG59XG4iXX0= */