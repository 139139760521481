import JSZip from 'jszip';
import React, { useState, useEffect } from 'react';

import { Select, Label } from '@avtjs/react-components';

const VALID_EXTS = ['sldasm', 'rvt', 'iam', 'dgn', 'obj'];

const SelectRootFile = ({ onSelect, file, onInvalidFile }) => {
  const [rootFiles, setRootFiles] = useState([]);

  useEffect(() => {
    if (!file) return;
    const asyncUnzip = async () => {
      const zip = JSZip();
      await zip.loadAsync(file);
      const roots = Object.keys(zip.files).filter((filename) =>
        VALID_EXTS.includes(filename.split('.').pop().toLowerCase())
      );

      if (!roots.length) {
        onInvalidFile();
      } else {
        setRootFiles(roots);
      }
    };
    asyncUnzip();
  }, [file]);

  return (
    <div className="field">
      <Label htmlFor="select-root-file">
        Select root file
        <span title="Field is required"> *</span>
      </Label>
      <Select
        id="select-root-file"
        onChange={(e) => onSelect(e.value || null)}
        options={rootFiles.map((f) => ({ value: f, label: f }))}
        required
      />
    </div>
  );
};

export default SelectRootFile;
