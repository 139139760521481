import React, { useState } from 'react';
import { Icon, Button } from '@avtjs/react-components';
import './IconPickerField.scss';

const DEFAULT_ICONS = [
  'settings',
  'abb-voltage',
  'abb-wrench',
  'abb',
  'abb-view',
  'abb-dashboard',
  'abb-hardware-devices',
  'abb-robot-tool',
  'graphic-eq',
  'abb-gauge',
  'abb-transformer',
  'abb-turbocharger',
  'abb-triangle-up',
  'abb-text',
  'abb-train',
  'abb-star',
  'abb-server',
  'abb-robot',
  'abb-robot-cabinet',
  'abb-qr-code',
  'abb-microphone',
  'abb-list',
  'abb-factory',
  'abb-euro',
  'abb-cut',
  'abb-database',
  'abb-anchor',
  'abb-04-variables',
  'abb-align',
  'abb-call',
  'abb-boolean',
  'abb-battery-charging',
  'abb-battery-low',
  'abb-battery-full',
  'abb-bar-chart',
  'abb-calibration',
  'abb-bluetooth',
  'abb-14-repair',
  'abb-12-wifi',
  'abb-12-bell-alarm',
  'abb-11-light-bulb',
  'abb-11-eco-light-bulb',
  'abb-11-power-transmitter-flash',
  'abb-11-power-transformer',
  'abb-advanced-settings',
  'abb-cart',
  'remove',
  'check',
  'edit',
  'chevron-right',
  'chevron-left',
  'map',
  'person',
  'refresh',
  'search',
  'wb-sunny',
  'arrow-chart',
  'close',
];

const IconPicker = (props) => {
  const {
    schema: { icons = [] },
    required,
    formData,
    value,
    onChange,
  } = props;
  const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);

  const handleIconChange = (icon) => {
    onChange(icon);
    setIsIconPickerOpen(false);
  };

  const iconList = icons.length ? icons : DEFAULT_ICONS;

  return (
    <div className="form-group field field-select">
      <label className="control-label">
        Icon
        {required && <span className="required">*</span>}
      </label>
      <Button
        activity="secondary"
        className="icon-picker-toggler"
        onClick={() => setIsIconPickerOpen(!isIconPickerOpen)}
        type="button"
      >
        <Icon icon={formData || value || DEFAULT_ICONS[1]} />
      </Button>
      {isIconPickerOpen && (
        <div className="icon-picker-popup">
          <div className="icon-picker-icons">
            {iconList.map((icon) => (
              <Button
                key={icon}
                activity="secondary"
                onClick={() => handleIconChange(icon)}
                type="button"
              >
                <Icon icon={icon} />
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconPicker;
