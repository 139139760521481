import React, { useEffect, useState, useMemo } from 'react';
import { Button, Label, Input } from '@avtjs/react-components';
import { useSelector, useDispatch } from 'react-redux';

import { createBookmark, updateBookmark } from '../../../bundles/bookmarks';
import { getTags } from '../../../bundles/tags';

import ComponentSelect from '../../ComponentSelect';
import TagSelect from '../../TagSelect';

const BookmarkPanel = ({ file, fileInfo, bookmark: defaultBookmark = {}, onClose }) => {
  // NOTE: We're memoing the initial data here to prevent it from being
  // overwritten if the video is paused a ms after the dialog was opened. We
  // can probably clean this up a bit.
  const initialBookmark = useMemo(() => {
    if (!defaultBookmark) {
      return {};
    }

    return defaultBookmark;
  }, [file, fileInfo]);

  const { bookmarkData: initialBookmarkdata = {} } = initialBookmark;

  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState(null);

  const availableTags = useSelector(getTags);
  const [bookmarkData, setBookmarkData] = useState(initialBookmarkdata);
  const [name, setName] = useState(initialBookmark.name || '');
  const [components, setComponents] = useState(initialBookmark.components || file.components);
  const [tags, setTags] = useState(
    (initialBookmark.tags || file.tags || []).filter((tagId) =>
      availableTags.find((tag) => tag.id === tagId)
    )
  );

  const [displayCreateSpinner, setDisplayCreateSpinner] = useState(false);
  const isCreatingBookmark = useSelector((state) => state.bookmarks.isCreating);

  const formatSeconds = (seconds, target) => {
    if (!seconds) return 0;

    switch (target) {
      case 'seconds':
        return Math.floor(seconds % 60);
      case 'minutes':
        return Math.floor(seconds / 60);
      case 'hours':
        return Math.floor(seconds / 60 / 60);
      default:
        return seconds;
    }
  };

  const [hours, setHours] = useState(formatSeconds(bookmarkData.time, 'hours'));
  const [minutes, setMinutes] = useState(formatSeconds(bookmarkData.time, 'minutes'));
  const [seconds, setSeconds] = useState(formatSeconds(bookmarkData.time, 'seconds'));
  const [isCreating, setCreating] = useState(isCreatingBookmark);

  useEffect(() => {
    if (isCreatingBookmark) {
      setCreating(true);
    }
  }, [isCreatingBookmark]);

  useEffect(() => {
    if (isCreating && !isCreatingBookmark) {
      onClose();
    }
  }, [isCreating, isCreatingBookmark]);

  const onSubmit = (e) => {
    setValidationError(null);
    e.preventDefault();

    setDisplayCreateSpinner(true);

    if (file.mime.includes('video')) {
      const time = hours * 60 * 60 + minutes * 60 + seconds;

      if (time > fileInfo.duration) {
        setValidationError('Time for bookmark is not valid for current video!');
        setDisplayCreateSpinner(false);
        return;
      }

      bookmarkData.time = time;
    }

    const fileId = file.file ? file.file.id : file.id;

    const bookmark = {
      tags,
      components,
      name,
      bookmarkData,
    };

    if (initialBookmark.id) {
      dispatch(updateBookmark(fileId, initialBookmark.id, bookmark));
    } else {
      dispatch(createBookmark(fileId, bookmark));
    }
  };

  const title = useMemo(
    () => (initialBookmark.id ? 'Edit bookmark' : 'Create bookmark'),
    [initialBookmark]
  );

  const cta = useMemo(
    () => (initialBookmark.id ? 'Update bookmark' : 'Create bookmark'),
    [initialBookmark]
  );

  const renderBookMarkOptions = () => {
    if (file.mime.includes('pdf')) {
      return (
        <div className="row">
          <div className="column">
            <Label>
              Page
              <Input
                type="number"
                id="start-page"
                min="1"
                max={fileInfo.pages}
                defaultValue={bookmarkData.page}
                onChange={(e) => {
                  setBookmarkData({ ...bookmarkData, page: e.target.value });
                }}
                required
              />
            </Label>
          </div>
        </div>
      );
    }

    if (file.mime.includes('video')) {
      return (
        <div className="row">
          <div className="column">
            <Label>
              Time
              <div className="row video-time-row">
                <Input
                  type="number"
                  id="start-time-h"
                  min="0"
                  max="59"
                  value={hours}
                  onChange={(e) => {
                    setHours(e.target.value);
                  }}
                />{' '}
                :
                <Input
                  type="number"
                  id="start-time-min"
                  min="0"
                  max="59"
                  value={minutes}
                  onChange={(e) => {
                    setMinutes(e.target.value);
                  }}
                />{' '}
                :
                <Input
                  type="number"
                  id="start-time-s"
                  min="0"
                  max="59"
                  value={seconds}
                  onChange={(e) => {
                    setSeconds(e.target.value);
                  }}
                />
              </div>
            </Label>
          </div>
        </div>
      );
    }

    return <div> No bookmark option available for file </div>;
  };

  return (
    <div className="bookmark-panel">
      <div className="bookmark-panel-title">{title}</div>
      <form onSubmit={onSubmit}>
        {validationError && <div className="validation-error-message"> {validationError} </div>}
        {renderBookMarkOptions()}
        <div className="row">
          <div className="column">
            <Label>
              Name
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Label>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <Label htmlFor="components-selector">Components</Label>
            <ComponentSelect
              value={components}
              onChange={setComponents}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <Label htmlFor="tags-selector">Tags</Label>
            <TagSelect
              onChange={setTags}
              value={tags}
              placeholder="Search or create..."
            />
          </div>
        </div>
        <div className="row buttons">
          <Button
            onClick={onClose}
            activity="secondary"
            type="button"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={displayCreateSpinner}
          >
            {cta}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BookmarkPanel;
