const propertySchema = {
  type: 'object',
  required: ['attribute', 'name', 'value', 'category'],
  properties: {
    attribute: {
      title: 'Attribute',
      type: 'string',
    },
    name: {
      title: 'Name',
      type: 'string',
    },
    value: {
      title: 'Value',
      type: 'string',
    },
    category: {
      title: 'Category',
      type: 'string',
    },
  },
};

export default propertySchema;
