import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Textarea, Label } from '@avtjs/react-components';

import { getModelVersions } from '../../../../../bundles/models';
import CustomSelect from '../../../../CustomSelect';

const COPY_MAPPING_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Only custom objects', value: 'objects' },
  { label: 'None', value: null },
];

const VersionOptions = ({ modelId, onSubmit, submitDisabled, onCancel }) => {
  const versions = useSelector((state) => getModelVersions(state, modelId));
  const [comment, setComment] = useState('');
  const [copyMappings, setCopyMappings] = useState(COPY_MAPPING_OPTIONS[0].value);
  const [mappingsFromVersion, setMappingsFromVersion] = useState();

  useEffect(() => {
    if (versions.length) {
      setMappingsFromVersion(versions[0].id);
    }
  }, [versions]);

  const isSaveDisabled = () => submitDisabled || !comment;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      comment,
      copyMappings,
      mappingsFromVersion,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="version-options-wrapper"
    >
      <div className="field">
        <Label htmlFor="comment">
          Comment
          <span title="Field is required"> *</span>
        </Label>
        <Textarea
          id="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        />
        <div className="help">
          A short comment that describes what was changed in this version of the model.
        </div>
      </div>

      <div className="field">
        <Label htmlFor="copy-mappings">
          Include mappings
          <span title="Field is required"> *</span>
        </Label>
        <CustomSelect
          id="copy-mappings"
          isMulti={false}
          value={copyMappings}
          onChange={setCopyMappings}
          rawOptions={COPY_MAPPING_OPTIONS}
        />
        <div className="help">
          Any destructive or positional changes made to models will likely invalidate model mappings
          and can also affect the positioning of custom object mappings.
        </div>
      </div>

      {copyMappings ? (
        <div className="field">
          <Label htmlFor="source-version">
            Mapping source
            <span title="Field is required"> *</span>
          </Label>
          <CustomSelect
            id="source-version"
            isMulti={false}
            value={mappingsFromVersion}
            onChange={setMappingsFromVersion}
            rawOptions={versions.map((v) => {
              const latest = versions.sort((a, b) => b.versionNumber - a.versionNumber)[0] || {};
              return {
                label: `${v.versionNumber} ${
                  v.versionNumber === latest.versionNumber ? '(latest)' : ''
                }`,
                value: v.id,
              };
            })}
          />
          <div className="help">Select the version from which to copy mappings.</div>
        </div>
      ) : null}

      <div className="submit">
        <Button
          activity="secondary"
          className="action"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <div className="flex-end">
          <Button
            type="submit"
            disabled={isSaveDisabled()}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default VersionOptions;
