/* eslint-disable no-const-assign */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  ModelViewer,
  ModelSelector,
  Gui,
  PushPins,
  Components,
  Measure,
  FirstPerson,
  ViewerSettings,
  THEME_AVT_DARK,
  THEME_AVT_BRIGHT,
} from '@avtjs/model-viewer';

// eslint-disable-next-line import/no-unresolved
import '@avtjs/model-viewer/dist/index.css';

import { useTheme } from '@avtjs/react-components';
import {
  shouldRenderComponent,
  shouldHighlightComponent,
  getComponentOverlay,
  shouldShowPin,
  getPinStyle,
  getPushpinTooltipContent,
  getComponentsTooltipContent,
} from './helpers';

import {
  requestMappings,
  requestGeometries,
  getIndexedMappingsBySite,
  getModelAndDesignationIndexedMappingIdsBySite,
} from '../../../bundles/geometry-mappings';
import {
  getPageComponents,
  getStaticComponents,
  getComponentTree,
  getFilteredActiveComponentId,
} from '../../../bundles/components';
import {
  addSubscriptions as addVariableSubscriptions,
  removeSubscriptions as removeVariableSubscriptions,
  getLastVariableData,
  refreshLastVariableData,
} from '../../../bundles/variables';
import { getSiteVariables } from '../../../bundles/sources';
import {
  setActiveComponentId,
  getActiveModelId,
  setActiveModelId,
  setViewerReady,
  getZoomComponentId,
  setZoomComponentId,
  getPollingDateRange,
  getTimezone,
  getPageType,
} from '../../../bundles/application';
import { getAllModels } from '../../../bundles/models';
import { getActiveSite } from '../../../bundles/sites';
import { useClientSize } from '../../../utils';
import { getServiceToken } from '../../../services';

import ConfirmationDialog from '../../ConfirmationDialog';
import PermissionDenied from '../../PermissionDenied';
import { getStateSets, getActiveStates } from '../../../bundles/statesets';

const ViewerPanel = (props) => {
  const {
    match: {
      params: { id: siteId },
    },
    pageIndex,
    panelId,
    display = true,
    pushPins: rawPushPins = [],
    tooltipOnHover = false,
    showAreaObjects = false,
    isolate = true,
    renderComponents,
    highlightComponents,
    overlayComponents,
    showPushPins,
    forceErrorPins: displayErrorPins,
    selectionType,
    focusActiveComponent,
    showActivePushpinTooltip,
    toolbarOptions = ['measure', 'firstperson'],
    data,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const timezone = useSelector(getTimezone);
  const pageType = useSelector(getPageType);
  const site = useSelector(getActiveSite);
  const activeComponentId = useSelector(getFilteredActiveComponentId);
  const pageComponents = useSelector(getPageComponents);
  const siteVariables = useSelector(getSiteVariables);
  const staticComponents = useSelector(getStaticComponents);
  const componentTree = useSelector(getComponentTree);
  const modelsHashmap = useSelector(getAllModels);
  const zoomComponentId = useSelector(getZoomComponentId);
  const activeModelId = useSelector(getActiveModelId);
  const indexedMappings = useSelector((state) => getIndexedMappingsBySite(state, siteId));
  const indexedMappingIds = useSelector((state) =>
    getModelAndDesignationIndexedMappingIdsBySite(state, siteId)
  );
  const latestVariableData = useSelector(getLastVariableData);
  const stateSets = useSelector(getStateSets);
  const { endDate, startDate } = useSelector(getPollingDateRange);

  const [components, setComponents] = useState([]);
  const [siteModels, setSiteModels] = useState([]);
  const [siteModelIds, setSiteModelIds] = useState([]);
  const [siteComponent, setSiteComponent] = useState(null);
  const [viewerClickedId, setViewerClickedId] = useState(null);
  const [versionConfig, setVersionConfig] = useState(null);
  const [focusedComponentIds, setFocusedComponentIds] = useState([]);
  const [visibleTooltip, setVisibleTooltip] = useState(undefined);
  const [fitToIds, setFitToIds] = useState([]);
  const [modelLoading, setModelLoading] = useState(true);
  const [clientSize, clientRef] = useClientSize();
  const [height, setHeight] = useState(clientSize.height);

  const initialModelPropose = {
    show: false,
    model: null,
    ignore: false,
  };
  const [proposeModelChange, setProposeModelChange] = useState(initialModelPropose);
  useEffect(() => {
    setHeight(clientSize.height);
  }, [clientSize]);
  useEffect(() => {
    dispatch(requestMappings(siteId));
    dispatch(setActiveModelId(null));
  }, []);

  const siteVariableIds = useMemo(() => siteVariables.map((v) => v.id), [siteVariables]);
  const pushPins = useMemo(
    () => [
      ...rawPushPins.map((pin) => ({
        ...pin,
        variables: (pin.variables || []).filter((v) => siteVariableIds.includes(v.variableId)),
      })),
    ],
    [rawPushPins, siteVariableIds]
  );

  useEffect(() => {
    const pushPinVariableIds = pushPins.reduce((acc, pin) => {
      if (pin.variables) {
        return acc.concat(
          pin.variables.filter((v) => v.variableId).map(({ variableId }) => variableId)
        );
      }
      return acc;
    }, []);

    if (pushPinVariableIds.length) {
      dispatch(addVariableSubscriptions(panelId, pushPinVariableIds));
    }

    return () => {
      if (pushPinVariableIds.length) {
        dispatch(removeVariableSubscriptions(panelId, pushPinVariableIds));
      }
    };
  }, [data, pushPins]);

  useEffect(() => {
    setSiteModels(() => Object.values(modelsHashmap));
    setSiteModelIds(() => Object.keys(modelsHashmap));
  }, [modelsHashmap]);

  useEffect(() => {
    if (!siteComponent && staticComponents.length) {
      setSiteComponent(() => staticComponents.find((c) => c.type === 'site'));
    }
  }, [staticComponents]);

  useEffect(() => {
    if (viewerClickedId === null) return;
    if (activeComponentId === viewerClickedId) return;

    setViewerClickedId(null);
  }, [activeComponentId]);

  useEffect(() => {
    if (
      siteComponent &&
      siteComponent.models &&
      siteComponent.models.length &&
      siteModelIds.length &&
      !siteModelIds.includes(activeModelId)
    ) {
      const activeId = siteComponent.models[0].modelId;
      dispatch(setActiveModelId(activeId));
    }
  }, [siteModelIds, siteComponent, activeModelId]);

  useEffect(() => {
    if (siteId && activeModelId) dispatch(requestGeometries(siteId, activeModelId));
  }, [siteId, activeModelId]);

  useEffect(() => {
    const compVariables = components
      .reduce((acc, curr) => {
        if (curr.variables.length > 0) {
          return [...acc, ...curr.variables.map((v) => v.id)];
        }
        return acc;
      }, [])
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
    dispatch(removeVariableSubscriptions(panelId, compVariables));
    setComponents(pageComponents);
  }, [pageComponents, panelId]);

  useEffect(() => {
    const compVariables = components
      .reduce((acc, curr) => {
        if (curr.variables.length > 0) {
          return [...acc, ...curr.variables.map((v) => v.id)];
        }
        return acc;
      }, [])
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
    dispatch(addVariableSubscriptions(panelId, compVariables));
  }, [components]);

  useEffect(() => {
    const model = siteModels.find((m) => m.id === activeModelId);

    if (!model) return;

    const [version] = model.versions
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .filter((v) => v.status === 'COMPLETED');
    if (version) {
      setVersionConfig(version.sceneConfig || {});
    }
  }, [activeModelId]);

  useEffect(() => {
    if (siteComponent && siteComponent.models && siteComponent.models.length && siteModels.length) {
      const siteModel = siteModels.find((model) => model.id === siteComponent.models[0].modelId);
      if (siteModel) {
        const [version] = siteModel.versions
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((v) => v.status === 'COMPLETED');
        if (version) {
          setVersionConfig(version.sceneConfig || {});
        }
      }
    }
  }, [siteComponent, siteModels]);

  const models = useMemo(() => {
    if (siteModels.length && siteComponent && siteComponent.models && siteComponent.models.length) {
      const rootId = siteComponent.models[0].modelId;
      const selectables = siteComponent.models.map((m) => m.modelId);
      // not including customName attached to component models
      return siteModels
        .map((model) => {
          const [version] = model.versions
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter((v) => v.status === 'COMPLETED');
          const [output] = (version && version.outputs.filter((o) => o.type === 'svf')) || [];
          if (output) {
            return {
              ...model,
              urn: output.url,
              config: version.sceneConfig,
              root: model.id === rootId,
              selectable: selectables.includes(model.id),
            };
          }
          return null;
        })
        .filter((model) => !!model);
    }
    return [];
  }, [siteModels, siteComponent]);

  const idMappedComponents = useMemo(() => {
    let comps = [];
    // return empty array of comps at first to let the viewer load models first
    if (Array.isArray(components) && indexedMappingIds && models) {
      comps = components.map((comp) => {
        const mappedComponent = { ...comp, mappingsByModel: {} };
        models.forEach((model) => {
          const mappingId = indexedMappingIds[`${model.id}@${comp.itemDesignation}`];
          if (mappingId) {
            mappedComponent.mappingsByModel = {
              ...mappedComponent.mappingsByModel,
              [model.id]: mappingId,
            };
          }
        });
        return mappedComponent;
      });
    }
    return comps;
  }, [components, models, indexedMappingIds]);

  const mappedComponents = useMemo(() => {
    let comps = [];
    if (idMappedComponents && indexedMappings && activeModelId) {
      comps = idMappedComponents.map((comp) => {
        const mappingId = comp.mappingsByModel[activeModelId];
        const { dbIds = [], objects = [], position } = indexedMappings[mappingId] || {};

        const stateSet = stateSets.find((s) => s.id === comp.stateset_id);

        return {
          ...comp,
          dbIds,
          objects,
          position,
          mappingId,
          stateSet,
        };
      });
    }
    return comps;
  }, [idMappedComponents, indexedMappings, activeModelId, stateSets]);

  const activeComponent = useMemo(
    () => mappedComponents && mappedComponents.find((c) => c.id === activeComponentId),
    [pageIndex, mappedComponents, activeComponentId]
  );

  const activeModel = useMemo(
    () => siteModels && siteModels.find((m) => m.id === activeModelId),
    [pageIndex, siteModels, activeModelId]
  );

  const findMappedChildrenIds = useCallback(() => {
    if (activeComponent && activeComponent.descendantIds) {
      return activeComponent.descendantIds.filter((id) => {
        const comp = mappedComponents.find((c) => c.id === id);
        return comp && comp.mappingsByModel[activeModelId];
      });
    }
    return [];
  }, [activeComponent, mappedComponents]);

  const findNearestAncestorWithModels = (parentId) => {
    const parent = mappedComponents.find((c) => c.id === parentId);
    if (parent && parent.models && parent.models.length) {
      return parent;
    }
    if (parent && parent.parent) {
      return findNearestAncestorWithModels(parent.parent);
    }
    return null;
  };

  const getNearestMappedAncestorModel = useCallback(() => {
    let mappedModel = null;

    if (activeComponent && activeComponent.parent) {
      const nearestAncestorWithModels = findNearestAncestorWithModels(activeComponent.parent);
      if (nearestAncestorWithModels) {
        nearestAncestorWithModels.models.forEach((model) => {
          if (
            !mappedModel &&
            (indexedMappingIds || {})[`${model.modelId}@${activeComponent.itemDesignation}`]
          ) {
            mappedModel = model;
          }
        });
      }
    }
    return mappedModel;
  }, [activeComponent]);

  const findMappedChildModelId = useCallback(
    (node = componentTree, compId = activeComponent.id, foundComp = false) => {
      // finds first child with mapping and returns the modelId of the model it's mapped to
      let modelId = null;
      let found = foundComp;
      let foundMapping = false;

      if (!node || !compId) return modelId;
      if (node.id === compId) {
        found = true;
      }
      if (found) {
        const comp = mappedComponents.find((c) => c.id === compId);
        const compMappedModels = comp && Object.keys(comp.mappingsByModel);
        foundMapping = compMappedModels && compMappedModels.length;
        if (foundMapping) {
          [modelId] = compMappedModels;
        } else if (node.children) {
          node.children.forEach((child) => {
            if (!modelId) {
              modelId = findMappedChildModelId(child, child.id, found);
            }
          });
        }
      } else if (node.children) {
        node.children.forEach((child) => {
          if (!modelId) {
            modelId = findMappedChildModelId(child, compId, found);
          }
        });
      }
      return modelId;
    },
    [componentTree, activeComponent]
  );

  const noShowNoIgnoreChange = {
    ...proposeModelChange,
    show: false,
    ignore: false,
  };

  useEffect(() => {
    if (activeComponent) {
      let foundMappings = false;
      let foundAlternateModelMapping = false;

      if (activeComponent.mappingsByModel[activeModelId]) {
        foundMappings = true;
        setFocusedComponentIds([activeComponent.id]);
        setProposeModelChange(() => noShowNoIgnoreChange);
      }
      if (!foundMappings) {
        const mappedChildrenIds = findMappedChildrenIds();
        if (mappedChildrenIds.length) {
          foundMappings = true;
          setFocusedComponentIds([activeComponent.id, ...mappedChildrenIds]);
          setProposeModelChange(() => noShowNoIgnoreChange);
        }
      }

      // Ignore checks if model is on the selected component.
      if (activeComponent.models.map((m) => m.modelId).includes(activeModelId)) {
        setFocusedComponentIds([activeComponent.id]);
        return;
      }

      if (!foundMappings && activeComponent.parent) {
        // find ancestor mapping if available
        const mappedAncestorModel = getNearestMappedAncestorModel();
        if (mappedAncestorModel) {
          foundAlternateModelMapping = true;
          setFocusedComponentIds([activeComponent.id]);
          if (
            !proposeModelChange.model ||
            proposeModelChange.model.id !== mappedAncestorModel.id ||
            proposeModelChange.model.modelId !== activeModel.id
          ) {
            setProposeModelChange(() => ({
              ignore:
                proposeModelChange.ignore &&
                proposeModelChange.model &&
                proposeModelChange.model.id === mappedAncestorModel.id,
              show: true,
              model: mappedAncestorModel,
            }));
          }
        }
      }
      if (!foundMappings && !foundAlternateModelMapping) {
        // we take first found child with mapping, and it's mapped model
        const mappedChildModelId = findMappedChildModelId();
        if (mappedChildModelId) {
          const modelComp = mappedComponents.find((comp) =>
            comp.models.map((model) => model.modelId).includes(mappedChildModelId)
          );
          if (modelComp) {
            const mappedDescendantModel = modelComp.models.find(
              (m) => m.modelId === mappedChildModelId
            );
            foundAlternateModelMapping = true;
            setFocusedComponentIds([activeComponent.id]);
            if (
              !proposeModelChange.model ||
              proposeModelChange.model.id !== mappedDescendantModel.id ||
              proposeModelChange.model.modelId !== activeModel.id
            ) {
              setProposeModelChange(() => ({
                ignore:
                  proposeModelChange.ignore &&
                  proposeModelChange.model &&
                  proposeModelChange.model.id === mappedDescendantModel.id,
                show: true,
                model: mappedDescendantModel,
              }));
            }
          }
        }
      }
      if (!foundMappings && !foundAlternateModelMapping) {
        // site level
        setFocusedComponentIds([activeComponent.id]);
        setProposeModelChange(() => noShowNoIgnoreChange);
      }
    } else {
      setFocusedComponentIds([]);
      setProposeModelChange(() => noShowNoIgnoreChange);
    }
  }, [
    activeComponent,
    activeModelId,
    componentTree,
    findMappedChildModelId,
    getNearestMappedAncestorModel,
  ]);

  useEffect(() => {
    if (zoomComponentId && zoomComponentId === activeComponentId) {
      setFitToIds(() => focusedComponentIds);
    } else {
      setFitToIds(() => []);
    }
  }, [zoomComponentId]);

  useEffect(() => {
    if (endDate && startDate) {
      dispatch(refreshLastVariableData());
    }
  }, [endDate, startDate]);

  const mapPushPinValues = useCallback(
    (pushPin) => {
      if (pushPin && pushPin.variables) {
        return {
          ...pushPin,
          variables: pushPin.variables.map((variable) => {
            const { variableId, name, unit } = variable;
            // We have already verified that pushpin variables exist when defining
            const sourceVar = siteVariables.find((v) => v.id === variableId);
            const latestVarData = (latestVariableData || {})[variableId] || [];
            return {
              ...variable,
              value: latestVarData[1],
              quality: latestVarData[2],
              timestamp: latestVarData[0],
              name: name || sourceVar.name || sourceVar.id,
              unit: unit || sourceVar.unit || '',
              variableDefaultUnit: sourceVar.unit,
            };
          }),
        };
      }
      return pushPin;
    },
    [latestVariableData, stateSets, siteVariables]
  );

  const mapComponentValues = useCallback(
    (comp, state) => {
      if (pageType === 'stateset') {
        return {
          displayName: comp.name,
          displayValue: comp.itemDesignation,
          stateText: state.text,
          stateDescription: state.description,
        };
      }
      return {
        displayName: comp.name,
        displayValue: comp.itemDesignation,
      };
    },
    [latestVariableData, stateSets, pageType]
  );

  const findActiveStates = useCallback(
    (component, variableData) => {
      const statefulVarsWithValues = component.variables.map((variable) => {
        const lastVariableData = Object.entries(variableData).find((v) => v[0] === variable.id);

        const stateset = stateSets.find((s) => s.id === variable.stateset_id);
        const [, value, quality] = lastVariableData?.[1] || [];

        return { value, stateset, quality };
      });
      return getActiveStates(statefulVarsWithValues);
    },
    [stateSets]
  );

  const liveComponents = useMemo(
    () =>
      mappedComponents.map((comp) => {
        const compWithHealth = components.find((c) => c.id === comp.id);
        const isActive = focusedComponentIds.includes(comp.id);
        const hasActive = focusedComponentIds.length;

        const pushPin = (pushPins || []).find((p) => p.itemDesignation === comp.itemDesignation);
        const config = {
          isActive,
          hasActive,
          viewerClickedId,
          focusActiveComponent,
          showPushPins,
          displayErrorPins,
          renderComponents,
          highlightComponents,
          overlayComponents,
        };
        const showPin = shouldShowPin(compWithHealth || comp, pushPin, config);
        const pushPinWithValues = showPin ? mapPushPinValues(pushPin) : null;

        const activeStates =
          comp && comp.variables.length ? findActiveStates(comp, latestVariableData) : [];

        const activeState = activeStates.length === 1 ? activeStates[0] : {};

        const state =
          comp.type === 'virtual' ? { ...(comp.stateSet?.states || {}), area: true } : activeState;

        const highlight = shouldHighlightComponent(config);

        const compToolTipWithValues = tooltipOnHover ? mapComponentValues(comp, state) : null;

        const overlay = highlight
          ? null
          : getComponentOverlay({ ...(compWithHealth || comp), state }, config, pageType);

        return {
          ...comp,
          componentId: comp.id,
          models: (comp.models || []).map((m) => ({
            ...m,
            ...(siteModels.find((sm) => sm.id === m.modelId) || {}),
          })),
          highlight,
          overlay,
          hide: highlight || overlay ? false : !shouldRenderComponent({ ...config, isolate }),
          showPin,
          pinStyle: getPinStyle({ ...(compWithHealth || comp), state }, pageType),
          pushpinTooltipContent: showPin
            ? () =>
                getPushpinTooltipContent(
                  compWithHealth || comp,
                  pushPinWithValues || {},
                  pageType,
                  timezone,
                  height
                )
            : null,
          componentsTooltipContent: tooltipOnHover
            ? () => getComponentsTooltipContent(compToolTipWithValues, timezone)
            : null,
        };
      }),
    [
      mappedComponents,
      components,
      focusedComponentIds,
      pushPins,
      viewerClickedId,
      focusActiveComponent,
      showPushPins,
      displayErrorPins,
      renderComponents,
      highlightComponents,
      overlayComponents,
      mapPushPinValues,
      findActiveStates,
      latestVariableData,
      mapComponentValues,
      siteModels,
      isolate,
      tooltipOnHover,
      stateSets,
      latestVariableData,
      pageType,
      clientSize,
    ]
  );

  const onGetAccessToken = useCallback(async (callback) => {
    const token = await getServiceToken('model');
    callback(token, 30);
  }, []);

  const onViewerCreated = useCallback(() => dispatch(setViewerReady(false)), []);

  const onGuiLoaded = useCallback(() => dispatch(setViewerReady(true)), []);

  const onModelChange = useCallback(() => {
    setModelLoading(true);
    dispatch(setViewerReady(false));
  }, []);

  const onModelChanged = useCallback(() => {
    setModelLoading(false);
    dispatch(setViewerReady(true));
  }, []);

  const handlePushPinClick = (component) => {
    if (window.isMobile) {
      if (visibleTooltip === component.id) {
        setVisibleTooltip(null);
      } else {
        setVisibleTooltip(component.id);
      }
    }

    dispatch(setActiveComponentId(component.id));
  };

  const handleComponentClick = useCallback((component) => {
    if (component) {
      setViewerClickedId(component.id);
      dispatch(setActiveComponentId(component.id));
    } else {
      dispatch(setActiveComponentId(null));
      dispatch(setZoomComponentId(null));
    }
  }, []);

  const handleDismissProposeModelChange = () => {
    setProposeModelChange(() => ({
      ...proposeModelChange,
      show: false,
      ignore: true,
    }));
  };

  const confirmProposeModelChange = (modelId) => {
    setProposeModelChange(() => ({ model: activeModel, show: false, ignore: false }));
    dispatch(setActiveModelId(modelId));
  };

  const handleModelSelectorChange = useCallback((modelId) => {
    dispatch(setActiveModelId(modelId));
  }, []);

  const handleError = useCallback((errors) => {
    throw errors[0] || new Error('Viewer error');
  }, []);

  const viewerComponents = useMemo(
    () => liveComponents.filter((c) => c.mappingsByModel[activeModelId]),
    [liveComponents, activeModelId, clientSize]
  );

  const pins = useMemo(
    () =>
      viewerComponents
        .filter((c) => c.showPin)
        .map((comp) => (
          <PushPins.Pin
            onClick={() => handlePushPinClick(comp)}
            id={comp.id}
            position={comp.position}
            dbIds={comp.dbIds}
            objectIds={comp.objects.reduce((acc, obj) => {
              acc.push(obj.id);
              return acc;
            }, [])}
            key={comp.id}
            showTooltip={showActivePushpinTooltip && comp.id === activeComponentId}
            style={comp.pinStyle}
          >
            {(!window.isMobile || (window.isMobile && visibleTooltip === comp.id)) && (
              <PushPins.Tooltip>{comp.pushpinTooltipContent()}</PushPins.Tooltip>
            )}
          </PushPins.Pin>
        )),
    [viewerComponents, showActivePushpinTooltip]
  );

  const viewerTheme = theme === 'dark' ? THEME_AVT_DARK : THEME_AVT_BRIGHT;
  const viewerOptions = useMemo(() => ({ reverseMouseZoomDir: true }), []);

  if (props.globalPermissionError) {
    return <PermissionDenied />;
  }

  return (
    <div
      ref={clientRef}
      className="viewer-component"
    >
      {proposeModelChange.show && !proposeModelChange.ignore && !modelLoading && (
        <div className="confirmation-dialog-container">
          <ConfirmationDialog
            modal={false}
            onCancel={handleDismissProposeModelChange}
            onConfirm={() => confirmProposeModelChange(proposeModelChange.model.modelId)}
            title="Change Model?"
            body={
              <p>
                The selected component does not exist in the current model.
                <br />
                Do you want to switch to <span>{proposeModelChange.model.customName}</span>?
              </p>
            }
            cancelText="Dismiss"
            confirmText="Change Model"
            confirmType="primary"
            slimButtons
          />
        </div>
      )}

      {models.length && versionConfig && activeModelId ? (
        <ModelViewer
          showUI={false}
          showViewer={display}
          alternateBranding={site?.siteId === 'non-identiq'}
          showProgress
          theme={viewerTheme}
          onGetAccessToken={onGetAccessToken}
          onViewerCreated={onViewerCreated}
          onGuiLoaded={onGuiLoaded}
          onModelChange={onModelChange}
          onModelChanged={onModelChanged}
          onViewerError={handleError}
          sceneConfig={versionConfig}
          options={viewerOptions}
          activeModelId={activeModelId}
        >
          <ModelSelector
            models={models}
            activeModelId={activeModelId}
            onModelChange={handleModelSelectorChange}
            closeable
          />
          <Components
            components={viewerComponents}
            model={activeModelId}
            onClick={handleComponentClick}
            isolate={isolate}
            selectionType={selectionType}
            disableSelection
            tooltipOnHover={tooltipOnHover}
            showAreaObjects={showAreaObjects}
            zoomTo={fitToIds}
          />
          <PushPins>{pins}</PushPins>
          <Gui key="avt-viewer-gui" />
          <Measure
            key="avt-viewer-tool-measure"
            showButton={toolbarOptions.includes('measure')}
          />
          <FirstPerson
            key="avt-viewer-tool-first-person"
            showButton={toolbarOptions.includes('firstperson')}
          />
          <ViewerSettings
            key="avt-viewer-tool-settings"
            showButton={toolbarOptions.includes('settings')}
          />
        </ModelViewer>
      ) : null}
    </div>
  );
};

export default withRouter(ViewerPanel);
