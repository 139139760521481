import React, { useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Icon } from '@avtjs/react-components';
import { capitalize } from '../../../../../utils';

import MoveItem from '../MoveItem';
import ManagePanels from '../ManagePanels';
import Delete from '../../../../Delete';
import JSONEditor from '../../../../JSONEditor';
import Heading from '../../../../Heading';
import StyledItem from '../../../../StyledItem';
import TypeLabel from '../../../../TypeLabel';

import columnSchema from '../../schemas/columnSchema';

const ColumnItem = ({
  activeTree = {},
  columnCount,
  moveColumn,
  removeColumn,
  pageIndex,
  sectionIndex,
  columnIndex,
  updateColumn,
  columnActive,
  setActiveTreeIndexes,
  panels = [],
  title,
  displayTitle,
  id,
}) => {
  const columnRef = useRef(null);

  const { panel: activePanelIndex = null } = activeTree;

  useEffect(() => {
    if (columnActive && columnRef.current && activePanelIndex === null) {
      columnRef.current.scrollIntoView();
    }
  }, [columnActive, activePanelIndex, columnRef.current]);

  const createPanel = () => {
    setActiveTreeIndexes({ ...activeTree, panel: panels.length, initial: false });
    updateColumn(pageIndex, sectionIndex, columnIndex, (column) => ({
      ...column,
      panels: [
        ...column.panels,
        {
          id: `panel-${uuidv4()}`,
          type: null,
        },
      ],
    }));
  };

  const movePanel = (from, to) => {
    updateColumn(pageIndex, sectionIndex, columnIndex, (column) => {
      const panel = column.panels[from];
      const updatedPanels = [...column.panels];
      updatedPanels.splice(from, 1);
      updatedPanels.splice(to, 0, panel);
      return {
        ...column,
        panels: updatedPanels,
      };
    });
    setActiveTreeIndexes({ ...activeTree, panel: to, initial: false });
  };

  const removePanel = (panelIndex) => {
    setActiveTreeIndexes({ ...activeTree, panel: undefined, initial: false });
    updateColumn(pageIndex, sectionIndex, columnIndex, (column) => {
      const updatedPanels = [...column.panels];
      updatedPanels.splice(panelIndex, 1);
      return {
        ...column,
        panels: updatedPanels,
      };
    });
  };

  const onUpdateColumn = useCallback(
    ({ formData }) => {
      updateColumn(pageIndex, sectionIndex, columnIndex, (column) => ({
        ...column,
        ...formData,
      }));
    },
    [updateColumn, pageIndex, sectionIndex, columnIndex]
  );

  const updatePanel = useCallback(
    (panelIndex, obj) =>
      updateColumn(pageIndex, sectionIndex, columnIndex, (column) => ({
        ...column,
        panels: [...column.panels.map((panel, index) => (index === panelIndex ? obj : panel))],
      })),
    [updateColumn, pageIndex, sectionIndex, columnIndex]
  );

  const toggleColumn = () => {
    if (columnActive) {
      setActiveTreeIndexes({
        ...activeTree,
        column: undefined,
        panel: undefined,
      });
    } else {
      setActiveTreeIndexes({
        ...activeTree,
        column: columnIndex,
      });
    }
  };

  const customValidate = useCallback(
    (formData, errors) => {
      columnSchema.required.forEach((fieldName) => {
        const validValue = formData[fieldName];
        if (!validValue) {
          errors[fieldName].addError(`Column ${capitalize(fieldName)} is required`);
        }
      });
      return errors;
    },
    [columnSchema]
  );
  // To remove duplicate error message
  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      switch (error.name) {
        case 'required': {
          if (error.property === 'title') {
            error.message = '';
          }
          break;
        }
        default:
      }
      return error;
    });

  return (
    <StyledItem
      ref={columnRef}
      itemClass="column-list-item"
      active={columnActive}
      onClick={toggleColumn}
      headerLabel={<TypeLabel type="column" />}
      headerContent={<div className="title">{title}</div>}
      headerActions={
        <>
          <MoveItem
            move={moveColumn}
            index={columnIndex}
            count={columnCount}
          />
          <Icon icon={columnActive ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />
        </>
      }
    >
      <JSONEditor
        title="Column properties"
        formData={{ title, displayTitle, id }}
        schema={columnSchema}
        onFormSubmit={onUpdateColumn}
        saveButtonText={'Update column properties'}
        customValidate={customValidate}
        customTransformErrors={(errors) => transformErrors(errors)}
      />

      <Heading
        contentLeft={
          <>
            <div className="title">Panels</div>
            <Button
              onClick={createPanel}
              activity="secondary"
              slim
            >
              Create panel
            </Button>
          </>
        }
      />
      <ManagePanels
        activeTree={activeTree}
        panels={panels}
        updatePanel={updatePanel}
        movePanel={movePanel}
        removePanel={removePanel}
        columnActive={columnActive}
        setActiveTreeIndexes={setActiveTreeIndexes}
      />

      <Delete
        onDelete={() => removeColumn(columnIndex)}
        title="Delete column"
      />
    </StyledItem>
  );
};

export default ColumnItem;
