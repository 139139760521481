/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import ConfirmationDialog from '../ConfirmationDialog';
import EditItemModal from '../EditItemModal';
import { DropdownMenu } from '../DropdownMenu';

/*
 * This component expects to be wrapped by a parent element with a height of 100%
 * so that it can be used to determine the placement of action menus.
 */
const ListItem = ({
  entity,
  item = {},
  itemIndex,
  columns,
  columnWidths = { type: 'percent', widths: [] },
  customEdit = false,
  isHeader,
  withActions = true,
  extraMenuActions = [],
  onEdit = () => {
    // do something onEdit
  },
  onSubmit = () => {
    // do something onSubmit
  },
  onDelete = () => {
    // do something onDelete
  },
  onChange = () => {
    // do something onChange
  },
  onClose = () => {
    // do something onClose
  },
  onExpand = () => {
    // do something onExpand
  },
  isExpanded = false,
  schema = {},
  disableDefaultActions = false,
  disableDelete = false,
  disableEdit = false,
  hideActionMenu = false,
  actionButtons = [],
  uiSchema = {},
  accordionContent,
  confirmationDialogTitle,
  confimationDialogBody,
  CustomEditModal,
  withTitle,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAccordionContent, setShowAccordionContent] = useState(isExpanded);

  const onEditItem = useCallback(() => {
    onEdit(item);
    if (!customEdit) {
      setShowEdit(true);
    }
  }, [item]);

  const onFormSubmit = useCallback(
    ({ formData: data }) => {
      onSubmit(data);
      setShowEdit(false);
    },
    [onSubmit, setShowEdit]
  );

  const onFormChange = useCallback(
    ({ formData: data }) => {
      if (typeof onChange === 'function') onChange(data);
    },
    [onChange]
  );

  const onConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);
    onDelete();
  }, [setShowConfirmDelete, onDelete]);

  const onCloseEditModal = useCallback(() => {
    setShowEdit(false);
    onClose();
  }, [setShowEdit, onClose]);

  // 0.2rem margin-right on each col + action column width
  const totalMarginsAndActions = columns.length * 0.2 + (withActions ? 7 : 0);
  const colWidth = `calc((100% - ${totalMarginsAndActions}rem) / ${columns.length})`;
  const getCustomWidth = (i) => {
    if (columnWidths.type === 'percent') {
      return `calc((100% - ${totalMarginsAndActions}rem) * (${columnWidths.widths[i]}/100))`;
    }
    if (columnWidths.type === 'rem') {
      if (columnWidths.widths[i] === 'fill') {
        const otherColsTotal = columnWidths.widths.reduce((acc, col) => {
          if (col !== 'fill') {
            return acc + col;
          }
          return acc;
        }, 0);

        return `calc(100% - ${totalMarginsAndActions}rem - ${otherColsTotal}rem)`;
      }
      return `${columnWidths.widths[i]}rem`;
    }
    return colWidth;
  };

  const hasAccordion = !!(accordionContent && accordionContent.length > 0);

  const classes = classnames({
    'list-item--clickable': hasAccordion,
    'list-item--header': isHeader,
  });

  const handleItemClick = () => {
    if (hasAccordion) {
      onExpand();
      setShowAccordionContent(!showAccordionContent);
    }
  };

  const itemMenuOptions = useMemo(() => {
    const options = [];

    if (extraMenuActions.some((action) => action.placement === 'before')) {
      options.push(
        ...extraMenuActions
          .filter((action) => action.placement === 'before')
          .map((action) => ({
            component: <div>{action.text}</div>,
            onSelect: action.action,
          }))
      );
    }

    const defaultOptions = [];
    if (!disableEdit) {
      defaultOptions.push({ component: <div>Edit</div>, onSelect: onEditItem });
    }

    if (!disableDelete) {
      defaultOptions.push({
        component: <div>Delete</div>,
        onSelect: () => setShowConfirmDelete(true),
      });
    }

    if (!disableDefaultActions) {
      options.push(...defaultOptions);
    }

    if (extraMenuActions) {
      options.push(
        ...extraMenuActions
          .filter((action) => action.placement !== 'before')
          .map((action) => ({
            component: <div>{action.text}</div>,
            onSelect: action.action,
          }))
      );
    }
    return !hideActionMenu ? options : [];
  }, [item, onEditItem, extraMenuActions, hideActionMenu]);

  const actionMenu = useMemo(
    () =>
      itemMenuOptions.length ? (
        <DropdownMenu
          trigger="avt-menu-dots"
          triggerSize="m"
          menuOptions={itemMenuOptions}
          className="list-item-menu"
          menuXPlacement="left"
          menuShouldBlockScroll
          styles={{
            dropdownIndicator: { padding: '0 0.5rem', filter: 'none' },
            option: { cursor: 'pointer' },
            menu: {
              minWidth: 'auto',
            },
          }}
        />
      ) : null,
    [itemMenuOptions]
  );

  const actions = <div className="actions">{hideActionMenu ? actionButtons : actionMenu}</div>;
  const actionColumn = isHeader ? (
    <div className="list-item__col actions--header">
      <div className="list-item--header__col">Actions</div>
    </div>
  ) : (
    actions
  );

  const editModal = CustomEditModal ? (
    React.cloneElement(CustomEditModal, { onCloseModal: onCloseEditModal, integration: item })
  ) : (
    <EditItemModal
      entity={entity}
      action="Update"
      formData={item}
      schema={schema}
      uiSchema={uiSchema}
      onSubmit={onFormSubmit}
      onChange={onFormChange}
      onCloseModal={onCloseEditModal}
      withTitle={withTitle}
    />
  );

  return (
    <div
      className={`list-item ${classes} `}
      onClick={handleItemClick}
    >
      {columns.map((c, i) => {
        const key = `list-item-${itemIndex}-column-${i}`;
        return (
          <div
            key={key}
            className={`list-item__col ${isHeader ? 'list-item--header__col' : ''}`}
            style={{
              width: columnWidths.widths[i] ? getCustomWidth(i) : colWidth,
            }}
          >
            {c}
          </div>
        );
      })}

      {withActions && actionColumn}

      {showEdit ? editModal : null}

      {showConfirmDelete ? (
        <ConfirmationDialog
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={onConfirmDelete}
          title={confirmationDialogTitle}
          body={confimationDialogBody}
          confirmText="Remove"
          confirmType="danger"
        />
      ) : null}

      {showAccordionContent &&
        accordionContent.map((list, i) => (
          <div
            className="list-item-accordion-content"
            key={i}
          >
            {list.map((content, index) => (
              <React.Fragment key={`row-${i}-col-${index}`}>
                <div
                  className="list-item__col"
                  style={{ width: columnWidths.widths[i] ? getCustomWidth(i) : colWidth }}
                >
                  {content}
                </div>
              </React.Fragment>
            ))}
            {withActions ? <div className="actions"></div> : null}
          </div>
        ))}
    </div>
  );
};

export default ListItem;
