import React, { useState, useEffect, memo } from 'react';
import { Button } from '@avtjs/react-components';
import { useDropzone } from 'react-dropzone';

import JSONEditor from '../../../../JSONEditor';
import SimpleModal from '../../../../SimpleModal';

const CreateAlgorithmModal = memo(({ schema, uiSchema, onSubmit, onCloseModal }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);

  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0) {
      setError(null);
      setFormData({ ...formData, file: accepted[0] });
    } else {
      setError((rejected[0].errors && rejected[0].errors[0]) || 'An unknown error occurred.');
    }
  };

  useEffect(() => {
    /*
     * algorithm executables use an unofficial mime type (application/x-executable),
     * so will not include a type
     */
    if (formData && formData.file && formData.file.type) {
      setError('Unsupported file type.');
      setFormData(() => {
        const copy = { ...formData };
        delete copy.file;
        return copy;
      });
    }
  }, [formData]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop,
  });

  const onChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setFormData({ ...formData, file: event.target.files[0] });
  };

  const onFormSubmit = ({ formData: data }) => {
    if (!formData.file) {
      console.error('Please choose a file.');
      return;
    }
    const fd = new FormData();
    Object.keys(data).forEach((key) => fd.append(key, data[key]));
    onSubmit(fd);
    onCloseModal();
  };

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Add algorithm"
    >
      <div className="create-algorithm-content">
        <ul
          className="upload-modal-choices"
          {...getRootProps()}
        >
          <li>
            <div className="meta">
              {!formData.file && (
                <>
                  {error && (
                    <div className="upload-error">
                      <div>{error}</div>
                      <div>Algorithms should be executables and uploaded individually.</div>
                    </div>
                  )}
                  <div className="title">Upload a new algorithm</div>
                  <div className="description">
                    Select an algorithm from your computer and upload it.
                  </div>
                </>
              )}

              {formData.file && (
                <>
                  <div className="title">{formData.file.name}</div>
                </>
              )}
            </div>
            <div className="actions">
              <div className="upload-button">
                <input
                  {...getInputProps({
                    type: 'file',
                    style: { display: 'none' },
                    onChange,
                  })}
                />
                <Button onClick={open}>Upload file</Button>
              </div>
            </div>
          </li>
        </ul>
        {formData.file && (
          <>
            <JSONEditor
              formData={formData}
              schema={schema}
              uiSchema={uiSchema}
              onFormSubmit={onFormSubmit}
              saveButtonText={'Create algorithm'}
              initialEditMode={true}
              showEditButton={false}
              cancelCallback={onCloseModal}
            />
          </>
        )}
      </div>
    </SimpleModal>
  );
});

export default CreateAlgorithmModal;
