import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { EXPORT_TIMESERIES_PROGRESS_LOGIC_EC } from '../../constants';

const LinearProgressWithLabel = (props) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          variant="body2"
          color="inherit"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          color="inherit"
          {...props}
        />
      </Box>
    </Box>
  );
};
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const ExportProgress = (props) => {
  const {
    exportType = 'signal',
    retrievedDataPoints,
    expectedDataPoints,
    onExportCompleted,
    useStaticTimer,
  } = props;
  const [progress, setProgress] = useState(0);

  if (exportType === 'event') {
    const { increment: currentRequest } = props;
    const numRequests = Math.ceil(expectedDataPoints / 10000);
    const currentProgress = (currentRequest / numRequests) * 100 || 0;
    if (currentProgress !== progress) {
      setProgress(currentProgress);
    }
  }

  const getProgressParams = () => {
    // default: signal export
    const { variables } = props;
    const incrementKeys = Object.keys(EXPORT_TIMESERIES_PROGRESS_LOGIC_EC);
    const dataPointRange = incrementKeys.reduce((prev, cur) =>
      Math.abs(cur - expectedDataPoints) < Math.abs(prev - expectedDataPoints) ? cur : prev
    );
    const progressParams = EXPORT_TIMESERIES_PROGRESS_LOGIC_EC[dataPointRange];
    const noOfSignals = variables?.length;
    if (!noOfSignals) {
      return progressParams.default;
    }
    const signalRange = Object.keys(progressParams).reduce((prev, cur) =>
      Math.abs(cur - noOfSignals) < Math.abs(prev - noOfSignals) ? cur : prev
    );
    return progressParams[signalRange] || progressParams.default;
  };

  const [increment, time] = getProgressParams();

  useEffect(() => {
    if (exportType === 'event' && progress === 100 && retrievedDataPoints === expectedDataPoints) {
      onExportCompleted();
    }

    if (exportType === 'signal' && progress === 100) {
      onExportCompleted();
    }
  }, [progress, exportType, retrievedDataPoints, expectedDataPoints, onExportCompleted]);

  useEffect(() => {
    let intervalId;
    if (exportType === 'signal') {
      intervalId = setInterval(
        () => {
          setProgress((prevProgress) => {
            if (prevProgress + increment >= 100) {
              return retrievedDataPoints > 0 ? 100 : prevProgress;
            }
            return prevProgress + increment;
          });
        },
        retrievedDataPoints > 0 ? 100 : (useStaticTimer ?? time)
      );
      if (progress === 100) {
        clearInterval(intervalId);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [exportType, progress, retrievedDataPoints]);

  return (
    <Box sx={{ width: '100%', paddingBottom: '1rem' }}>
      <label> Downloading ... </label>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

export default ExportProgress;
