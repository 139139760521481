import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, pairwise, switchMap } from 'rxjs/operators';
import { scaleOrdinal } from '@visx/scale';
import { useDispatch } from 'react-redux';

import { colors, useTheme } from '@avtjs/react-components';

import { useAsync, useSelect$, useSubscribe } from '../../../utils';
import { getEvents, getUserEvents, getEventBuckets } from '../../../services';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import getNotification from '../../../bundles/notification-defaults';
import { getPollingDateRange, getRangeFromLabel } from '../../../bundles/application';
import { getUserEventTypes, getIotUpdated } from '../../../bundles/events';
import {
  getStaticComponents,
  getScopedComponents,
  getComponentsUpdatedAt,
  getFilteredActiveComponentId,
} from '../../../bundles/components';
import { useSetLoading } from '../../LoadingLayer';
import { EVENT_TYPE_USER, EVENT_TYPE_IOT, EVENT_TYPE_ALL } from '../../../constants';

const iotSeverities = ['critical', 'warning', 'minor', 'info', 'internal'];

const context = createContext();

/**
 * Event timeline provider that manages the local state of Event Timeline
 * @typedef { number } UtcTimestamp
 * @param {{
 *  site: <Site obj>{},
 *  dateDomain: [UtcTimestamp, UtcTimestamp, string],
 *  brushBucketCount: number,
 *  timelineBucketCount: number,
 *  componentScope: {
 *    selectedScope: boolean,
 *    activeComponentTypeFilter: string[],
 *  },
 *  filterText: string,
 *  filterParams: {
 *    iot: { eventType: string[] },
 *    user: {
 *     type: string[],
 *    assignee: string,
 *    creator: string,
 *    tags: string[],
 *    status: string,
 *    activity: string,
 *  },
 *  children: any,
 *  availableIotEventTypes: string[],
 *  availableUserEventTypes: string[],
 *  hideInactiveSystemEvents: boolean,
 *  eventTypeFilter: string,
 * }} props
 */
export function EventTimelineProvider({
  site,
  dateDomain,
  brushBucketCount = 25,
  timelineBucketCount = 25,
  componentScope = {},
  eventListOrdering = 'desc',
  filterText,
  filterParams,
  children,
  availableIotEventTypes = [],
  availableUserEventTypes = [],
  hideInactiveSystemEvents,
  eventTypeFilter,
}) {
  // State subjects
  const site$ = useMemo(() => new BehaviorSubject(site), []);
  useEffect(() => {
    if (site !== site$.value) {
      site$.next(site);
    }
  }, [site, site$]);

  const dateDomain$ = useMemo(() => new BehaviorSubject(dateDomain), []);
  useEffect(() => {
    if (dateDomain$.value !== dateDomain) {
      dateDomain$.next(dateDomain);
    }
  }, [dateDomain]);

  const filterText$ = useMemo(() => new BehaviorSubject(filterText), []);
  const availableUserEventTypes$ = useMemo(() => new BehaviorSubject(availableUserEventTypes), []);
  const availableIotEventTypes$ = useMemo(() => new BehaviorSubject(availableIotEventTypes), []);
  const eventTypeFilter$ = useMemo(() => new BehaviorSubject(eventTypeFilter), []);
  const filterParams$ = useMemo(() => new BehaviorSubject(filterParams), []);
  const hideInactiveSystemEvents$ = useMemo(
    () => new BehaviorSubject(hideInactiveSystemEvents),
    []
  );

  useEffect(() => {
    if (filterParams !== filterParams$.value) {
      filterParams$.next(filterParams);
    }
  }, [filterParams, filterParams$]);

  useEffect(() => {
    if (filterText !== filterText$.value) {
      filterText$.next(filterText);
    }
  }, [filterText, filterText$]);

  useEffect(() => {
    if (JSON.stringify(eventTypeFilter) !== eventTypeFilter$.value) {
      eventTypeFilter$.next(eventTypeFilter);
    }
  }, [JSON.stringify(eventTypeFilter), eventTypeFilter$]);

  useEffect(() => {
    const eventTypeIds = availableIotEventTypes.map((v) => v.id);
    const iotEventIds = eventTypeIds.filter((id) => iotSeverities.includes(id));
    if (JSON.stringify(iotEventIds) !== availableIotEventTypes$.value) {
      availableIotEventTypes$.next(iotEventIds);
    }
  }, [JSON.stringify(availableIotEventTypes), availableIotEventTypes$]);

  useEffect(() => {
    const eventTypeIds = availableUserEventTypes.map((v) => v.id);
    const UserEventIds = eventTypeIds.filter((id) => !iotSeverities.includes(id));
    if (JSON.stringify(UserEventIds) !== availableUserEventTypes$.value) {
      availableUserEventTypes$.next(UserEventIds);
    }
  }, [JSON.stringify(availableUserEventTypes), availableUserEventTypes$]);

  useEffect(() => {
    if (hideInactiveSystemEvents !== hideInactiveSystemEvents$.value) {
      hideInactiveSystemEvents$.next(hideInactiveSystemEvents);
    }
  }, [hideInactiveSystemEvents, hideInactiveSystemEvents$]);

  const brushBucketCount$ = useMemo(() => new BehaviorSubject(brushBucketCount), []);
  const timelineBucketCount$ = useMemo(() => new BehaviorSubject(timelineBucketCount), []);
  useEffect(() => {
    if (brushBucketCount$.value !== brushBucketCount) {
      brushBucketCount$.next(brushBucketCount);
    }
  }, [brushBucketCount]);
  useEffect(() => {
    if (timelineBucketCount$.value !== timelineBucketCount) {
      timelineBucketCount$.next(timelineBucketCount);
    }
  }, [timelineBucketCount]);

  const activeComponentId$ = useSelect$(getFilteredActiveComponentId);
  const componentsUpdatedAt$ = useSelect$(getComponentsUpdatedAt);
  const components$ = useSelect$(getStaticComponents);

  const scopedToAllComps$ = useMemo(() => new BehaviorSubject(false), []);
  useEffect(() => {
    const sub = activeComponentId$.pipe(distinctUntilChanged()).subscribe((res) => {
      const { selectedScope, activeComponentTypeFilter } = componentScope;
      const isScopedToAllComps =
        String(activeComponentTypeFilter) === 'any' &&
        (String(selectedScope) === 'all' ||
          (selectedScope && selectedScope.includes('all') && !res));
      if (isScopedToAllComps !== scopedToAllComps$.value) {
        scopedToAllComps$.next(isScopedToAllComps);
      }
    });
    return () => sub.unsubscribe();
  }, [componentScope, activeComponentId$]);

  const scopedComponents$ = useMemo(() => {
    const { selectedScope } = componentScope;
    if (String(selectedScope) === 'all') {
      return components$;
    }
    return combineLatest([components$, componentsUpdatedAt$, activeComponentId$]).pipe(
      map(([components, componentsUpdatedAt, activeComponent]) => {
        if (!activeComponent) {
          if (selectedScope?.includes('none')) return [];
          return components;
        }
        return getScopedComponents({
          components,
          componentsUpdatedAt,
          activeComponentId: activeComponent,
          componentScope: JSON.stringify(componentScope),
        });
      })
    );
  }, [componentsUpdatedAt$, activeComponentId$, componentScope]);

  const scopedComponentIds$ = useMemo(() => new BehaviorSubject([]), []);
  const scopedIotComponentIds$ = useMemo(() => new BehaviorSubject([]), []);

  useEffect(() => {
    const sub = scopedComponents$.pipe(distinctUntilChanged()).subscribe((res) => {
      const iotComps = res.filter((c) => c.eventSources && c.eventSources.length > 0);
      scopedComponentIds$.next(res.map((c) => c.id));
      scopedIotComponentIds$.next(iotComps.map((c) => c.id));
    });
    return () => sub.unsubscribe();
  }, [scopedComponents$]);

  // panel-specific, local date domain
  const panelDateDomain$ = useMemo(() => new BehaviorSubject(dateDomain), []);

  // selected sub-domain within active domain
  const selectedDateDomain$ = useMemo(() => new BehaviorSubject(null), []);

  useEffect(() => {
    const sub = dateDomain$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (res !== panelDateDomain$.value && res[1]) {
        panelDateDomain$.next([res[0], res[1]], res[2]);
      }
    });
    return () => sub.unsubscribe();
  }, [dateDomain$]);

  useEffect(() => {
    const sub = selectedDateDomain$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (
        res &&
        res === panelDateDomain$.value &&
        dateDomain$.value &&
        (res[0] <= dateDomain$.value[0] || res[1] >= dateDomain$.value[1])
      ) {
        selectedDateDomain$.next(null);
      }
    });
    return () => sub.unsubscribe();
  }, [selectedDateDomain$]);

  useEffect(() => {
    const sub = panelDateDomain$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prev, current]) => {
        if (prev[0] !== current[0] || prev[1] !== current[1]) {
          selectedDateDomain$.next(null);
        }
      });
    return () => sub.unsubscribe();
  }, [panelDateDomain$]);

  const eventListOrdering$ = useMemo(() => new BehaviorSubject(eventListOrdering), []);

  useEffect(() => {
    if (eventListOrdering$.value !== eventListOrdering) {
      eventListOrdering$.next(eventListOrdering);
    }
  }, [eventListOrdering, eventListOrdering$]);

  const iotUpdated$ = useSelect$(getIotUpdated);

  // brush data
  const ctx = useMemo(
    () => ({
      site$,
      dateDomain$,
      hideInactiveSystemEvents$,
      filterText$,
      selectedDateDomain$,
      panelDateDomain$,
      activeComponentId$,
      scopedComponentIds$,
      scopedIotComponentIds$,
      scopedToAllComps$,
      brushBucketCount$,
      timelineBucketCount$,
      eventListOrdering$,
      iotUpdated$,
      availableUserEventTypes$,
      availableIotEventTypes$,
      eventTypeFilter$,
      filterParams$,
    }),
    [
      site$,
      dateDomain$,
      hideInactiveSystemEvents$,
      filterText$,
      selectedDateDomain$,
      panelDateDomain$,
      scopedComponentIds$,
      scopedIotComponentIds$,
      scopedToAllComps$,
      brushBucketCount$,
      timelineBucketCount$,
      eventListOrdering$,
      availableUserEventTypes$,
      availableIotEventTypes$,
      eventTypeFilter$,
      filterParams$,
    ]
  );
  return <context.Provider value={ctx}>{children}</context.Provider>;
}

export function getFilterStateCallback() {
  const {
    filterText$,
    hideInactiveSystemEvents$,
    filterParams$,
    eventTypeFilter$,
    availableUserEventTypes$,
    availableIotEventTypes$,
  } = useContext(context);

  return useCallback(
    () => ({
      text: filterText$.value,
      types: [...availableIotEventTypes$.value, ...availableUserEventTypes$.value],
      active: hideInactiveSystemEvents$.value,
      params: filterParams$.value,
      eventTypeFilter: eventTypeFilter$.value,
    }),
    [
      filterText$,
      hideInactiveSystemEvents$,
      filterParams$,
      eventTypeFilter$,
      availableUserEventTypes$,
      availableIotEventTypes$,
    ]
  );
}

// global date domain
export function useDateDomain() {
  const { dateDomain$ } = useContext(context);
  return useSubscribe(dateDomain$, dateDomain$.value);
}

export function useSetDateDomain() {
  const { dateDomain$ } = useContext(context);
  return useCallback((val) => dateDomain$.next(val), [dateDomain$]);
}

// panel-specific, local date domain
export function usePanelDateDomain() {
  const { panelDateDomain$ } = useContext(context);
  return useSubscribe(panelDateDomain$, panelDateDomain$.value);
}

export function useSetPanelDateDomain() {
  const { panelDateDomain$ } = useContext(context);
  return useCallback(
    (val) => {
      panelDateDomain$.next(val);
    },
    [panelDateDomain$]
  );
}

// selected sub-domain within active domain
export function useSelectedDateDomain() {
  const { selectedDateDomain$ } = useContext(context);
  return useSubscribe(selectedDateDomain$, selectedDateDomain$.value);
}

export function useOnSelectedDateDomainChange() {
  const { selectedDateDomain$ } = useContext(context);
  return useCallback(
    (res) => {
      const min = res?.[0];
      const max = res?.[1];
      const old = selectedDateDomain$.value;
      const oldMin = old?.[0];
      const oldMax = old?.[1];
      if (min !== oldMin || max !== oldMax) {
        selectedDateDomain$.next(res);
      }
    },
    [selectedDateDomain$]
  );
}

// component scope hooks
export function useScopedComponentIds() {
  const { scopedComponentIds$ } = useContext(context);
  return useSubscribe(scopedComponentIds$, scopedComponentIds$.value);
}

export function useScopedIotComponentIds() {
  const { scopedIotComponentIds$ } = useContext(context);
  return useSubscribe(scopedIotComponentIds$, scopedIotComponentIds$.value);
}

export function useScopedToAllComps() {
  const { scopedToAllComps$ } = useContext(context);
  return useSubscribe(scopedToAllComps$, scopedToAllComps$.value);
}

export function useUserEventTypes() {
  const userEventTypes$ = useSelect$(getUserEventTypes);
  return useSubscribe(userEventTypes$, userEventTypes$.value);
}

export function useStyling() {
  const theme = useTheme();

  return useMemo(() => {
    const textColor = theme === 'dark' ? colors.Grey60 : colors.Grey50;
    const tickStroke = theme === 'dark' ? colors.Grey80 : colors.Grey20;
    const background = theme === 'dark' ? colors.Grey90 : colors.Grey0;
    const indicatorStroke = colors.StatusRed;

    const tickLabelProps = {
      textAnchor: 'middle',
      fontFamily: 'ABB, sans-serif',
      fontSize: 9,
      fill: textColor,
    };
    const yTickLabelProps = () => ({
      ...tickLabelProps,
      width: 70,
      verticalAnchor: 'middle',
      textAnchor: 'end',
    });
    const xTickLabelProps = () => tickLabelProps;
    const brushSelectionIndicator = {
      fill: 'steelblue',
      fillOpacity: 0.2,
      stroke: 'steelblue',
      strokeWidth: 3,
      strokeOpacity: 0.8,
    };

    return {
      textColor,
      tickStroke,
      background,
      indicatorStroke,
      xTickLabelProps,
      yTickLabelProps,
      brushSelectionIndicator,
    };
  }, [theme]);
}

const handleUserEventErrors = (e, dispatch) => {
  console.error('Unable to fetch user events: ', e);
  dispatch(checkIsOnline());
  dispatch(displayNotification(getNotification('getUserEvents', 'error')()));
  return {};
};

const handleIotEventErrors = (e, dispatch) => {
  console.error('Unable to fetch iot events: ', e);
  dispatch(checkIsOnline());
  dispatch(displayNotification(getNotification('getIotEvents', 'error')()));
  return {};
};

export function useDateDomainHistogram(type) {
  const {
    site$,
    brushBucketCount$,
    activeComponentId$,
    scopedComponentIds$,
    scopedIotComponentIds$,
    scopedToAllComps$,
    hideInactiveSystemEvents$,
    filterText$,
    iotUpdated$,
    availableIotEventTypes$,
    availableUserEventTypes$,
    filterParams$,
    eventTypeFilter$,
  } = useContext(context);
  const dispatch = useDispatch();
  const site = useSubscribe(site$, site$.value);
  const dateDomain = usePanelDateDomain();
  const filterParams = useSubscribe(filterParams$, filterParams$.value);
  const hideInactiveSystemEvents = useSubscribe(
    hideInactiveSystemEvents$,
    hideInactiveSystemEvents$.value
  );
  const filterText = useSubscribe(filterText$, filterText$.value);
  const brushBucketCount = useSubscribe(brushBucketCount$, brushBucketCount$.value);
  const activeComp = useSubscribe(activeComponentId$);
  const scopedComponentIds = useSubscribe(scopedComponentIds$);
  const scopedIotComponentIds = useSubscribe(scopedIotComponentIds$);
  const scopedToAllComps = useSubscribe(scopedToAllComps$);
  const iotUpdated = useSubscribe(iotUpdated$);
  const availableIotEventTypes = useSubscribe(
    availableIotEventTypes$,
    availableIotEventTypes$.value
  );
  const eventTypeFilter = useSubscribe(eventTypeFilter$, eventTypeFilter$.value);
  const availableUserEventTypes = useSubscribe(
    availableUserEventTypes$,
    availableUserEventTypes$.value
  );

  return useAsync(async () => {
    if (dateDomain.length && scopedComponentIds && scopedIotComponentIds) {
      let from = dateDomain[0];
      let to = dateDomain[1] ? dateDomain[1] : Date.now();
      if (dateDomain?.[2]) {
        const relativeDomain = getRangeFromLabel(dateDomain[2]);
        from = relativeDomain.startDate;
        to = relativeDomain.endDate;
      }
      const interval = Math.floor((to - from) / brushBucketCount);
      const iotTypeFilters =
        (filterParams?.iot.eventType?.length || availableIotEventTypes.length) &&
        filterParams?.iot.eventType?.length
          ? filterParams?.iot.eventType
          : availableIotEventTypes;
      const userEventTypeFilters =
        (filterParams?.user?.type?.length || availableUserEventTypes.length) &&
        filterParams?.user?.type?.length
          ? filterParams?.user?.type
          : availableUserEventTypes;
      const [iot, userEvent] = await Promise.all([
        iotTypeFilters.length > 0 &&
        (type === EVENT_TYPE_IOT || type === 'all') &&
        [EVENT_TYPE_IOT, EVENT_TYPE_ALL].includes(eventTypeFilter)
          ? getEventBuckets({
              site: site.id,
              components: scopedIotComponentIds,
              from,
              to,
              interval,
              active: hideInactiveSystemEvents,
              ...(filterText && { message: filterText }),
              types: iotTypeFilters,
            }).then(
              (res) => res,
              (e) => handleIotEventErrors(e, dispatch)
            )
          : {},
        userEventTypeFilters.length > 0 &&
        (type === EVENT_TYPE_USER || type === 'all') &&
        [EVENT_TYPE_USER, EVENT_TYPE_ALL].includes(eventTypeFilter)
          ? getUserEvents({
              site: site.id,
              org: site.org,
              from,
              to,
              ...(filterText && { name: filterText }),
              ...(filterParams?.user?.assignee && { assignedTo: filterParams?.user?.assignee }),
              ...(filterParams?.user?.creator && { users: filterParams?.user?.creator }),
              ...(filterParams?.user?.tags && { tags: filterParams?.user?.tags }),
              ...(filterParams?.user?.status &&
                filterParams?.user?.status !== 'all' && { state: [filterParams?.user?.status] }),
              ...(filterParams?.user?.activity &&
                filterParams?.user?.activity !== 'all' && {
                  planned: filterParams?.user?.activity === 'planned',
                }),
              ...(filterParams?.user?.status && filterParams?.user?.status !== 'all'
                ? { task: true }
                : {}),
              type: userEventTypeFilters,
              components:
                scopedComponentIds.length === 0 || scopedToAllComps
                  ? undefined
                  : scopedComponentIds,
              withRecurring: true,
            }).then(
              (res) => res,
              (e) => handleUserEventErrors(e, dispatch)
            )
          : {},
      ]);
      const iotBuckets = iot?.values?.[0]?.buckets || [];
      const userEvents = userEvent.data || [];
      const histogram = {};

      iotBuckets.forEach((bucket) => {
        const fromTime = bucket.from;
        const key = (Math.floor(fromTime / interval) * interval).toString();
        histogram[key] = bucket.numEvents;
      });

      userEvents.forEach((event) => {
        const fromTime = new Date(event.from).getTime();
        const key = (Math.floor(fromTime / interval) * interval).toString();
        if (!histogram[key]) {
          histogram[key] = 0;
        }
        histogram[key] += 1;
      });
      const values = Object.values(histogram);
      const min = Math.min(...values);
      const max = Math.max(...values);
      const buckets = Object.keys(histogram).map((key) => ({
        count: histogram[key],
        from: new Date(Number(key)),
        to: new Date(Number(key) + interval),
      }));
      return {
        min,
        max,
        buckets,
        dateDomain,
      };
    }
    return {
      min: undefined,
      max: undefined,
      buckets: [],
      dateDomain,
    };
  }, [
    dateDomain,
    site,
    brushBucketCount,
    activeComp,
    scopedComponentIds,
    scopedIotComponentIds,
    scopedToAllComps,
    hideInactiveSystemEvents,
    filterText,
    iotUpdated,
    availableIotEventTypes,
    availableUserEventTypes,
    eventTypeFilter,
  ]);
}

export function severityWeight(severity) {
  switch (severity) {
    case 'critical':
      return 10;
    case 'warning':
      return 5;
    default:
      return 0;
  }
}

function timelineEventSort(a, b) {
  const weightA = severityWeight(a.severity);
  const weightB = severityWeight(b.severity);
  if (weightA === weightB) {
    return a.from < b.from ? -1 : 1;
  }
  return weightA < weightB ? -1 : 1;
}

export function useActiveTimelineData() {
  const {
    site$,
    timelineBucketCount$,
    activeComponentId$,
    scopedComponentIds$,
    scopedIotComponentIds$,
    scopedToAllComps$,
    hideInactiveSystemEvents$,
    filterText$,
    iotUpdated$,
    availableIotEventTypes$,
    availableUserEventTypes$,
    eventTypeFilter$,
    filterParams$,
  } = useContext(context);
  const dispatch = useDispatch();
  const site = useSubscribe(site$, site$.value);
  const filterParams = useSubscribe(filterParams$, filterParams$.value);
  const hideInactiveSystemEvents = useSubscribe(
    hideInactiveSystemEvents$,
    hideInactiveSystemEvents$.value
  );
  const filterText = useSubscribe(filterText$, filterText$.value);
  const bucketCount = useSubscribe(timelineBucketCount$, timelineBucketCount$.value);
  const panelDateDomain = usePanelDateDomain();
  const selectedDateDomain = useSelectedDateDomain();
  const activeComp = useSubscribe(activeComponentId$);
  const scopedComponentIds = useSubscribe(scopedComponentIds$);
  const scopedIotComponentIds = useSubscribe(scopedIotComponentIds$);
  const scopedToAllComps = useSubscribe(scopedToAllComps$);
  const iotUpdated = useSubscribe(iotUpdated$);
  const availableIotEventTypes = useSubscribe(availableIotEventTypes$);
  const eventTypeFilter = useSubscribe(eventTypeFilter$);
  const availableUserEventTypes = useSubscribe(availableUserEventTypes$);

  return useAsync(async () => {
    const dateDomain = selectedDateDomain || panelDateDomain;
    if (dateDomain.length && scopedComponentIds && scopedIotComponentIds) {
      let from = dateDomain[0];
      let to = dateDomain[1] ? dateDomain[1] : Date.now();
      if (dateDomain?.[2]) {
        const relativeDomain = getRangeFromLabel(dateDomain[2]);
        from = relativeDomain.startDate;
        to = relativeDomain.endDate;
      }
      const interval = Math.max(1, Math.floor((to - from) / bucketCount));
      const iotTypeFilters =
        (filterParams?.iot.eventType?.length || availableIotEventTypes.length) &&
        filterParams?.iot.eventType?.length
          ? filterParams?.iot.eventType
          : availableIotEventTypes;
      const userEventTypeFilters =
        (filterParams?.user?.type?.length || availableUserEventTypes.length) &&
        filterParams?.user?.type?.length
          ? filterParams?.user?.type
          : availableUserEventTypes;
      const result = await Promise.all([
        iotTypeFilters.length > 0 && [EVENT_TYPE_IOT, EVENT_TYPE_ALL].includes(eventTypeFilter)
          ? getEventBuckets({
              site: site.id,
              components: scopedIotComponentIds,
              from,
              to,
              interval,
              active: hideInactiveSystemEvents,
              ...(filterText && { message: filterText }),
              types: iotTypeFilters,
            }).then(
              (res) => res,
              (e) => handleIotEventErrors(e, dispatch)
            )
          : {},
        userEventTypeFilters.length > 0 &&
        [EVENT_TYPE_USER, EVENT_TYPE_ALL].includes(eventTypeFilter)
          ? getUserEvents({
              site: site.id,
              org: site.org,
              from,
              to,
              ...(filterText && { name: filterText }),
              ...(filterParams?.user?.assignee && { assignedTo: filterParams?.user?.assignee }),
              ...(filterParams?.user?.creator && { users: filterParams?.user?.creator }),
              ...(filterParams?.user?.tags && { tags: filterParams?.user?.tags }),
              ...(filterParams?.user?.status &&
                filterParams?.user?.status !== 'all' && { state: [filterParams?.user?.status] }),
              ...(filterParams?.user?.activity &&
                filterParams?.user?.activity !== 'all' && {
                  planned: filterParams?.user?.activity === 'planned',
                }),
              ...(filterParams?.user?.status && filterParams?.user?.status !== 'all'
                ? { task: true }
                : {}),
              type: userEventTypeFilters,
              components:
                scopedComponentIds.length === 0 || scopedToAllComps
                  ? undefined
                  : scopedComponentIds,
              withRecurring: true,
            }).then(
              (res) => res,
              (e) => handleUserEventErrors(e, dispatch)
            )
          : {},
      ]);
      const iotBuckets = result?.[0]?.values?.[0]?.buckets || [];
      const userEvents = [...(result?.[1]?.data || [])].sort(timelineEventSort);

      const userEventTypes = userEvents
        .reduce((acc, event) => {
          if (!acc.includes(event.type)) {
            acc.push(event.type);
          }
          return acc;
        }, [])
        .sort()
        .reverse();
      return {
        limitedUserEvents: !!result?.[1]?.limited,
        userEvents,
        userEventTypes,
        iotBuckets,
        dateDomain,
      };
    }
    return {
      limitedUserEvents: false,
      userEvents: [],
      userEventTypes: [],
      iotBuckets: [],
      dateDomain,
    };
  }, [
    site,
    panelDateDomain,
    selectedDateDomain,
    bucketCount,
    activeComp,
    scopedComponentIds,
    scopedIotComponentIds,
    scopedToAllComps,
    hideInactiveSystemEvents,
    filterText,
    iotUpdated,
    availableIotEventTypes,
    availableUserEventTypes,
    eventTypeFilter,
    filterParams,
  ]);
}

function sortByTimestamp(ordering) {
  if (ordering === 'desc') {
    return (a, b) => (a.timestamp > b.timestamp ? -1 : 1);
  }
  return (a, b) => (a.timestamp < b.timestamp ? -1 : 1);
}

function inRange(timestamp, ordering, start, end) {
  if (ordering === 'desc') {
    return timestamp >= end && timestamp <= start;
  }
  return timestamp >= start && timestamp <= end;
}

export function useActiveListEvents(limit = 100, scoped = true) {
  const {
    site$,
    activeComponentId$,
    scopedIotComponentIds$,
    scopedComponentIds$,
    scopedToAllComps$,
    eventListOrdering$,
    hideInactiveSystemEvents$,
    filterText$,
    iotUpdated$,
    availableIotEventTypes$,
    eventTypeFilter$,
    availableUserEventTypes$,
    filterParams$,
  } = useContext(context);
  const dispatch = useDispatch();
  const site = useSubscribe(site$, site$.value);
  const setLoading = useSetLoading();
  const panelDateDomain = usePanelDateDomain();
  const selectedDateDomain = useSelectedDateDomain();
  const activeComp = useSubscribe(activeComponentId$);
  const scopedComponentIds = useSubscribe(scopedComponentIds$);
  const scopedIotComponentIds = useSubscribe(scopedIotComponentIds$);
  const scopedToAllComps = useSubscribe(scopedToAllComps$);
  const iotUpdated = useSubscribe(iotUpdated$);
  const eventListOrdering = useSubscribe(eventListOrdering$);
  const filterParams = useSubscribe(filterParams$, filterParams$.value);
  const hideInactiveSystemEvents = useSubscribe(
    hideInactiveSystemEvents$,
    hideInactiveSystemEvents$.value
  );
  const filterText = useSubscribe(filterText$, filterText$.value);
  const availableIotEventTypes = useSubscribe(availableIotEventTypes$);
  const availableUserEventTypes = useSubscribe(availableUserEventTypes$);
  const eventTypeFilter = useSubscribe(eventTypeFilter$);

  const dateDomain = selectedDateDomain || panelDateDomain || [];
  let from = dateDomain.length && dateDomain[0];
  let to = dateDomain.length && dateDomain[1] ? dateDomain[1] : Date.now();
  if (dateDomain?.[2]) {
    const relativeDomain = getRangeFromLabel(dateDomain[2]);
    from = relativeDomain.startDate;
    to = relativeDomain.endDate;
  }
  const items$ = useMemo(() => new BehaviorSubject([]), []);
  const hasNextPage$ = useMemo(() => new BehaviorSubject(true), []);
  const totalEventCount$ = useMemo(() => new BehaviorSubject(-1), []);
  const getNextPage$ = useMemo(() => new BehaviorSubject(), []);

  useAsync(async () => {
    if (!from || !scopedComponentIds || !scopedIotComponentIds) return;
    getNextPage$.next();

    const userEventTypeFilters =
      (filterParams?.user?.type?.length || availableUserEventTypes.length) &&
      filterParams?.user?.type?.length
        ? filterParams?.user?.type
        : availableUserEventTypes;

    const iotTypeFilters =
      (filterParams?.iot?.eventType?.length || availableIotEventTypes.length) &&
      filterParams?.iot?.eventType?.length
        ? filterParams?.iot?.eventType
        : availableIotEventTypes;

    const userEventQuery = {
      site: site.id,
      org: site.org,
      from,
      to,
      ...(filterText && { name: filterText }),
      ...(filterParams?.user?.assignee && { assignedTo: filterParams?.user?.assignee }),
      ...(filterParams?.user?.creator && { users: filterParams?.user?.creator }),
      ...(filterParams?.user?.tags && { tags: filterParams?.user?.tags }),
      ...(filterParams?.user?.status &&
        filterParams?.user?.status !== 'all' && { state: [filterParams?.user?.status] }),
      ...(filterParams?.user?.activity &&
        filterParams?.user?.activity !== 'all' && {
          planned: filterParams?.user?.activity === 'planned',
        }),
      ...(filterParams?.user?.status && filterParams?.user?.status !== 'all' ? { task: true } : {}),
      type: userEventTypeFilters,
      components:
        !scoped || scopedComponentIds.length === 0 || scopedToAllComps
          ? undefined
          : scopedComponentIds,
      withRecurring: true,
    };

    const iotEventQuery = {
      site: site.id,
      components: !scoped ? [] : scopedIotComponentIds,
      limit,
      order: eventListOrdering,
      from,
      to,
      active: hideInactiveSystemEvents,
      ...(filterText && { message: filterText }),
      types: iotTypeFilters,
    };

    const userEvent =
      userEventTypeFilters.length > 0 && [EVENT_TYPE_USER, EVENT_TYPE_ALL].includes(eventTypeFilter)
        ? await (async () =>
            getUserEvents(userEventQuery).then(
              (res) => res,
              (e) => handleUserEventErrors(e, dispatch)
            ))()
        : {};
    const userEvents = userEvent.data || [];
    const endUserEvents = userEvents
      .filter((ev) => ev.to && ev.to > ev.from)
      .map((event) => ({
        timestamp: new Date(event.to).getTime(),
        id: event.id,
        event,
        type: 'user-end',
      }));

    const startUserEvents = userEvents.map((event) => ({
      timestamp: new Date(event.from).getTime(),
      id: event.id,
      event,
      type: event.to && event.to > event.from ? 'user-start' : 'user',
    }));

    hasNextPage$.next(true);
    totalEventCount$.next(-1);

    let sourceQueryOptions;
    let lastOrdering;
    let previousEnd = eventListOrdering === 'asc' ? from : to;

    async function next() {
      if (getNextPage$.value) {
        getNextPage$.next();
      }
      if (eventListOrdering !== lastOrdering) {
        // if ordering changes we reset
        previousEnd = eventListOrdering === 'asc' ? from : to;
        sourceQueryOptions = undefined;
      }

      const {
        values: iotEventsPre = [],
        total = 0,
        sourceOptions: nextSourceQueryOptions,
      } = iotTypeFilters.length > 0 && [EVENT_TYPE_IOT, EVENT_TYPE_ALL].includes(eventTypeFilter)
        ? await (async () =>
            getEvents({
              ...iotEventQuery,
              from,
              to,
              order: eventListOrdering,
              sourceOptions: sourceQueryOptions,
            }).then(
              (res) => res,
              (e) => handleIotEventErrors(e, dispatch)
            ))()
        : {};

      const hasNext = total === limit;
      hasNextPage$.next(hasNext);
      totalEventCount$.next(total + userEvents.length);
      const iotEvents = iotEventsPre.map((event) => ({
        timestamp: Number(event.timestamp),
        id: event.id,
        event,
        type: 'iot',
      }));

      if (!hasNext) {
        const nextItems = [...startUserEvents, ...endUserEvents, ...iotEvents].sort(
          sortByTimestamp(eventListOrdering)
        );
        if (eventListOrdering !== lastOrdering) {
          items$.next(nextItems);
        } else {
          items$.next(items$.value.concat(nextItems));
        }
      } else {
        const currentEnd = iotEvents.at(-1).timestamp;
        const nextItems = [
          ...startUserEvents.filter((e) =>
            inRange(e.timestamp, eventListOrdering, previousEnd, currentEnd)
          ),
          ...endUserEvents.filter((e) =>
            inRange(e.timestamp, eventListOrdering, previousEnd, currentEnd)
          ),
          ...iotEvents,
        ].sort(sortByTimestamp(eventListOrdering));

        if (eventListOrdering !== lastOrdering) {
          // new list if ordering changed
          items$.next(nextItems);
        } else {
          items$.next(items$.value.concat(nextItems));
        }

        getNextPage$.next(next);
        previousEnd = currentEnd;
      }
      lastOrdering = eventListOrdering;
      sourceQueryOptions = nextSourceQueryOptions;
    }
    setLoading(true);
    if (eventListOrdering) {
      await next();
      setLoading(false);
    }
  }, [
    from,
    to,
    activeComp,
    scopedComponentIds,
    scopedIotComponentIds,
    scopedToAllComps,
    eventListOrdering,
    site,
    hideInactiveSystemEvents,
    filterText,
    items$,
    getNextPage$,
    hasNextPage$,
    totalEventCount$,
    setLoading,
    iotUpdated,
    panelDateDomain,
    selectedDateDomain,
    availableIotEventTypes,
    availableUserEventTypes,
    eventTypeFilter,
    filterParams,
  ]);

  const events = useSubscribe(items$, []);
  const hasNextPage = useSubscribe(hasNextPage$, hasNextPage$.value);
  const totalEventCount = useSubscribe(totalEventCount$, totalEventCount$.value);
  const handleNext = useCallback(() => {
    if (!hasNextPage$.value) {
      return Promise.resolve();
    }
    const getNext = getNextPage$.value;
    if (getNext) {
      return getNext();
    }
    return Promise.resolve();
  }, [getNextPage$, hasNextPage$]);
  return {
    events,
    next: handleNext,
    hasNextPage,
    totalEventCount,
  };
}

const zScale = scaleOrdinal({
  domain: ['critical', 'warning', 'minor', 'info', 'internal'],
  range: ['#F03040', '#FFD800', '#3366FF', '#3366FF', '#3366FF'],
});

export function useZScale() {
  return zScale;
}

export function useFilterParams() {
  const { filterParams$ } = useContext(context);
  const [value, setValue] = useState(filterParams$.value);
  useEffect(() => {
    filterParams$.subscribe(setValue);
  }, [filterParams$, setValue]);
  const onChange = useCallback(
    (e) => {
      if (e?.target) {
        filterParams$.next(e.target.value);
      } else {
        filterParams$.next(e);
      }
    },
    [filterParams$]
  );
  return [value, onChange];
}

export function useEventTypeFilter() {
  const { eventTypeFilter$ } = useContext(context);
  const [value, setValue] = useState(eventTypeFilter$.value);
  useEffect(() => {
    eventTypeFilter$.subscribe(setValue);
  }, [eventTypeFilter$, setValue]);
  const onChange = useCallback(
    (e) => {
      if (e?.target) {
        eventTypeFilter$.next(e.target.value);
      } else {
        eventTypeFilter$.next(e);
      }
    },
    [eventTypeFilter$]
  );
  return [value, onChange];
}

export function useFilterText() {
  const { filterText$ } = useContext(context);
  const [value, setValue] = useState(filterText$.value);
  useEffect(() => {
    filterText$.subscribe(setValue);
  }, [filterText$, setValue]);
  const onChange = useCallback(
    (e) => {
      if (e?.target) {
        filterText$.next(e.target.value);
      } else {
        filterText$.next(e);
      }
    },
    [filterText$]
  );
  return [value, onChange];
}

export function useSetEventListOrdering() {
  const { eventListOrdering$ } = useContext(context);
  const [value, setValue] = useState(eventListOrdering$.value);
  useEffect(() => {
    eventListOrdering$.subscribe(setValue);
  }, [eventListOrdering$, setValue]);
  const onChange = useCallback((val) => eventListOrdering$.next(val), [eventListOrdering$]);
  return [value, onChange];
}

const hoverContext = createContext(new BehaviorSubject());

export function useHoverContext(eventId) {
  const hovering$ = useContext(hoverContext);
  const setHovering = useCallback(
    (isHovering) => {
      if (!eventId) {
        return;
      }
      if (isHovering) {
        hovering$.next(eventId);
      } else if (hovering$.value === eventId) {
        hovering$.next();
      }
    },
    [hovering$, eventId]
  );
  const hovering = useSubscribe(hovering$);
  useEffect(() => () => setHovering(false), [setHovering]);
  return [eventId && eventId === hovering, setHovering];
}

export function useDateDomainSource(panelId) {
  const today$ = useMemo(() => new BehaviorSubject(Date.now()), []);
  const pollingRangeDate$ = useSelect$((state) => getPollingDateRange(state, panelId), []);
  const refresh = useCallback(() => today$.next(Date.now()), [today$]);
  const dateDomain$ = useMemo(
    () =>
      pollingRangeDate$.pipe(
        switchMap(({ startDate, endDate, label }) =>
          today$.pipe(map(() => [startDate, endDate, label]))
        )
      ),
    [today$, pollingRangeDate$]
  );
  const dateDomain = useSubscribe(dateDomain$, []);
  return [dateDomain, refresh];
}

export function useHideInactiveSystemEvents() {
  const { hideInactiveSystemEvents$ } = useContext(context);
  const [value, setValue] = useState(hideInactiveSystemEvents$.value);
  useEffect(() => {
    hideInactiveSystemEvents$.subscribe(setValue);
  }, [hideInactiveSystemEvents$, setValue]);
  const setHideInactiveSystemEvents = useCallback(
    (hide) => {
      if (hide !== hideInactiveSystemEvents$.value) {
        hideInactiveSystemEvents$.next(hide);
      }
    },
    [hideInactiveSystemEvents$]
  );
  return [value, setHideInactiveSystemEvents];
}
