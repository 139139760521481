import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReactQrcode } from 'reactjs-qrcode-generator';
import { Button } from '@avtjs/react-components';
import { getSelectedQRS, getQRSSettings } from '../../bundles/qrCodes';

const QrGenerationView = ({
  match: {
    params: { id: siteId },
  },
}) => {
  const selectedQRS = useSelector(getSelectedQRS);
  const { elemsInRow, scanForPagePath, scanForPageId, cuttingLinesShown } =
    useSelector(getQRSSettings);

  const makeTd = ({ id, name, itemDesignation }) => (
    <td
      className="generatedQrComponent"
      key={id}
    >
      <div className="componentTitle">
        <h2>{name}</h2>
        <h4 className="titleLower">{itemDesignation}</h4>
      </div>
      <ReactQrcode
        qrvalue={`${window.location.origin}/sites/${siteId}/${scanForPagePath}/?page=${scanForPageId}&activeComponent=${id}&openedFrom=qr-code`}
        level="1"
      />
    </td>
  );

  const generatedQrCodes = useMemo(() => {
    const Rows = [];
    let Row = [];
    selectedQRS.forEach((elem, i) => {
      if (i > 0 && i % elemsInRow === 0) {
        Rows.push(Row);
        Row = [];
      }
      Row.push(makeTd(elem));
    });

    const emptyCells = elemsInRow - (selectedQRS.length % elemsInRow);
    for (let i = 0; i < emptyCells; i += 1) {
      Row.push(<td key={`empty_${i}`}></td>);
    }
    Rows.push(Row);

    return Rows.map((row, i) => <tr key={`${i}_${elemsInRow}`}>{row}</tr>);
  }, [selectedQRS, elemsInRow]);

  const print = () => {
    window.print();
  };

  return (
    <div className="container">
      <div className="containerHeader">
        <Button
          activity="primary"
          className="printButton"
          onClick={print}
        >
          Print
        </Button>
      </div>
      <table
        className={`containerBody elemsInRow_${elemsInRow} ${
          cuttingLinesShown ? 'showCuttingLines' : ''
        }`}
      >
        <tbody>{generatedQrCodes}</tbody>
      </table>
    </div>
  );
};

export default QrGenerationView;
