import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@avtjs/react-components';

import FileThumbnail from '../../../../FileThumbnail';
import { DropdownMenu } from '../../../../DropdownMenu';
import Pill from '../../../../Pill';
import { truncateFilename } from '../../../../../utils';
import { getOnline } from '../../../../../bundles/notifications';

const GridItem = ({
  item,
  selectedFiles,
  getDropdownOptions = () => [],
  disableSelect,
  isShiftPressed,
  onPreview,
  onSelectFile,
  truncateLength = 25,
}) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const online = useSelector(getOnline);

  const onClickFile = (file) => {
    if (isShiftPressed) {
      onSelectFile(file);
    } else {
      onPreview(file);
    }
  };

  const imageLoaded = (loaded = true) => {
    setThumbnailLoaded(loaded);
  };

  const options = getDropdownOptions(item);

  const [truncatedFilename] = truncateFilename(item.filename, truncateLength);

  return (
    <div
      className="grid-item"
      onClick={() => onClickFile(item)}
    >
      <div className={`grid-image type-${item.fileType} ${thumbnailLoaded ? '' : 'loading'}`}>
        <FileThumbnail
          online={online}
          file={item}
          loaded={imageLoaded}
        />
        {!disableSelect && (
          <Button
            design="text"
            className={`checkbox-wrapper ${item.isSelected && 'selected'} ${
              selectedFiles.length ? 'display' : ''
            }`}
            tooltip="Select"
          >
            <Icon
              style={{ cursor: 'pointer', color: '#FFF' }}
              icon={`${item.isSelected ? 'check-box' : 'check-box-outline-blank'}`}
              onClick={(e) => {
                e.stopPropagation();
                onSelectFile(item);
              }}
            />
          </Button>
        )}
        {!!options.length && (
          <div className="dropdown-wrapper">
            <DropdownMenu
              trigger={
                <Button
                  design="text"
                  tooltip="More..."
                >
                  <Icon
                    style={{ cursor: 'pointer', color: '#FFF' }}
                    icon="abb-menu-narrow"
                  />
                </Button>
              }
              menuOptions={options}
              menuXPlacement="left"
              styles={{
                dropdownIndicator: { filter: 'none', padding: 'calc(0.15rem + 2.5px)' },
                control: { minHeight: 'unset' },
              }}
            />
          </div>
        )}
        {item.bookmarks.length > 0 && (
          <Pill
            icon="bookmark"
            text={item.bookmarks.length}
          />
        )}
      </div>

      <div className="grid-item-footer">
        <div className="grid-item-title">
          <span title={item.filename}>{truncatedFilename}</span>
        </div>
      </div>
    </div>
  );
};

export default GridItem;
