import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@avtjs/react-components';

import HeroSection from '../HeroSection';
import Section from '../Section';
import Column from '../Column';
import Panel from '../Panel';
import Loader from '../Loader';

import AppContext from '../../AppContext';
import { setActiveComponentFilter, setPageType } from '../../bundles/application';
import { getSourcesLoaded } from '../../bundles/sources';
import { getModelsLoaded } from '../../bundles/models';
import { getComponentsLoaded, getFilteredActiveComponentId } from '../../bundles/components';
import { getVisualizationsLoaded } from '../../bundles/visualizations';
import { getStateSetsLoaded } from '../../bundles/statesets';
import { DEFAULT_PAGETYPE } from '../../constants';

const shouldRender = (displayWhen, activeComponentId = null) => {
  // displayWhen.componentIsActive section property can be null, true or false
  const { componentIsActive = null } = displayWhen;
  return (
    componentIsActive === null ||
    (componentIsActive && !!activeComponentId) ||
    (componentIsActive === false && !activeComponentId)
  );
};

const Page = ({ site, pages, page, pageConfig }) => {
  const dispatch = useDispatch();
  const {
    setIsFullScreen: setIsFullScreenGlobal,
    isFullscreen,
    isViewerFullScreen,
  } = useContext(AppContext);
  const activeComponentId = useSelector(getFilteredActiveComponentId);

  const sourcesLoaded = useSelector(getSourcesLoaded);
  const modelsLoaded = useSelector(getModelsLoaded);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const visualizationsLoaded = useSelector(getVisualizationsLoaded);
  const statesetsLoaded = useSelector(getStateSetsLoaded);

  const [hiddenPanels, setHiddenPanels] = useState([]);

  useEffect(() => {
    setHiddenPanels([]);
  }, [!isFullscreen]);

  useEffect(() => {
    if (pageConfig) {
      const { activeComponentFilter, pageType } = pageConfig;
      if (activeComponentFilter && activeComponentFilter.active) {
        dispatch(setActiveComponentFilter(activeComponentFilter));
      } else {
        dispatch(setActiveComponentFilter(null));
      }
      dispatch(setPageType(pageType || DEFAULT_PAGETYPE));
    }
    if (
      !(
        document.body.classList.contains('is-fullscreen') &&
        document.body.classList.contains('persist')
      )
    ) {
      setIsFullScreenGlobal(false);
    }
  }, [page, pageConfig]);

  const pageIndex = pages.findIndex(({ path }) => page.toLowerCase() === path.toLowerCase());
  const { viewer: viewerConfig, sections: sectionConfig = [] } = pageConfig;

  const sections = useMemo(
    () =>
      sectionConfig.map((s, sectionIndex) => ({
        ...s,
        columns: s.columns.map((c, columnIndex) => ({
          ...c,
          panels: c.panels.map((p, panelIndex) => ({
            ...p,
            panelIndex,
            id: `${sectionIndex}-${columnIndex}-${panelIndex}`,
          })),
          columnIndex,
        })),
        sectionIndex,
      })),
    [pageConfig]
  );

  const visibleSections = useMemo(() => {
    if (!sections.length) return [];
    return sections.filter(({ displayWhen = {} }) => shouldRender(displayWhen, activeComponentId));
  }, [sections, activeComponentId]);

  const viewer = useMemo(
    () => ({
      ...viewerConfig,
      panels: viewerConfig?.panels?.map((p, panelIndex) => ({
        ...p,
        id: `HS-${panelIndex}`,
      })),
    }),
    [viewerConfig]
  );

  const floatingPanels = useMemo(
    () => [
      ...sections
        .map((s) => s.columns.map((c) => c.panels.filter((p) => p.options && p.options.allowFloat)))
        .flat(3),
      ...((viewer && viewer.panels) || []).filter((p) => p.options && p.options.allowFloat).flat(1),
    ],
    [sections, viewer]
  );

  const allowFloating = floatingPanels && floatingPanels.length > 0;

  const togglePanel = useCallback((id) => {
    setHiddenPanels((panels) => {
      if (!panels.includes(id)) {
        return [...panels, id];
      }
      return panels.filter((p) => p !== id);
    });
  });

  const assetsLoaded =
    sourcesLoaded && modelsLoaded && componentsLoaded && visualizationsLoaded && statesetsLoaded;

  if (!site) {
    return (
      <Redirect
        exact
        to="/sites/"
      />
    );
  }

  if (!pages || pages.length === 0) {
    return <div className="page-component-error">No pages.</div>;
  }

  if (typeof page === 'undefined') {
    return <Redirect to={`/sites/${site.id}/${pages[0].path}`} />;
  }

  if (typeof pageConfig === 'undefined') {
    return <div className="page-component-error">Page does not exist.</div>;
  }

  return assetsLoaded ? (
    <>
      {isViewerFullScreen && allowFloating && <div className="draggable-container" />}
      <div className="page-component">
        <div className="page-content">
          <HeroSection
            site={site}
            pageIndex={pageIndex}
            viewer={viewer}
            hiddenPanels={hiddenPanels}
            onHidePanel={togglePanel}
          />

          {visibleSections.map(
            ({
              id: sectionId,
              title: sectionTitle,
              displayTitle,
              columns,
              options: sectionOptions = {},
              sectionIndex,
            }) => (
              <Section
                displayTitle={displayTitle}
                key={`${sectionId}-${sectionIndex}`}
                title={sectionTitle}
                {...sectionOptions}
              >
                {columns.map(
                  ({
                    title: columnTitle,
                    panels = [],
                    displayTitle: displayColumnTitle,
                    columnIndex,
                  }) => (
                    <Column
                      key={columnIndex}
                      title={columnTitle}
                      displayTitle={displayColumnTitle}
                    >
                      {panels.map((panel) => (
                        <Panel
                          hidePanel={
                            hiddenPanels.includes(panel.id) ||
                            (isViewerFullScreen && panel.options && !panel.options.allowFloat)
                          }
                          columnIndex={columnIndex}
                          pageIndex={pageIndex}
                          panelIndex={panel.panelIndex}
                          sectionIndex={sectionIndex}
                          key={panel.panelIndex}
                          site={site}
                          onHidePanel={togglePanel}
                          pageType={pageConfig?.pageType}
                          {...panel}
                        />
                      ))}
                    </Column>
                  )
                )}
              </Section>
            )
          )}
        </div>
        {isViewerFullScreen && allowFloating && (
          <div className="dock-tools">
            <div>
              {floatingPanels.map((panel) => (
                <Button
                  className={`${hiddenPanels.includes(panel.id) ? '' : 'active'}`}
                  key={panel.id}
                  onClick={() => togglePanel(panel.id)}
                >
                  {panel.title}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="page-loader">
      <Loader
        overlay
        text={`Retrieving ${site.name}...`}
      />
    </div>
  );
};

export default Page;
