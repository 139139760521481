.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;
}
.base-view .panel {
  padding: 0 0 1rem;
  min-height: calc(100vh - 1px);
  display: flex;
  flex-direction: column;
}
.base-view .loading-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXciLCJzb3VyY2VzIjpbIkJhc2VWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnQGF2dGpzL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzcyc7XG5cbi5zaXRlLWRldGFpbC12aWV3LWNvbXBvbmVudCB7XG4gIHBhZGRpbmctbGVmdDogNHJlbTtcbn1cblxuLm5vLXNpZGUtbWFyZ2luIHtcbiAgcGFkZGluZy1sZWZ0OiAwICFpbXBvcnRhbnQ7XG59XG5cbi5iYXNlLXZpZXcge1xuICBwYWRkaW5nLWxlZnQ6IDRyZW07XG4gIHBhZGRpbmctdG9wOiAxcHg7XG5cbiAgLnBhbmVsIHtcbiAgICBwYWRkaW5nOiAwIDAgMXJlbTtcbiAgICBtaW4taGVpZ2h0OiBjYWxjKDEwMHZoIC0gMXB4KTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIH1cblxuICAubG9hZGluZy1jb250YWluZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogOTB2aDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIH1cbn1cbiJdfQ== */