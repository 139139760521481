import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, Spinner, Can } from '@avtjs/react-components';

import VersionListItem from '../VersionListItem';
import Delete from '../../../../Delete';
import JSONEditor from '../../../../JSONEditor';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';
import ModelEditModal from '../ModelEditModal';

import { versionSchema } from '../../schemas/versionSchema';
import { getModelIdsWithProcessingVersions, getUploadingIds } from '../../../../../bundles/models';

const ModelListItem = ({
  model,
  site,
  schema,
  onDelete,
  onSubmit,
  uploads,
  withMappingButtons,
  // onAddVersion,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [displayModelEditModal, setDisplayModelEditModal] = useState(false);

  const processingIds = useSelector(getModelIdsWithProcessingVersions);
  const uploadingIds = useSelector(getUploadingIds);

  const updateFields = {
    name: model.name,
    modelType: model.modelType,
  };

  const latestVersion = useMemo(
    () =>
      model.versions
        .filter((v) => v.status === 'COMPLETED' && !v.removed)
        .reduce((acc, v) => {
          if (acc.versionNumber > v.versionNumber) return acc;
          return v;
        }, {}),
    [JSON.stringify(model.versions)]
  );

  const hasPendingVersions = processingIds.includes(model.id);
  const isUploading = uploadingIds.includes(model.id);
  const disabled = hasPendingVersions || isUploading || displayModelEditModal;

  return (
    <StyledItem
      itemClass="model-list-item"
      active={isOpen}
      onClick={() => setOpen(!isOpen)}
      headerLabel={
        <TypeLabel
          color={hasPendingVersions ? 'yellow' : 'grey'}
          text={model.modelType}
        />
      }
      headerContent={<div className="title">{model.name}</div>}
      headerActions={
        <>
          {(hasPendingVersions || isUploading) && (
            <Spinner
              size="s"
              className="spinner"
            />
          )}
          {withMappingButtons && (
            <Button
              activity="primary"
              slim
              disabled={disabled}
              onClick={() => setDisplayModelEditModal(true)}
              title={disabled ? 'Available once upload & processing is completed' : ''}
            >
              Edit mapping
            </Button>
          )}
          <Icon icon={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />
        </>
      }
    >
      <JSONEditor
        title="Model properties"
        formData={updateFields}
        schema={schema}
        onFormSubmit={({ formData }) => onSubmit(model.id, { ...model, ...formData })}
        saveButtonText={'Update model'}
      />

      {model.versions.length > 0 ? (
        <VersionListItem
          key={model.versions.at(0)}
          version={model.versions.at(0)}
          model={model}
          schema={versionSchema}
          upload={uploads.find((u) => u.versionId === model.versions.at(0).id)}
        />
      ) : (
        ''
      )}

      <Can
        permission="models/Delete"
        scope={{ org: site.org, site: site.id }}
      >
        <Delete
          onDelete={() => onDelete(model.id)}
          title="Delete model"
        />
      </Can>

      {displayModelEditModal && (
        <ModelEditModal
          geometryMapping
          sceneConfig
          title={`${model.name} - v${latestVersion.versionNumber}`}
          model={model}
          version={latestVersion}
          onCloseModal={() => setDisplayModelEditModal(false)}
          activeExtension="mapping"
        />
      )}
    </StyledItem>
  );
};

export default ModelListItem;
