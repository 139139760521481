import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropdownMenu } from '../../DropdownMenu';

import { deleteBookmark } from '../../../bundles/bookmarks';

const FileBookmarks = ({ file, activeBookmark, setActiveBookmark, onEditBookmark }) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState([]);

  const sortedBookmarks = useMemo(
    () =>
      (file.bookmarks || []).sort((a, b) => {
        const sortKey = file.fileType === 'video' ? 'time' : 'page';
        return a.bookmarkData[sortKey] - b.bookmarkData[sortKey];
      }),
    [file.bookmarks]
  );

  const handleOnClickDelete = (bookmark) => {
    dispatch(deleteBookmark(file.id, bookmark.id));
  };

  return (
    <div className="file-bookmarks custom-scrollbar">
      {sortedBookmarks.length === 0 && <div className="no-bookmarks">No bookmarks</div>}
      {sortedBookmarks.map((bookmark) => {
        const options = [
          {
            label: 'Edit bookmark',
            value: 'edit',
            onSelect: () => onEditBookmark(bookmark),
          },
          {
            label: 'Delete bookmark',
            value: 'delete',
            onSelect: () => handleOnClickDelete(bookmark),
          },
        ];

        return (
          <div
            className={`bookmark-list-item ${activeBookmark === bookmark.id ? 'active' : ''}`}
            key={bookmark.id}
            onClick={() => setActiveBookmark(bookmark.id)}
          >
            <div>
              <div className="bookmark-name">{bookmark.name}</div>
              <div
                className="bookmark-context"
                onClick={() => setActiveBookmark(bookmark.id)}
              >
                {file.fileType === 'pdf'
                  ? `Page ${bookmark.bookmarkData.page}`
                  : `Time ${bookmark.bookmarkData.time}s`}
              </div>
            </div>
            <DropdownMenu
              trigger="avt-menu-dots"
              menuOptions={options}
              className={`bookmark-list-item--menu ${
                menuOpen.includes(bookmark.id) ? 'active' : ''
              }`}
              menuXPlacement="left"
              onMenuOpen={() => setMenuOpen(() => [...menuOpen, bookmark.id])}
              onMenuClose={() => setMenuOpen(() => menuOpen.filter((id) => id !== bookmark.id))}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FileBookmarks;
