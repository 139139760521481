import React from 'react';
import classnames from 'classnames';
import { Icon, Spinner } from '@avtjs/react-components';

const StepIndicator = ({
  title,
  icon,
  active,
  checked,
  wizardType,
  onSelect,
  disabled,
  loading,
}) => {
  const activeClass = classnames({ 'step-indicator--active': active });
  const completedClass = classnames({ checked });
  const disabledClass = classnames({ 'step-indicator--disabled': disabled });

  return (
    <li
      className={`step-indicator step-indicator--${wizardType} ${activeClass} ${disabledClass}`}
      onClick={disabled ? () => null : onSelect}
      title={disabled ? 'Requires at least one source' : ''}
    >
      <div className="step-indicator__title-container">
        <div className="step-indicator__icon">
          <Icon icon={icon} />
        </div>
        <div className="step-indicator__title">{title}</div>
      </div>
      {loading ? (
        <Spinner
          size="s"
          className="spinner"
        />
      ) : (
        <div className={`step-indicator__completion ${completedClass}`}>
          <Icon icon="check-circle" />
        </div>
      )}
    </li>
  );
};

export default StepIndicator;
