import React from 'react';

import { Button, Icon } from '@avtjs/react-components';

const MoveItem = ({ move, index, count }) => (
  <div className="move-item-component">
    <Button
      disabled={index - 1 < 0}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        move(index, index - 1);
      }}
      activity="secondary"
      icon={<Icon icon="keyboard-arrow-up" />}
      slim
    />
    <Button
      disabled={index + 1 > count - 1}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        move(index, index + 1);
      }}
      activity="secondary"
      icon={<Icon icon="keyboard-arrow-down" />}
      slim
    />
  </div>
);

export default MoveItem;
