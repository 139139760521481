import React from 'react';
import { Button } from '@avtjs/react-components';

const StepNavigator = ({ onBack, onSkip, onNext, nextDisabled, onClose, nextLabel }) => (
  <div className="step-navigator">
    <div>
      {onBack ? (
        <Button
          activity="secondary"
          className="step-navigator__button-back"
          onClick={onBack}
        >
          {'Previous'}
        </Button>
      ) : null}
    </div>
    <div>
      {onSkip ? (
        <Button
          activity="secondary"
          onClick={onSkip}
        >
          Skip
        </Button>
      ) : null}
      {onNext ? (
        <Button
          onClick={onNext}
          className="step-navigator__button-next"
          disabled={nextDisabled}
        >
          {nextLabel || 'Next'}
        </Button>
      ) : null}
      {onClose ? (
        <Button
          className="step-navigator__button-close"
          onClick={onClose}
        >
          Done
        </Button>
      ) : null}
    </div>
  </div>
);

export default StepNavigator;
