import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@avtjs/react-components';
import { capitalize } from '../../../../../utils';
import Delete from '../../../../Delete';
import JSONEditor from '../../../../JSONEditor';
import MoveItem from '../MoveItem';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';

import { panelSchema, getExtendedPanelSchema } from '../../../../panels/panelSchema';
import { getComponentsPanelSchema } from '../../../../panels/ComponentsPanel';
import { getFilesPanelSchema } from '../../../../panels/FilesPanel';
import { textPanelSchema } from '../../../../panels/TextPanel';
import { tfrPanelSchema } from '../../../../panels/TFRPanel';
import { getVisualizationsPanelSchema } from '../../../../panels/VisualizationsPanel';
import { healthPanelSchema } from '../../../../panels/HealthPanel';
import { getComponentPropertiesPanelSchema } from '../../../../panels/ComponentPropertiesPanel';

import { useFilesSession, requestFiles, getFiles } from '../../../../../bundles/files';
import { requestTypes, getTypes } from '../../../../../bundles/types';
import { requestTags, getTags } from '../../../../../bundles/tags';
import { getStaticComponents } from '../../../../../bundles/components';
import { getVisualizations } from '../../../../../bundles/visualizations';
import { getActiveSite } from '../../../../../bundles/sites';
import { getUserEventTypes, requestUserEventTypes } from '../../../../../bundles/events';
import { getEventTimeLinePanelSchema } from '../../../../panels/EventTimelinePanel/jsonSchema';

const ManagePanels = ({
  activeTree = {},
  panels = [],
  updatePanel,
  movePanel,
  removePanel,
  columnActive = false,
  setActiveTreeIndexes,
}) => {
  const dispatch = useDispatch();
  const panelRef = useRef(null);
  const { org, id: siteId } = useSelector(getActiveSite);

  const sessionId = useFilesSession();
  const { files } = useSelector((state) => getFiles(state, sessionId));
  const types = useSelector(getTypes);
  const tags = useSelector(getTags);
  const visualizations = useSelector(getVisualizations);
  const stateComponents = useSelector(getStaticComponents);
  const eventTypes = useSelector(getUserEventTypes);

  const { panel: activePanelIndex = null } = activeTree;

  useEffect(() => {
    dispatch(
      requestFiles(sessionId, {
        org,
        site: siteId,
        sortBy: 'filename',
        order: 'asc',
      })
    );
    dispatch(requestTypes(siteId));
    dispatch(requestTags(siteId));
    dispatch(requestUserEventTypes());
  }, []);

  const components = useMemo(() => stateComponents, [JSON.stringify(stateComponents)]);

  const updatedFilesPanelSchema = useMemo(
    () => getFilesPanelSchema(files || [], types || [], tags || []),
    [files && JSON.stringify(files.map((f) => f.id)), JSON.stringify(types), JSON.stringify(tags)]
  );

  const updatedEventTimelineSchema = useMemo(
    () => getEventTimeLinePanelSchema(eventTypes || []),
    [JSON.stringify(types)]
  );

  const updatedVisualizationsPanelSchema = useMemo(
    () => getVisualizationsPanelSchema(visualizations),
    [JSON.stringify(visualizations)]
  );

  const updatedComponentsPanelSchema = useMemo(
    () => getComponentsPanelSchema(components),
    [components]
  );

  const updatedComponentPropertiesPanelSchema = useMemo(() => {
    const categories = components
      .reduce(
        (acc, cur) => [
          ...acc,
          ...(cur.properties || []).map((p) => p.category.toLowerCase()),
          // removes duplicates
        ],
        []
      )
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
    return getComponentPropertiesPanelSchema(categories);
  }, [components]);

  const extendedPanels = useMemo(
    () => ({
      components: updatedComponentsPanelSchema,
      'event-timeline': updatedEventTimelineSchema,
      files: updatedFilesPanelSchema,
      text: textPanelSchema,
      'tfr-viewer': tfrPanelSchema,
      visualizations: updatedVisualizationsPanelSchema,
      health: healthPanelSchema,
      'component-properties': updatedComponentPropertiesPanelSchema,
    }),
    [
      updatedComponentsPanelSchema,
      updatedFilesPanelSchema,
      updatedVisualizationsPanelSchema,
      updatedComponentPropertiesPanelSchema,
      updatedEventTimelineSchema,
    ]
  );

  const updatedPanelSchema = useMemo(
    () => getExtendedPanelSchema(extendedPanels),
    [panelSchema, extendedPanels]
  );

  useEffect(() => {
    if (columnActive && panelRef.current && activePanelIndex !== null) {
      panelRef.current.scrollIntoView();
    }
  }, [columnActive, panelRef.current, activePanelIndex]);

  const togglePanel = (panelIndex) => {
    if (activePanelIndex === panelIndex) {
      setActiveTreeIndexes({
        ...activeTree,
        panel: undefined,
        initial: false,
      });
    } else {
      setActiveTreeIndexes({
        ...activeTree,
        panel: panelIndex,
        initial: false,
      });
    }
  };

  const onClickMovePanel = (from, to) => {
    movePanel(from, to);
    setActiveTreeIndexes({
      ...activeTree,
      panel: to,
      initial: false,
    });
  };

  const onUpdatePanel = useCallback(
    ({ formData }) => updatePanel(activePanelIndex, formData),
    [updatePanel, activePanelIndex]
  );

  const customValidate = useCallback(
    (formData, errors) => {
      updatedPanelSchema.required.forEach((fieldName) => {
        const validValue = formData[fieldName];
        if (!validValue) {
          errors[fieldName].addError(`Panel ${capitalize(fieldName)} is required`);
        }
      });
      return errors;
    },
    [updatedPanelSchema]
  );
  // To remove duplicate error message
  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      const errorName = error.name === 'required' ? error.name : error.property;
      switch (errorName) {
        case 'required': {
          if (error.property === 'title') {
            error.message = '';
          }
          break;
        }
        case '.type': {
          error.message = '';

          break;
        }
        default:
      }
      return error;
    });

  return (
    <div
      className="manage-panels-component"
      ref={panelRef}
    >
      <div className="panel-list">
        <div className="panel-handles">
          {panels.map((panel, panelIndex) => (
            <StyledItem
              key={panelIndex}
              itemClass={`panel-handle ${activePanelIndex === panelIndex ? 'selected' : ''}`}
              onClick={() => togglePanel(panelIndex)}
              headerLabel={
                <TypeLabel
                  type="panel"
                  text={panel.type}
                />
              }
              headerContent={<div className="title">{panel.title}</div>}
              headerActions={
                <>
                  <MoveItem
                    move={onClickMovePanel}
                    index={panelIndex}
                    count={panels.length}
                  />
                  <Icon icon="keyboard-arrow-right" />
                </>
              }
            />
          ))}
        </div>
        <div className="panel-editor">
          {columnActive && activePanelIndex !== null ? (
            <>
              {panels.map((panel, panelIndex) => (
                <div key={panelIndex}>
                  {panelIndex === activePanelIndex ? (
                    <JSONEditor
                      title="Panel properties"
                      context={panels[activePanelIndex]}
                      formData={panels[activePanelIndex]}
                      schema={updatedPanelSchema}
                      onFormSubmit={onUpdatePanel}
                      saveButtonText={'Update panel properties'}
                      customValidate={customValidate}
                      customTransformErrors={(errors) => transformErrors(errors)}
                      editorOnly
                    />
                  ) : null}
                </div>
              ))}
              <Delete
                onDelete={() => {
                  setActiveTreeIndexes({
                    ...activeTree,
                    panel: undefined,
                    initial: false,
                  });
                  removePanel(activePanelIndex);
                }}
                title="Delete panel"
              />
            </>
          ) : (
            <div className="empty">Create or select a panel</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePanels;
