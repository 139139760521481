import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner, Icon } from '@avtjs/react-components';

import {
  requestModels,
  createModelAndInitialVersion,
  addModelVersion,
  updateModel,
  deleteModel,
  getModelsLoaded,
  getAllModels,
  getModelIdsWithProcessingVersions,
  PENDING,
  PROCESSING,
} from '../../../bundles/models';

import { getUploads, getUploaded } from '../../../bundles/uploads';
import { getActiveSite, requestModelThumbnail, getThumbnails } from '../../../bundles/sites';

import Heading from '../../Heading';
import SimpleModal from '../../SimpleModal';
import ModelListItem from './components/ModelListItem';
import CreateModelModal from './components/CreateModelModal';
import { modelSchema } from './schemas/modelSchema';

const ModelView = ({ wizardType, withMappingButtons = false }) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const hashmap = useSelector(getAllModels);
  const modelsLoaded = useSelector(getModelsLoaded);
  const uploads = useSelector(getUploads);
  const completedUploads = useSelector(getUploaded);
  const processingIds = useSelector(getModelIdsWithProcessingVersions);
  const thumbnails = useSelector(getThumbnails);

  const [displayCreateModel, setDisplayCreateModel] = useState({ show: false, modelId: null });
  const [siteModels, setSiteModels] = useState([]);

  useEffect(() => {
    if (site.id) {
      dispatch(requestModels(site.id));
    }
  }, [site]);

  useEffect(() => {
    setSiteModels(() => Object.values(hashmap));
  }, [hashmap]);

  useEffect(() => {
    siteModels.forEach((model) => {
      model.versions.forEach((version) => {
        if (
          !(version.outputs || []).some((o) => [PENDING, PROCESSING].includes(o.status)) &&
          !thumbnails[`${model.id}/${version.id}`]
        ) {
          dispatch(requestModelThumbnail(model.id, version.id));
        }
      });
    });
  }, [siteModels, thumbnails]);

  useEffect(() => {
    let pollingDelay;
    if (completedUploads.length) {
      let shouldPoll = false;
      completedUploads.forEach(({ modelId, versionId }) => {
        if (hashmap[modelId]) {
          const version = hashmap[modelId].versions.find((v) => v.id === versionId);
          if (
            !version ||
            (version.outputs || []).some((o) => [PENDING, PROCESSING].includes(o.status))
          ) {
            shouldPoll = true;
          }
        }
      });
      if (shouldPoll) {
        pollingDelay = setTimeout(() => dispatch(requestModels(site.id)), 3000);
      } else {
        clearTimeout(pollingDelay);
      }
    }
    return () => clearTimeout(pollingDelay);
  }, [completedUploads]);

  const onDelete = useCallback(
    (modelId) => {
      dispatch(deleteModel(site.id, modelId));
    },
    [site]
  );

  const onUpdate = useCallback(
    (modelId, data) => {
      const update = {
        org: data.org,
        site: site.id,
        name: data.name,
        modelType: data.modelType,
      };
      dispatch(updateModel(modelId, update));
    },
    [site]
  );

  const onCreate = useCallback(
    (formData, file, rootFile, componentId) => {
      if (displayCreateModel.modelId) {
        dispatch(
          addModelVersion(
            {
              ...formData,
              site: site.id,
              org: site.org,
              file,
              rootFile,
            },
            displayCreateModel.modelId
          )
        );
      } else {
        dispatch(
          createModelAndInitialVersion(
            {
              ...formData,
              site: site.id,
              org: site.org,
              file,
              rootFile,
            },
            componentId
          )
        );
      }
      setDisplayCreateModel({ show: false, modelId: null });
    },
    [site, !!displayCreateModel.modelId]
  );

  const onClose = useCallback(() => {
    setDisplayCreateModel({ show: false, modelId: null });
  }, [setDisplayCreateModel]);

  const wizClass = wizardType ? `--${wizardType}` : '';

  return (
    <>
      {modelsLoaded ? (
        <>
          <Heading
            contentLeft={<div className="title">Models</div>}
            contentRight={
              <Button
                className={`button-create${wizardType ? wizClass : ''}`}
                onClick={() => setDisplayCreateModel({ show: true, modelId: null })}
              >
                Add new model
              </Button>
            }
          />
          {wizardType && (
            <div className={`model-upload-info${!processingIds.length ? '--hidden' : ''}`}>
              <Icon icon="abb-information-circle-1" />
              <div>
                <p>
                  {"It's possible to continue configuring your site while models are uploading, " +
                    "but don't forget to do the geometry mapping once they are complete."}
                </p>
              </div>
            </div>
          )}
          {siteModels.length > 0 ? (
            siteModels.map((model, modelIndex) => (
              <ModelListItem
                key={modelIndex}
                model={model}
                site={site}
                schema={modelSchema}
                onDelete={onDelete}
                onSubmit={onUpdate}
                uploads={uploads.filter((u) => u.modelId === model.id)}
                withMappingButtons={withMappingButtons}
                onAddVersion={setDisplayCreateModel}
              />
            ))
          ) : (
            <div>No models yet, create one to get started</div>
          )}
        </>
      ) : (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      )}
      {displayCreateModel.show && (
        <SimpleModal
          onClose={onClose}
          title={`Add ${displayCreateModel.modelId ? 'new model version' : 'new model'}`}
          className="create-model"
        >
          <CreateModelModal
            site={site}
            schema={modelSchema}
            modelId={displayCreateModel.modelId}
            onSubmit={onCreate}
            onCloseModal={onClose}
          />
        </SimpleModal>
      )}
    </>
  );
};

export default ModelView;
