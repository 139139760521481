import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Spinner, useAuth, Input } from '@avtjs/react-components';

import EditIntegrationModal from './EditIntegrationModal';
import ListItem from '../../ListItem';
import Heading from '../../Heading';
import {
  requestIntegrations,
  getAllIntegrations,
  getSiteIntegrations,
  createIntegration,
  createIntegrationTemplate,
  updateIntegration,
  updateIntegrationTemplate,
  deleteIntegration,
  getIntegrationsLoaded,
  getIntegrationTypes,
  getTemplates,
  syncRemoteAssets,
} from '../../../bundles/integrations';
import { getActiveSite, requestSites, getSites } from '../../../bundles/sites';
import { importSource, getSources, getImportableSourceTypes } from '../../../bundles/sources';

const IntegrationView = ({ templateView = false, siteView = true }) => {
  const dispatch = useDispatch();
  const { id: siteId } = useSelector(getActiveSite);
  const { realm } = useAuth();
  const integrations = useSelector(siteView ? getSiteIntegrations : getAllIntegrations);
  const templates = useSelector(getTemplates);
  const integrationTypes = useSelector(getIntegrationTypes);
  const sites = useSelector(getSites);
  const sources = useSelector(getSources);
  const importableSourceTypes = useSelector(getImportableSourceTypes);
  const [syncingData, setSyncingData] = useState(false);
  const [syncingAssets, setSyncingAssets] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const integrationsLoaded = useSelector(getIntegrationsLoaded);
  const [showCreateIntegrationForm, setShowCreateIntegrationForm] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (realm) {
      dispatch(requestIntegrations({ org: realm }));
      dispatch(requestSites());
    }
  }, [siteId, realm]);

  const sortedIntegrationsWithSites = useMemo(() => {
    if (integrations) {
      const integrationsWithSiteNames = integrations.map((i) => {
        const site = sites.find((s) => s.id === i.site);
        return {
          ...i,
          siteName: site?.name,
        };
      });
      if (sortAscending) {
        return [...integrationsWithSiteNames.sort((a, b) => a.name.localeCompare(b.name))];
      }
      return [...integrationsWithSiteNames.sort((a, b) => b.name.localeCompare(a.name))];
    }
    return {};
  }, [integrations, sortAscending, sites]);

  const onSubmit = useCallback(
    (data, create = true) => {
      const templateData = {
        ...data,
        org: realm,
      };
      const integrationData = { ...templateData };
      if (siteId && !integrationData.site) {
        integrationData.site = siteId;
      }

      if (templateView && create) {
        dispatch(createIntegrationTemplate(templateData));
      } else if (templateView) {
        dispatch(updateIntegrationTemplate(data.id, templateData));
      } else if (create) {
        dispatch(createIntegration(integrationData));
      } else {
        dispatch(updateIntegration(data.id, integrationData));
      }
      setShowCreateIntegrationForm(false);
    },
    [templateView, siteId, realm]
  );

  const onClose = () => setShowCreateIntegrationForm(false);

  const onDeleteIntegration = useCallback((integrationId) => {
    dispatch(deleteIntegration(integrationId));
  }, []);

  const handleSort = useCallback(() => setSortAscending(!sortAscending), [sortAscending]);

  const orderIcon = sortAscending ? 'abb-caret-down' : 'abb-caret-up';
  const headerColumns = [
    <Button
      key="integration-header-name"
      onClick={handleSort}
      icon={<Icon icon={orderIcon} />}
      iconPosition="right"
      activity="secondary"
    >
      Integration name
    </Button>,
  ];

  const getListItemColumns = (integration, i) => {
    const siteName = !siteView && integration.siteName ? `(${integration.siteName})` : '';
    return [
      <div
        key={`integration-list-item-${i}-name`}
        className="ellipsed-text"
      >
        {`${integration.name} ${siteName}`}
      </div>,
    ];
  };

  const getIntegrationModal = useCallback(
    ({ creating, isTemplate } = {}) => (
      <EditIntegrationModal
        integrationTypes={integrationTypes}
        onCloseModal={onClose}
        siteView={siteView}
        isTemplate={isTemplate}
        creating={creating}
        onSubmit={onSubmit}
        templates={templates}
        overlayCanClose={false}
        siteIntegrations={siteView ? integrations : []}
      />
    ),
    [templates, integrationTypes, siteView]
  );

  const filteredIntegrations = useMemo(() => {
    const nonTemplateIntegrations = sortedIntegrationsWithSites.filter(
      (integration) => integration.site
    );
    if (searchText) {
      const filters = searchText.trim().split(' ');

      return nonTemplateIntegrations.filter((i) => {
        return (
          filters.every((f) => (i.name || '').toLowerCase().indexOf(f.toLowerCase()) !== -1) ||
          filters.every((f) => (i.siteName || '').toLowerCase().indexOf(f.toLowerCase()) !== -1)
        );
      });
    }

    return nonTemplateIntegrations;
  }, [sortedIntegrationsWithSites, searchText]);

  const columnWidths = { type: 'rem', widths: ['fill'] };

  const handleSyncAssets = (integration) => {
    setSyncingAssets(true);
    dispatch(syncRemoteAssets(integration.id, integration.site, () => setSyncingAssets(false)));
  };

  const handleSyncData = (site, source) => {
    setSyncingData(true);
    dispatch(importSource(site, source, () => setSyncingData(false)));
  };

  const integrationList = useMemo(() => {
    if (!integrationsLoaded) {
      return (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      );
    }
    return (templateView ? templates : filteredIntegrations).map((integration, i) => {
      const extraMenuActions = [];
      const integratedSource = sources.find((source) => source.integration === integration.id);

      if (siteId && integration.configuration?.asset && integration.type !== 'custom') {
        extraMenuActions.push({
          text: syncingAssets ? <Spinner size="s" /> : 'Sync assets',
          action: () => (syncingAssets ? null : handleSyncAssets(integration)),
          placement: 'after',
        });
      }

      if (integratedSource && siteId && importableSourceTypes.includes(integratedSource.type)) {
        extraMenuActions.push({
          text: syncingData ? <Spinner /> : 'Sync data',
          action: () => handleSyncData(siteId, integratedSource.id),
          placement: 'after',
        });
      }

      return (
        <ListItem
          key={integration.id}
          itemIndex={i}
          entity={`Integration (${integration.name})`}
          item={integration}
          columns={getListItemColumns(integration, i)}
          columnWidths={columnWidths}
          onDelete={() => onDeleteIntegration(integration.id)}
          confirmationDialogTitle={'Remove integration'}
          confimationDialogBody={
            <>
              <p style={{ paddingTop: '1.5rem' }}>
                Are you sure you want to remove this integration?
              </p>
            </>
          }
          CustomEditModal={getIntegrationModal({ isTemplate: templateView })}
          extraMenuActions={siteView ? extraMenuActions : undefined}
        />
      );
    });
  }, [
    integrationsLoaded,
    filteredIntegrations,
    siteId,
    syncingAssets,
    syncingData,
    importableSourceTypes,
    sources,
  ]);

  return (
    <>
      <Heading
        contentLeft={
          !siteView && !templateView ? (
            <Input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search by integration or site name"
              className="user-filter"
            />
          ) : null
        }
        contentRight={
          (templateView || siteView) && (
            <Button
              onClick={() => setShowCreateIntegrationForm(true)}
              className="button-create"
              icon={<Icon icon="abb-plus" />}
            >
              {`Add ${templateView ? 'Template' : 'Integration'}`}
            </Button>
          )
        }
      />
      <div className="manage-integrations-body custom-thin-scrollbar">
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerColumns}
            columnWidths={columnWidths}
          />
          {integrationList}
        </div>
      </div>
      {showCreateIntegrationForm &&
        getIntegrationModal({ creating: true, isTemplate: templateView })}
    </>
  );
};

export default IntegrationView;
