import { camelToSentence } from '../../../../utils';

const componentIconList = [
  'avt-dot',
  'avt-reactor',
  'abb-04-variables',
  'abb-battery-empty',
  'abb-battery-half',
  'abb-battery-full',
  'abb-binary-data',
  'abb-close',
  'abb-controller-cabinet',
  'abb-double-right',
  'abb-drive',
  'abb-fieldbus',
  'abb-folder',
  'abb-group',
  'abb-home',
  'abb-io-devices',
  'abb-key',
  'abb-joystick',
  'abb-level',
  'abb-magnet',
  'abb-network',
  'abb-panel',
  'abb-plugin',
  'abb-pump',
  'abb-robot-tool',
  'abb-robot',
  'abb-sld-1',
  'abb-sld-2',
  'abb-solar-building',
  'abb-stop',
  'abb-system',
  'abb-temperature-celsius-2',
  'abb-temperature-fahrenheit-2',
  'abb-temperature',
  'abb-track-2',
  'abb-transformer',
  'abb-tree-view',
  'abb-video-camera-on',
];

const getComponentSchema = (
  component = {},
  components = [],
  models = [],
  sources = [],
  sourceTypes = [],
  stateSets = [],
  variables = [],
  thumbnails = []
) => {
  const filteredSources = sources.filter((s) => sourceTypes.map((st) => st.name).includes(s.type));
  const areaStateSets = stateSets.filter((stateset) => !!stateset.isAreaStateSet);
  const modelSchema = models.length
    ? {
        models: {
          type: 'array',
          title: 'Models',
          items: {
            type: 'object',
            title: '',
            required: ['modelId', 'customName'],
            properties: {
              modelId: {
                type: 'string',
                title: 'Model',
                placeholder: 'Select a model',
                oneOf: models.map((model) => ({
                  const: model.id,
                  title: `${model.name} ${model.id}`,
                })),
              },
              customName: {
                type: 'string',
                title: 'Display name',
              },
            },
            dependencies: {
              modelId: {
                oneOf: models.map((m) => {
                  const hasCustomThumbnail =
                    m.versions?.[0]?.thumbnail?.split(':')[1]?.split('/')[0] === 'image';
                  return {
                    properties: {
                      modelId: {
                        enum: [m.id],
                      },
                      image: {
                        type: 'string',
                        title: ' ',
                        thumbnail:
                          (hasCustomThumbnail && m.versions?.[0]?.thumbnail) ||
                          thumbnails[`${m.id}/${m.versions?.[0]?.id}`],
                      },
                    },
                  };
                }),
              },
            },
          },
        },
      }
    : {};

  const commonNonVirtualProps = {
    icon: {
      type: 'string',
      title: 'Icon',
      icons: componentIconList,
      showLabel: false,
    },

    ...modelSchema,

    source: {
      type: ['string', 'null'],
      title: 'Data Source',
      default: null,
      placeholder: 'None',
      oneOf: [
        {
          const: null,
          title: 'None',
        },
        ...filteredSources.map((source) => ({
          const: source.id,
          title: source.name,
        })),
      ],
    },

    ...(filteredSources.length
      ? {
          eventSources: {
            type: 'array',
            title: 'Event Sources',
            items: { $ref: '#/definitions/eventSourceItems' },
          },
        }
      : {}),

    ...(variables.length
      ? {
          variables: {
            isMulti: true,
            type: 'array',
            title: 'Status Signals',
            uniqueItems: true,
            default: [],
            items: {
              type: ['string', 'null'],
              anyOf: [
                {
                  const: null,
                  title: 'None',
                },
                ...variables.map((variable) => ({
                  const: variable.id,
                  title: variable.name,
                })),
              ],
            },
          },
        }
      : {}),
  };

  const sortedCompsNotSelfOrDescendant = components
    .toSorted((a, b) =>
      a.itemDesignation.toLowerCase().localeCompare(b.itemDesignation.toLowerCase())
    )
    .filter((c) => c.id !== component.id && !component.descendantIds?.includes(c.id));

  const commonNonSiteProps = {
    parent: {
      type: 'string',
      title: 'Parent',
      anyOf: sortedCompsNotSelfOrDescendant.map((comp) => ({
        const: comp.id,
        title: `${comp.name} (${comp.itemDesignation})`,
      })),
    },
  };

  const sourceSchemaConfigProperties = filteredSources.map((source) => {
    const sourceType = sourceTypes.find((st) => st.name === source.type);
    const sourceSchemaProperties = {
      source: { enum: [source.id] },
    };

    if (sourceType && Object.keys(sourceType.schemas.component.properties).length) {
      sourceSchemaProperties.sourceOptions = {
        type: 'object',
        title: '',
        required: sourceType.schemas.component.required,
        properties: {
          ...Object.entries(sourceType.schemas.component.properties).reduce((acc, [k, v]) => {
            acc[k] = { ...v, title: camelToSentence(k) };
            return acc;
          }, {}),
        },
      };
    }
    return { properties: sourceSchemaProperties };
  });

  const schema = {
    type: 'object',
    required: ['name', 'type', 'itemDesignation'],

    definitions: {
      eventSourceItems: {
        title: '',
        type: 'object',

        properties: {
          name: {
            type: 'string',
            title: 'Name',
          },
          description: {
            type: 'string',
            title: 'Description',
          },
          source_id: {
            type: ['string', 'null'],
            title: 'Data Source',
            default: null,
            oneOf: [
              {
                const: null,
                title: 'None',
              },
              ...filteredSources.map((source) => ({
                const: source.id,
                title: source.name,
              })),
            ],
          },
        },

        dependencies: {
          source_id: {
            oneOf: [
              {
                properties: {
                  source_id: { enum: [null] },
                },
              },
              ...filteredSources.map((source) => {
                const sourceType = sourceTypes.find((st) => st.name === source.type);

                return {
                  properties: {
                    source_id: { enum: [source.id] },

                    options: {
                      type: 'object',
                      title: '',
                      required: sourceType.schemas.event.required,
                      properties: {
                        ...Object.entries(sourceType.schemas.event.properties).reduce(
                          (acc, [k, v]) => {
                            acc[k] = { ...v, title: camelToSentence(k) };
                            return acc;
                          },
                          {}
                        ),
                      },
                    },
                  },
                };
              }),
            ],
          },
        },
      },
    },

    properties: {
      name: {
        type: 'string',
        title: 'Name',
        default: '',
      },

      itemDesignation: {
        type: 'string',
        title: 'Item Designation',
      },
    },

    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: { enum: ['virtual'] },
              ...(component.type !== 'site' ? commonNonSiteProps : {}),
              ...(areaStateSets.length
                ? {
                    stateset_id: {
                      type: ['string', 'null'],
                      title: 'Area status',
                      placeholder: 'Select a state',
                      default: null,
                      oneOf: [
                        {
                          const: null,
                          title: 'None',
                        },
                        ...areaStateSets.map((stateset) => ({
                          const: stateset.id,
                          title: stateset.name,
                        })),
                      ],
                    },
                  }
                : {}),
            },
          },
          {
            properties: {
              type: { enum: ['branch', 'group', 'component'] },
              ...(component.type !== 'site' ? commonNonSiteProps : {}),
              ...commonNonVirtualProps,
            },
            dependencies: {
              source: {
                oneOf: [
                  {
                    properties: {
                      source: { enum: [null, undefined, ''] },
                    },
                  },
                  ...sourceSchemaConfigProperties,
                ],
              },
            },
          },
          {
            properties: {
              type: { enum: ['site'] },
              ...commonNonVirtualProps,
            },
            dependencies: {
              source: {
                oneOf: [
                  {
                    properties: {
                      source: { enum: [null, undefined, ''] },
                    },
                  },
                  ...sourceSchemaConfigProperties,
                ],
              },
            },
          },
        ],
      },
    },
  };

  if (component.type !== 'site') {
    schema.properties.type = {
      type: 'string',
      title: 'Type',
      default: 'component',
      oneOf: [
        { const: 'branch', title: 'Branch' },
        { const: 'group', title: 'Group' },
        { const: 'component', title: 'Component' },
        { const: 'virtual', title: 'Area' },
      ],
    };
    schema.required.push('parent');
  } else {
    schema.properties = {
      type: {
        type: 'string',
        title: 'Type',
        default: 'site',
        oneOf: [{ const: 'site', title: 'Site' }],
      },
      ...schema.properties,
      ...commonNonVirtualProps,
    };
  }
  return schema;
};

const VALID_ORDERED_COMPONENT_KEYS = [
  'name',
  'itemDesignation',
  'parent',
  'type',
  'icon',
  'source', // component.source_id re-keyed (duplicate key error with event source keys)
  'sourceOptions', // component.options re-keyed
  'sourceConfig',
  'models',
  'eventSources',
  'stateset_id',
  'variables',
];

const componentUiSchema = {
  'ui:order': VALID_ORDERED_COMPONENT_KEYS,
  icon: {
    'ui:field': 'iconPicker',
  },
  options: {
    'ui:classNames': 'field-array',
  },
  sourceConfig: {
    'ui:classNames': 'field-array',
  },
  models: {
    items: {
      image: {
        'ui:field': 'modelArrayField',
      },
    },
  },
};

export { getComponentSchema, componentUiSchema, VALID_ORDERED_COMPONENT_KEYS };
