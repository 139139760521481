#global-modal-container .signal-viewer-template-modal {
  z-index: 10000;
}

.confirmation-dialog {
  margin-top: 5rem !important;
}
.confirmation-dialog.delete-template .template-name {
  font-weight: bold;
}
.confirmation-dialog .body {
  padding: 0.5rem 0;
}
.confirmation-dialog .body span {
  font-weight: 700;
}
.confirmation-dialog .actions {
  width: auto;
  margin-left: 1rem;
}

.simple-modal-wrapper {
  margin-top: 5rem !important;
}

.tfr-template-modal .text-danger {
  margin-top: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvcGFuZWxzL1RGUlBhbmVsL2NvbXBvbmVudHMvTmV3VGVtcGxhdGVNb2RlbCIsInNvdXJjZXMiOlsiTmV3VGVtcGxhdGVNb2RlbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7OztBQUdGO0VBQ0U7O0FBRUU7RUFDRTs7QUFHSjtFQUNFOztBQUVBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBOzs7QUFJSjtFQUNFOzs7QUFHQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiI2dsb2JhbC1tb2RhbC1jb250YWluZXIgLnNpZ25hbC12aWV3ZXItdGVtcGxhdGUtbW9kYWwge1xuICB6LWluZGV4OiAxMDAwMDtcbn1cblxuLmNvbmZpcm1hdGlvbi1kaWFsb2cge1xuICBtYXJnaW4tdG9wOiA1cmVtICFpbXBvcnRhbnQ7XG4gICYuZGVsZXRlLXRlbXBsYXRlIHtcbiAgICAudGVtcGxhdGUtbmFtZSB7XG4gICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICB9XG4gIH1cbiAgLmJvZHkge1xuICAgIHBhZGRpbmc6IDAuNXJlbSAwO1xuXG4gICAgc3BhbiB7XG4gICAgICBmb250LXdlaWdodDogNzAwO1xuICAgIH1cbiAgfVxuXG4gIC5hY3Rpb25zIHtcbiAgICB3aWR0aDogYXV0bztcbiAgICBtYXJnaW4tbGVmdDogMXJlbTtcbiAgfVxufVxuXG4uc2ltcGxlLW1vZGFsLXdyYXBwZXIge1xuICBtYXJnaW4tdG9wOiA1cmVtICFpbXBvcnRhbnQ7XG59XG4udGZyLXRlbXBsYXRlLW1vZGFsIHtcbiAgLnRleHQtZGFuZ2VyIHtcbiAgICBtYXJnaW4tdG9wOiAwLjVyZW07XG4gIH1cbn1cbiJdfQ== */