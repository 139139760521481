import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ExportTimeSeriesModal from '../SiteAdminView/VariableView/components/ExportTimeSeriesModal';
import { getSiteVariables } from '../../bundles/sources';

import GraphVisualization, {
  schema as graphSchema,
  uiSchema as graphUiSchema,
} from './visualizations/GraphVisualization';
import MetricVisualization, {
  schema as metricSchema,
  uiSchema as metricUiSchema,
} from './visualizations/MetricVisualization';
import StatusVisualization, {
  schema as statusSchema,
  uiSchema as statusUiSchema,
} from './visualizations/StatusVisualization';
import BarGaugeVisualization, {
  schema as barGaugeSchema,
  uiSchema as barGaugeUiSchema,
} from './visualizations/BarGaugeVisualization';
import GaugeVisualization, {
  schema as gaugeSchema,
  uiSchema as gaugeUiSchema,
} from './visualizations/GaugeVisualization';
import StateVisualization, {
  schema as stateSchema,
  uiSchema as stateUiSchema,
} from './visualizations/StateVisualization';

const visualizations = [
  {
    name: 'Graph',
    type: 'graph',
    category: 'graphs',
    icon: 'abb-trend-2',
    component: GraphVisualization,
    schema: graphSchema,
    uiSchema: graphUiSchema,
  },
  {
    name: 'Metric',
    type: 'metric',
    category: 'metrics',
    icon: 'abb-numericals',
    component: MetricVisualization,
    schema: metricSchema,
    uiSchema: metricUiSchema,
  },
  {
    name: 'Status',
    type: 'status',
    category: 'metrics',
    icon: 'check-circle',
    component: StatusVisualization,
    schema: statusSchema,
    uiSchema: statusUiSchema,
  },
  {
    name: 'Bar gauge',
    type: 'bar-gauge',
    category: 'graphs',
    icon: 'abb-gauge',
    component: BarGaugeVisualization,
    schema: barGaugeSchema,
    uiSchema: barGaugeUiSchema,
  },
  {
    name: 'Gauge',
    type: 'gauge',
    category: 'graphs',
    icon: 'abb-gauge',
    component: GaugeVisualization,
    schema: gaugeSchema,
    uiSchema: gaugeUiSchema,
  },
  {
    name: 'State',
    type: 'state',
    category: 'State',
    icon: 'states',
    component: StateVisualization,
    schema: stateSchema,
    uiSchema: stateUiSchema,
  },
];

const config = (type) =>
  visualizations.find(({ type: _type }) => _type === type) || {
    schema: {},
    component: () => 'No component',
  };

const schema = (type) => config(type).schema;

const uiSchema = (type) => config(type).uiSchema;

const component = (type) => config(type).component;

const Visualization = ({ visualization, exportGranularity, ...props }) => {
  const siteVariables = useSelector(getSiteVariables);
  const variables = visualization.variables.map((v) => {
    const variable = siteVariables.find(({ id }) => id === v.id);
    return {
      ...v,
      name: variable?.name,
      source: variable?.source,
      itemDesignation: variable?.itemDesignation,
    };
  });
  const Component = component(visualization.type);

  const [showExportForm, setShowExportForm] = useState(false);

  const handleCloseExportModal = useCallback(() => setShowExportForm(false), [setShowExportForm]);

  const handleExportData = useCallback(() => {
    setShowExportForm(true);
  }, []);

  return (
    <>
      <div className="corevisualization-component">
        <Component
          visualization={{ ...visualization, variables }}
          onExportData={handleExportData}
          {...props}
        />
      </div>
      {showExportForm && visualization.variables?.length > 0 && (
        <ExportTimeSeriesModal
          selectedVizVariables={variables}
          pollingDateRange={props.pollingDateRange}
          onCloseModal={handleCloseExportModal}
          showAggregation={false}
          exportGranularity={exportGranularity}
          onExportCompleted={handleCloseExportModal}
          parentPanelId={props.panelId}
        />
      )}
    </>
  );
};

export default Visualization;
export { schema, uiSchema, config, component, visualizations };
