import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Icon, colors } from '@avtjs/react-components';

import { DropdownMenu } from '../../../../DropdownMenu';
import { useDoubleClick, replacer } from '../../../../../utils';
import { STATUSES } from '../../../../../constants';
import { getLastVariableData } from '../../../../../bundles/variables';
import { getLinkedSources } from '../../../../../bundles/sources';
import { getAllIntegrations } from '../../../../../bundles/integrations';
import { getStateSets, getActiveStates } from '../../../../../bundles/statesets';
import { getPageType } from '../../../../../bundles/application';

const DefaultStatusItem = () => <Icon icon="blank" />;

const TreeItem = React.forwardRef(
  (
    {
      activeModelId,
      canDelete,
      autoLoad,
      canEdit,
      depth,
      expanded = false,
      flat,
      hasChildren,
      item,
      viewerReady,
      onComponentClick,
      onComponentDoubleClick,
      onCreate,
      onDelete,
      onModelClick,
      onUpdate,
      selected = false,
      shouldDisplayStatus,
      toggleExpansion,
      treeRoot,
      children,
      showModels,
    },
    ref
  ) => {
    const {
      id,
      itemDesignation,
      simpleDesignation,
      icon,
      name,
      models,
      filteredStatus,
      ownStatus,
      stateset: areaState,
    } = item;

    const sources = useSelector(getLinkedSources);
    const integrations = useSelector(getAllIntegrations);
    const lastVarData = useSelector(getLastVariableData);
    const stateSets = useSelector(getStateSets);
    const pageType = useSelector(getPageType);

    const [showItemMenu, setShowItemMenu] = useState(false);
    const [StatusIcon, setStatusIcon] = useState(() => DefaultStatusItem);
    const [menuPositionY, setMenuPositionY] = useState('top');
    const [itemBodyRef, itemBody] = useDoubleClick(() => onComponentDoubleClick(id));

    const relevantHealthStatus = expanded && !flat ? ownStatus : filteredStatus;

    const findActiveStates = (data) => {
      const statefulVarsWithValues = item.variables.map((variable) => {
        const lastVariableData = Object.entries(data).find((v) => v[0] === variable.id);

        const stateset = stateSets.find((s) => s.id === variable.stateset_id);
        const [, value, quality] = lastVariableData?.[1] || [];

        return { value, stateset, quality };
      });

      return getActiveStates(statefulVarsWithValues);
    };

    const activeStates = useMemo(
      () => (item && item.variables.length ? findActiveStates(lastVarData) : []),
      [item, lastVarData]
    );

    const getStatusIcon = (status) => {
      const { color: statusColor, icon: statusIcon } = STATUSES[status.status] || {};
      const [r, g, b] = statusColor || [0, 0, 0];
      let statusText = (!status.errors && !status.warns && status.statusText) || '';
      if (status.eventText) {
        statusText = status.eventText;
      }
      return (
        <div title={statusText}>
          <Icon
            icon={statusIcon}
            style={{ color: `rgba(${r}, ${g}, ${b}, ${statusColor ? 1 : 0})` }}
            className="selected"
            key={status.id}
            size={flat ? 'm' : 's'}
          />
        </div>
      );
    };

    const getStateIcon = (states) => {
      let stateText = states[0].text;
      let stateIcon = 'status';
      let stateColor = states[0].color;

      if (states.length > 1) {
        stateText = 'Multiple active states found';
        stateIcon = 'abb-help-circle-1';
        stateColor = colors.StatusRed;
      }

      if (states[0].quality !== undefined && states[0].quality !== 1) {
        stateText = 'Component status is invalid';
        stateColor += '33'; // makes opacity 0.2
      }

      return (
        <div title={stateText}>
          <Icon
            icon={stateIcon}
            style={{ color: stateColor }}
            key={states[0].id}
            size={flat ? 'm' : 's'}
          />
        </div>
      );
    };

    useEffect(() => {
      let iconSet = false;
      switch (pageType) {
        case 'health':
          if (relevantHealthStatus && relevantHealthStatus.status >= 0) {
            setStatusIcon(() => () => getStatusIcon(relevantHealthStatus));
            iconSet = true;
          }
          break;
        case 'risk':
          if (filteredStatus && filteredStatus?.eventRiskStatus?.length > 0) {
            setStatusIcon(() => () => getStatusIcon(filteredStatus.eventRiskStatus[0]));
            iconSet = true;
          }
          break;
        case 'stateset':
          if (areaState) {
            setStatusIcon(() => () => getStateIcon([areaState.states]));
            iconSet = true;
          } else if (activeStates.length) {
            setStatusIcon(() => () => getStateIcon(activeStates));
            iconSet = true;
          }
          break;
        default:
          iconSet = false;
      }
      if (iconSet === false) {
        setStatusIcon(() => () => (
          <Icon
            icon={icon || 'avt-dot'}
            size={flat ? 'm' : 's'}
          />
        ));
      }
    }, [
      expanded,
      JSON.stringify(filteredStatus),
      JSON.stringify(ownStatus),
      activeStates,
      pageType,
    ]);

    const checkActive = (modelId) => modelId === activeModelId;

    const handleExpandClick = (e) => {
      e.stopPropagation();
      toggleExpansion(id);
    };

    const handleComponentClick = (e, modelId, isActive) => {
      e.stopPropagation();
      onComponentClick(id);
      if (!isActive && modelId && viewerReady) {
        onModelClick(modelId);
      }
    };

    const handleModelClick = (e, modelId, isActive) => {
      e.stopPropagation();
      if (!isActive && viewerReady) {
        setShowItemMenu(false);
        onModelClick(modelId);
      }
    };

    const onUpdateComponent = () => {
      setShowItemMenu(false);
      onUpdate({ show: false, componentId: id });
    };

    const onCreateComponent = () => {
      setShowItemMenu(false);
      onCreate({
        show: true,
        parent: id,
        type: 'component',
      });
    };

    const onDeleteComponent = () => {
      setShowItemMenu(false);
      onDelete({ show: false, componentId: id });
    };

    const onIntegrationLink = (link) => {
      setShowItemMenu(false);
      window.open(link, '_blank');
    };

    const getMenuPosition = () => {
      if (treeRoot && itemBody) {
        const { height: rH, top: rT } = treeRoot.getBoundingClientRect();
        const { top: iT } = itemBody.getBoundingClientRect();
        const repositionAt = rH * 0.5 + rT;

        if (iT > repositionAt) {
          setMenuPositionY('top');
        } else {
          setMenuPositionY('bottom');
        }
      }
    };

    useEffect(() => {
      if (treeRoot) {
        treeRoot.addEventListener('scroll', () => setShowItemMenu(false));
      }
      return () => {
        if (treeRoot) {
          treeRoot.removeEventListener('scroll', () => setShowItemMenu(false));
        }
      };
    }, [treeRoot]);

    const onMenuOpen = () => {
      getMenuPosition();
      setShowItemMenu(true);
    };

    let ComponentIcon = () => (
      <Icon
        icon={icon || 'avt-dot'}
        size={flat ? 'm' : 's'}
      />
    );
    if (shouldDisplayStatus) {
      ComponentIcon = StatusIcon;
    }

    let ToggleIcon;
    if (hasChildren) {
      ToggleIcon = () => (
        <div onClick={(e) => handleExpandClick(e)}>
          <Icon
            icon={expanded ? 'abb-caret-down' : 'abb-caret-right'}
            size="s"
          />
        </div>
      );
    } else {
      ToggleIcon = () => <div></div>;
    }

    const buildModelOptions = () =>
      models.map((m) => {
        let iconType;
        if (m.modelType === '2d') iconType = 'avt-2d';
        else iconType = 'avt-3d';
        const modelIsActive = checkActive(m.modelId);

        const optionClasses = classnames('tree-item-model', {
          active: modelIsActive,
          'viewer-loading': !viewerReady,
        });

        return {
          component: (
            <div
              key={m.modelId}
              className={optionClasses}
              onClick={(e) => handleModelClick(e, m.modelId, modelIsActive)}
            >
              <div>{m.customName || m.name}</div>
              <Icon
                icon={iconType}
                size="s"
              />
            </div>
          ),
        };
      });

    const itemMenuOptions = useMemo(() => {
      const options = [];

      if (models.length > 0 && showModels) {
        options.push({ options: buildModelOptions() });
      }

      const componentOptions = [];
      if (canEdit) {
        componentOptions.push(
          { component: <div>Edit Component</div>, onSelect: onUpdateComponent },
          { component: <div>Add Component</div>, onSelect: onCreateComponent }
        );
      }

      if (canDelete && !item.integrationOptions) {
        componentOptions.push({
          component: <div>Delete Component</div>,
          onSelect: onDeleteComponent,
        });
      }
      if (componentOptions.length) {
        options.push({ options: componentOptions });
      }

      const deeplinks = [];
      if (item.sourceConfig?.hasSourceLink) {
        sources.forEach((source) => {
          const { domain, componentSourceLink } = source.options;
          const link = componentSourceLink
            .replace('<domain>', domain)
            .replace('<assetId>', item.sourceConfig.assetId);

          deeplinks.push({
            component: <div className="tree-item-deeplink">Detailed asset information</div>,
            onSelect: () => onIntegrationLink(link),
          });
        });
      }
      if (item.integrationOptions) {
        Object.entries(item.integrationOptions).forEach(([k, v]) => {
          const integration = integrations.find((i) => i.id === k);
          const { endpoint, enabled } = integration?.configuration?.deeplinks?.componentLink || {};
          let link;
          if (endpoint && enabled) {
            try {
              link = endpoint.replace(/({\w+})/g, (match) => replacer(match, v));
              deeplinks.push({
                component: (
                  <div className="tree-item-deeplink">{`Asset information (${integration.name})`}</div>
                ),
                onSelect: () => onIntegrationLink(link),
              });
            } catch (error) {
              console.error(error);
            }
          }
        });
      }
      if (deeplinks.length) {
        options.push({ options: deeplinks });
      }

      return options;
    }, [item, showModels, models, canEdit, canDelete, sources, integrations]);

    const defaultModelId = models.length && models[0].modelId;
    const models3dIds = models.filter((m) => m.modelType !== '2d').map((m) => m.modelId);
    const models2dIds = models.filter((m) => m.modelType === '2d').map((m) => m.modelId);
    const hasActive3d = models3dIds.includes(activeModelId);
    const hasActive2d = models2dIds.includes(activeModelId);
    const [model3dId] = models3dIds;
    const [model2dId] = models2dIds;

    const contentClasses = classnames('tree-item-content', {
      [`level-${depth}`]: !flat,
      'flat-list': flat,
      leaf: !hasChildren,
      'menu-active': showItemMenu,
      selected,
    });
    const model3dClasses = classnames('tree-item-model', {
      active: hasActive3d,
      'viewer-loading': !viewerReady,
    });
    const model2dClasses = classnames('tree-item-model', {
      active: hasActive2d,
      'viewer-loading': !viewerReady,
    });

    return (
      <div
        className="tree-item"
        role="tree-item"
        ref={(el) => ref(el, id)}
      >
        <div
          className={contentClasses}
          onClick={(e) =>
            handleComponentClick(e, autoLoad ? defaultModelId : null, checkActive(defaultModelId))
          }
          ref={itemBodyRef}
        >
          <div className="content-left">
            {!flat && <ToggleIcon />}
            <div className="component-type">
              <ComponentIcon />
            </div>
            <div className="tree-item-name">
              {flat ? (
                <>
                  <div
                    key={`${id}-ng-1`}
                    className="tree-item-name-long"
                  >
                    {name}
                  </div>
                  <div key={`${id}-ng-2`}>{itemDesignation}</div>
                </>
              ) : (
                <>
                  <div key={`${id}-ng-1`}>{simpleDesignation || itemDesignation}</div>
                  <div
                    key={`${id}-ng-2`}
                    className="tree-item-name-long"
                  >
                    {name}
                  </div>
                  {showModels ? (
                    <>
                      {model3dId && (
                        <div className={model3dClasses}>
                          <Icon
                            key={`${id}-ng-3`}
                            icon="avt-3d"
                            onClick={(e) => handleModelClick(e, model3dId, hasActive3d)}
                            size="s"
                          />
                        </div>
                      )}
                      {model2dId && (
                        <div className={model2dClasses}>
                          <Icon
                            key={`${id}-ng-4`}
                            icon="avt-2d"
                            onClick={(e) => handleModelClick(e, model2dId, hasActive2d)}
                            size="s"
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div className={flat ? 'content-right status-icon-padding' : 'content-right'}>
            <DropdownMenu
              trigger="avt-menu-dots"
              triggerIconColor={selected ? '#fff' : undefined}
              menuOptions={itemMenuOptions}
              className="tree-item-menu"
              menuXPlacement="left"
              menuYPlacement="bottom"
              onMenuOpen={onMenuOpen}
              onMenuClose={() => setShowItemMenu(false)}
              styles={{
                dropdownIndicator: { padding: '0 0.5rem', filter: 'none' },
                option: { cursor: 'pointer' },
                container: { position: 'absolute' },
                menu: {
                  minWidth: 'auto',
                  top: menuPositionY === 'bottom' ? '100%' : 'auto',
                  bottom: menuPositionY === 'bottom' ? 'auto' : '100%',
                },
              }}
            />
          </div>
        </div>
        {expanded && children}
      </div>
    );
  }
);

export default TreeItem;
