import React, { useState, memo, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import JSONEditor from '../../../../JSONEditor';
import SimpleModal from '../../../../SimpleModal';
import { getNewTemplateSchema } from '../../tfrPanelSchema';
import ConfirmationDialog from '../../../../ConfirmationDialog';
import { createTfrTemplate, deleteTfrTemplate } from '../../../../../bundles/tfr-templates';

const NewTemplateModel = memo((props) => {
  const { onCloseModal, templateData = {}, tfrTemplates = [], site, setShowLoader } = props;
  const isDelete = templateData.actionType === 'delete';
  const isExists = templateData.actionType === 'exists';
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const newTemplateSchema = useMemo(() => {
    return getNewTemplateSchema();
  });

  const onChange = ({ formData: updatedFormData }) => {
    setFormData(updatedFormData);
  };

  const onTemplateSave = (form) => {
    setShowLoader(true);
    const data = {
      site: site.id,
      name: form.name,
      value: { template: templateData.template },
      org: site.org,
      templateType: 'tfr',
    };
    onCloseModal();
    dispatch(
      createTfrTemplate(data, () => {
        setShowLoader(false);
      })
    );
  };

  const onTemplateDelete = () => {
    setShowLoader(true);
    onCloseModal();
    dispatch(
      deleteTfrTemplate(templateData.id, () => {
        setShowLoader(false);
      })
    );
  };

  const customValidate = useCallback(
    (data, errors) => {
      tfrTemplates.forEach((template) => {
        if (template.name.toLowerCase() === data.name.toLowerCase()) {
          errors.name.addError(
            'Error: This view template name is already in use. Please choose a different name.'
          );
        }
      });
      return errors;
    },
    [tfrTemplates]
  );

  return (
    <>
      {!isDelete && !isExists && (
        <SimpleModal
          onClose={onCloseModal}
          title="Save as new template"
          containerClass="signal-viewer-template-modal"
        >
          <div className="tfr-template-modal">
            <div className="tfr-template-content">
              <JSONEditor
                formData={formData}
                schema={newTemplateSchema}
                onFormChange={onChange}
                onFormSubmit={({ formData: form }) => onTemplateSave(form)}
                saveButtonText={'Save'}
                initialEditMode={true}
                showEditButton={false}
                cancelCallback={onCloseModal}
                customValidate={customValidate}
              />
            </div>
          </div>
        </SimpleModal>
      )}
      {isDelete && (
        <ConfirmationDialog
          modal={true}
          onCancel={onCloseModal}
          onConfirm={() => onTemplateDelete()}
          title="Delete template"
          body={
            <>
              <p>Are you sure you want to delete the template?</p>
              <p className="template-name">{templateData.name}</p>
            </>
          }
          confirmType="danger"
          confirmText="Delete"
          className="delete-template"
        />
      )}
      {isExists && (
        <ConfirmationDialog
          modal={true}
          onCancel={onCloseModal}
          onConfirm={onCloseModal}
          title="Existing template"
          body={
            <>
              <p>
                This template configuration duplicate an existing template. Please choose a
                different configuration.
              </p>
              <p>
                Existing template name:
                <span style={{ fontWeight: 'bold' }}> {templateData.name}</span>
              </p>
            </>
          }
          cancelText="Dismiss"
          confirmText="Close"
          confirmType="info"
        />
      )}
    </>
  );
});

export default NewTemplateModel;
