import React, { useReducer, useMemo } from 'react';
import { ThemeProvider } from '@avtjs/react-components';
import { MODE_SUMMARY } from './constants';

const nightmode = localStorage.getItem('nightmode') === 'true';
const isTouchDevice =
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export const initialState = {
  values: [],
  mode: MODE_SUMMARY,
  isTouchDevice,
  isFullscreen: false,
  isViewerFullScreen: false,
  theme: nightmode ? 'dark' : 'light',
  setTheme: () => null,
  setMode() {
    console.warn('Not set');
  },
};

document.body.classList = [`${initialState.theme}-theme`];

const AppContext = React.createContext(initialState);

const AppContextReducer = (state, action) => ({ ...state, ...action });

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppContextReducer, initialState);

  const setTheme = (theme) => {
    dispatch({ theme });
    localStorage.setItem('nightmode', theme === 'dark');
    document.body.classList.replace(`${state.theme}-theme`, `${theme}-theme`);
  };

  const setIsFullScreen = (isFullscreen, persist) => {
    dispatch({ isFullscreen });
    dispatch({ isViewerFullScreen: isFullscreen && persist });
    if (isFullscreen) {
      document.body.classList.add('is-fullscreen');
      if (persist) {
        document.body.classList.add('persist');
      }
    } else {
      document.body.classList.remove('is-fullscreen');
      document.body.classList.remove('persist');
    }
  };

  const context = useMemo(
    () => ({
      ...state,
      setTheme,
      setIsFullScreen,
    }),
    [state, setTheme, setIsFullScreen]
  );

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={state.theme}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppContext;
