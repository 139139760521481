import React from 'react';
import { Icon, Button } from '@avtjs/react-components';

const StepDescription = ({ text, checked = false }) => (
  <li className={`checked-step${checked ? '--checked' : ''}`}>
    <div>
      <Icon icon="check-circle" />
    </div>
    <div>{text}</div>
  </li>
);

const WizardTypeStep = (props) => {
  const { site, onWizardType } = props;

  const stepComplete = (wizType, stepName) => {
    const wizard = (site.creationState || []).find(({ name }) => name === wizType) || {};
    return (wizard.steps || []).map(({ name }) => name).includes(stepName);
  };

  return (
    <>
      {/* <div className="wizard-step-top">
        <div className="wizard-step-top__header--wizard-select">
          <p>Choose a configuration plan to start</p>
        </div>
      </div> */}
      <div className="wizard-type-step">
        <div
          className="wizard-select wizard-select-basic"
          onClick={() => onWizardType('basic')}
        >
          <div className="wizard-select__vertical-container">
            <div className="wizard-select__heading">
              {/* <div className="wizard-select__header">Basic</div> */}
              <div className="wizard-select__header">Get Started</div>
              <div className="wizard-select__subheader">
                <div>Essential content for IdentiQ starter package</div>
                {/* <div
                  className="wizard-select__subheader__info-trigger"
                  onClick={e => e.stopPropagation()}
                >
                  <Icon icon="abb-information-circle-1" />
                </div>
                <div className="wizard-select__subheader__info-tooltip">
                  Basic info
                </div> */}
              </div>
            </div>
            <ul className="wizard-select__description">
              <StepDescription
                checked={stepComplete('basic', 'member')}
                text="Add site members"
              />
              <StepDescription
                checked={stepComplete('basic', 'component')}
                text="Setup component tree"
              />
              <StepDescription
                checked={stepComplete('basic', 'model')}
                text="Upload 3D & 2D models"
              />
            </ul>
            <div className="wizard-select__button-container">
              <Button>Start</Button>
            </div>
          </div>
        </div>
        {/*
        // Uncomment when re-adding advanced wizard option
        <div
          className="wizard-select wizard-select-advanced"
          onClick={() => onWizardType('advanced')}
        >
          <div className="wizard-select__vertical-container">
            <div className="wizard-select__heading">
              <div className="wizard-select__header">Advanced</div>
              <div className="wizard-select__subheader">
                <div>Essential content with data for IdentiQ professional package</div>
        */}
        {/*
                <div
                  className="wizard-select__subheader__info-trigger"
                  onClick={e => e.stopPropagation()}
                >
                  <Icon icon="abb-information-circle-1" />
                </div>
                <div className="wizard-select__subheader__info-tooltip">
                  Advanced info
                </div>
        */}
        {/*
        // Uncomment when re-adding advanced wizard option
              </div>
            </div>
            <ul className="wizard-select__description">
              <StepDescription
                checked={stepComplete('advanced', 'member')}
                text="Add site members"
              />
              <StepDescription
                checked={stepComplete('advanced', 'component')}
                text="Setup component tree"
              />
              <StepDescription
                checked={stepComplete('advanced', 'model')}
                text="Upload 3D & 2D models"
              />
              <StepDescription
                checked={stepComplete('advanced', 'source')}
                text="Add data sources"
              />
              <StepDescription
                checked={stepComplete('advanced', 'variable')}
                text="Visualize signals"
              />
            </ul>
            <div className="wizard-select__button-container">
              <Button>Start</Button>
            </div>
          </div>
        </div>
        */}
      </div>
    </>
  );
};

export default WizardTypeStep;
