import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can, Icon, useAuth, Input } from '@avtjs/react-components';

import UserList from './UserList';
import Heading from '../../../../Heading';
import PermissionLegend from '../../../../PermissionLegend';
import SimpleModal from '../../../../SimpleModal';
import RoleSelect from '../../../../RoleSelect';

import {
  requestMembers,
  getTenantUsers,
  isLoadingMembers,
  isLoadingTenantUsers,
  updateMemberAndConnection,
  deleteMemberConnection,
  getMembers,
  getGrants,
} from '../../../../../bundles/auth';
import { requestUsers, getUsersLoading } from '../../../../../bundles/ad';

const UsersTab = ({ siteId, wizardType }) => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const loadingMembers = useSelector(isLoadingMembers);
  const loadingTenantUsers = useSelector(isLoadingTenantUsers);
  const loadingUsers = useSelector(getUsersLoading);
  const tenantUsers = useSelector(getTenantUsers);
  const grants = useSelector(getGrants);

  const orgMembers = useSelector((state) => getMembers(state, `org/${realm}`));
  const siteMembers = useSelector((state) => getMembers(state, `site/${siteId}`));

  const [initialized, setInitialized] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [displayRoleDescriptions, setDisplayRoleDescriptions] = useState(false);
  const [tenantUsersWithSiteRole, setTenantUsersWithSiteRole] = useState([]);

  useEffect(() => {
    dispatch(requestUsers());
  }, []);

  useEffect(() => {
    if (!loadingMembers) {
      setInitialized(true);
    }
  }, [loadingMembers]);

  useEffect(() => {
    if (!loadingTenantUsers && siteId) {
      setTenantUsersWithSiteRole(
        tenantUsers.reduce((acc, user) => {
          const currentRoleAndSites = Object.entries(user.roles).find(([, roleSites]) =>
            roleSites.includes(siteId)
          );
          if (currentRoleAndSites) {
            acc.push({ ...user, role: currentRoleAndSites[0] });
          }
          return acc;
        }, [])
      );
    }
  }, [loadingTenantUsers, tenantUsers, siteId, initialized]);

  useEffect(() => {
    if (!loadingUsers) {
      dispatch(requestMembers(undefined, true));
    }
  }, [loadingUsers]);

  const onDeleteUser = useCallback(
    (userId) => {
      const userMember = siteMembers.find((member) => member.connected.includes(`users/${userId}`));
      dispatch(deleteMemberConnection(userMember.id, userId));
    },
    [siteMembers]
  );

  const onRoleChange = useCallback(
    (memberType, user) => {
      const selectedRole = orgMembers.find((obj) => obj.type === memberType);
      const postData = {
        scope: { org: realm, site: siteId },
        permissions: selectedRole.permissions,
        type: selectedRole.type,
        objectId: `site/${siteId}`,
        oid: user.id,
      };
      dispatch(updateMemberAndConnection(postData, true));
    },
    [orgMembers, realm, siteId]
  );

  const getRoleOptions = useCallback(
    (user) => {
      if (!grants || !orgMembers) {
        return [];
      }
      const userOrgOrSiteLevelPermissions = Object.entries(grants).reduce(
        (acc, [permission, scopes]) => {
          const relevantScopes = ['()', `(org:${realm})`, `(org:${realm},site:${siteId})`];
          if (new Set(scopes).intersection(new Set(relevantScopes)).size) {
            return [...acc, permission];
          }
          return acc;
        },
        []
      );
      // user can only assign member which has same or less permissions as they have
      const availableMembers = orgMembers.filter(
        (member) =>
          new Set(member.permissions).intersection(new Set(userOrgOrSiteLevelPermissions)).size ===
          member.permissions.length
      );
      return availableMembers.map((m) => ({
        label: m.type,
        value: m.type,
        onSelect: () => onRoleChange(m.type, user),
      }));
    },
    [grants, orgMembers, onRoleChange]
  );

  const wizClass = wizardType ? `--${wizardType}` : '';

  return (
    <>
      <Heading
        contentLeft={
          <>
            <Input
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Search by name or email"
              className="user-filter"
            />
            <RoleSelect
              id="role-filter"
              value={roleFilter}
              isMulti={false}
              onChange={setRoleFilter}
              realm={realm}
            />
          </>
        }
        contentRight={
          <Can
            permission="members/Write"
            scope={{ org: realm }}
          >
            <Button
              className={`role-descriptions${wizardType ? wizClass : ''}`}
              onClick={() => setDisplayRoleDescriptions(true)}
              activity="secondary"
              design="text"
              icon={
                <div className={`info-icon${wizardType ? wizClass : ''}`}>
                  <Icon icon="abb-information-circle-1" />
                </div>
              }
            >
              Role descriptions
            </Button>
          </Can>
        }
      />

      <UserList
        org={realm}
        siteId={siteId}
        tenantUsers={tenantUsersWithSiteRole}
        searchText={searchText}
        roleFilter={roleFilter}
        isLoading={loadingUsers || loadingTenantUsers}
        onDelete={onDeleteUser}
        getRoleOptions={getRoleOptions}
      />

      {displayRoleDescriptions && (
        <SimpleModal
          title="Role descriptions"
          className="role-permissions-modal"
          size="s"
          onClose={() => setDisplayRoleDescriptions(false)}
        >
          <PermissionLegend />
        </SimpleModal>
      )}
    </>
  );
};

export default UsersTab;
