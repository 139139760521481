import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Icon, Button } from '@avtjs/react-components';
import { refreshData, getPollingActive } from '../../../bundles/application';

const PollingRefresh = ({ siteId }) => {
  const dispatch = useDispatch();
  const isPolling = useSelector(getPollingActive);

  const spinningClass = classnames({ polling: isPolling });

  return (
    <div>
      <Button
        activity="secondary"
        className="refresh-variables-button"
        onClick={() => dispatch(refreshData(siteId))}
        icon={
          <Icon
            icon="refresh"
            size="s"
            className={spinningClass}
          />
        }
        disabled={isPolling}
        tooltip="Refresh now"
      />
    </div>
  );
};

export default PollingRefresh;
