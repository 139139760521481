import React, { memo, useState, useMemo } from 'react';
import { Label } from '@avtjs/react-components';

import ComponentSelect from '../../../../ComponentSelect';
import JSONEditor from '../../../../JSONEditor';
import ModelUpload from '../ModelUpload';
import InvalidFile from '../InvalidFile';
import VersionOptions from '../VersionOptions';

const CreateModelModal = memo(({ schema, modelId, onSubmit, onCloseModal }) => {
  const [fileState, setFileState] = useState({
    rootFile: undefined,
    droppedFiles: [],
    invalidRootFile: false,
  });
  const [componentId, setComponentId] = useState(null);
  const [file] = fileState.droppedFiles;
  const [modelMeta, setModelMeta] = useState({});

  const onDropAccepted = (droppedFiles) =>
    setFileState({
      ...fileState,
      droppedFiles,
    });

  const onRootFileSelected = (rootFile) =>
    setFileState({
      ...fileState,
      rootFile,
    });

  const onInvalidRootFile = () =>
    setFileState({
      ...fileState,
      invalidRootFile: true,
    });

  const onCancelClick = () =>
    setFileState({
      ...fileState,
      droppedFiles: [],
      invalidRootFile: false,
    });

  const isZip = useMemo(() => {
    if (file) {
      return file.name.split('.').pop().toLowerCase() === 'zip';
    }
    return false;
  }, [file]);

  const isSaveDisabled = !file || (isZip && !fileState.rootFile) || !componentId;

  if (fileState.invalidRootFile) {
    return (
      <InvalidFile
        className="invalid-file-container"
        fileName={file.name}
        handleCancelClick={onCancelClick}
      />
    );
  }

  const onSubmitNewModel = ({ formData }) => {
    onSubmit(formData, file, fileState.rootFile, componentId);
  };

  return (
    <div className="create-model-wrapper">
      <ModelUpload
        onDropAccepted={onDropAccepted}
        file={file}
        showSelector={file && isZip}
        onSelect={onRootFileSelected}
        onInvalidFile={onInvalidRootFile}
        initial={!modelId}
      />
      {!modelId ? (
        <>
          <Label htmlFor="component-select">Component</Label>
          <ComponentSelect
            id="component-select"
            placeholder="Select the component which the model will be attached to"
            isMulti={false}
            onChange={setComponentId}
          />
        </>
      ) : null}
      {modelId ? (
        <VersionOptions
          modelId={modelId}
          onSubmit={(versionOptions) => onSubmit(versionOptions, file, fileState.rootFile)}
          submitDisabled={isSaveDisabled}
          onCancel={onCloseModal}
        />
      ) : (
        <JSONEditor
          schema={schema}
          formData={modelMeta}
          onFormChange={({ formData }) => setModelMeta(formData)}
          onFormSubmit={onSubmitNewModel}
          saveButtonText={'Create model'}
          initialEditMode={true}
          showEditButton={false}
          cancelCallback={onCloseModal}
          submitDisabled={isSaveDisabled}
        />
      )}
    </div>
  );
});

export default CreateModelModal;
