import React, { useMemo, useCallback, useRef, useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';
import { contrastChart, Spinner, Icon, Button, Can } from '@avtjs/react-components';
import {
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Bar,
  Dot,
  LineChart,
  YAxis,
  XAxis,
  Line,
  BarChart,
  Tooltip,
  Legend,
  Area,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import classnames from 'classnames';

import { getActiveSite } from '../../../../bundles/sites';
import { useSubscription, getSingleVisualization } from '../../../../bundles/visualizations';
import { getTimezone } from '../../../../bundles/application';
import { getSiteVariablesIndex } from '../../../../bundles/sources';
import { getNested, useClientSize } from '../../../../utils';
import { utcToSite, getDateTickFormatter } from '../../../../datetimeUtils';
import { THRESHOLD_TYPES } from '../../../../constants';
import {
  convertValue,
  units,
  format as unitFormat,
  MULTIPLICATIVE_CATEGORIES,
} from '../../../../units';

import Loader from '../../../Loader';

const getColor = (index, config) => {
  if (config.color) {
    return config.color;
  }

  return contrastChart[index % contrastChart.length];
};

const Chart = ({ mode, ...props }) => {
  switch (mode) {
    case 'area':
      return <AreaChart {...props} />;
    case 'step':
      return <LineChart {...props} />;
    case 'bar':
      return <BarChart {...props} />;
    case 'line':
    default:
      return <LineChart {...props} />;
  }
};

const ValueRepresentation = ({ mode, monotone, ...props }) => {
  switch (mode) {
    case 'area':
      return (
        <Area
          type="monotone"
          {...props}
        />
      );
    case 'step':
      return (
        <Line
          type="stepBefore"
          {...props}
        />
      );
    case 'bar':
      return (
        <Bar
          barSize={2}
          {...props}
        />
      );
    case 'line':
    default:
      return (
        <Line
          type={monotone ? 'monotone' : 'linear'}
          {...props}
        />
      );
  }
};

const CustomLegend = ({ thresholdsData, payload }) => {
  const items = thresholdsData[THRESHOLD_TYPES[thresholdsData.thresholdType]?.key || ''] || [];

  return (
    <ul className="graph-legend">
      {payload.map((dataSet, i) => {
        const key = `${dataSet.color} + ${i}`;
        return (
          <li
            key={key}
            className="graph-legend-item"
          >
            <svg
              width="10"
              height="10"
            >
              <rect
                width="10"
                height="10"
                fill={dataSet.color}
              />
            </svg>
            {`${dataSet.value} (${dataSet.dataKey.split('.')[1]})`}
          </li>
        );
      })}

      {items.map((thd, i) => {
        const key = `${thd.fill} + ${i}`;
        return (
          <li
            key={key}
            className="graph-legend-item"
          >
            <svg
              width="40"
              height="5"
              className="graph-legend-image"
            >
              {thresholdsData.thresholdType === THRESHOLD_TYPES.linear.value && (
                <line
                  strokeDasharray="5, 3"
                  x1="0"
                  y1="0"
                  x2="40"
                  y2="0"
                  strokeWidth="5"
                  stroke={thd.fill}
                />
              )}

              {thresholdsData.thresholdType === THRESHOLD_TYPES.area.value && (
                <rect
                  width="40"
                  height="5"
                  fill={thd.fill}
                />
              )}
            </svg>
            {thd.description}
          </li>
        );
      })}
    </ul>
  );
};

const GraphVisualization = memo(
  (GraphVisualizationProps) => {
    const {
      visualization,
      panelId,
      pollingDateRange,
      onExportData,
      isPreview,
      syncVisualizationTooltip,
      selected,
      onSelect,
      openSignalViewer,
    } = GraphVisualizationProps;
    const { duration = 0 } = pollingDateRange;
    const dispatch = useDispatch();
    const site = useSelector(getActiveSite);
    const timezone = useSelector(getTimezone);
    const variablesIndex = useSelector(getSiteVariablesIndex);
    const [open, setIsOpen] = useState(false);

    const {
      initialLoaded,
      seriesValues: values,
      loading,
    } = useSubscription(visualization.id, panelId);
    const { variables = [], configuration = {} } = visualization;
    const {
      axes = {},
      series: variableAxisConf = [],
      thresholds = {},
      legend: { show: showLegend } = {},
    } = configuration;
    const [{ width, height }, clientRef] = useClientSize();
    const ref = useRef(null);
    const currentThresholdKey = THRESHOLD_TYPES[thresholds.thresholdType]?.key || '';

    const getSortedThresholds = (thr) =>
      thr[currentThresholdKey]
        ?.slice()
        .sort((a, b) =>
          thr.thresholdType === THRESHOLD_TYPES.linear.value ? a.value - b.value : a.min - b.min
        ) || [];

    const sortedThresholds = getSortedThresholds(thresholds);

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleToggleOpen = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsOpen((prev) => !prev);
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (initialLoaded && !selected) {
        dispatch(getSingleVisualization(visualization, panelId));
      }
    }, [
      panelId,
      initialLoaded,
      JSON.stringify(`${pollingDateRange.startDate}:${pollingDateRange.endDate}`),
    ]);

    const firstVarDefaultUnit = variablesIndex?.[variables[0]?.id]?.unit || '';
    const lastVarDefaultUnit = variablesIndex?.[variables.at(-1)?.id]?.unit || '';
    const data = useMemo(() => {
      if (!values) return [];
      const hasConfig = variables.some((variable) =>
        values.some((value) => value.variable === variable.id)
      );
      if (!hasConfig) return [];

      return values.reduce((acc, { values: dataValues, name, granularity }) => {
        const [id, agg] = name.split('.');
        const varConfig = variables.find((v) => v.id === id && v.aggregate === agg);
        const varUnit =
          varConfig?.unit === 'variable-default'
            ? (variablesIndex || {})[id]?.unit || ''
            : varConfig?.unit || '';

        const varAxis = variableAxisConf.find((s) => s.id === name)?.axis || 'left';
        const axisKey = `y${varAxis.slice(0, 1).toUpperCase()}${varAxis.slice(1)}`;
        const axisUnit = axes[axisKey]?.unit === 'variable-default' ? varUnit : axes[axisKey]?.unit;

        dataValues.forEach(([time, value, quality]) => {
          const parsedVal = parseFloat(value);
          const cleanedVal = Number.isNaN(parsedVal) ? undefined : parsedVal;

          let axisAlignedValue = cleanedVal;

          // we need to check that the variable unit is the same as the axis unit,
          // and normalize the value since we can receive values for the same var unit,
          // but differing prefixes (e.g. kW vs W)
          if (varUnit !== axisUnit) {
            const { category: varCat, multiplicative: varMult } =
              units.find((u) => u.id === varUnit) || {};
            const { category: axisCat, multiplicative: axisMult } =
              units.find((u) => u.id === axisUnit) || {};

            if (varCat === axisCat && MULTIPLICATIVE_CATEGORIES.includes(varCat)) {
              axisAlignedValue = convertValue(axisAlignedValue, varMult, axisMult);
            }
          }
          if (quality !== 1 && !isPreview) axisAlignedValue = null;

          const index = acc.findIndex((entry) => entry.time === time);
          if (index === -1) {
            const insertIndex = acc.findIndex(
              (_, i) => acc[i].time > time && (acc[i - 1] || {}).time < time
            );
            if (insertIndex === -1) {
              acc.push({
                time,
                [name]: axisAlignedValue,
                granularity,
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              acc = [
                ...acc.slice(0, insertIndex),
                {
                  time,
                  [name]: axisAlignedValue,
                  granularity,
                },
                ...acc.slice(insertIndex),
              ];
            }
          } else {
            acc[index] = {
              ...acc[index],
              [name]: axisAlignedValue,
            };
          }
        });
        return acc;
      }, []);
    }, [values, variables, variablesIndex, axes, variableAxisConf, isPreview]);

    const CustomizedDot = useCallback(
      (customizedDotProps) => {
        const { cx, cy, r, fill, index, dataKey, value } = customizedDotProps;
        if (
          value !== undefined &&
          value !== null &&
          !data[index - 1]?.[dataKey] &&
          !data[index + 1]?.[dataKey]
        ) {
          return (
            <Dot
              cx={cx}
              cy={cy}
              r={r}
              fill={fill}
            />
          );
        }
        return null;
      },
      [data]
    );

    const ticks = useMemo(() => {
      const numTicks = Math.floor((width - 75) / 100);
      // data sets should be returning 100 datapoints
      const interval = Math.max(Math.floor(data.length / numTicks), 1);
      // Use offset start index by 1 interval so tick doesn't extend past y-axis
      const tickIndexes = [interval];
      while (tickIndexes.length <= numTicks) {
        tickIndexes.push(tickIndexes[tickIndexes.length - 1] + interval);
      }
      return data.filter((_, i) => tickIndexes.includes(i)).map(({ time }) => time);
    }, [width, data]);

    const formatTicks = useCallback(
      getDateTickFormatter(duration, timezone, { ticks, configuration }),
      [duration, timezone, ticks, configuration]
    );

    const series = useMemo(
      () =>
        variables.map(({ id, aggregate, fill, label, unit, decimals }, index) => {
          const { name } = (variablesIndex || {})[id] || {};
          const seriesId = `${id}.${aggregate}`;
          const { series: seriesConfig = [] } = configuration;
          const config = seriesConfig.find(({ id: _id }) => _id === seriesId) || {};
          const displayUnit =
            unit === 'variable-default' ? (variablesIndex || {})[id]?.unit || '' : unit;

          return {
            variableId: id,
            id: seriesId,
            label: label || name,
            decimals: decimals || 'auto',
            unit: displayUnit,
            aggregate,
            color: getColor(index, config),
            axis: config.axis || 'left',
            // linear fill option deprecated, but still supported
            connectNulls: fill === 'connect' || fill === 'connect-monotone' || fill === 'linear',
            useCustomDots: fill === 'null' || fill === 'null-monotone',
            monotone: fill.includes('monotone'),
          };
        }),
      [variables, variablesIndex, configuration]
    );

    const getDomainValue = (val) => {
      // Perform this check because 0 is falsy, but a very common minValue.
      if (val !== undefined && val !== null) return val;

      return 'auto';
    };

    const getYaxisFormatter = (position, defaultUnit) => (v) => {
      const axisUnit = getNested(configuration, `axes.${position}.unit`);
      const { value, unit } = unitFormat(
        axisUnit === 'variable-default' ? defaultUnit : axisUnit,
        v,
        getNested(configuration, `axes.${position}.decimals`)
      );
      return `${value} ${unit}`;
    };

    const getYaxisLabel = (position) => ({
      value: getNested(configuration, `axes.${position}.label`),
      angle: -90,
      style: { textAnchor: 'middle' },
      position: position === 'yLeft' ? 'insideLeft' : 'insideRight',
    });

    const xScale = getNested(configuration, 'axes.x.scale') === 'linear' ? 'auto' : 'time';

    const tooltipLabelFormatter = (time) => {
      const dateFormat = 'yyyy-MM-dd, HH:mm:ss';
      return format(utcToSite(time, timezone), dateFormat, { timeZone: timezone });
    };

    const getValueFormatter =
      (decimals, varUnit, aggregate, axis) => (val, _name, valueFormatterProps) => {
        const { payload: { granularity } = {} } = valueFormatterProps;
        const axisKey = `y${axis.slice(0, 1).toUpperCase()}${axis.slice(1)}`;
        const axisUnit =
          axes[axisKey]?.unit === 'variable-default' ? varUnit : axes[axisKey]?.unit || varUnit;
        let formatted = unitFormat(varUnit, val, decimals);
        if (varUnit !== axisUnit) {
          const { category: varCat } = units.find((u) => u.id === varUnit) || {};
          const { category: axisCat } = units.find((u) => u.id === axisUnit) || {};

          if (varCat === axisCat && MULTIPLICATIVE_CATEGORIES.includes(varCat)) {
            formatted = unitFormat(axisUnit, val, decimals);
          }
        }
        return `${formatted.value} ${formatted.unit} (${aggregate}, ${granularity})`;
      };

    const handleDownloadClick = () => {
      onExportData(data[0].granularity);
    };
    const handleSelect = () => {
      onSelect(visualization);
    };
    const classes = classnames('menu', { open });

    const vizHeight = height - 28; // less header
    const loaderHeight = vizHeight - 28; // less loader padding
    return (
      <div
        className={`graph-visualization-component ${selected ? 'checked' : ''}`}
        ref={clientRef}
      >
        {!isPreview && (
          <div
            className="graph-header"
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="header-title">
              <div
                className={`select ${selected ? 'checked' : ''}`}
                onClick={handleSelect}
              >
                <Icon
                  icon={`${selected ? 'check-box' : 'check-box-outline-blank'}`}
                  size="s"
                />
              </div>
              <span className="title">{visualization.name || '\u00A0'}</span>
            </div>
            <div className={classes}>
              {initialLoaded && loading && (
                <Spinner
                  size="s"
                  className="spinner"
                />
              )}
              <div ref={ref}>
                <Button
                  design="text"
                  tooltip="More...  "
                  onClick={handleToggleOpen}
                >
                  <Icon
                    icon="abb-menu-narrow"
                    size="s"
                  />
                </Button>
              </div>
              <div className="content">
                <div className="choices">
                  {!loading && !isPreview && data?.length > 0 && (
                    <Can
                      permission="variables/Write"
                      scope={{
                        org: site.org,
                        site: site.id,
                      }}
                    >
                      <div
                        className="choice"
                        onClick={handleDownloadClick}
                      >
                        Download
                      </div>
                    </Can>
                  )}

                  <div
                    className="choice"
                    onClick={() => openSignalViewer(visualization)}
                  >
                    Open in Signal Viewer
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!initialLoaded && loading ? (
          <Loader
            text="Loading ..."
            height={loaderHeight}
          />
        ) : (
          <div className="graph-wrapper">
            <ResponsiveContainer
              width="100%"
              height={vizHeight}
            >
              <Chart
                syncId={syncVisualizationTooltip ? panelId : uuidv4()}
                mode={configuration.mode}
                data={data}
              >
                <CartesianGrid
                  strokeWidth={1}
                  strokeDasharray="3 3"
                />
                <YAxis
                  yAxisId="left"
                  domain={[
                    getDomainValue(
                      visualization.configuration.axes
                        ? visualization.configuration.axes.yLeft.minValue
                        : null
                    ),
                    getDomainValue(
                      visualization.configuration.axes
                        ? visualization.configuration.axes.yLeft.maxValue
                        : null
                    ),
                  ]}
                  axisLine={false}
                  tick={{ fontSize: 11 }}
                  tickFormatter={getYaxisFormatter('yLeft', firstVarDefaultUnit)}
                  label={getYaxisLabel('yLeft')}
                  hide={getNested(configuration, 'axes.yLeft.show') === false}
                />
                <YAxis
                  yAxisId="right"
                  domain={['auto', 'auto']}
                  orientation="right"
                  axisLine={false}
                  tick={{ fontSize: 11 }}
                  tickFormatter={getYaxisFormatter('yRight', lastVarDefaultUnit)}
                  label={getYaxisLabel('yRight')}
                  hide={getNested(configuration, 'axes.yRight.show') === false}
                />
                <XAxis
                  domain={['auto', 'auto']}
                  type="number"
                  scale={xScale}
                  axisLine={false}
                  dataKey="time"
                  tick={{ fontSize: 11 }}
                  ticks={ticks}
                  tickFormatter={formatTicks}
                  hide={getNested(configuration, 'axes.x.show') === false}
                />
                {series.map(
                  ({
                    id: seriesId,
                    color,
                    label,
                    axis,
                    connectNulls,
                    decimals,
                    unit: baseUnit,
                    aggregate,
                    useCustomDots,
                    monotone,
                  }) =>
                    ValueRepresentation({
                      mode: configuration.mode || 'line',
                      data,
                      name: label,
                      key: seriesId,
                      dataKey: seriesId,
                      stroke: color,
                      fill: color,
                      yAxisId: axis,
                      animationDuration: 0,
                      isAnimationActive: false,
                      dot: useCustomDots && <CustomizedDot />,
                      strokeWidth: 2,
                      connectNulls,
                      monotone,
                      formatter: getValueFormatter(decimals, baseUnit, aggregate, axis),
                    })
                )}

                <Tooltip
                  allowEscapeViewBox={{ x: false, y: true }}
                  isAnimationActive={false}
                  wrapperStyle={{ zIndex: '1000' }}
                  labelFormatter={tooltipLabelFormatter}
                  labelStyle={{ marginBottom: '0.4rem' }}
                />

                {sortedThresholds.map((threshold, i) => {
                  if (thresholds.thresholdType === THRESHOLD_TYPES.linear.value) {
                    return (
                      <ReferenceLine
                        key={`th-${i}-${threshold.value}+${threshold.fill}`}
                        y={`${threshold.value}`}
                        stroke={`${threshold.fill}`}
                        strokeDasharray="5 5"
                        strokeWidth={3}
                        yAxisId={getNested(configuration, 'axes.yLeft.show') ? 'left' : 'right'}
                      />
                    );
                  }

                  if (thresholds.thresholdType === THRESHOLD_TYPES.area.value) {
                    return (
                      <ReferenceArea
                        key={`th-${i}-${threshold.min}+${threshold.fill}`}
                        y1={threshold.min}
                        y2={threshold.max}
                        fill={`${threshold.fill}`}
                        yAxisId={getNested(configuration, 'axes.yLeft.show') ? 'left' : 'right'}
                      />
                    );
                  }

                  return null;
                })}
                {showLegend ? (
                  <Legend
                    content={<CustomLegend thresholdsData={thresholds} />}
                    height={36}
                  />
                ) : null}
              </Chart>
            </ResponsiveContainer>
            {data.length === 0 && (
              <div
                className="no-data"
                style={{
                  marginLeft: getNested(configuration, 'axes.yLeft.show') ? '35px' : 'none',
                  marginRight: getNested(configuration, 'axes.yRight.show') ? '35px' : 'none',
                }}
              >
                No data
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
  (prevProps, props) => JSON.stringify(prevProps) === JSON.stringify(props)
);

export default GraphVisualization;
