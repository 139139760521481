import React from 'react';

import PageItem from '../PageItem';

const PageList = ({ pages, updatePages, selectPage }) => {
  const movePage = (from, to) => {
    updatePages((existingPages) => {
      if (to >= 0 && to < existingPages.length) {
        let updatedPages = [...existingPages];
        const [fromElement] = updatedPages.splice(from, 1);
        updatedPages.splice(to, 0, fromElement);

        updatedPages = updatedPages.map((page, index) => ({
          ...page,
          pageIndex: index,
        }));

        return updatedPages;
      }
      return existingPages;
    });
  };

  return (
    <div className="page-list-component">
      {pages.map((page, index) => (
        <PageItem
          index={index}
          {...page}
          key={page.id}
          pageCount={pages.length}
          movePage={movePage}
          selectPage={selectPage}
        />
      ))}
    </div>
  );
};

export default PageList;
