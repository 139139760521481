/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HvProvider } from '@hitachivantara/uikit-react-core';
import { Router as ReactRouter, Route, Switch, Redirect } from 'react-router-dom';
import { AuthorizationProvider, useAuth } from '@avtjs/react-components';

import {
  requestGrants,
  getGrants,
  authorizeUser,
  hasCheckedInvitations,
  getIsTenantOrSuperAdmin,
  getHasPermission,
} from '../../bundles/auth';
import { getActiveSite, requestSites, getSitesLoaded } from '../../bundles/sites';
import { requestInstructionals } from '../../bundles/files';
import { getOnline } from '../../bundles/notifications';

import BaseView from '../BaseView';

import SiteType from '../SiteType';
import SiteDetailView from '../SiteDetailView';
import SiteAdminView from '../SiteAdminView';
import LayoutView from '../SiteAdminView/LayoutView';
import IntegrationView from '../SiteAdminView/IntegrationView';
import QrGenerationView from '../QrGenerationView';
import SourceView from '../SiteAdminView/SourceView';
import VariableView from '../SiteAdminView/VariableView';
import ComponentView from '../SiteAdminView/ComponentView';
import VisualizationView from '../SiteAdminView/VisualizationView';
import ModelView from '../SiteAdminView/ModelView';
import AlgorithmsView from '../SiteAdminView/AlgorithmsView';
import AlgorithmDetailView from '../AlgorithmDetailView';
import TaskDetailView from '../TaskDetailView';
import MemberView from '../SiteAdminView/MemberView';
import TagsView from '../SiteAdminView/TagsView';
import SupportView from '../SupportView';
import NoGrantsView from '../NoGrantsView';
import StatesView from '../SiteAdminView/StatesView';
import SiteView from '../SiteAdminView/SiteView';
import PageLastUpdated from '../PageHeader/PageLastUpdated';
import SourceConnectivityNotification from '../Notifications/SourceConnectivityNotification';

import GlobalMessagePortal from '../Notifications/GlobalMessagePortal';
import TopNotification from '../Notifications/TopNotification';
import AppContext from '../../AppContext';
import TenantAdminIntegrationView from '../TenantAdminView/components/TenantAdminIntegrationView';
import TenantAdminUserView from '../TenantAdminView/components/TenantAdminUserView/TenantAdminUserView';

const { AuthProvider } = AuthorizationProvider;

const NotFoundPage = () => (
  <div className="not-found">
    <h2>Sorry, page not found...</h2>
  </div>
);

const Router = ({ history }) => {
  const dispatch = useDispatch();

  const { subject, realm, profile } = useAuth();

  const { email } = profile;
  const [isLoadingSites, setLoadingSites] = useState(true);
  const [isLoadingGrants, setLoadingGrants] = useState(true);

  const grants = useSelector(getGrants);
  const site = useSelector(getActiveSite);
  const sitesLoaded = useSelector(getSitesLoaded);

  const online = useSelector(getOnline);
  const invitesChecked = useSelector(hasCheckedInvitations);

  const { setTheme, theme } = useContext(AppContext);

  const setAvtTheme = useCallback(() => {
    setTheme(theme);
  }, [theme]);

  const hvTheme = useMemo(() => (theme === 'dark' ? 'wicked' : null), [theme]);

  const removeFirstLoader = () => {
    const loader = document.getElementById('first-loader');
    if (loader) loader.remove();
  };

  useEffect(() => {
    if (online && isLoadingGrants) {
      dispatch(requestGrants(subject, () => setLoadingGrants(false)));
    }
  }, [online, isLoadingGrants]);

  useEffect(() => {
    if (online && invitesChecked) {
      setLoadingGrants(true);
    }
  }, [online, invitesChecked]);

  const isTenantAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, realm));
  const canReadSites = useSelector((state) => getHasPermission(state, 'sites/Read'));

  useEffect(() => {
    if (online && canReadSites) {
      dispatch(requestSites(() => setLoadingSites(false)));
      dispatch(requestInstructionals());
    }
  }, [online, canReadSites]);

  useEffect(() => {
    dispatch(
      authorizeUser({
        email,
        id: subject,
        org: realm,
      })
    );
  }, []);

  if (isLoadingGrants || (canReadSites && isLoadingSites)) {
    return (
      <div>
        {!online && (
          <GlobalMessagePortal>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
      </div>
    );
  }

  if (history.location && history.location.pathname !== '/sites/' && sitesLoaded) {
    removeFirstLoader();
  }

  if (invitesChecked && !isLoadingGrants && (!grants || !canReadSites)) {
    removeFirstLoader();
    return <NoGrantsView />;
  }

  return (
    <HvProvider
      uiKitTheme={hvTheme}
      changeTheme={setAvtTheme}
    >
      <div className={isLoadingGrants || isLoadingSites ? 'loading-main-content' : 'main-content'}>
        <ReactRouter history={history}>
          <AuthProvider value={{ grants }}>
            <Switch>
              {/* Pre-site routes */}
              <Route
                exact
                path="/"
              >
                <Redirect to="/sites/" />
              </Route>
              <Route
                exact
                path="/sites/"
                render={(props) => (
                  <SiteType
                    {...props}
                    sitesReady={!isLoadingSites}
                    realm={realm}
                    noSiteView
                  />
                )}
              />
              <Route
                path="/tenant-administration/users"
                render={(props) =>
                  isTenantAdmin ? (
                    <BaseView
                      title={{
                        type: 'string',
                        value: 'Tenant Administration - Users',
                      }}
                      noSitesView
                      className="manage-view"
                      isTenantAdminView
                      {...props}
                    >
                      <TenantAdminUserView {...props} />
                    </BaseView>
                  ) : (
                    <Redirect to="/sites/" />
                  )
                }
              />
              <Route
                path="/tenant-administration/integrations"
                render={(props) =>
                  isTenantAdmin ? (
                    <BaseView
                      title={{
                        type: 'string',
                        value: 'Tenant Administration - Integrations',
                      }}
                      noSitesView
                      className="manage-view"
                      isTenantAdminView
                      {...props}
                    >
                      <TenantAdminIntegrationView {...props} />
                    </BaseView>
                  ) : (
                    <Redirect to="/sites/" />
                  )
                }
              />

              {/* Admin - Settings - Data */}
              <Route
                path="/sites/:id/admin/integrations"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="integrations/Write"
                    trail={['Administration', 'Integrations']}
                    {...props}
                  >
                    <IntegrationView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/sources"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="sources/Write"
                    trail={['Administration', 'Sources']}
                    {...props}
                  >
                    <SourceView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/variables"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="variables/Write"
                    trail={['Administration', 'Signals']}
                    {...props}
                  >
                    <VariableView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/components/qr"
                render={(props) => <QrGenerationView {...props} />}
              />
              <Route
                path="/sites/:id/admin/components"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="components/Write"
                    trail={['Administration', 'Components']}
                    {...props}
                  >
                    <ComponentView />
                  </BaseView>
                )}
              />

              <Route
                path="/sites/:id/admin/states"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="statesets/Write"
                    trail={['Administration', 'States']}
                    {...props}
                  >
                    <StatesView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/visualizations"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="visualizations/Write"
                    trail={['Administration', 'Visualizations']}
                    {...props}
                  >
                    <VisualizationView />
                  </BaseView>
                )}
              />

              {/* Admin - Settings - General routes */}
              <Route
                path="/sites/:id/admin/info"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="sites/Write"
                    trail={['Administration', 'Site']}
                    {...props}
                  >
                    <SiteView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/members"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="members/Write"
                    trail={['Administration', 'Members']}
                    {...props}
                  >
                    <MemberView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/layout/(pages)?/:page?/(sections)?/:section?/(columns)?/:column?/(panels)?/:panel?"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="sites/Write"
                    {...props}
                  >
                    <LayoutView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/models"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="models/Write"
                    trail={['Administration', 'Models']}
                    {...props}
                  >
                    <ModelView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/algorithms/:algorithmId/tasks/:taskId"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="algorithms/Write"
                    trail={['Administration', 'Algorithms', 'Algorithm', 'Task']}
                    {...props}
                  >
                    <TaskDetailView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/algorithms/:algorithmId"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="algorithms/Write"
                    trail={['Administration', 'Algorithms', 'Algorithm']}
                    {...props}
                  >
                    <AlgorithmDetailView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/algorithms"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="algorithms/Write"
                    trail={['Administration', 'Algorithms']}
                    {...props}
                  >
                    <AlgorithmsView />
                  </BaseView>
                )}
              />
              <Route
                path="/sites/:id/admin/tags"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    requiredPermission="tags/Write"
                    trail={['Administration', 'Tags']}
                    {...props}
                  >
                    <TagsView />
                  </BaseView>
                )}
              />
              {/* Admin - Settings */}
              <Route
                path="/sites/:id/admin"
                render={(props) => (
                  <BaseView
                    siteAdminView
                    site={site}
                    title={{
                      type: 'string',
                      value: 'Administration',
                    }}
                    className="settings-component"
                    withPadding={false}
                    {...props}
                  >
                    <SiteAdminView {...props} />
                  </BaseView>
                )}
              />

              <Route
                path="/sites/:id/support"
                render={(props) => (
                  <BaseView
                    site={site}
                    title={{
                      type: 'string',
                      value: 'Support Information',
                    }}
                    headerChildren={<p>Version 1.4</p>}
                    className="support-view-component"
                    withPadding={false}
                    withConnectivity={false}
                    {...props}
                  >
                    <SupportView {...props} />
                  </BaseView>
                )}
              />

              <Route
                path="/sites/:id/:page?"
                render={(props) => (
                  <BaseView
                    site={site}
                    withSidebar
                    withRefresh
                    headerChildren={<PageLastUpdated />}
                    className="site-detail-view-component"
                    withPadding={false}
                    {...props}
                  >
                    <SiteDetailView
                      {...props}
                      subject={subject}
                    />
                  </BaseView>
                )}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </AuthProvider>
        </ReactRouter>
        {!online && (
          <GlobalMessagePortal site={site}>
            <TopNotification
              icon="abb-wifi-network-disconnected"
              message="No internet connection"
            />
          </GlobalMessagePortal>
        )}
        <GlobalMessagePortal>{site.id && <SourceConnectivityNotification />}</GlobalMessagePortal>
      </div>
    </HvProvider>
  );
};

export default Router;
