import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components } from 'react-select';
import { Select, colors, useTheme } from '@avtjs/react-components';

import PopoutSelect from '../PopoutSelect';
import { requestTypes, getTypes } from '../../bundles/types';
import { getActiveSite } from '../../bundles/sites';
import Loader from '../Loader';

const MultiValueRemove = (props) => {
  const { limitTypes } = props.selectProps;
  if (limitTypes.includes(props.data.value)) {
    return null;
  }

  return <components.MultiValueRemove {...props} />;
};

const ClearIndicator = (props) => {
  const { limitTypes } = props.selectProps;
  if (limitTypes.length === (props.getValue() || []).length) {
    return null;
  }
  const clearValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let value;
    if (limitTypes) {
      value = props.selectProps.options.filter((o) => limitTypes.includes(o.value));
    }
    props.setValue(value);
  };

  return (
    <components.ClearIndicator
      {...{
        ...props,
        innerProps: {
          ...props.innerProps,
          onMouseDown: clearValue,
          onTouchEnd: clearValue,
        },
      }}
    />
  );
};

export default function TypeSelect({
  label = 'Types',
  icon,
  onChange = () => null,
  value: selectedTypes,
  popout = false,
  placeholder,
  availableTypes,
  limitTypes = [],
  isDisabled = false,
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  menuMaxHeight,
  fullHeight = false,
  hideMenu = false,
  ...props
}) {
  let SelectComponent = Select;
  if (popout) {
    SelectComponent = PopoutSelect;
  }

  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const site = useSelector(getActiveSite);
  const types = useSelector(getTypes);

  useEffect(() => {
    if (!availableTypes) {
      dispatch(requestTypes(site.id));
    }
  }, [availableTypes]);

  const overrideStyles = () => ({
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.Grey10 : base.color,
    }),
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      ...(hideMenu ? { display: 'none' } : {}),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
    control: (base) => ({
      ...base,
      backgroundColor: `${theme === 'dark' ? colors.Grey90 : colors.Grey0}`,
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: '3px 6px',
    }),
  });

  const typeOptions = useMemo(
    () =>
      (availableTypes || types).map((t) => ({
        value: t,
        label: t,
      })),
    [types, availableTypes]
  );

  const handleOnChange = useCallback(
    (selection) => {
      onChange(selection ? selection.map((option) => option.value) : []);
    },
    [onChange]
  );

  if (!typeOptions) {
    return <Loader />;
  }

  const localValue =
    selectedTypes &&
    typeOptions.filter((option) => (selectedTypes || []).find((id) => option.value === id));

  return (
    <SelectComponent
      label={popout && label}
      icon={popout && icon}
      isMulti
      innerRef={ref}
      onChange={handleOnChange}
      creatable={false}
      value={localValue}
      options={typeOptions}
      isDisabled={isDisabled}
      closeMenuOnSelect={false}
      components={{ MultiValueRemove, ClearIndicator }}
      placeholder={placeholder || 'Search...'}
      limitTypes={limitTypes}
      menuXPlacement={menuXPlacement}
      menuYPlacement={popout ? 'bottom' : menuYPlacement}
      styles={(menuMaxHeight || !fullHeight) && overrideStyles()}
      {...props}
    />
  );
}
