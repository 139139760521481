.site-create-view-component {
  display: flex;
}
.site-create-view-component .avt-react-components--icon {
  margin-left: auto;
  opacity: 0.5;
}
.site-create-view-component:hover .avt-react-components--icon {
  opacity: 0.75;
}
.site-create-view-component .loader-component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.light-theme .site-create-view-component .loader-component {
  background: rgba(242.25, 242.25, 242.25, 0.6);
}

.dark-theme .site-create-view-component .loader-component {
  background: rgba(0, 0, 0, 0.6);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUNyZWF0ZVZpZXciLCJzb3VyY2VzIjpbIlNpdGVDcmVhdGVWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDSTs7QUFDQTtFQUNJO0VBQ0E7O0FBRUo7RUFDSTs7QUFFSjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQU1BO0VBQ0k7OztBQU9KO0VBQ0kiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiQGF2dGpzL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzc1wiO1xuLnNpdGUtY3JlYXRlLXZpZXctY29tcG9uZW50IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIC5hdnQtcmVhY3QtY29tcG9uZW50cy0taWNvbiB7XG4gICAgICAgIG1hcmdpbi1sZWZ0OiBhdXRvO1xuICAgICAgICBvcGFjaXR5OiAwLjU7XG4gICAgfVxuICAgICY6aG92ZXIgLmF2dC1yZWFjdC1jb21wb25lbnRzLS1pY29uIHtcbiAgICAgICAgb3BhY2l0eTogMC43NTtcbiAgICB9XG4gICAgLmxvYWRlci1jb21wb25lbnQge1xuICAgICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgICAgIHRvcDogMDtcbiAgICAgICAgcmlnaHQ6IDA7XG4gICAgICAgIGJvdHRvbTogMDtcbiAgICAgICAgbGVmdDogMDtcbiAgICB9XG59XG5cbi5saWdodC10aGVtZSB7XG4gICAgLnNpdGUtY3JlYXRlLXZpZXctY29tcG9uZW50IHtcbiAgICAgICAgLmxvYWRlci1jb21wb25lbnQge1xuICAgICAgICAgICAgYmFja2dyb3VuZDogcmdiYSgkTGlnaHRBcHBsaWNhdGlvbkJhY2tncm91bmQsIDAuNik7XG4gICAgICAgIH1cbiAgICB9XG59XG5cbi5kYXJrLXRoZW1lIHtcbiAgICAuc2l0ZS1jcmVhdGUtdmlldy1jb21wb25lbnQge1xuICAgICAgICAubG9hZGVyLWNvbXBvbmVudCB7XG4gICAgICAgICAgICBiYWNrZ3JvdW5kOiByZ2JhKCREYXJrQXBwbGljYXRpb25CYWNrZ3JvdW5kLCAwLjYpO1xuICAgICAgICB9XG4gICAgfVxufVxuIl19 */