import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Icon } from '@avtjs/react-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import { getTimezone } from '../../bundles/application';
import { utcToSite, datetimeString, getSiteDayStart, siteToUtc } from '../../datetimeUtils';

const RELATIVE_OPTIONS = [{ value: 'none', label: 'None' }];

/*
 * This component expects a value in the form of a UTC Date, timestamp, or string,
 * will display a picker to select a date and time shown in the sites timezone (based on coordinates),
 * and will update the value via an onChange handler in the form of a UTC Date string.
 */

const DateTimePicker = React.memo(
  ({
    history,
    id,
    value, // UTC Date | string | number
    relativeValue,
    placeholder = 'Select date',
    relativeOptions = RELATIVE_OPTIONS,
    withRelativeOptions = false,
    onChange,
  }) => {
    const timezone = useSelector(getTimezone);
    const [open, setOpen] = useState(false);
    // now is a Date object with local offset, but datetime of site
    const [now] = useState(utcToSite(new Date(), timezone));
    // siteDateTime is a Date object with local offset, but datetime of site
    const [siteDateTime, setSiteDateTime] = useState(
      value ? utcToSite(value, timezone) : getSiteDayStart(now)
    );

    const ref = useRef(null);
    const dateInputRef = useRef(null);
    const timeSecondsRef = useRef(null);
    const timeHoursRef = useRef(null);

    const DATE = `polling-range_${id}`;
    const TIME_SECONDS = `polling-range-time-s_${id}`;
    const TIME_HOURS = `polling-range-time-h_${id}`;

    const relativeDisplayValue = RELATIVE_OPTIONS.find((opt) => opt.value === relativeValue)?.label;

    useEffect(() => {
      if (value) {
        setSiteDateTime(utcToSite(value, timezone));
      } else {
        setSiteDateTime(getSiteDayStart(now));
      }
    }, [value, timezone, now]);

    const handleClickOutside = useCallback(
      (event) => {
        if (!ref.current) return;
        if (
          !ref.current.contains(event.target) &&
          ref.current.classList.contains('open') &&
          !event.target.classList.contains('CalendarDay') &&
          !event.target.classList.contains('css-fn1hnl-option')
        ) {
          onChange('absolute', siteToUtc(siteDateTime, timezone).toISOString());
          setOpen(false);
        }
      },
      [ref.current, siteDateTime]
    );

    const handleOpen = useCallback((e) => {
      if (e.target === ref.current && (e.key === ' ' || e.code === 'Space')) {
        setOpen(true);
      }
    }, []);

    useEffect(() => {
      const unlisten = history.listen(() => {
        setOpen(false);
      });
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keyup', handleOpen);
      document
        .querySelector('#global-modal-container')
        .addEventListener('click', handleClickOutside);
      document.querySelector('#global-modal-container').addEventListener('keyup', handleOpen);
      return () => {
        unlisten();
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('keyup', handleOpen);
        document
          .querySelector('#global-modal-container')
          .removeEventListener('click', handleClickOutside);
        document.querySelector('#global-modal-container').removeEventListener('keyup', handleOpen);
      };
    }, [handleClickOutside, handleOpen]);

    const setFocus = (inputId, selectionStart = 0, selectionEnd = 4) => {
      const input = document.getElementById(inputId);
      if (inputId === TIME_SECONDS) {
        timeSecondsRef.current.focus();
      } else if (inputId === TIME_HOURS) {
        timeHoursRef.current.focus();
      } else if (input) {
        input.focus();
      }
      if (input?.setSelectionRange) {
        input.setSelectionRange(selectionStart, selectionEnd);
      }
    };

    const handleFieldChange = (nextId, selectionStart = 0, selectionEnd = 4) => {
      if (nextId) {
        setFocus(nextId, selectionStart, selectionEnd);
      }
    };

    const onExit = (e) => {
      if (e.key === 'Escape') {
        setOpen(false);
        setSiteDateTime(utcToSite(value, timezone));
      }
    };

    useEffect(() => {
      if (open) {
        document.addEventListener('keyup', onExit);
      } else {
        document.removeEventListener('keyup', onExit);
      }
      return () => {
        document.removeEventListener('keyup', onExit);
      };
    }, [open]);

    const handleSubmitRelativePeriod = (label) => {
      onChange('relative', relativeOptions.find((opt) => opt.label === label).value);
      setOpen(false);
    };

    const classes = classnames('select-period-component', { open });

    const setTime = useCallback(
      (siteTime) => {
        // 'HH:mm:ss'
        const siteDateTimeCopy = new Date(siteDateTime || now);
        const [hours, minutes, seconds] = siteTime.split(':');
        siteDateTimeCopy.setHours(hours, minutes, seconds, 0);
        setSiteDateTime(siteDateTimeCopy);
      },
      [siteDateTime, now]
    );

    const setDate = useCallback(
      (zonedTimestamp) => {
        // zonedTimestamp is a site time, with local offset, in ms
        if (zonedTimestamp !== now.getTime()) {
          const update = new Date(zonedTimestamp);
          setSiteDateTime((currentDate) => {
            update.setHours(currentDate.getHours());
            update.setMinutes(currentDate.getMinutes());
            update.setSeconds(currentDate.getSeconds());
            return update;
          });
        }
      },
      [timezone, now]
    );

    const time = siteDateTime ? siteDateTime.toTimeString().split(' ')[0] : '00:00:00';

    const pickerSpaceLeft = dateInputRef.current?.getBoundingClientRect().left || 0;
    const pickerClass = `absolute-time-picker ${pickerSpaceLeft < 330 ? 'calendar-right' : ''}`;

    return (
      <div
        className={classes}
        tabIndex={0}
        ref={ref}
      >
        <div
          className="header"
          onClick={() => setOpen(!open)}
        >
          <div className="label">
            {relativeDisplayValue || null}
            {!relativeDisplayValue && (
              <div>{value ? datetimeString(siteDateTime) : placeholder}</div>
            )}
          </div>
          <Icon
            icon="abb-time-and-date"
            size="s"
          />
        </div>
        <div className="content">
          <div className="absolute">
            {withRelativeOptions && <div className="title">Absolute time</div>}
            <div
              className={pickerClass}
              ref={dateInputRef}
            >
              <div className="datetime-row">
                <DatePicker
                  id={DATE}
                  value={siteDateTime?.getTime() || now?.getTime()} // site time, with local offset, in ms
                  onChange={setDate}
                  onTabOrArrowBack={() => handleFieldChange(null)}
                  onTextComplete={() => handleFieldChange(TIME_HOURS)}
                />
                <TimePicker
                  withSeconds
                  value={time} // HH:mm:ss, site time string
                  hourInputRef={timeHoursRef}
                  secondsInputRef={timeSecondsRef}
                  onChange={setTime}
                  onTabOrArrowBack={() => handleFieldChange(DATE, 8, 10)}
                  onTextComplete={() => handleFieldChange(null)}
                />
              </div>
            </div>
          </div>
          {withRelativeOptions ? (
            <>
              <hr />
              <div className="relative">
                <div className="title">Relative time</div>
                <div className="fixed-periods choices">
                  {relativeOptions.map(({ label }) => (
                    <div
                      className={`choice ${value === label ? 'selected' : ''}`}
                      key={label}
                      role="button"
                      onClick={() => handleSubmitRelativePeriod(label)}
                    >
                      {label}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
);

export default withRouter(DateTimePicker);
