import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { Icon, useAuth, Button } from '@avtjs/react-components';
import {
  pollingIntervals as periods,
  getPollingInterval,
  setPollingInterval,
  getPollingActive,
} from '../../../bundles/application';
import { getIsTenantOrSuperAdmin, getIsContributorOrAdmin } from '../../../bundles/auth';
import { getLinkedSources } from '../../../bundles/sources';
import { getAllIntegrations } from '../../../bundles/integrations';
import { replacer } from '../../../utils';

const HeaderSettings = ({ site, noSiteView }) => {
  const dispatch = useDispatch();
  const { realm } = useAuth();

  const pollingInterval = useSelector(getPollingInterval);
  const isPolling = useSelector(getPollingActive);
  const sources = useSelector(getLinkedSources);
  const integrations = useSelector(getAllIntegrations);
  const isTenantAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, realm));
  const isContributorOrAdmin = useSelector((state) => getIsContributorOrAdmin(state, site));

  const [open, setIsOpen] = useState(false);
  const [showIntervals, setShowIntervals] = useState(false);
  const ref = useRef(null);

  const classes = classnames('header-settings-component', { open });
  const spinningClass = classnames({ isPolling });

  const { integrationOptions } = site || {};

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleToggleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleToggleIntervals = () => {
    setShowIntervals((prev) => !prev);
  };

  const handleSelectInterval = (interval) => {
    dispatch(setPollingInterval(interval));
    handleToggleIntervals();
  };

  const openSourceLink = (source) => {
    window.open(
      source.options.siteSourceLink
        .replace('<domain>', source.options.domain)
        .replace('<substationId>', source.options.thirdPartyId),
      '_blank'
    );
  };

  const onIntegrationLink = (e, link) => {
    handleToggleOpen(e);
    window.open(link, '_blank');
  };

  if (noSiteView && !isTenantAdmin) return null;

  return (
    <div className={classes}>
      <div ref={ref}>
        <Button
          design="text"
          tooltip="More...  "
          className="toggle-container"
          onClick={handleToggleOpen}
        >
          <Icon
            icon="avt-menu-dots"
            size="m"
          />
        </Button>
      </div>
      <div className="content">
        <div className="choices">
          {site && !noSiteView && (
            <>
              <div
                className="choice"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleIntervals();
                }}
              >
                {pollingInterval ? (
                  <Icon
                    icon="refresh"
                    className={spinningClass}
                  />
                ) : (
                  <Icon icon="pause" />
                )}
                Polling interval
                {pollingInterval && ` - ${periods.find((p) => p.value === pollingInterval).label}`}
              </div>

              {showIntervals && (
                <div className="sub-choices">
                  {periods.map(({ label, value }) => (
                    <div
                      key={value}
                      className={`sub-choice ${pollingInterval === value ? 'selected' : ''}`}
                      onClick={() => handleSelectInterval(value)}
                      role="button"
                    >
                      {label}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {isContributorOrAdmin && !noSiteView && (
            <>
              <NavLink
                className="choice"
                title="Support"
                exact
                to={{
                  pathname: `/sites/${site.id}/support/`,
                  search: window.location.search,
                }}
              >
                <Icon icon="support" />
                <span>Support</span>
              </NavLink>

              <NavLink
                className="choice"
                title="Administration"
                exact
                to={{
                  pathname: `/sites/${site.id}/admin/`,
                  search: window.location.search,
                }}
              >
                <Icon icon="settings" />
                <span>Administration</span>
              </NavLink>
            </>
          )}

          {!noSiteView && integrationOptions
            ? Object.entries(integrationOptions).map(([k, v]) => {
                const integration = integrations.find((i) => i.id === k);
                const { endpoint, enabled } = integration?.configuration?.deeplinks?.siteLink || {};
                let link;
                if (endpoint && enabled) {
                  try {
                    link = endpoint.replace(/({\w+})/g, (match) => replacer(match, v));
                    return (
                      <div
                        key={k}
                        className="choice"
                        onClick={(e) => onIntegrationLink(e, link)}
                        role="button"
                      >
                        <Icon
                          icon="abb-wheel"
                          size="m"
                        />
                        {`Site Information (${integration.name})`}
                      </div>
                    );
                  } catch (error) {
                    console.error(error);
                    return null;
                  }
                }
                return null;
              })
            : null}

          {!noSiteView
            ? sources
                .filter((source) => source.options.siteSourceLink)
                .map((source) => (
                  <div
                    key={source.id}
                    className="choice"
                    onClick={() => openSourceLink(source)}
                    role="button"
                  >
                    <Icon
                      icon="abb-wheel"
                      size="m"
                    />
                    Detailed Site Information
                  </div>
                ))
            : null}

          {isTenantAdmin && noSiteView && (
            <>
              <NavLink
                className="choice"
                title="Tenant Administration"
                exact
                to={{
                  pathname: `/tenant-administration/users`,
                  search: window.location.search,
                }}
              >
                <span>Tenant Administration</span>
              </NavLink>

              {/* <NavLink
                className="choice"
                title="Integrations"
                exact
                to={{
                  pathname: `/integrations/`,
                  search: window.location.search,
                }}
              >
                <span>Integrations</span>
              </NavLink> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(HeaderSettings);
