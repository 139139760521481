/* eslint-disable no-bitwise */
import { colors } from '@avtjs/react-components';

const colorOptions = [
  { value: colors.Blue60, label: 'Blue' },
  { value: colors.Orange40, label: 'Orange' },
  { value: colors.Green50, label: 'Green' },
  { value: colors.Red60, label: 'Red' },
  { value: colors.Purple40, label: 'Purple' },
  { value: '#999999', label: 'Grey' }, // imported Greys use darken function which breaks
  { value: colors.Gold30, label: 'Gold' },
  { value: colors.Magenta30, label: 'Magenta' },
  { value: colors.Teal30, label: 'Teal' },
];

const lightenDarkenColor = (col, amt) => {
  let usePound = false;
  let color = col;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  if (color.length === 3) {
    color = `${color[0].repeat(2)}${color[1].repeat(2)}${color[2].repeat(2)}`;
  }

  const num = parseInt(color, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export default colorOptions;
export { lightenDarkenColor };
