import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Label, Button, Error, Select } from '@avtjs/react-components';

import SimpleModal from '../SimpleModal';
import ComponentSelect from '../ComponentSelect';
import { getStaticComponents } from '../../bundles/components';
import { getSources, getInjectableSourceTypes } from '../../bundles/sources';
import { addIotEventsFile } from '../../bundles/events';

const intervalOptions = [
  { label: 'milliseconds', value: 1 },
  { label: 'seconds', value: 1000 },
  { label: 'minutes', value: 1000 * 60 },
  { label: 'hours', value: 1000 * 60 * 60 },
  { label: 'days', value: 1000 * 60 * 60 * 24 },
];

const EventFileModal = ({ file, isTenantAdmin = false, error, onClose }) => {
  const [componentId, setComponentId] = useState();
  const [offset, setOffset] = useState(0);
  const [interval, setInterval] = useState(intervalOptions[0]);
  const components = useSelector(getStaticComponents);
  const sources = useSelector(getSources);
  const injectableSources = useSelector(getInjectableSourceTypes);

  const availableComponents = useMemo(
    () =>
      components.filter((c) => {
        if (c.eventSources && c.eventSources.length) {
          let hasInjectableSource = false;
          c.eventSources.forEach((es) => {
            const source = sources.find((s) => s.id === es.source_id);
            if (source && injectableSources.includes(source.type)) {
              hasInjectableSource = true;
            }
          });
          return hasInjectableSource;
        }
        return false;
      }),
    [components, sources, injectableSources]
  );

  const dispatch = useDispatch();
  const handleSubmit = () => {
    const comp = components.find((c) => c.id === componentId);
    let sourceId;
    if (comp && comp.eventSources.length === 1) {
      sourceId = comp.eventSources[0].source_id;
    } else if (comp) {
      // just taking the first injectable source since we only have one
      // injectable type and probably shouldn't be multiple anyway...
      sourceId = (
        comp.eventSources.find((es) => {
          const source = sources.find((s) => s.id === es.source_id);
          return !!source && injectableSources.includes(source.type);
        }) || {}
      ).source_id;
    }
    if (comp && sourceId) {
      dispatch(
        addIotEventsFile({
          file,
          offset: offset * interval.value,
          source: sourceId,
          component: componentId,
          site: comp.site,
          org: comp.org,
        })
      );
    }
    onClose();
  };

  if (file) {
    return (
      <SimpleModal
        onClose={onClose}
        size="s"
        title="Add event file"
        className="event-file-modal-wrapper"
      >
        <div className="event-file-modal-wrapper__field-group">
          <Label htmlFor="components">
            Select the component which the events will be attached to
          </Label>
          <ComponentSelect
            id="components"
            isMulti={false}
            availableComponents={availableComponents}
            onChange={setComponentId}
          />
          {!availableComponents.length && (
            <Error>
              No components with suitable event sources found. Connect appropriate source(s) before
              proceeding.
            </Error>
          )}
        </div>
        {isTenantAdmin && (
          <div className="event-file-modal-wrapper__field-group">
            <Label htmlFor="offset">Optional event timestamp offset (+/-)</Label>
            <div className="event-file-modal-wrapper__multi-field-group">
              <Input
                type="number"
                step={1}
                value={offset}
                onChange={(e) => setOffset(e.target.value)}
              />
              <Select
                isMulti={false}
                options={intervalOptions}
                value={interval}
                onChange={setInterval}
                hideSelectedOptions={false}
              />
            </div>
          </div>
        )}
        <div className="event-file-modal-wrapper__actions">
          <Button
            activity="primary"
            disabled={!componentId}
            onClick={handleSubmit}
          >
            Add events
          </Button>
          <Button
            activity="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </SimpleModal>
    );
  }

  return (
    <SimpleModal
      onClose={onClose}
      size="s"
      title="Add event file - Error"
      className="event-file-modal-wrapper"
    >
      <div className="event-file-modal-wrapper__errors">
        <div>{error}</div>
        <div>Event files should be uploaded individually and in .xml format.</div>
      </div>
      <div className="event-file-modal-wrapper__actions">
        <Button
          activity="primary"
          type="button"
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </SimpleModal>
  );
};

export default EventFileModal;
