import React, { useMemo, useCallback } from 'react';
import { Select } from '@avtjs/react-components';

const DisplayPropertiesPicker = ({
  disabled,
  readonly,
  required,
  value,
  schema: { isMulti, placeholder },
  options: { enumOptions = [] },
  onChange,
  ...props
}) => {
  const getLabel = (val) => (enumOptions.find((o) => o.value === val) || {}).label;

  const theValue = useMemo(() => {
    if (typeof value === 'boolean') {
      return { label: getLabel(value), value: value.toString() };
    }
    return { label: getLabel(value), value };
  }, [value]);

  const setValues = useCallback(
    ({ value: val }) => {
      const v = (() => {
        if (val === 'true') return true;
        if (val === 'false') return false;
        return null;
      })();
      onChange(v);
    },
    [onChange]
  );

  const stringOptions = enumOptions.map((o) => ({
    ...o,
    value: o.value !== null ? o.value.toString() : null,
  }));

  const overrideStyles = {
    container: (base) => ({ ...base, lineHeight: 'normal' }),
    singleValue: (base) => ({ ...base, lineHeight: 'normal' }),
    control: (base) => ({ ...base, lineHeight: 'normal' }),
  };

  return (
    <Select
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      disabled={disabled}
      readOnly={readonly}
      required={required}
      placeholder={placeholder}
      defaultValue={theValue}
      onChange={setValues}
      options={stringOptions}
      styles={overrideStyles}
      {...props}
    />
  );
};

export default DisplayPropertiesPicker;
