import React, { useState } from 'react';
import { Button, ButtonGroup, Can, Icon } from '@avtjs/react-components';
import { useSelector } from 'react-redux';

import EventTimelineFilters from './EventTimelineFilters';

import ExportEventModal from './ExportEventModal/ExportEventModal';
import { getActiveSite } from '../../../bundles/sites';

const EventTimelineHeader = ({
  format,
  layouts = [],
  activeLayout,
  onSetLayout,
  onCreateEvent,
  width,
  isFullScreen,
  panelId,
  includeTypes,
  panelEventType,
  availableIotEventTypes,
  availableUserEventTypes,
  allUserEventTypes,
}) => {
  const site = useSelector(getActiveSite);
  const [showExportEventModal, setShowExportEventModal] = useState(false);

  // Removes limited list view from the button group, since this should not be an option for the user to select in the event timeline header.
  const filteredLayouts = layouts.filter((layout) => layout.type !== 'limited list view');
  const hasAvailableEventTypes = availableIotEventTypes.length || availableUserEventTypes.length;

  const onClose = () => {
    setShowExportEventModal(false);
  };

  if (format === 'narrow') return null;

  return (
    <div
      className={`event-timeline-panel-header ${format} ${
        isFullScreen ? 'fullscreen' : 'normalscreen'
      } `}
    >
      <div className="event-timeline-panel-toolbar">
        <div className="event-timeline-panel-btn-wrapper">
          {layouts.length > 1 && (
            <ButtonGroup
              layouts={filteredLayouts}
              activeLayout={activeLayout}
              onClick={onSetLayout}
            />
          )}
          <Button
            icon={
              <Icon
                style={{ width: '85%' }}
                icon="abb-download"
              />
            }
            activity="secondary"
            onClick={() => setShowExportEventModal(!showExportEventModal)}
            tooltip={'Export events'}
            disabled={!hasAvailableEventTypes}
          ></Button>
          <Can
            permission="events/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <Button
              icon={
                <Icon
                  style={{ width: '85%' }}
                  icon="abb-plus"
                />
              }
              activity="primary"
              onClick={onCreateEvent}
              tooltip={width < 800 && 'Create new'}
              disabled={!hasAvailableEventTypes}
            >
              <div className="button-text">Create new</div>
            </Button>
          </Can>
        </div>

        <EventTimelineFilters
          panelId={panelId}
          includeTypes={includeTypes}
          eventTypeFilter={panelEventType}
          availableIotEventTypes={availableIotEventTypes}
          availableUserEventTypes={availableUserEventTypes}
          site={site}
        />
      </div>

      {showExportEventModal && (
        <ExportEventModal
          onClose={onClose}
          panelId={panelId}
          site={site}
          panelEventType={panelEventType}
          availableIotEventTypes={availableIotEventTypes}
          availableUserEventTypes={availableUserEventTypes}
          allUserEventTypes={allUserEventTypes}
        />
      )}
    </div>
  );
};

export default EventTimelineHeader;
