import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Button, ButtonGroup, Input } from '@avtjs/react-components';

import TagSelect from '../../../../TagSelect';
import TypeSelect from '../../../../TypeSelect';

const Filters = ({
  availableTags,
  availableTypes,
  filterValue,
  hideSearch,
  includeTags = [],
  includeTypes = [],
  limitTags,
  setFiletypeFilter,
  setFilterValue,
  setTagFilter,
}) => {
  const [typeValue, setTypeValue] = useState(includeTypes);
  const [tagValue, setTagValue] = useState(includeTags);

  useEffect(() => {
    setFiletypeFilter(() => typeValue || []);
  }, [JSON.stringify(typeValue)]);

  useEffect(() => {
    setTagFilter(() => tagValue || []);
  }, [JSON.stringify(tagValue)]);

  useEffect(() => {
    setTypeValue(() => includeTypes);
  }, [JSON.stringify(includeTypes)]);

  useEffect(() => {
    setTagValue(() => includeTags);
  }, [JSON.stringify(includeTags)]);

  const isFixedTagOption = useCallback(({ value }) => includeTags.includes(value), [includeTags]);

  return (
    <div className="filter-tools">
      {!hideSearch && (
        <Input
          className="files-panel-search"
          type="text"
          placeholder="Search..."
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
        />
      )}
      <div className="filter-tools-select-wrapper">
        <TypeSelect
          onChange={setTypeValue}
          value={typeValue}
          popout
          availableTypes={availableTypes}
          isDisabled={!!includeTypes.length}
          menuXPlacement="left"
          limitTypes={includeTypes}
          hideMenu={!!includeTypes.length}
        />
        <TagSelect
          onChange={setTagValue}
          value={tagValue}
          creatable={false}
          popout
          availableTags={availableTags}
          isDisabled={availableTags.length === 0 || limitTags.fixedTags}
          menuXPlacement="left"
          isOptionDisabled={isFixedTagOption}
          limitTags={limitTags}
          backspaceRemovesValue={!(limitTags.includesTogether || []).length}
          hideMenu={limitTags.fixedTags}
        />
      </div>
    </div>
  );
};

const FilesHeader = ({
  availableTags,
  availableTypes,
  canDelete,
  canDownload,
  canEdit,
  disableSelect,
  fileViewType,
  files = [],
  filterValue,
  format,
  hideFilters,
  hideSearch,
  hideToolbar,
  hideUploadButton,
  includeTags,
  includeTypes,
  isFullScreen,
  layouts,
  limitTags,
  onDeleteSelected,
  onDownloadSelected,
  onEditSelected,
  selectedFiles,
  setDisplayUploadModal,
  setFileViewType,
  setFiletypeFilter,
  setFilterValue,
  setTagFilter,
  toggleSelectAllFiles,
  width,
}) => {
  if (hideToolbar) return null;

  const panelViews = [
    {
      type: 'table',
      icon: 'abb-list',
      tooltip: 'View as list',
    },
    {
      type: 'grid',
      icon: 'abb-matrix',
      tooltip: 'View as thumbnails',
    },
  ];

  const allSelected =
    files && selectedFiles && files.length && files.length === selectedFiles.length;

  return (
    <div className={`files-panel-header ${isFullScreen ? 'widest' : format}`}>
      <div className="files-panel-toolbar primary">
        <div className="files-panel-toolbar-btn-wrapper">
          {['Grid & list', 'List & grid'].includes(layouts) && (
            <ButtonGroup
              layouts={panelViews}
              activeLayout={fileViewType}
              onClick={setFileViewType}
            />
          )}
          {!hideUploadButton && canEdit && (
            <Button
              icon={
                <Icon
                  style={{ width: '80%' }}
                  icon="abb-upload"
                />
              }
              activity="primary"
              onClick={() => setDisplayUploadModal(true)}
              tooltip={width < 800 && 'Upload files'}
            >
              <div className="button-text">{format === 'narrow' ? 'Upload' : 'Upload files'}</div>
            </Button>
          )}
        </div>
        {!hideFilters && (
          <Filters
            availableTags={availableTags}
            availableTypes={availableTypes}
            filterValue={filterValue}
            hideSearch={hideSearch}
            includeTags={includeTags}
            includeTypes={includeTypes}
            limitTags={limitTags}
            setFiletypeFilter={setFiletypeFilter}
            setFilterValue={setFilterValue}
            setTagFilter={setTagFilter}
          />
        )}
      </div>
      {(!disableSelect || !hideUploadButton) && (
        <div className="files-panel-toolbar secondary">
          {!disableSelect && (
            <>
              <label className="select-all-label">
                <Icon
                  className={`select-all ${allSelected ? 'checked' : ''}`}
                  icon={`${allSelected ? 'check-box' : 'check-box-outline-blank'}`}
                  size="s"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectAllFiles(files);
                  }}
                />
                <span>Select all</span>
              </label>

              {!!selectedFiles.length && (
                <div className="handle-selected-files">
                  {canDownload && (
                    <Button
                      onClick={onDownloadSelected}
                      activity="secondary"
                      icon={<Icon icon="abb-download" />}
                      tooltip="Download selected"
                      slim={format === 'narrow' && true}
                    />
                  )}
                  {canDelete && (
                    <Button
                      onClick={onDeleteSelected}
                      activity="secondary"
                      icon={<Icon icon="abb-trash" />}
                      tooltip="Delete selected"
                      slim={format === 'narrow' && true}
                    />
                  )}
                  {canEdit && (
                    <Button
                      onClick={onEditSelected}
                      activity="secondary"
                      disabled={!selectedFiles.filter((f) => !f.bookmarkData).length}
                      icon={<Icon icon="abb-edit" />}
                      tooltip="Edit selected"
                      slim={format === 'narrow' && true}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FilesHeader;
