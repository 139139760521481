import React, { useCallback, useMemo } from 'react';
import { Select, useTheme, colors } from '@avtjs/react-components';

import PopoutSelect from '../PopoutSelect';
import Loader from '../Loader';

export default function CustomSelect({
  label,
  icon,
  isMulti = true,
  onChange = () => null,
  value,
  defaultValue,
  closeMenuOnSelect,
  popout = false,
  placeholder,
  rawOptions = [], // [{ value/id, label/name }]
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  menuMaxHeight,
  fullHeight = false,
  ...props
}) {
  const SelectComponent = popout ? PopoutSelect : Select;

  const theme = useTheme();

  const overrideStyles = () => ({
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.Grey10 : base.color,
    }),
    singleValue: (base) => ({
      ...base,
      lineHeight: 'normal',
    }),
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
    }),
  });

  const options = useMemo(
    () =>
      rawOptions.map((o) => ({
        value: o.value || o.id,
        label: o.label || o.name,
      })),
    [rawOptions]
  );

  const handleOnChange = useCallback(
    (selection) => {
      if (isMulti) {
        onChange(selection ? selection.map((option) => option.value) : []);
      } else {
        onChange(selection ? selection.value : undefined);
      }
    },
    [onChange, isMulti]
  );

  const localValue = useMemo(() => {
    if (options && isMulti) {
      return options.filter((option) => (value || []).find((id) => option.value === id));
    }
    return options.find((option) => option.value === value);
  }, [value, options]);

  const localDefaultValue = useMemo(() => {
    if (options && isMulti) {
      return options.filter((option) => defaultValue.includes(option.value));
    }
    return options.find((o) => o.value === defaultValue);
  }, [defaultValue, options]);

  if (!options) {
    return <Loader />;
  }

  return (
    <SelectComponent
      label={popout && label}
      icon={popout && icon}
      isMulti={isMulti}
      onChange={handleOnChange}
      value={localValue}
      defaultValue={localDefaultValue}
      options={options}
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder || 'Select an option...'}
      menuXPlacement={menuXPlacement}
      menuYPlacement={popout ? 'bottom' : menuYPlacement}
      styles={(menuMaxHeight || !fullHeight) && overrideStyles()}
      {...props}
    />
  );
}
