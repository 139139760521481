/* eslint-disable no-shadow */
import { Button } from '@avtjs/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SimpleModal from '../../SimpleModal';
import { useIsLoading } from '../../LoadingLayer';
import Loader from '../../Loader';
import EventTimelineFilters from './EventTimelineFilters';
import { EventList } from './EventList';
import {
  EventTimelineProvider,
  getFilterStateCallback,
  useActiveListEvents,
} from './EventTimelineState';
import Indicator from './Indicator';
import { useClientSize, useComponentId } from '../../../utils';
import {
  getPollingDateRange,
  getRangeFromPartial,
  setPollingDateRange,
} from '../../../bundles/application';
import { getUserEventTypes, requestUserEventTypes } from '../../../bundles/events';
import { SEVERITIES, STATELESS_EVENTS } from '../../../constants';

const RelatedEventsModal = ({
  panelId,
  readOnly,
  onSave,
  onClose,
  excludeEventId,
  eventListColumns,
  onSelectEvent = () => null,
  availableIotEventTypes,
  availableUserEventTypes,
  site,
}) => {
  const [{ width }, clientRef] = useClientSize();
  const dateRange = useSelector((state) => getPollingDateRange(state, panelId));

  const handleOnSelectEvent = useCallback(
    (event) => {
      if (readOnly) {
        onSelectEvent(event);
        onClose();
      }
    },
    [readOnly, onSelectEvent]
  );

  const getFilterState = getFilterStateCallback();
  const handleSave = () => {
    onSave({
      from: new Date(dateRange.startDate).toISOString(),
      to: new Date(dateRange.endDate).toISOString(),
      ...getFilterState(),
    });
  };

  const getListEvents = (clickable = true) => (
    <div className="related-events__list-container">
      <EventList
        width={width}
        height={420}
        onOpenEvent={clickable ? handleOnSelectEvent : undefined}
        excludeEventId={excludeEventId}
        listColumns={eventListColumns}
        scoped={false}
        allUserEventTypes={availableUserEventTypes}
      />
    </div>
  );

  const relatedEvents = readOnly ? (
    getListEvents()
  ) : (
    <>
      <div className="related-events-modal__event-filters">
        <EventTimelineFilters
          allowRound={false}
          panelId={panelId}
          enableGlobalDates={false}
          eventTypeFilter={'all'}
          availableIotEventTypes={availableIotEventTypes}
          availableUserEventTypes={availableUserEventTypes}
          site={site}
          mode={'relatedEvents'}
        />
      </div>
      {getListEvents(false)}
      <div className="related-events-modal__buttons">
        <Button
          activity="primary"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          activity="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <SimpleModal
      title="Related events"
      size="l"
      onClose={() => onClose()}
    >
      <div
        ref={clientRef}
        className="related-events-modal"
      >
        {relatedEvents}
      </div>
    </SimpleModal>
  );
};

const EventListDisplay = ({ onViewAll, excludeEventId, readOnly, onSelectEvent = () => null }) => {
  const { events: listEvents = [], totalEventCount } = useActiveListEvents(undefined, false);
  const [events, setEvents] = useState([]);
  const loading = useIsLoading();

  useEffect(() => {
    setEvents(listEvents);
  }, [JSON.stringify(listEvents)]);

  const handleSelect = useCallback(
    (item) => {
      if (readOnly) {
        onSelectEvent({
          eventType: item.type,
          event: item.event,
          eventId: item.id,
        });
      }
    },
    [readOnly, onSelectEvent]
  );

  if (loading) {
    return <Loader height="calc(100% - 1.9rem)" />;
  }
  return (
    <div className="related-events__list">
      <ul>
        {events
          .filter((e) => e.id !== excludeEventId)
          .slice(0, 5)
          .map((item) => {
            const isStateLessEvent = STATELESS_EVENTS.includes(item.event.type);
            const userEventState = isStateLessEvent ? 'stateless' : item.event.state;
            return (
              <li
                key={item.id + item.type}
                className={`related-events__display-item ${readOnly ? 'clickable' : ''}`}
                onClick={() => handleSelect(item)}
              >
                <Indicator
                  eventState={item.type !== 'iot' && userEventState}
                  severity={item.type === 'iot' && item.event.severity}
                  round={item.type !== 'iot'}
                />
                {item.type === 'user-start' && '[START] '}
                {item.type === 'user-end' && '[END] '}
                {item.event.message || item.event.name}
              </li>
            );
          })}
      </ul>
      {events.length > 5 && readOnly && (
        <Button
          onClick={onViewAll}
          activity="secondary"
          design="text"
          className="related-events__see-all"
        >
          See all {totalEventCount} events
        </Button>
      )}
      {events.length > 5 && !readOnly && (
        <div className="event-count">{totalEventCount} total events</div>
      )}
    </div>
  );
};

const RelatedEvents = ({
  site,
  sourceEventId,
  value = {},
  addEvents = false,
  readOnly = false,
  hideInactiveSystemEvents,
  eventListColumns,
  onChange = () => null,
  onSelectEvent = () => null,
  onAddDone = () => null,
}) => {
  const dispatch = useDispatch();
  const [showEventModal, setShowEventModal] = useState(false);
  const panelId = useComponentId();
  const dateRange = useSelector((state) => getPollingDateRange(state, panelId));
  const [dateDomain, setDateDomain] = useState();
  const [initialized, setInitialized] = useState(false);
  const [relatedEventFilters, setRelatedEventFilters] = useState(value);

  const { from, to, text, active, params, eventTypes: eventTypeFilter } = relatedEventFilters;
  const fromMs = from && new Date(from).getTime();
  const toMs = to && new Date(to).getTime();
  const isActive = !!fromMs && !!toMs;

  const allUserEventTypes = useSelector(getUserEventTypes);
  const allIotEventTypes = [
    ...Object.entries(SEVERITIES).map(([k, v]) => ({
      name: v,
      id: k,
    })),
  ];

  useEffect(() => {
    dispatch(requestUserEventTypes());
  }, []);

  useEffect(() => {
    if (!initialized) {
      const today = Date.now();
      const initStart = fromMs || dateRange?.startDate || Date.now() - 6 * 30 * 24 * 60 * 60 * 1000; // ~6mo
      const initEnd = toMs || dateRange?.endDate || today;

      const range = getRangeFromPartial({ startDate: initStart, endDate: initEnd });
      dispatch(setPollingDateRange(range, panelId));
      setInitialized(true);
    }
  }, [fromMs, toMs, dateRange, initialized]);

  useEffect(() => {
    if (initialized && dateRange) {
      setDateDomain([dateRange.startDate, dateRange.endDate]);
    }
  }, [dateRange, initialized]);

  useEffect(() => {
    setRelatedEventFilters(value);
  }, [JSON.stringify(value)]);

  const onSave = (eventFilters) => {
    onChange(eventFilters);
    onAddDone();
    setShowEventModal(false);
  };

  const handleViewAll = useCallback(() => {
    setShowEventModal(true);
  }, []);

  const handleClose = () => {
    onAddDone();
    setShowEventModal(false);
  };

  useEffect(() => {
    if (addEvents && !showEventModal.show) {
      setShowEventModal(true);
    }
  }, [addEvents, showEventModal]);

  return (
    <div className={`related-events ${!isActive ? 'empty' : ''}`}>
      {initialized && dateDomain ? (
        <EventTimelineProvider
          site={site}
          dateDomain={dateDomain}
          filterText={text}
          eventOrdering="asc"
          availableIotEventTypes={allIotEventTypes}
          availableUserEventTypes={allUserEventTypes}
          eventTypeFilter={eventTypeFilter || 'all'}
          filterParams={params}
          hideInactiveSystemEvents={hideInactiveSystemEvents || active}
        >
          {isActive && (
            <EventListDisplay
              onViewAll={handleViewAll}
              excludeEventId={sourceEventId}
              onSelectEvent={onSelectEvent}
              readOnly={readOnly}
            />
          )}
          {showEventModal && relatedEventFilters && (
            <RelatedEventsModal
              panelId={panelId}
              readOnly={readOnly}
              onSave={onSave}
              onClose={handleClose}
              onSelectEvent={onSelectEvent}
              excludeEventId={sourceEventId}
              eventListColumns={eventListColumns}
              availableIotEventTypes={allIotEventTypes}
              availableUserEventTypes={allUserEventTypes}
              site={site}
            />
          )}
        </EventTimelineProvider>
      ) : null}
    </div>
  );
};

export default RelatedEvents;
