import React, { useState, useEffect } from 'react';
import { Button, Select } from '@avtjs/react-components';

import SimpleModal from '../../../../SimpleModal';

const LayoutImportPanel = ({
  layouts,
  selectedLayoutKey,
  onFileInput,
  fileName,
  importError,
  onLayoutSelection,
  disabled,
  onCancel,
  onApply,
}) => {
  const [uploadEnabled, setUploadEnabled] = useState(true);
  const [selectExisting, setSelectExisting] = useState(true);

  const handleSelectLayout = (layoutSelection) => {
    setUploadEnabled(false);
    onLayoutSelection(layoutSelection.value);
  };

  useEffect(() => {
    if (fileName) {
      setSelectExisting(false);
    }
  }, [fileName]);

  return (
    <SimpleModal
      onClose={onCancel}
      title="Import Layout"
    >
      <div className="layout-import-panel">
        {importError && <div className="layout-file-input-error">{importError}</div>}
        <div className={`layout-file-input-wrapper${uploadEnabled ? '' : ' hide'}`}>
          <label
            htmlFor="file-upload"
            className="layout-file-input"
          >
            Open
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={onFileInput}
          />
          <span id="file-upload-value">{fileName || 'Choose file...'}</span>
        </div>
        <div className="layout-list">
          <Select
            value={layouts.filter((l) => l.value === selectedLayoutKey)}
            onChange={handleSelectLayout}
            options={layouts}
            isDisabled={!selectExisting}
          />
        </div>
        <div className="layout-import-panel--actions">
          <Button
            onClick={onCancel}
            activity="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={onApply}
            disabled={disabled}
          >
            Apply
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
};

export default LayoutImportPanel;
