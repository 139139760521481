import React from 'react';
import { useAuth, Button } from '@avtjs/react-components';

import logo from '../../../images/logo-hitachi.svg'; // eslint-disable-line import/no-unresolved

const NoGrantsView = () => {
  const auth = useAuth();
  return (
    <div className="no-grants-view-component">
      <div className="no-grants-view-panel">
        <img
          className="hitachi-logo"
          src={logo}
          alt=""
        />
        <p>You don't have any permissions yet.</p>
        <p>Please contact the site manager to add requested permissions to your user.</p>
        <Button
          onClick={() => {
            auth.logOut();
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default NoGrantsView;
