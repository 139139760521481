import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { requestComponents } from '../../../bundles/components';
import {
  getLayout,
  hasBeenUpdated,
  resetLayout,
  saveLayout,
  updateLayout,
} from '../../../bundles/layouts';
import { getActiveSite } from '../../../bundles/sites';
import { updateTrail } from '../../../bundles/trails';
import { requestVisualizations } from '../../../bundles/visualizations';

import Heading from '../../Heading';

import { routeTrail, getNewUrl } from './routeTrails';
import LayoutContent from './widgets/LayoutContent';
import UpdateWidget from './widgets/UpdateWidget';
import './Forms.scss';
import CloseImportButton from './widgets/CloseImportButton';
import createRouteTrail from './trailConfig';

const crypto = window.crypto || window.msCrypto;
const array = new Uint32Array(1);

const LayoutView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { url, params } = useRouteMatch();

  const {
    column: initialColumnIndex,
    page: initialPageIndex,
    panel: initialPanelIndex,
    section: initialSectionIndex,
  } = params;

  const site = useSelector(getActiveSite);
  const layout = useSelector((state) => getLayout(state, site.id));
  const updated = useSelector((state) => hasBeenUpdated(state, site.id));

  const { pages = [] } = layout;
  const [activeTree, setActiveTree] = useState(false);

  const routeTrails = useMemo(() => createRouteTrail(activeTree, setActiveTree), [activeTree]);

  const setTrail = () => {
    if (activeTree) {
      const page = pages[activeTree.page];
      const trail = routeTrail({
        routeTrails,
        context: {
          page,
          activeTree,
        },
        url: history.location.pathname,
      });
      dispatch(updateTrail(trail));
    }
  };

  useEffect(() => {
    setActiveTree({
      page: initialPageIndex ? Number(initialPageIndex) : undefined,
      section: initialSectionIndex ? Number(initialSectionIndex) : undefined,
      column: initialColumnIndex ? Number(initialColumnIndex) : undefined,
      panel: initialPanelIndex ? Number(initialPanelIndex) : undefined,
    });
    if (pages.length) setTrail();
  }, []);

  useEffect(() => {
    if (pages.length) setTrail();
  }, [params]);

  useEffect(() => {
    if (activeTree) {
      history.replace(getNewUrl({ activeTree, url }));
    }
  }, [activeTree]);

  useEffect(() => {
    if (site.id) {
      dispatch(requestComponents(site.id));
      dispatch(requestVisualizations(site.id));
    }
  }, [site.id]);

  const updatePages = useCallback(
    (callback) => {
      const updatedPages = callback(pages);
      dispatch(updateLayout(site.id, { ...layout, pages: updatedPages }));
    },
    [JSON.stringify(pages), JSON.stringify(layout), site.id]
  );

  const updatePage = useCallback(
    (pageIndex, callback) => {
      const page = layout.pages[pageIndex];
      const updatedPage = callback(page);

      const layoutCopy = JSON.parse(JSON.stringify(layout));
      layoutCopy.pages[pageIndex] = updatedPage;

      dispatch(updateLayout(site.id, layoutCopy));
    },
    [JSON.stringify(layout), site.id]
  );

  const exportLayout = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(layout)], { type: 'text/json' });
    element.href = URL.createObjectURL(file);
    element.download = `${site.name}_layout.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const updateSection = useCallback(
    (pageIndex, sectionIndex, callback) => {
      const page = layout.pages[pageIndex];
      const section = page.sections[sectionIndex];
      const updatedSection = callback(section);
      const layoutCopy = JSON.parse(JSON.stringify(layout));

      layoutCopy.pages[pageIndex].sections[sectionIndex] = updatedSection;

      dispatch(updateLayout(site.id, layoutCopy));
    },
    [JSON.stringify(layout), site.id]
  );

  const updateColumn = useCallback(
    (pageIndex, sectionIndex, columnIndex, callback) => {
      const page = layout.pages[pageIndex];
      const section = page.sections[sectionIndex];
      const column = section.columns[columnIndex];
      const updatedColumn = callback(column);
      const layoutCopy = JSON.parse(JSON.stringify(layout));

      layoutCopy.pages[pageIndex].sections[sectionIndex].columns[columnIndex] = updatedColumn;

      dispatch(updateLayout(site.id, layoutCopy));
      setTrail();
    },
    [JSON.stringify(layout), site.id]
  );

  const onSaveLayout = useCallback(() => {
    dispatch(saveLayout(site.id, layout));
    setTrail();
  }, [site.id, layout]);

  const setActiveTreeIndexes = (update = {}) => {
    const {
      page = undefined,
      section = undefined,
      column = undefined,
      panel = undefined,
      initial = false,
    } = update;
    setActiveTree(() => ({
      page,
      section,
      column,
      panel,
      initial,
    }));
  };

  const createPage = () => {
    updatePages((existingPages) => [
      ...existingPages,
      {
        id: `page-${uuidv4()}`,
        title: 'Untitled page',
        path: `path-${Math.floor(crypto.getRandomValues(array)[0] * 100000)}`,
        icon: 'abb-voltage',
        sections: [],
      },
    ]);
  };

  const applyImport = (importedLayout) => {
    dispatch(
      updateLayout(site.id, {
        ...importedLayout,
        id: layout.id,
        site_id: layout.site_id,
      })
    );
  };

  return (
    <div className={`layout-view ${updated ? 'updated' : ''}`}>
      <Heading
        contentLeft={<div className="title">Layout</div>}
        contentRight={
          <CloseImportButton
            createPage={createPage}
            applyImport={applyImport}
            exportLayout={exportLayout}
            activeTree={activeTree}
            setActiveTree={setActiveTree}
          />
        }
      />

      <UpdateWidget
        updated={updated}
        onClick={() => dispatch(resetLayout(site.id))}
        onSaveLayout={onSaveLayout}
      />

      <LayoutContent
        activeTree={activeTree}
        layout={layout}
        pages={pages}
        setActiveTreeIndexes={setActiveTreeIndexes}
        updateColumn={updateColumn}
        updatePage={updatePage}
        updatePages={updatePages}
        updateSection={updateSection}
      />
    </div>
  );
};

export default LayoutView;
