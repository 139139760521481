/* eslint-disable default-param-last */
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import {
  getSiteMappings as getSiteMappingsApi,
  createMapping as createMappingApi,
  updateOrDeleteMappings as updateOrDeleteMappingsApi,
  updateMapping as updateMappingApi,
  deleteMapping as deleteMappingApi,
  getGeometries as getGeometriesApi,
  createOrUpdateGeometries as createOrUpdateGeometriesApi,
  deleteGeometry as deleteGeometryApi,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_MAPPINGS = 'dt/item-designation/REQUEST_MAPPINGS';
const RECEIVE_MAPPINGS = 'dt/item-designation/RECEIVE_MAPPINGS';
const RECEIVE_MAPPING = 'dt/item-designation/RECEIVE_MAPPING';

const CREATE_MAPPING = 'dt/item-designation/CREATE_MAPPING';
const UPDATE_OR_DELETE_MAPPINGS = 'dt/item-designation/UPDATE_OR_DELETE_MAPPINGS';
const UPDATE_MAPPING = 'dt/item-designation/UPDATE_MAPPING';
const DELETE_MAPPING = 'dt/item-designation/DELETE_MAPPING';
const DELETED_MAPPING = 'dt/item-designation/DELETED_MAPPING';

const REQUEST_GEOMETRIES = 'dt/geometry/REQUEST_GEOMETRIES';
const RECEIVE_GEOMETRIES = 'dt/geometry/RECEIVE_GEOMETRIES';

const CREATE_GEOMETRY_OR_UPDATE = 'dt/geometry/CREATE_GEOMETRY';
const DELETE_GEOMETRY = 'dt/geometry/DELETE_GEOMETRY';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestMappings = (siteId, { callback, modelId, versionId } = {}) => ({
  type: REQUEST_MAPPINGS,
  siteId,
  callback,
  modelId,
  versionId,
});

export const receiveMappings = (siteId, mappings) => ({
  type: RECEIVE_MAPPINGS,
  siteId,
  mappings,
});

export const receiveMapping = (siteId, modelId, mapping) => ({
  type: RECEIVE_MAPPING,
  siteId,
  modelId,
  mapping,
});

export const updateMapping = (siteId, modelId, versionId, mapping, callback) => ({
  type: UPDATE_MAPPING,
  siteId,
  modelId,
  versionId,
  mapping,
  callback,
});

export const createMapping = (siteId, modelId, versionId, mapping, callback) => ({
  type: CREATE_MAPPING,
  siteId,
  modelId,
  versionId,
  mapping,
  callback,
});

export const updateOrDeleteMappings = (siteId, modelId, versionId, scope, callback) => ({
  type: UPDATE_OR_DELETE_MAPPINGS,
  siteId,
  modelId,
  versionId,
  scope,
  callback,
});

export const deleteMapping = (siteId, modelId, versionId, mappingId) => ({
  type: DELETE_MAPPING,
  siteId,
  modelId,
  versionId,
  mappingId,
});

export const deletedMapping = (siteId, modelId, mappingId) => ({
  type: DELETED_MAPPING,
  siteId,
  modelId,
  mappingId,
});

export const requestGeometries = (siteId, modelId, callback) => ({
  type: REQUEST_GEOMETRIES,
  siteId,
  modelId,
  callback,
});

export const receiveGeometries = (siteId, modelId, geometries) => ({
  type: RECEIVE_GEOMETRIES,
  siteId,
  modelId,
  geometries,
});

export const createOrUpdateGeometry = (siteId, modelId, geometries, callback) => ({
  type: CREATE_GEOMETRY_OR_UPDATE,
  siteId,
  modelId,
  geometries,
  callback,
});

export const deleteGeometry = (siteId, modelId, geometryId, callback) => ({
  type: DELETE_GEOMETRY,
  siteId,
  modelId,
  geometryId,
  callback,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  mappingsBySite: {
    /*
    siteId: {
      ids: [<mappingId1>, <mappingId2>],
      modelAndDesignationToMappingId: {},
      mappings: {
        [<mappingId1>]: <mapping1>,
        [<mappingId2>]: <mapping2>,
      },
    },
    */
  },
  geometriesBySiteAndModel: {
    /*
    siteId: {
      modelId1: [
        {
          ...geoData,
        },
        {
          ...geo2Data
        },
      ],
    },
    */
  },
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MAPPINGS: {
      const { mappings, siteId } = action;
      const ids = [];
      const modelAndDesignationToMappingId = {};
      const mappingsById = mappings.reduce((acc, mapping) => {
        ids.push(mapping.id);
        mapping.itemDesignations.forEach((d) => {
          modelAndDesignationToMappingId[`${mapping.model_id}@${d}`] = mapping.id;
        });
        return { ...acc, [mapping.id]: mapping };
      }, {});

      return {
        ...state,
        mappingsBySite: {
          ...state.mappingsBySite,
          [siteId]: {
            ids,
            modelAndDesignationToMappingId,
            mappings: mappingsById,
          },
        },
      };
    }

    case RECEIVE_GEOMETRIES: {
      const { siteId, modelId, geometries } = action;

      return {
        ...state,
        geometriesBySiteAndModel: {
          ...state.geometriesBySiteAndModel,
          [siteId]: {
            ...state.geometriesBySiteAndModel[siteId],
            [modelId]: geometries,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

const getSiteIndexedMappings = (state) => state.geometryMappings.mappingsBySite;
const getSiteIndexedGeometries = (state) => state.geometryMappings.geometriesBySiteAndModel;

export const getIndexedMappingsBySite = createSelector(
  [(_, siteId) => siteId, getSiteIndexedMappings, getSiteIndexedGeometries],
  (siteId, mappingsBySite, geometriesBySiteAndModel) => {
    if (mappingsBySite[siteId]) {
      const siteGeometries = Object.values(geometriesBySiteAndModel?.[siteId] || {}).flat();
      const indexedMappings = {};
      Object.entries(mappingsBySite[siteId].mappings).forEach(
        ([key, { geometryIds, ...mapping }]) => {
          indexedMappings[key] = {
            ...mapping,
            objects: geometryIds
              .map((id) => siteGeometries.find((geometry) => geometry.id === id))
              .filter((geometry) => !!geometry),
          };
        }
      );
      return indexedMappings;
    }
    return null;
  }
);

export const getModelAndDesignationIndexedMappingIdsBySite = createSelector(
  [(_, siteId) => siteId, getSiteIndexedMappings],
  (siteId, mappingsBySite) => {
    if (mappingsBySite[siteId]) {
      return mappingsBySite[siteId].modelAndDesignationToMappingId;
    }
    return null;
  }
);

export const getVersionMappings = createSelector(
  [
    (_, siteId, modelId, versionId) => [siteId, modelId, versionId],
    getSiteIndexedMappings,
    getSiteIndexedGeometries,
  ],
  ([siteId, modelId, versionId], mappingsBySite, geometriesBySiteAndModel) => {
    const { ids = [], mappings = {} } = mappingsBySite[siteId] || {};
    const modelGeometries = geometriesBySiteAndModel?.[siteId]?.[modelId] || [];
    return ids
      .map((mappingId) => {
        const mapping = mappings[mappingId];
        return {
          ...mapping,
          objects: mapping.geometryIds
            .map((geometryId) => modelGeometries.find((geometry) => geometry.id === geometryId))
            .filter((geometry) => !!geometry),
        };
      })
      .filter((mapping) => mapping.version_id === versionId);
  }
);

export const getSiteGeometries = createSelector(
  [(_, siteId) => siteId, getSiteIndexedGeometries],
  (siteId, geometriesBySiteAndModel) =>
    Object.values(geometriesBySiteAndModel?.[siteId] || {}).flat()
);

export const getModelGeometries = createSelector(
  [(_, siteId, modelId) => [siteId, modelId], getSiteIndexedGeometries],
  ([siteId, modelId], geometriesBySiteAndModel) =>
    geometriesBySiteAndModel?.[siteId]?.[modelId] || []
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestMappings(action) {
  try {
    const { siteId, callback, modelId, versionId } = action;
    const { values: mappings } = yield call(getSiteMappingsApi, siteId);
    yield put(receiveMappings(siteId, mappings));

    if (callback && modelId && versionId) {
      const newMappings = yield select((state) =>
        getVersionMappings(state, siteId, modelId, versionId)
      );
      yield call(callback, newMappings);
    }
  } catch (e) {
    console.error('Unable to fetch mapping data: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getMappings', 'error')()));
  }
}

function* doCreateMapping(action) {
  const { siteId, modelId, versionId, mapping, callback } = action;

  try {
    yield call(createMappingApi, modelId, versionId, mapping);
    yield put(requestMappings(siteId, { callback, modelId, versionId }));
    yield put(displayNotification(getNotification('createMapping', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to create geometry mapping: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createMapping', 'error')(modelId)));
  }
}

function* doUpdateOrDeleteMappings(action) {
  const { siteId, modelId, versionId, scope, callback } = action;
  try {
    yield updateOrDeleteMappingsApi(modelId, versionId, scope);
    yield put(requestMappings(siteId));
    yield call(callback);
    yield put(displayNotification(getNotification('clearMappings', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to update/delete geometry mappings: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('clearMappings', 'error')(modelId)));
  }
}

function* doUpdateMapping(action) {
  const { siteId, modelId, versionId, mapping, callback } = action;

  try {
    yield call(updateMappingApi, modelId, versionId, mapping.id, mapping);
    yield put(requestMappings(siteId, { callback, modelId, versionId }));
    yield put(displayNotification(getNotification('updateMapping', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to update geometry mapping: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateMapping', 'error')(modelId)));
  }
}

function* doDeleteMapping(action) {
  const { siteId, modelId, versionId, mappingId } = action;

  try {
    yield call(deleteMappingApi, modelId, versionId, mappingId);
    yield put(requestMappings(siteId));
    yield put(displayNotification(getNotification('deleteMapping', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to delete geometry mapping: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteMapping', 'error')(modelId)));
  }
}

function* doRequestGeometries(action) {
  try {
    const { siteId, modelId, callback } = action;
    const { values: geometries } = yield call(getGeometriesApi, modelId);

    yield put(receiveGeometries(siteId, modelId, geometries));
    if (callback) yield call(callback, geometries);
  } catch (e) {
    console.error('Unable to fetch geometry: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getGeometries', 'error')()));
  }
}

function* doCreateOrUpdateGeometry(action) {
  const { siteId, modelId, geometries, callback } = action;

  try {
    yield call(createOrUpdateGeometriesApi, modelId, { geometries });
    yield put(requestGeometries(siteId, modelId, callback));
    yield put(displayNotification(getNotification('createGeometry', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to create geometry: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createGeometry', 'error')(modelId)));
    if (callback) yield call(callback);
  }
}

function* doDeleteGeometry(action) {
  const { siteId, modelId, geometryId, callback } = action;

  try {
    yield deleteGeometryApi(modelId, geometryId);
    yield put(requestGeometries(siteId, modelId, callback));
    yield put(requestMappings(siteId));
    yield put(displayNotification(getNotification('deleteGeometry', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to delete geometry : ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteGeometry', 'error')(modelId)));
    if (callback) yield call(callback);
  }
}

export const sagas = [
  takeEvery(REQUEST_MAPPINGS, doRequestMappings),
  takeLatest(UPDATE_MAPPING, doUpdateMapping),
  takeLatest(CREATE_MAPPING, doCreateMapping),
  takeLatest(UPDATE_OR_DELETE_MAPPINGS, doUpdateOrDeleteMappings),
  takeLatest(DELETE_MAPPING, doDeleteMapping),
  takeEvery(REQUEST_GEOMETRIES, doRequestGeometries),
  takeLatest(CREATE_GEOMETRY_OR_UPDATE, doCreateOrUpdateGeometry),
  takeLatest(DELETE_GEOMETRY, doDeleteGeometry),
];
