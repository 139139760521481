/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareDesc, parse } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { Spinner, Button, useTheme, Icon, Input } from '@avtjs/react-components';
import { ComtradeViewer } from '@avtjs/comtrade-viewer';
import '@avtjs/comtrade-viewer/dist/index.css';
import loadZipFile from './utils';
import { formatBytes, debounce } from '../../../utils';
import { utcToSite, datetimeString } from '../../../datetimeUtils';
import { getPollingDateRange, getTimezone } from '../../../bundles/application';
import { useFilesSession, getFiles, requestFiles } from '../../../bundles/files';
import {
  updateTfrTemplate,
  requestTfrTemplates,
  fetchTfrTemplates,
} from '../../../bundles/tfr-templates';
import { getComponentsLoaded } from '../../../bundles/components';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import getNotification from '../../../bundles/notification-defaults';
import { getUserEventTypes } from '../../../bundles/events';
import services from '../../../services';

import { useEventModal } from '../../EventModal';
import Empty from '../../Empty';
import Pagination from '../../Pagination';
import NewTemplateModel from './components/NewTemplateModel';
import Loader from '../../Loader';

const formatDuration = (samples, rate) => {
  const duration = samples / rate;
  if (duration > 0.5) {
    return `${parseInt(duration, 10)} seconds`;
  }
  return `${duration * 1000} milliseconds`;
};

const TFRList = ({
  loading,
  tfrs,
  onTFRSelect,
  filesPerPage,
  onTFRDownload,
  timezone,
  onSearch,
  compsLoaded,
  filesLoading,
  pages,
  page,
  setPage,
  isLeftPanel = false,
  selectedTfr,
  setSelectedTfr,
}) => {
  const [search, setSearch] = useState('');
  const onSearchContainer = (e) => {
    debounce(setSearch(e.target.value), 300);
    onSearch(e);
  };

  const renderEmpty = () => (
    <Empty
      text={search.length > 0 ? 'No records found' : 'No recordings available for this site'}
    />
  );

  const renderLoading = () => (
    <div className="tfr-list-loading">
      Loading recordings ... <Spinner size="s" />
    </div>
  );

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className="date">Date</th>
          <th className="name">Name</th>
          <th className="duration">Duration</th>
          <th className="rate">Sample rate</th>
          <th
            className="size"
            colSpan="2"
          >
            Size
          </th>
        </tr>
      </thead>
      <tbody>
        {tfrs.slice(0, filesPerPage).map((file) => {
          const { filename, size, attributes: { ts, sampleRates = [] } = {} } = file;
          const rate = (sampleRates[0] || {}).rate || 0;
          const samples = (sampleRates[0] || {}).end || 0;
          const duration = formatDuration(samples, rate);

          return (
            <tr key={`file-${file.id}`}>
              <td className="date">{datetimeString(utcToSite(ts, timezone), timezone)}</td>
              <td className="name">{filename.replace('.zip', '')}</td>
              <td className="duration">{duration}</td>
              <td className="rate">{rate} Hz</td>
              <td className="size">{formatBytes(size)}</td>
              <td className="view">
                <Button
                  onClick={() => onTFRDownload(file.resourceUrl)}
                  disabled={loading !== null}
                  tooltip="Download"
                  activity="secondary"
                >
                  Download
                </Button>
                <Button
                  disabled={loading !== null}
                  onClick={() => onTFRSelect(file, 'tfr-list')}
                  className="view-button"
                >
                  {loading === file.id ? <Spinner size="s" /> : 'View'}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const renderList = () => (
    <div className="tfr-list">
      <div className="tfr-list-header">TFR File Name</div>
      {tfrs.map((tfr) => (
        <div
          key={tfr.id}
          className={`tfr-item ${selectedTfr === tfr.id ? 'selected-tfr' : ''}`}
          onClick={() => {
            onTFRSelect(tfr, 'tfr-view');
            setSelectedTfr(tfr.id);
          }}
        >
          {tfr.filename.replace('.zip', '')}
        </div>
      ))}
    </div>
  );

  const renderContent = () => {
    if (!tfrs.length) return renderEmpty();
    if (!compsLoaded || filesLoading) return renderLoading();
    return isLeftPanel ? renderList() : renderTable();
  };

  return (
    <>
      <div className="tfr-online-selector">
        <div className="tfr-online-selector--heading">
          {!isLeftPanel && <h4 className="tfr-list-title">Recordings</h4>}
          <Input
            type="text"
            onChange={onSearchContainer}
            value={search}
            placeholder="Search TFR file name..."
          />
        </div>
        {renderContent()}
      </div>
      <Pagination
        pages={pages}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

const SelectFiles = ({
  configFile,
  dataFile,
  eventsFile,
  onConfigSelect,
  onDataSelect,
  onEventsSelect,
  onIgnoreEvents,
}) => (
  <div className="select-files-component">
    {!configFile && (
      <label className="select-files--field">
        Load configuration file
        <input
          className="trf-viewer--input"
          disabled={configFile !== null}
          type="file"
          name="file"
          id="file"
          accept=".cfg"
          onChange={onConfigSelect}
        />
      </label>
    )}
    {Boolean(configFile) && !dataFile && (
      <label className="select-files--field">
        Load data file
        <input
          className="trf-viewer--input"
          disabled={dataFile !== null}
          type="file"
          name="file"
          id="file"
          accept=".dat"
          onChange={onDataSelect}
        />
      </label>
    )}
    {Boolean(dataFile) && (
      <>
        <label className="select-files--field">
          Load events file
          <input
            className="trf-viewer--input"
            disabled={eventsFile !== null}
            type="file"
            name="file"
            id="file"
            accept=".xml"
            onChange={onEventsSelect}
          />
        </label>
        <Button
          onClick={onIgnoreEvents}
          activity="secondary"
        >
          Get Events from API
        </Button>
      </>
    )}
  </div>
);

const ComtradeContainer = React.memo(
  ({
    configFile,
    tfrs,
    dataFile,
    eventsFile,
    getEventsCallback,
    onOpenEvent,
    onFormReset,
    theme,
    tz,
    site,
    onSearch,
    onTFRSelect,
    selectedTfrId,
    compsLoaded,
    filesLoading,
    pages,
    page,
    setPage,
    loading,
    onTFRDownload,
    timezone,
    loadTfr,
  }) => {
    const dispatch = useDispatch();
    const tfrTemplates = useSelector(fetchTfrTemplates);
    const [showNewTemplate, setShowNewTemplate] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const [showNav, setShowNav] = useState(false);
    const [dataFileToViewer, setDataFileToViewer] = useState({});
    const [selectedTfr, setSelectedTfr] = useState(selectedTfrId);
    useEffect(() => {
      const body = document.querySelector('body');
      body.classList.add('no-scroll');

      return () => body.classList.remove('no-scroll');
    }, []);

    useEffect(() => {
      setDataFileToViewer(dataFile);
    }, [dataFile]);

    const onCloseModal = useCallback(() => {
      setShowNewTemplate(false);
    });

    const onTemplateActions = useCallback((action) => {
      setTemplateData(action);
      switch (action.actionType) {
        case 'save': {
          setShowLoader(true);
          const data = {
            site: site.id,
            name: action.name,
            value: { template: action.template },
            org: site.org,
            templateType: 'tfr',
          };
          dispatch(
            updateTfrTemplate(action.id, data, () => {
              setShowLoader(false);
            })
          );
          break;
        }
        default: {
          setShowNewTemplate(true);
        }
      }
    }, []);

    return (
      <div className="tfr-view">
        <div className={`tfr-sidebar${showNav ? ' show' : ''}`}>
          <div className={`tfr-sidebar-header${showNav ? ' show' : ''}`}>
            <Icon
              size={'m'}
              icon={'abb-list'}
              onClick={() => setShowNav(true)}
            />
            <Icon
              size={'m'}
              icon={'abb-caret-left'}
              onClick={() => setShowNav(false)}
            />
          </div>
          <div className={`tfr-sidebar-body${showNav ? ' show' : ''}`}>
            <TFRList
              tfrs={tfrs}
              onTFRSelect={onTFRSelect}
              filesPerPage={2}
              loading={loading}
              onTFRDownload={onTFRDownload}
              timezone={timezone}
              onSearch={onSearch}
              compsLoaded={compsLoaded}
              filesLoading={filesLoading}
              pages={pages}
              page={page}
              setPage={setPage}
              selectedTfr={selectedTfr}
              setSelectedTfr={setSelectedTfr}
              isLeftPanel={true}
            />
          </div>
        </div>
        <div className={`tfr-view-details${showNav ? ' show' : ''}`}>
          {(loadTfr || showLoader) && (
            <div className="tfr-page-loader">
              <Loader
                text={loadTfr ? 'Loading TFR File...' : ''}
                overlay
              />
            </div>
          )}

          <ComtradeViewer
            configFile={configFile}
            dataFile={dataFileToViewer}
            eventsFile={eventsFile}
            theme={theme}
            getEventsCallback={getEventsCallback}
            selectEventCallback={onOpenEvent}
            onTemplateActions={onTemplateActions}
            templateList={tfrTemplates}
            tz={tz}
            show={showNav}
          >
            <Button
              icon={<Icon icon="back" />}
              onClick={onFormReset}
              activity="secondary"
              slim
            >
              Close
            </Button>
          </ComtradeViewer>

          {showNewTemplate && (
            <NewTemplateModel
              templateData={templateData}
              onCloseModal={onCloseModal}
              tfrTemplates={tfrTemplates}
              site={site}
              setShowLoader={setShowLoader}
            />
          )}
        </div>
      </div>
    );
  }
);

const TFRPanel = ({ site, canLoadLocal = false, filesPerPage = 10 }) => {
  const dispatch = useDispatch();
  const sessionId = useFilesSession();
  const theme = useTheme();
  const {
    files: allFiles,
    pages,
    loading: filesLoading,
  } = useSelector((state) => getFiles(state, sessionId));
  const timezone = useSelector(getTimezone);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loadTfr, setLoadTfr] = useState(false);

  useEffect(() => {
    dispatch(requestTfrTemplates({ site: site.id, templateType: 'tfr' }));
  }, [site.id]);

  const query = useMemo(() => {
    const q = {
      org: site.org,
      site: site.id,
      fileType: 'comtrade',
      limit: filesPerPage,
      order: 'desc',
      page,
    };

    if (search) {
      q.search = search;
    }

    return q;
  }, [page, search]);

  useEffect(() => {
    dispatch(requestFiles(sessionId, query));
  }, [query]);

  const onSearch = (e) => {
    debounce(setSearch(e.target.value), 300);
  };

  const compsLoaded = useSelector(getComponentsLoaded);
  const { startDate, endDate } = useSelector(getPollingDateRange);

  const parseTimeStr = (timeStr, revision, defaultTs) => {
    const dateFormats = {
      1991: 'MM/dd/yy,HH:mm:ss.SSSSSS',
      1999: 'd/M/yyyy,HH:mm:ss.SSSSSS',
      2013: 'd/M/yyyy,HH:mm:ss.SSSSSS',
    };

    let ts = defaultTs;
    const date = parse(timeStr, dateFormats[revision], new Date());

    if (!Number.isNaN(date.getTime())) {
      ts = fromZonedTime(date, timezone).getTime();
    }

    return ts;
  };

  const comtradeFiles = useMemo(
    () =>
      (allFiles || [])
        .map((file) => {
          const {
            attributes: { time, revision },
          } = file;
          let ts = time;
          if (typeof time === 'string') {
            ts = parseTimeStr(time, revision, file.createdAt);
          }
          if (typeof time === 'undefined') {
            ts = file.createdAt;
          }
          return {
            ...file,
            attributes: {
              ...file.attributes,
              ts,
            },
          };
        })
        .sort(({ attributes: { ts: tsA } }, { attributes: { ts: tsB } }) =>
          compareDesc(new Date(tsA), new Date(tsB))
        ),
    [allFiles, startDate, endDate]
  );

  const [configFile, setConfigFile] = useState(null);
  const [dataFile, setDataFile] = useState(null);
  const [eventsFile, setEventsFile] = useState(null);
  const [ignoreEvents, setIgnoreEvents] = useState(false);
  const [loading, setLoading] = useState(null);
  const [selectedTfrId, setSelectedTfrId] = useState('');

  const onConfigSelect = async (event) => {
    if (event.target.files) {
      setConfigFile(event.target.files[0]);
    }
  };

  const onDataSelect = async (event) => {
    if (event.target.files) {
      setDataFile(event.target.files[0]);
    }
  };

  const onEventsSelect = async (event) => {
    if (event.target.files) {
      setEventsFile(event.target.files[0]);
    }
  };

  const onTFRSelect = async (file, pageType) => {
    const { id, resourceUrl } = file;
    setLoading(id);
    setSelectedTfrId(id);
    if (pageType === 'tfr-view') {
      setLoadTfr(true);
    }
    // TODO: why is the server returning an resource URL without the protocol?
    // We are removing the protocol incase it gets implemented later.
    const archive = await loadZipFile(`https://${resourceUrl.replace('https://', '')}`);

    const files = Object.values(archive.files).reduce((acc, compressedFile) => {
      const fileName = compressedFile.name.toLowerCase();

      if (!acc.cfgFile && !fileName.includes('__MACOSX') && fileName.includes('.cfg')) {
        acc.cfgFile = compressedFile;
      }
      if (!acc.datFile && !fileName.includes('__MACOSX') && fileName.includes('.dat')) {
        acc.datFile = compressedFile;
      }
      if (!acc.eventsFile && !fileName.includes('__MACOSX') && fileName.includes('.xml')) {
        acc.eventsFile = compressedFile;
      }
      return acc;
    }, {});

    if (!files.cfgFile || !files.datFile) {
      // TODO: we should notify the user
      setLoading(null);
      throw new Error('COMTRADE archive was missing .cfg or .dat file');
    }

    const cfgData = await files.cfgFile.async('blob');
    const datData = await files.datFile.async('blob');
    const cfgFile = new File([cfgData], files.cfgFile.name);
    const datFile = new File([datData], files.datFile.name);

    if (!files.eventsFile) {
      setIgnoreEvents(true);
    } else {
      const eventsData = await files.eventsFile.async('blob');
      const evtsFile = new File([eventsData], files.eventsFile.name);
      setEventsFile(evtsFile);
    }

    setConfigFile(cfgFile);
    setDataFile(datFile);
    setLoading(null);
    setLoadTfr(false);
  };

  const onFormReset = () => {
    setConfigFile(null);
    setDataFile(null);
    setEventsFile(null);
    setIgnoreEvents(false);
  };

  const getEvents = useCallback(
    async ({ from, to }) => {
      const events = await services.data
        .getEventBuckets({
          site: site.id,
          from,
          to,
          interval: Math.round((to - from) / 400), // 400 event buckets
          aggregationType: 'time_severity_lists',
        })
        .then(
          (res) => res,
          (e) => {
            console.error('Unable to fetch iot events: ', e);
            dispatch(checkIsOnline());
            dispatch(displayNotification(getNotification('getIotEvents', 'error')()));
            return { values: [] };
          }
        );
      return events.values;
    },
    [site]
  );

  const [eventDetail, onEventDetail] = useEventModal();

  const userEventTypes = useSelector(getUserEventTypes);
  const onOpenEvent = useCallback(
    (event) => {
      onEventDetail({
        modalType: 'iot',
        event,
        eventId: event.id,
        userEventTypes,
        site,
      });
    },
    [userEventTypes, site]
  );

  const onTFRDownload = (resourceUrl) => {
    setTimeout(() => {
      window.location = `${window.location.protocol}//${resourceUrl}`;
    });
  };

  if (configFile && dataFile && (eventsFile || ignoreEvents)) {
    return (
      <>
        <ComtradeContainer
          configFile={configFile}
          tfrs={comtradeFiles}
          dataFile={dataFile}
          eventsFile={eventsFile}
          getEventsCallback={getEvents}
          onOpenEvent={onOpenEvent}
          onFormReset={onFormReset}
          theme={theme}
          tz={timezone}
          site={site}
          onSearch={onSearch}
          onTFRSelect={onTFRSelect}
          selectedTfrId={selectedTfrId}
          compsLoaded={compsLoaded}
          filesLoading={filesLoading}
          pages={pages}
          page={page}
          setPage={setPage}
          loading={loading}
          onTFRDownload={onTFRDownload}
          timezone={timezone}
          loadTfr={loadTfr}
        />
        {eventDetail}
      </>
    );
  }

  return (
    <div className="tfr-panel-component">
      {canLoadLocal && (
        <div className="tfr-local-selector">
          <h4 className="tfr-list-title">Select local files</h4>
          <SelectFiles
            configFile={configFile}
            dataFile={dataFile}
            eventsFile={eventsFile}
            ignoreEvents={ignoreEvents}
            onConfigSelect={onConfigSelect}
            onDataSelect={onDataSelect}
            onEventsSelect={onEventsSelect}
            onIgnoreEvents={() => setIgnoreEvents(true)}
          />
        </div>
      )}
      <TFRList
        tfrs={comtradeFiles}
        onTFRSelect={onTFRSelect}
        filesPerPage={filesPerPage}
        loading={loading}
        onTFRDownload={onTFRDownload}
        timezone={timezone}
        onSearch={onSearch}
        compsLoaded={compsLoaded}
        filesLoading={filesLoading}
        pages={pages}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default TFRPanel;
