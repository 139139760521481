import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@avtjs/react-components';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from '../../../../../utils';

import SectionItem from '../SectionItem';
import Delete from '../../../../Delete';
import JSONEditor from '../../../../JSONEditor';
import Heading from '../../../../Heading';

import { panelSchema, getExtendedPanelSchema } from '../../../../panels/panelSchema';
import { getComponentsPanelSchema } from '../../../../panels/ComponentsPanel';

import { getFilesPanelSchema } from '../../../../panels/FilesPanel';
import { textPanelSchema } from '../../../../panels/TextPanel';
import { tfrPanelSchema } from '../../../../panels/TFRPanel';
import { getVisualizationsPanelSchema } from '../../../../panels/VisualizationsPanel';
import { healthPanelSchema } from '../../../../panels/HealthPanel';
import { getViewerPanelSchema, viewerPanelUiSchema } from '../../../../panels/ViewerPanel';
import { getPageSchema, pageUiSchema } from '../../schemas/pageSchema';
import { getComponentPropertiesPanelSchema } from '../../../../panels/ComponentPropertiesPanel';

import { getStaticComponents } from '../../../../../bundles/components';
import { requestSources, getSources, getSiteVariables } from '../../../../../bundles/sources';
import { useFilesSession, requestFiles, getFiles } from '../../../../../bundles/files';
import { requestTypes, getTypes } from '../../../../../bundles/types';
import { requestTags, getTags } from '../../../../../bundles/tags';
import { getVisualizations } from '../../../../../bundles/visualizations';
import { getActiveSite } from '../../../../../bundles/sites';
import { requestUserEventTypes, getUserEventTypes } from '../../../../../bundles/events';
import { getEventTimeLinePanelSchema } from '../../../../panels/EventTimelinePanel/jsonSchema';

const EditPage = ({
  page: { title, path, icon, activeComponentFilter, pageType, viewer = {}, sections = [] },
  activeTree = {},
  setActiveTreeIndexes,
  updatePages,
  updatePage,
  updateSection,
  updateColumn,
}) => {
  const dispatch = useDispatch();
  const { org, id: siteId } = useSelector(getActiveSite);

  const sessionId = useFilesSession();
  const { files } = useSelector((state) => getFiles(state, sessionId));
  const types = useSelector(getTypes);
  const tags = useSelector(getTags);
  const visualizations = useSelector(getVisualizations);
  const stateComponents = useSelector(getStaticComponents);
  const sources = useSelector(getSources);
  const variables = useSelector(getSiteVariables);

  const eventTypes = useSelector(getUserEventTypes);

  useEffect(() => {
    dispatch(
      requestFiles(sessionId, {
        org,
        site: siteId,
        sortBy: 'filename',
        order: 'asc',
      })
    );
    dispatch(requestTypes(siteId));
    dispatch(requestTags(siteId));
    dispatch(requestSources({ siteId }));
    dispatch(requestUserEventTypes());
  }, []);

  const { page: activePageIndex, section: activeSectionIndex } = activeTree;

  const components = useMemo(() => stateComponents, [JSON.stringify(stateComponents)]);

  const sourceVars = useMemo(
    () =>
      variables.reduce((acc, variable) => {
        const source = sources.find((s) => s.id === variable.source_id);
        acc.push({ ...variable, sourceName: source?.name });
        return acc;
      }, []),
    [sources, variables]
  );

  const updatedPageSchema = useMemo(() => getPageSchema(components), [components]);

  const updatedFilesPanelSchema = useMemo(
    () => getFilesPanelSchema(files || [], types || [], tags || []),
    [files && JSON.stringify(files.map((f) => f.id)), JSON.stringify(types), JSON.stringify(tags)]
  );

  const updatedEventTimelineSchema = useMemo(
    () => getEventTimeLinePanelSchema(eventTypes),
    [JSON.stringify(eventTypes)]
  );

  const updatedVisualizationsPanelSchema = useMemo(
    () => getVisualizationsPanelSchema(visualizations),
    [JSON.stringify(visualizations)]
  );

  const updatedComponentsPanelSchema = useMemo(
    () => getComponentsPanelSchema(components),
    [components]
  );

  const updatedComponentPropertiesPanelSchema = useMemo(() => {
    const categories = components
      .reduce(
        (acc, cur) => [
          ...acc,
          ...(cur.properties || []).map((p) => p.category.toLowerCase()),
          // removes duplicates
        ],
        []
      )
      .filter((val, idx, arr) => arr.indexOf(val) === idx);
    return getComponentPropertiesPanelSchema(categories);
  }, [components]);

  const extendedPanels = useMemo(
    () => ({
      components: updatedComponentsPanelSchema,
      'event-timeline': updatedEventTimelineSchema,
      files: updatedFilesPanelSchema,
      text: textPanelSchema,
      'tfr-viewer': tfrPanelSchema,
      visualizations: updatedVisualizationsPanelSchema,
      health: healthPanelSchema,
      'component-properties': updatedComponentPropertiesPanelSchema,
    }),
    [
      updatedComponentsPanelSchema,
      updatedFilesPanelSchema,
      updatedVisualizationsPanelSchema,
      updatedEventTimelineSchema,
    ]
  );

  const updatedPanelSchema = useMemo(
    () => getExtendedPanelSchema(extendedPanels),
    [panelSchema, extendedPanels]
  );

  const updatedViewerPanelSchema = useMemo(
    () => getViewerPanelSchema(updatedPanelSchema, sourceVars, components),
    [updatedPanelSchema, sourceVars, components]
  );

  const updateViewerProps = useCallback(
    ({ formData: viewerProps }) =>
      updatePage(activePageIndex, (page) => ({ ...page, viewer: viewerProps })),
    [updatePage, activePageIndex]
  );

  const updatePageProps = useCallback(
    ({ formData: pageProps }) => updatePage(activePageIndex, (page) => ({ ...page, ...pageProps })),
    [updatePage, activePageIndex]
  );

  const createSection = useCallback(() => {
    setActiveTreeIndexes({ page: activePageIndex, section: sections.length });
    updatePage(activePageIndex, (page) => ({
      ...page,
      sections: [
        ...page.sections,
        {
          id: `section-${uuidv4()}`,
          title: 'Untitled section',
          columns: [],
          displayWhen: {
            componentIsActive: null,
          },
        },
      ],
    }));
  }, [updatePage, activePageIndex]);

  const deletePage = useCallback(() => {
    setActiveTreeIndexes();
    updatePages((pages) => pages.filter((_, index) => index !== activePageIndex));
  }, [setActiveTreeIndexes, updatePages, activePageIndex]);

  const moveSection = (from, to) => {
    updatePage(activePageIndex, (page) => {
      const section = page.sections[from];
      const updatedSections = [...page.sections];
      updatedSections.splice(from, 1);
      updatedSections.splice(to, 0, section);
      return {
        ...page,
        sections: updatedSections,
      };
    });
    setActiveTreeIndexes({ ...activeTree, section: to, initial: false });
  };

  const removeSection = (index) => {
    setActiveTreeIndexes({ page: activePageIndex });
    updatePage(activePageIndex, (page) => {
      const updatedSections = [...page.sections];
      updatedSections.splice(index, 1);
      return {
        ...page,
        sections: updatedSections,
      };
    });
  };

  // If we upgrade to RJSFv5, we can try to get rid of the custom validation.
  const customValidate = useCallback(
    (formData, errors) => {
      updatedPageSchema.required.forEach((fieldName) => {
        const validValue = formData[fieldName] || formData[fieldName] === 0;
        if (!validValue) {
          errors[fieldName].addError(`Page ${capitalize(fieldName)} is required`);
        }
      });

      return errors;
    },
    [updatedPageSchema]
  );
  // To remove duplicate error message
  const transformErrors = (errors) => errors.map((error) => ({ ...error, message: '' }));
  return (
    <div className="edit-page-component">
      <Heading
        typeLabel="page"
        contentLeft={<div className="title">{title}</div>}
        large
      />

      <JSONEditor
        title="Page properties"
        schema={updatedPageSchema}
        uiSchema={pageUiSchema}
        formData={{
          title,
          path,
          icon,
          activeComponentFilter,
          pageType,
        }}
        onFormSubmit={updatePageProps}
        customValidate={customValidate}
        customTransformErrors={(errors) => transformErrors(errors)}
      />

      <JSONEditor
        title="Top/Viewer section"
        formData={viewer}
        schema={updatedViewerPanelSchema}
        uiSchema={viewerPanelUiSchema}
        onFormSubmit={updateViewerProps}
        saveButtonText={'Update viewer properties'}
        initialEditMode={activeSectionIndex === 'viewer'}
      />

      <Heading
        contentLeft={
          <>
            <div className="title">Sections</div>
            <Button
              onClick={createSection}
              activity="secondary"
              slim
            >
              Create section
            </Button>
          </>
        }
      />

      <div className="page-content">
        <div className="section-list">
          {sections.map((section, i) => {
            const key = `section-${i}`;
            return (
              <SectionItem
                key={key}
                activeTree={activeTree}
                sectionIndex={i}
                sectionCount={sections.length}
                moveSection={moveSection}
                removeSection={removeSection}
                updateSection={updateSection}
                updateColumn={updateColumn}
                sectionActive={activeTree.section === i}
                setActiveTreeIndexes={setActiveTreeIndexes}
                {...section}
              />
            );
          })}
        </div>
        <Delete
          onDelete={deletePage}
          title="Delete page"
        />
      </div>
    </div>
  );
};

export default EditPage;
