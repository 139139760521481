/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import html2canvas from 'html2canvas';
import { useTheme } from '@avtjs/react-components';
import { downloadBlob } from '../../../../utils';
import ListItem from '../../../ListItem';
import './ExportEventStats.scss';
import { USER_EVENTS } from '../../../../constants';

const PieChartExport = ({
  site,
  eventsStats,
  onClose,
  setEventsStatsRetCount,
  dateDisplay,
  allUserEventTypes,
}) => {
  const chartRef = useRef(null);
  const [animationEndAck, setAnimationEndAck] = useState(0);

  const theme = useTheme();
  const transformEvents = (events, keyName) => {
    return events.reduce((acc, event) => {
      let key = event[keyName];
      key =
        keyName === 'state'
          ? USER_EVENTS.find((e) => e.id === key).type
          : allUserEventTypes.find((e) => e.id === event[keyName])?.name;
      const existingObj = acc.find((e) => e.name === key);
      const supportingKeyName = keyName === 'state' ? 'type' : 'state';
      if (event.count) {
        if (!existingObj) {
          let color = '';
          if (keyName === 'state') {
            color = USER_EVENTS.find((e) => e.type === key).color;
          }
          acc.push({
            name: key,
            [event[supportingKeyName]]: Number(event.count),
            value: Number(event.count),
            fill: color,
          });
        } else {
          existingObj[event[supportingKeyName]] = Number(event.count);
          existingObj.value += Number(event.count);
        }
      }
      return acc;
    }, []);
  };

  const categorziedEvents = eventsStats.reduce(
    (acc, event) => {
      if (event.planned) {
        acc.plannedEvents.push(event);
      } else {
        acc.reactiveEvents.push(event);
      }
      return acc;
    },
    { plannedEvents: [], reactiveEvents: [] }
  );
  const { plannedEvents, reactiveEvents } = categorziedEvents;

  const stateGroupedPlannedEvents = transformEvents(plannedEvents, 'state');
  const stateGroupedReactiveEvents = transformEvents(reactiveEvents, 'state');
  const typeGroupedPlannedEvents = transformEvents(plannedEvents, 'type');
  const typeGroupedReactiveEvents = transformEvents(reactiveEvents, 'type');

  const canvasToSvg = async (element) => {
    try {
      const canvas = await html2canvas(element);

      // Create SVG element
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svg.setAttribute('width', canvas.width);
      svg.setAttribute('height', canvas.height);

      // Create SVG image element with Canvas data
      const svgImage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      svgImage.setAttribute('x', '0');
      svgImage.setAttribute('y', '0');
      svgImage.setAttribute('width', canvas.width);
      svgImage.setAttribute('height', canvas.height);
      svgImage.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        canvas.toDataURL('image/png')
      );

      // Append image element to SVG
      svg.appendChild(svgImage);

      return new XMLSerializer().serializeToString(svg);

      // eslint-disable-next-line
    } catch (error) {
      console.error('Error converting canvas to SVG:', error);
      return null;
    }
  };

  const exportToSVG = async () => {
    const chart = chartRef.current;
    const hideElementStyle = {
      display: 'flex',
      position: 'absolute',
      left: '-800rem',
    };

    Object.assign(chart.style, hideElementStyle);
    const svgXml = await canvasToSvg(chart);
    const fileName = `${site}-Exported Statistics-${new Date().toISOString().slice(0, 10).replace(/-/g, '')}.svg`;
    downloadBlob(svgXml, fileName, 'image/svg+xml;charset=utf-8');
    chart.display = 'none';
    setEventsStatsRetCount(10000);
    onClose();
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    percent,
  }) => {
    const percentwithDec = percent * 100;
    const displayPercent =
      percentwithDec < 1 ? percentwithDec.toFixed(1) : percentwithDec.toFixed(0);
    const offset = displayPercent < 5 ? 125 : 20;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + offset;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <>
        {displayPercent < 5 && (
          <line
            x1={cx}
            y1={cy}
            x2={x}
            y2={y}
            stroke={'#b3b3b3'}
            fill={`${theme === 'dark' ? '#e6e6e6' : '#1a1a1a'}`}
            strokeWidth={1}
          />
        )}
        <text
          x={x}
          y={y}
          fill={`${theme === 'dark' ? '#e6e6e6' : '#1a1a1a'}`}
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={displayPercent < 5 ? '0.7em' : '1em'}
        >
          {`${value} (${displayPercent}%)`}
        </text>
      </>
    );
  };

  const onEnd = () => {
    setAnimationEndAck(animationEndAck + 1);
  };

  useEffect(() => {
    if (animationEndAck === 2) {
      exportToSVG();
    }
  }, [animationEndAck]);

  const headers = ['Type', 'Total', 'Pending', 'Closed', 'Open', 'Overdue', 'To-do'];

  const listItems = (events) => {
    return events.map((e) => (
      <ListItem
        key={e.id}
        entity={`exportStats (${e.name})`}
        item={e}
        columns={[
          e.name,
          e.value ? e.value : 0,
          e.pending ? e.pending : 0,
          e.closed ? e.closed : 0,
          e.open ? e.open : 0,
          e.overdue ? e.overdue : 0,
          e.todo ? e.todo : 0,
        ]}
        columnWidths={{ type: 'rem', widths: [11, 7, 7, 7, 7, 7, 7] }}
        withActions={false}
      ></ListItem>
    ));
  };

  return (
    <div className="export-event-stats">
      {/* Hidden div to hold the chart for export */}
      <div
        className="report"
        ref={chartRef}
        style={{ display: 'none' }}
      >
        <div className="chart">
          <label>Status report (Planned tasks)</label>
          <h1>{dateDisplay}</h1>

          {stateGroupedPlannedEvents?.length > 0 ? (
            <>
              <PieChart
                width={600}
                height={600}
              >
                <Pie
                  data={stateGroupedPlannedEvents}
                  cx={300}
                  cy={300}
                  innerRadius={0}
                  outerRadius={180}
                  labelLine={false}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                  onAnimationEnd={onEnd}
                >
                  {stateGroupedPlannedEvents.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.fill}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
              <div className="list-container">
                <ListItem
                  isHeader
                  columns={headers}
                  columnWidths={{ type: 'rem', widths: [11, 7, 7, 7, 7, 7, 7] }}
                  withActions={false}
                />
                {listItems(typeGroupedPlannedEvents)}
              </div>
            </>
          ) : (
            <div className="noEvents">There is no planned task in selected time range</div>
          )}
        </div>
        <div className="chart">
          <label>Status report (Reactive tasks)</label>
          <h1>{dateDisplay}</h1>
          {stateGroupedReactiveEvents?.length > 0 ? (
            <>
              <PieChart
                width={600}
                height={600}
              >
                <Pie
                  data={stateGroupedReactiveEvents}
                  cx={300}
                  cy={300}
                  innerRadius={0}
                  outerRadius={180}
                  fill="#8884d8"
                  dataKey="value"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  onAnimationEnd={onEnd}
                >
                  {stateGroupedReactiveEvents.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.fill}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
              <div className="list-container">
                <ListItem
                  isHeader
                  columns={headers}
                  columnWidths={{ type: 'rem', widths: [11, 7, 7, 7, 7, 7, 7] }}
                  withActions={false}
                ></ListItem>
                {listItems(typeGroupedReactiveEvents)}
              </div>
            </>
          ) : (
            <div className="noEvents">There is no reactive task in selected time range</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChartExport;
