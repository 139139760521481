import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@avtjs/react-components';

import { getSiteVariables, getSources } from '../../../bundles/sources';
import { getStaticComponents } from '../../../bundles/components';
import { getPushPinSchema } from '../../panels/ViewerPanel';
import Heading from '../../Heading';
import FormListItem from '../../FormListItem';
import EditItemModal from '../../EditItemModal';

const ViewerSettings = ({
  settings = {}, // from controlled parent state
  onUpdate,
  onSave,
  onFullSettings,
  onClose,
}) => {
  const sources = useSelector(getSources);
  const variables = useSelector(getSiteVariables);
  const components = useSelector(getStaticComponents);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const sourceVars = useMemo(
    () =>
      variables.reduce((acc, variable) => {
        const source = sources.find((s) => s.id === variable.source_id);
        acc.push({ ...variable, sourceName: source?.name });
        return acc;
      }, []),
    [sources, variables]
  );

  const onCreatePushPin = useCallback(
    ({ formData: newPin }) => {
      onUpdate({
        ...settings,
        pushPins: [...(settings.pushPins || []), newPin],
      });
      setShowCreateForm(false);
      setHasChanges(true);
    },
    [settings, onUpdate, setHasChanges]
  );

  const onUpdatePushPin = useCallback(
    (updatedPin, pinIndex) => {
      onUpdate({
        ...settings,
        pushPins: [
          ...settings.pushPins.slice(0, pinIndex),
          updatedPin,
          ...settings.pushPins.slice(pinIndex + 1),
        ],
      });
      setHasChanges(true);
    },
    [settings, onUpdate, setHasChanges]
  );

  const onDeletePushPin = useCallback(
    (pinIndex) => {
      onUpdate({
        ...settings,
        pushPins: [
          ...settings.pushPins.slice(0, pinIndex),
          ...settings.pushPins.slice(pinIndex + 1),
        ],
      });
      setHasChanges(true);
    },
    [settings, onUpdate, setHasChanges]
  );

  const onCloseCreateForm = useCallback(() => {
    setShowCreateForm(false);
  }, [setShowCreateForm]);

  const schema = useMemo(
    () => getPushPinSchema(sourceVars, components),
    [sourceVars, JSON.stringify(components)]
  );

  return (
    <>
      <div className="column-content">
        <Heading
          contentLeft={<div className="title">Viewer: Push Pins</div>}
          contentRight={
            <Button
              activity="primary"
              slim
              onClick={() => setShowCreateForm(true)}
            >
              Add
            </Button>
          }
        />

        {settings.pushPins && settings.pushPins.length > 0 ? (
          settings.pushPins.map((pin, pinIndex) => (
            <FormListItem
              entity="Push Pin"
              schema={schema}
              formData={pin}
              key={pinIndex}
              identifier={pinIndex}
              onDelete={onDeletePushPin}
              onSubmit={onUpdatePushPin}
              title={pin.itemDesignation}
              editorOnly
            />
          ))
        ) : (
          <div>No push pins yet, create one to get started</div>
        )}
      </div>

      {showCreateForm && (
        <EditItemModal
          entity="Push Pin"
          schema={schema}
          onSubmit={onCreatePushPin}
          onCloseModal={onCloseCreateForm}
        />
      )}

      <div className="panel-settings-modal__actions">
        <Button
          activity="secondary"
          type="text"
          onClick={onClose}
        >
          Cancel
        </Button>
        <div className="panel-settings-modal__primary-buttons">
          {onFullSettings && (
            <Button
              activity="primary"
              type="button"
              onClick={onFullSettings}
            >
              Full Settings
            </Button>
          )}
          <Button
            activity="primary"
            type="button"
            disabled={!hasChanges}
            onClick={() => onSave({ formData: { ...settings } })}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default ViewerSettings;
