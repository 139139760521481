import React, { useEffect, useState } from 'react';
import { Button } from '@avtjs/react-components';
import { useDispatch, useSelector } from 'react-redux';

import SimpleModal from '../../../../SimpleModal';
import FileDetail from '../FileDetail';

import { updateFiles } from '../../../../../bundles/files';
import { getActiveSite } from '../../../../../bundles/sites';

import fileUpsertValidation, { validateFileOnPropertyChange } from '../fileUpsertValidation';

const FileEditModal = ({
  files: initialFiles,
  availableComponents,
  availableTags,
  availableTypes,
  limitTags,
  onCloseModal,
}) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const [files, setFiles] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (initialFiles.length) {
      const updatedFiles = initialFiles.map((file) => ({
        ...file,
        file: {
          name: file.filename,
          size: file.size,
          type: file.fileType,
          mime: file.mime,
        },
      }));
      setFiles([...updatedFiles]);
    }
  }, [initialFiles]);

  const handleClickRemoveFile = (id) => {
    const filteredFiles = files.filter((file) => file.id !== id);
    setFiles(filteredFiles);
    setHasErrors(false);
    if (!filteredFiles.length) onCloseModal();
  };

  const validateForm = (filesToValidate) => {
    let errors = {};
    const validFiles = [];
    if (limitTags == null) return { filesToValidate, errors };

    filesToValidate.forEach((file) => {
      const { hasError, objectOfErrors } = fileUpsertValidation({
        file,
        identifier: file.id,
        limitTags,
        availableTags,
        isEditing: true,
      });

      errors = { ...objectOfErrors };
      if (!hasError) {
        if (!file.context) {
          validFiles.push({ ...file, context: { type: 'general', identifier: 'files-panel' } });
        } else {
          validFiles.push(file);
        }
      }
    });

    return { validFiles, errors };
  };

  const handleSetFileProperty = (key, value, index) => {
    const updatedFiles = files;

    updatedFiles[index] = {
      ...files[index],
      [key]: value,
    };

    const { file, errors, hasError } = validateFileOnPropertyChange({
      key,
      file: updatedFiles[index],
      identifier: updatedFiles[index].id,
      limitTags,
      availableTags,
    });
    setHasErrors(hasError);
    updatedFiles[index] = { ...file, errors };

    setFiles([...updatedFiles]);
  };

  const handleClickUpdate = () => {
    const { validFiles, errors } = validateForm(files);
    if (!Object.keys(errors).length) {
      dispatch(updateFiles(site.id, validFiles, site.org));
      onCloseModal();
    } else {
      const filesWithErrors = files.map((file) => ({
        ...file,
        errors: errors[file.id],
      }));
      setFiles(filesWithErrors);
    }
  };

  const handleCopyData = (fileData) => {
    Object.keys(fileData).forEach((key) => {
      files.forEach((file, i) => {
        handleSetFileProperty(key, fileData[key], i);
      });
    });
  };

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Edit files"
      size="m"
    >
      <div className="file-edit-modal">
        <div className="file-edit-item-wrapper">
          {files.map((file, i) => (
            <FileDetail
              file={file}
              availableComponents={availableComponents}
              availableTags={availableTags}
              availableTypes={availableTypes}
              onClickRemove={() => handleClickRemoveFile(file.id)}
              onCopyData={files.length > 1 ? handleCopyData : undefined}
              onSetProperty={(key, value) => handleSetFileProperty(key, value, i)}
              key={file.id}
            />
          ))}
        </div>
        <div className="bottom-toolbar">
          {!!files.length && (
            <div className="row">
              <Button
                activity="primary"
                disabled={hasErrors}
                onClick={handleClickUpdate}
              >
                Update files
              </Button>
            </div>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default FileEditModal;
