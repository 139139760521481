import React, { memo } from 'react';
import { Button } from '@avtjs/react-components';

import ErrorBoundary from '../ErrorBoundary';
import Page from './Page';
import './Page.scss';

const ErrorComponent = memo(() => (
  <div className="page-component">
    <div className="bound-error">
      <div>
        <p>Something went wrong on this page</p>
        <Button
          onClick={() => window.location.reload()}
          activity="secondary"
          type="button"
        >
          Reload Page
        </Button>
      </div>
    </div>
  </div>
));

const BoundPage = (props) => (
  <ErrorBoundary
    ErrorComponent={ErrorComponent}
    {...props}
  >
    <Page {...props} />
  </ErrorBoundary>
);

export default BoundPage;
