/* eslint-disable default-param-last */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import {
  getModels as getModelsApi,
  createModel as createModelApi,
  updateModel as updateModelApi,
  deleteModel as deleteModelApi,
  updateSceneConfig as updateSceneConfigApi,
} from '../services';
import { getComponentHashmap, doUpdateComponent } from './components';

import { addFile, getAdded, initUpload } from './uploads';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_MODELS = 'dt/models/REQUEST_MODELS';
export const RECEIVED_MODELS = 'dt/models/RECEIVED_MODELS';
export const CREATE_MODEL_AND_INITIAL_VERSION = 'dt/models/CREATE_MODEL_AND_INITIAL_VERSION';
export const ADD_MODEL_VERSION = 'dt/models/ADD_MODEL_VERSION';
export const UPDATE_MODEL = 'dt/models/UPDATE_MODEL';
export const DELETE_MODEL = 'dt/models/DELETE_MODEL';
export const UPDATE_SCENE_CONFIG = 'dt/models/UPDATE_SCENE_CONFIG';
export const RESET_MODELS = 'dt/models/RESET_MODELS';
export const ADD_UPLOADING_ID = 'dt/models/ADD_UPLOADING_ID';
export const REMOVE_UPLOADING_ID = 'dt/models/REMOVE_UPLOADING_ID';

export const PENDING = 'PENDING';
export const PROCESSING = 'PROCESSING';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const REMOVED = 'REMOVED';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestModels = (siteId) => ({
  type: REQUEST_MODELS,
  siteId,
});

export const resetModels = () => ({
  type: RESET_MODELS,
});

export const receiveModels = (models, siteId) => ({
  type: RECEIVED_MODELS,
  models,
  siteId,
});

export const createModelAndInitialVersion = (data, componentId) => ({
  type: CREATE_MODEL_AND_INITIAL_VERSION,
  data,
  componentId,
});

export const addModelVersion = (data, modelId) => ({
  type: ADD_MODEL_VERSION,
  data,
  modelId,
});

export const updateModel = (modelId, data) => ({
  type: UPDATE_MODEL,
  modelId,
  data,
});

export const deleteModel = (siteId, modelId) => ({
  type: DELETE_MODEL,
  siteId,
  modelId,
});

export const updateSceneConfig = (siteId, modelId, versionId, data) => ({
  type: UPDATE_SCENE_CONFIG,
  siteId,
  modelId,
  versionId,
  data,
});

export const addUploadingId = (modelId) => ({
  type: ADD_UPLOADING_ID,
  modelId,
});

export const removeUploadingId = (modelId) => ({
  type: REMOVE_UPLOADING_ID,
  modelId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  hashmap: {},
  siteLoaded: undefined,
  uploadingIds: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_MODELS: {
      const { models, siteId } = action;
      const updatedHashmap = {};
      models.forEach((model) => {
        updatedHashmap[model.id] = model;
      });

      return {
        ...state,
        hashmap: updatedHashmap,
        siteLoaded: siteId,
      };
    }
    case ADD_UPLOADING_ID: {
      return {
        ...state,
        uploadingIds: [...state.uploadingIds, action.modelId],
      };
    }
    case REMOVE_UPLOADING_ID: {
      return {
        ...state,
        uploadingIds: state.uploadingIds.filter((id) => id !== action.modelId),
      };
    }
    case RESET_MODELS:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        hashmap: {},
        siteLoaded: undefined,
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getModelsLoaded = (state) => state.models.siteLoaded;

export const getAllModels = (state) => state.models.hashmap;

export const getModelVersions = (state, modelId) =>
  (state.models.hashmap[modelId] && state.models.hashmap[modelId].versions) || [];

export const checkHasModels = (state) => Object.keys(state.models.hashmap).length;

export const getModelIdsWithProcessingVersions = createSelector(getAllModels, (modelsMap) =>
  Object.values(modelsMap)
    .filter((model) => model.versions.some((v) => ![COMPLETED, PENDING, FAILED].includes(v.status)))
    .map((model) => model.id)
);

export const getUploadingIds = (state) => state.models.uploadingIds;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doRequestModels(action) {
  const { siteId } = action;
  try {
    const { values: models } = yield call(getModelsApi, siteId);
    yield put(receiveModels(models, siteId));
  } catch (e) {
    console.error('Unable to fetch models: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getModels', 'error')()));
    yield put(receiveModels([], siteId));
  }
}

function* doCreateModelAndUploadFile(action) {
  const {
    data: { site, org, name, modelType, file, rootFile },
    componentId,
  } = action;

  const payload = {
    site,
    org,
    name,
    modelType,
  };

  try {
    const { id: modelId } = yield call(createModelApi, payload);
    yield put(addUploadingId(modelId));
    const comps = yield select(getComponentHashmap);
    const comp = comps[componentId];
    if (comp) {
      yield call(doUpdateComponent, {
        id: componentId,
        siteId: site,
        data: {
          models: [...comp.models, { modelId, customName: name }],
        },
      });
    }
    yield call(doRequestModels, { siteId: site });
    yield put(addFile(file, site.id, modelId));
    const addedFiles = yield select(getAdded);

    yield put(
      initUpload({
        id: addedFiles[0].id,
        comment: 'Initial upload',
        org,
        rootFile,
        siteId: site.id,
      })
    );

    yield put(displayNotification(getNotification('createModel', 'success')(name)));
  } catch (e) {
    console.error('Unable to create model: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createModel', 'error')()));
  }
}

function* doAddModelVersion(action) {
  const {
    data: { site, org, file, rootFile, comment, copyMappings, mappingsFromVersion },
    modelId,
  } = action;

  try {
    yield put(addFile(file, site.id, modelId));
    const [addedFile] = yield select(getAdded);

    if (addedFile) {
      yield put(
        initUpload({
          id: addedFile.id,
          org,
          rootFile,
          siteId: site.id,
          comment,
          copyMappings,
          mappingsFromVersion,
        })
      );
      yield call(doRequestModels, { siteId: site });
      yield put(displayNotification(getNotification('addModelVersion', 'success')()));
    }
  } catch (e) {
    console.error('Unable to create model: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('addModelVersion', 'error')()));
  }
}

function* doUpdateModel(action) {
  const { modelId, data } = action;

  try {
    yield updateModelApi(modelId, data);
    yield call(doRequestModels, { siteId: data.site });
    yield put(displayNotification(getNotification('updateModel', 'success')(data.name, modelId)));
  } catch (e) {
    console.error('Unable to update model: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateModel', 'error')(modelId)));
  }
}

function* doDeleteModel(action) {
  const { siteId, modelId } = action;
  const model = yield select((state) => state.models.hashmap[modelId]);
  try {
    yield deleteModelApi(modelId);
    yield call(doRequestModels, { siteId });
    yield put(displayNotification(getNotification('deleteModel', 'success')(model.name, modelId)));
  } catch (e) {
    console.error('Unable to delete model: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteModel', 'error')(modelId)));
  }
}

function* doUpdateSceneConfig(action) {
  const { siteId, modelId, versionId, data } = action;

  try {
    yield updateSceneConfigApi(modelId, versionId, data);
    yield call(doRequestModels, { siteId });
    yield put(displayNotification(getNotification('updateSceneConfig', 'success')(modelId)));
  } catch (e) {
    console.error('Unable to update scene configuration: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateSceneConfig', 'error')(modelId)));
  }
}

export const sagas = [
  takeLatest(REQUEST_MODELS, doRequestModels),
  takeLatest(CREATE_MODEL_AND_INITIAL_VERSION, doCreateModelAndUploadFile),
  takeLatest(ADD_MODEL_VERSION, doAddModelVersion),
  takeLatest(UPDATE_MODEL, doUpdateModel),
  takeLatest(DELETE_MODEL, doDeleteModel),
  takeLatest(UPDATE_SCENE_CONFIG, doUpdateSceneConfig),
];
