import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components } from 'react-select';
import { Select, Creatable, colors, useTheme } from '@avtjs/react-components';

import PopoutSelect from '../PopoutSelect';
import { requestTags, getTags } from '../../bundles/tags';
import { getActiveSite } from '../../bundles/sites';
import { displayNotification, checkIsOnline } from '../../bundles/notifications';
import getNotification from '../../bundles/notification-defaults';
import services from '../../services';
import Loader from '../Loader';

const MultiValueRemove = (props) => {
  const {
    limitTags: { allIncludes },
  } = props.selectProps;
  if (allIncludes && allIncludes.map((tag) => tag.id).includes(props.data.value)) {
    return null;
  }

  return <components.MultiValueRemove {...props} />;
};

const ClearIndicator = (props) => {
  const {
    limitTags: { allIncludes },
  } = props.selectProps;
  if (allIncludes && allIncludes.length === (props.getValue() || []).length) {
    return null;
  }
  const clearValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let value;
    if (allIncludes) {
      value = props.selectProps.options.filter((o) =>
        allIncludes.map((tag) => tag.id).includes(o.value)
      );
    }
    props.setValue(value);
  };

  return (
    <components.ClearIndicator
      {...{
        ...props,
        innerProps: {
          ...props.innerProps,
          onMouseDown: clearValue,
          onTouchEnd: clearValue,
        },
      }}
    />
  );
};

export default function TagSelect({
  label = 'Tags',
  icon,
  onChange = () => null,
  value: selectedIds,
  creatable = true,
  popout = false,
  placeholder,
  availableTags,
  limitTags = {},
  isDisabled = false,
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  menuMaxHeight,
  fullHeight = false,
  hideMenu = false,
  editable = true,
  ...props
}) {
  let SelectComponent = Select;
  if (popout) {
    SelectComponent = PopoutSelect;
  } else if (creatable) {
    SelectComponent = Creatable;
  }

  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const site = useSelector(getActiveSite);
  const tags = useSelector(getTags);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!availableTags) {
      dispatch(requestTags(site.id));
    }
  }, [availableTags]);

  const overrideStyles = () => ({
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.Grey10 : base.color,
    }),
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      ...(hideMenu ? { display: 'none' } : {}),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
    control: (base) => ({
      ...base,
      backgroundColor: `${theme === 'dark' ? colors.Grey90 : colors.Grey0}`,
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: '3px 6px',
    }),
  });

  const tagOptions = useMemo(
    () =>
      (availableTags || tags).map((t) => ({
        value: t.id,
        label: t.name,
      })),
    [tags, availableTags]
  );

  const createTag = async (name) => {
    const { id } = await services.collaboration.createTag({
      name,
      org: site.org,
      site: site.id,
    });
    if (id) {
      dispatch(requestTags(site.id));
      onChange(selectedIds ? [...selectedIds, id] : [id]);
    }
  };

  const isExcludedTag = (name) => {
    if (limitTags.allExcludes) {
      const found = limitTags.allExcludes.find(
        (tag) => tag.name.toLowerCase() === name.toLowerCase()
      );
      if (found) {
        return true;
      }
    }
    return false;
  };

  const handleCreate = async (name) => {
    setIsLoading(true);
    try {
      if (isExcludedTag(name)) {
        dispatch(displayNotification(getNotification('createTag', 'excluded')(name)));
      } else {
        await createTag(name);
      }
    } catch (e) {
      console.error('Unable to create tag: ', e);
      dispatch(checkIsOnline());
      dispatch(displayNotification(getNotification('createTag', 'error')(name)));
    }
    setIsLoading(false);
    ref.current.focus();
  };

  const handleOnChange = useCallback(
    (selection) => {
      onChange(selection ? selection.map((option) => option.value) : []);
    },
    [onChange]
  );

  if (!tagOptions) {
    return <Loader />;
  }

  const localValue =
    selectedIds &&
    tagOptions.filter((option) => (selectedIds || []).find((id) => option.value === id));

  return editable ? (
    <SelectComponent
      label={popout && label}
      icon={popout && icon}
      isMulti
      innerRef={ref}
      onChange={handleOnChange}
      creatable={creatable}
      onCreateOption={creatable && handleCreate}
      value={localValue?.length ? localValue : null}
      options={tagOptions}
      isDisabled={isDisabled || isLoading}
      closeMenuOnSelect={false}
      formatCreateLabel={creatable && ((input) => `Create new tag: "${input}"`)}
      components={{ MultiValueRemove, ClearIndicator }}
      placeholder={placeholder || `Search${creatable ? ' or create' : '...'}`}
      limitTags={limitTags}
      menuXPlacement={menuXPlacement}
      menuYPlacement={popout ? 'bottom' : menuYPlacement}
      styles={(menuMaxHeight || !fullHeight) && overrideStyles()}
      {...props}
    />
  ) : (
    <div className="tag-display-container">
      {localValue?.map((option, i) => (
        <div
          key={`${option.value}-${i}`}
          className="blocked-item"
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}
