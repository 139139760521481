.edit-page-component .properties-title,
.edit-page-component .sections-title {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 1rem 0;
}
.edit-page-component .delete-page {
  padding: 1rem;
  text-align: center;
}

.dark-theme .edit-page-component .json-editor-component .prop-table .row,
.dark-theme .edit-page-component .json-editor-component .key,
.dark-theme .edit-page-component .json-editor-component .value {
  border: 1px solid rgb(25.5, 25.5, 25.5);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvRWRpdFBhZ2UiLCJzb3VyY2VzIjpbIkVkaXRQYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7QUFBQTtFQUVFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7OztBQU9FO0FBQUE7QUFBQTtFQUdFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnQGF2dGpzL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzcyc7XG5cbi5lZGl0LXBhZ2UtY29tcG9uZW50IHtcbiAgLnByb3BlcnRpZXMtdGl0bGUsXG4gIC5zZWN0aW9ucy10aXRsZSB7XG4gICAgZm9udC1zaXplOiAxLjFyZW07XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgICBtYXJnaW46IDFyZW0gMDtcbiAgfVxuXG4gIC5kZWxldGUtcGFnZSB7XG4gICAgcGFkZGluZzogMXJlbTtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIH1cbn1cblxuLmRhcmstdGhlbWUge1xuICAuZWRpdC1wYWdlLWNvbXBvbmVudCB7XG4gICAgLmpzb24tZWRpdG9yLWNvbXBvbmVudCB7XG4gICAgICAucHJvcC10YWJsZSAucm93LFxuICAgICAgLmtleSxcbiAgICAgIC52YWx1ZSB7XG4gICAgICAgIGJvcmRlcjogMXB4IHNvbGlkICRHcmV5OTA7XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */