import React, { useState, useMemo } from 'react';
import { Spinner } from '@avtjs/react-components';
import { useSelector } from 'react-redux';

import { getHasPermission } from '../../../../../bundles/auth';
import ListItem from '../../../../ListItem';

const InvitationList = (props) => {
  const {
    org,
    formData,
    invitations,
    searchText,
    roleFilter,
    isLoading,
    schema,
    uiSchema,
    onEdit = () => {},
    onUpdate,
    onChange,
    onClose = () => {},
    onDelete,
    getPermissions,
    canEdit = true,
  } = props;
  const [expanded, setExpanded] = useState([]);
  const canDeleteInvitations = useSelector((state) =>
    getHasPermission(state, 'invitations/Delete', { org })
  );

  const roleFilteredInvites = useMemo(() => {
    if (roleFilter !== 'all') {
      return invitations.filter((u) => (u.roles[roleFilter] || []).length);
    }
    return invitations;
  }, [invitations, roleFilter]);

  const filteredInvites = useMemo(() => {
    if (searchText) {
      const filters = searchText.trim().split(' ');
      return roleFilteredInvites.filter((u) =>
        filters.every((f) => (u.email?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1)
      );
    }
    return roleFilteredInvites;
  }, [roleFilteredInvites, searchText]);

  const headers = ['Email', 'Site access'];

  const headerCols = headers.map((h, i) => {
    const key = `col-${i}`;
    return (
      <div
        key={key}
        className="list-item--header__col"
      >
        {h}
      </div>
    );
  });

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      ) : (
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerCols}
          />
          {filteredInvites
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((invitation, i) => {
              const item = {
                ...invitation,
                ...(invitation.id === formData?.id ? formData : {}),
              };
              return (
                <ListItem
                  key={invitation.id}
                  itemIndex={i}
                  entity={`invitation for ${invitation.email}`}
                  withTitle={false}
                  item={item}
                  schema={schema}
                  uiSchema={uiSchema}
                  onDelete={() => onDelete(invitation.id)}
                  disableDelete={!canDeleteInvitations}
                  confirmationDialogTitle={'Delete invitation'}
                  confimationDialogBody={
                    <>
                      <p style={{ paddingTop: '1.5rem' }}>
                        Are you sure you want to remove <b>{invitation.email}'s</b> invitation?
                      </p>
                    </>
                  }
                  onEdit={onEdit}
                  disableEdit={!canEdit}
                  onSubmit={(data) => onUpdate(data, invitation.id)}
                  onChange={(data) => onChange(data)}
                  onClose={onClose}
                  onExpand={() => setExpanded(invitation.id)}
                  isExpanded={expanded.includes(invitation.id)}
                  accordionContent={getPermissions(invitation, 'invitation')}
                  columns={[
                    <div
                      key={`row-${i}-email`}
                      className="ellipsed-text"
                    >
                      {invitation.email}
                    </div>,
                    <div
                      key={`row-${i}-count`}
                      className="count-indicator"
                    >
                      {Object.values(invitation.roles).reduce(
                        (acc, siteIds) => acc + siteIds.length,
                        0
                      )}
                    </div>,
                  ]}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

export default InvitationList;
