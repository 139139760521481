.tfr-view .tfr-view-details .signal-viewer {
  padding-left: 0px;
}
.tfr-view .tfr-view-details .select-period-component .header {
  min-height: 38px;
  width: 100%;
  justify-content: flex-start !important;
}
.tfr-view .tfr-view-details .select-period-component .header:hover {
  border-color: #b3b3b3 !important;
}
.tfr-view .tfr-view-details .select-period-component .header .label {
  display: flex;
  flex: 1;
  height: 21px;
  align-items: center;
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child {
  margin-left: 8px !important;
  margin-right: 0;
  position: relative;
  color: #cccccc !important;
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child svg {
  transform: scale(1.4);
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child :hover {
  color: #999999;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2lnbmFsVmlld2VyQ29udGFpbmVyIiwic291cmNlcyI6WyJTaWduYWxWaWV3ZXJDb250YWluZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJSTtFQUNFOztBQUdBO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBRUE7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBRUY7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ0BhdnRqcy9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3MnO1xuXG4udGZyLXZpZXcge1xuICAudGZyLXZpZXctZGV0YWlscyB7XG4gICAgLnNpZ25hbC12aWV3ZXIge1xuICAgICAgcGFkZGluZy1sZWZ0OiAwcHg7XG4gICAgfVxuICAgIC5zZWxlY3QtcGVyaW9kLWNvbXBvbmVudCB7XG4gICAgICAuaGVhZGVyIHtcbiAgICAgICAgbWluLWhlaWdodDogMzhweDtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydCAhaW1wb3J0YW50O1xuICAgICAgICAmOmhvdmVyIHtcbiAgICAgICAgICBib3JkZXItY29sb3I6ICNiM2IzYjMgIWltcG9ydGFudDtcbiAgICAgICAgfVxuICAgICAgICAubGFiZWwge1xuICAgICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgICAgZmxleDogMTtcbiAgICAgICAgICAvLyBib3JkZXItcmlnaHQ6IDEuNXB4IHNvbGlkICNjYWNhY2E7XG4gICAgICAgICAgaGVpZ2h0OiAyMXB4O1xuICAgICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIH1cbiAgICAgICAgPiBkaXY6bGFzdC1jaGlsZCB7XG4gICAgICAgICAgbWFyZ2luLWxlZnQ6IDhweCAhaW1wb3J0YW50O1xuICAgICAgICAgIG1hcmdpbi1yaWdodDogMDtcbiAgICAgICAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgICAgICAgY29sb3I6ICNjY2NjY2MgIWltcG9ydGFudDtcbiAgICAgICAgICBzdmcge1xuICAgICAgICAgICAgdHJhbnNmb3JtOiBzY2FsZSgxLjQpO1xuICAgICAgICAgIH1cbiAgICAgICAgICA6aG92ZXIge1xuICAgICAgICAgICAgY29sb3I6ICM5OTk5OTk7XG4gICAgICAgICAgfVxuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */