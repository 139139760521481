import React, { memo } from 'react';
import { Button } from '@avtjs/react-components';

import ErrorBoundary from '../ErrorBoundary';
import Panel from './Panel';
import './Panel.scss';

const ErrorComponent = memo(({ onReload, retries, type }) => {
  const reloader = retries > 0 ? () => window.location.reload() : onReload;
  const reloadCta = retries > 0 ? 'Reload Page' : 'Reload Panel';
  return (
    <div className="panel-component">
      <div className="bound-error">
        <div>
          <p>
            Something went wrong in this <span className="bound-error__panel-type">{type}</span>{' '}
            Panel
          </p>
          <Button
            onClick={reloader}
            activity="secondary"
            type="button"
          >
            {reloadCta}
          </Button>
        </div>
      </div>
    </div>
  );
});

const BoundPanel = (props) => (
  <ErrorBoundary
    ErrorComponent={ErrorComponent}
    {...props}
  >
    <Panel {...props} />
  </ErrorBoundary>
);

export default BoundPanel;
