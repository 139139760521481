import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon, Can, Button } from '@avtjs/react-components';

import SiteFilter from '../SiteFilter';
import SiteCreateView from '../SiteCreateView';
import AppContext from '../../AppContext';
import SystemMessages from '../SystemMessages';
import { isFunction } from '../../utils';
import PageSettings from './PageSettings';

const PageHeader = ({
  site,
  realm,
  title,
  singlePage,
  isTenantAdminView,
  children,
  sitesList = false,
  setFilter = () => '',
}) => {
  const { isTouchDevice } = useContext(AppContext);
  const [setCanSidebarExpand] = useState(true);
  const headerRef = useRef();
  const location = useLocation();

  const validSite = site && site.org && site.id;
  const showSitesMap = validSite || isTenantAdminView;
  const pathParts = location.pathname?.split('/') || [];
  const [isSettings, setIsSettings] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (headerRef.current) {
        if (window.pageYOffset === 0 && headerRef.current.classList.contains('scroll')) {
          headerRef.current.classList.remove('scroll');
        } else if (window.pageYOffset > 0 && !headerRef.current.classList.contains('scroll')) {
          headerRef.current.classList.add('scroll');
        }
      }
    };
  }, []);

  const expandSideBar = (expand) => (isTouchDevice ? setCanSidebarExpand(expand) : null);

  const buildRoute = useCallback((index) => pathParts.slice(0, index + 4).join('/'), [pathParts]);

  const siteName = useMemo(() => {
    if (validSite) {
      return (
        <div className="site-name">
          {singlePage ? (
            <NavLink
              title={`${site.name} - Home`}
              exact
              to={() => buildRoute(-1)}
            >
              {site.name}
            </NavLink>
          ) : (
            site.name
          )}
        </div>
      );
    }
    return null;
  }, [site, validSite, singlePage]);

  const breadCrumbs = (val, i) => {
    let bcTitle = '';
    let bcTo = '';
    if (Object.hasOwnProperty.call(val, 'title')) {
      bcTitle = val.title;
      bcTo = val.match.url;
    } else {
      bcTitle = val;
      bcTo = buildRoute(i);
    }
    if (i === title.value.length - 1) {
      return (
        <div
          key={`bc-${i}`}
          className="page-title--current"
        >
          {bcTitle}
        </div>
      );
    }
    return (
      <div
        key={`bc-${i}`}
        className="page-title--breadcrumb"
      >
        {isFunction(val.onClick) ? (
          <div
            title={bcTitle}
            onClick={() => {
              val.onClick();
            }}
          >
            {bcTitle}
          </div>
        ) : (
          <NavLink
            title={bcTitle}
            exact
            to={bcTo}
          >
            {bcTitle}
          </NavLink>
        )}

        <span>{'>'}</span>
      </div>
    );
  };
  const onSettings = () => {
    setIsSettings(true);
  };

  const handleCloseSettings = useCallback(() => setIsSettings(false), [setIsSettings]);

  const pageTitle = useMemo(() => {
    if (!title) {
      return null;
    }
    if (title.type === 'string') {
      return <div className="page-title">{title.value || ''}</div>;
    }
    if (title.type === 'image') {
      return (
        <img
          className="page-title"
          src={title.value}
        />
      );
    }
    if (title.type === 'breadcrumb' && title.value?.length) {
      return <div className="page-title">{title.value.map((val, i) => breadCrumbs(val, i))}</div>;
    }
    return null;
  }, [title]);

  const sitesListClass = sitesList ? ' sites-list' : '';
  const tenantAdmin = isTenantAdminView ? 'tenant-admin' : '';

  return (
    <div
      ref={headerRef}
      className={`page-header-component${sitesListClass} ${tenantAdmin}`}
    >
      <SystemMessages />
      <div className="page-header-content-wrapper">
        <div className="page-header-left">
          {showSitesMap && (
            <div className="page-header-icon-wrapper">
              <NavLink
                onClick={() => expandSideBar(false)}
                title="Map"
                exact
                to="/sites/"
              >
                <Icon icon="map" />
              </NavLink>
            </div>
          )}
          <div className={`page-title-container${sitesListClass}`}>
            {siteName}
            <div className={'page-setting-title'}>
              {pageTitle}
              {title.index >= 0 && (
                <Button
                  className="page-setting"
                  onClick={onSettings}
                  slim
                  activity="secondary"
                  icon={<Icon icon="abb-settings" />}
                  tooltip="Settings"
                />
              )}
              {isSettings && title.index >= 0 && (
                <PageSettings
                  site={site}
                  pageIndex={title.index}
                  onClose={handleCloseSettings}
                />
              )}
            </div>
          </div>
        </div>

        {sitesList && (
          <div className="site-list-tools">
            <SiteFilter onChange={setFilter} />
            <Can permission="sites/Create">
              <SiteCreateView realm={realm} />
            </Can>
          </div>
        )}

        <div className="tools">{children}</div>
      </div>
    </div>
  );
};

export default PageHeader;
