import React, { useState } from 'react';
import { Button, Icon } from '@avtjs/react-components';
import { parseISO, format } from 'date-fns';

import { formatBytes, getFilenameWithoutExt } from '../../../../../utils';

import { DropdownMenu } from '../../../../DropdownMenu';
import Pill from '../../../../Pill';
import FileListBookmarks from '../FileListBookmarks';
import FiletypeIcon from '../../../../FiletypeIcon';

const TableRow = ({
  showFileExtension,
  filterValue,
  tagFilter,
  activeComponentId,
  visibleBookmarkLists,
  onToggleBookmarkList,
  disableSelect,
  isShiftPressed,
  onPreview,
  onSelectFile,
  file,
  getDropdownOptions = () => [],
}) => {
  const hideFileExtension = (filename) => getFilenameWithoutExt(filename) || filename;

  const onClickFile = (f) => {
    if (isShiftPressed) {
      onSelectFile(f);
    } else {
      onPreview(f);
    }
  };

  const options = getDropdownOptions(file);
  return (
    <div className="file-table-row">
      {!disableSelect && (
        <div className="table-cell select-column">
          <Button
            design="text"
            tooltip="Select"
          >
            <Icon
              className={`checkbox ${file.isSelected ? 'checked' : ''}`}
              icon={`${file.isSelected ? 'check-box' : 'check-box-outline-blank'}`}
              size="s"
              onClick={(e) => {
                e.stopPropagation();
                onSelectFile(file);
              }}
            />
          </Button>
        </div>
      )}

      <div className="table-cell filename-column">
        <div className="filename">
          <FiletypeIcon
            file={file}
            onClick={() => onClickFile(file)}
          />
          <span
            className="filename-text"
            onClick={() => onClickFile(file)}
          >
            {showFileExtension ? file.filename : hideFileExtension(file.filename)}
          </span>
          {file.bookmarks.length > 0 && (
            <Pill
              icon="bookmark"
              text={file.bookmarks.length}
              onClick={() => onToggleBookmarkList(file.id)}
            />
          )}
        </div>
        <FileListBookmarks
          file={file}
          onPreview={onPreview}
          filterValue={filterValue}
          bookmarks={file.bookmarks}
          tagFilter={tagFilter}
          activeComponentId={activeComponentId}
          bookmarkListToggled={visibleBookmarkLists.indexOf(file.id) !== -1}
        />
      </div>

      <div className="table-cell type-column">
        <span className="type-badge">{file.type}</span>
      </div>

      <div className="table-cell size-column">
        <span>{formatBytes(file.size)}</span>
      </div>

      <div className="table-cell created-at-column">
        <span>{format(parseISO(file.createdAt), 'yyyy-MM-dd')}</span>
      </div>

      <div className="table-cell actions-column">
        {!!options.length && (
          <DropdownMenu
            trigger="abb-menu-narrow"
            menuOptions={options}
            menuXPlacement="left"
          />
        )}
      </div>
    </div>
  );
};

const FileTable = ({
  files,
  tagFilter,
  activeComponentId,
  filterValue,
  showFileExtension,
  getDropdownOptions,
  onPreview,
  onSelectFile,
  isShiftPressed,
  disableSelect,
  setSortBy,
  sortBy,
  setOrder,
  order,
}) => {
  const [visibleBookmarkLists, setVisibleBookmarkLists] = useState([]);

  const tableHeaders = [
    ['Filename', 'filename-column', 'filename'],
    ['Type', 'type-column', 'type'],
    ['Size', 'size-column', 'size'],
    ['Created at', 'created-at-column', 'createdAt'],
  ];

  const sortList = (sortOption) => {
    if (sortOption === sortBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
      return;
    }
    setSortBy(sortOption);
    setOrder(sortOption !== 'filename' ? 'desc' : 'asc');
  };

  const sortingIcon = (fileSortOption) => {
    if (sortBy !== fileSortOption) {
      return 'abb-caret-down';
    }
    if (order === 'asc') {
      return 'abb-caret-up';
    }
    return 'abb-caret-down';
  };

  const onToggleBookmarkList = (fileId) => {
    setVisibleBookmarkLists((currentValue) =>
      currentValue.indexOf(fileId) !== -1
        ? currentValue.filter((id) => id !== fileId)
        : [...currentValue, fileId]
    );
  };

  return (
    <div className="file-table-component">
      <div className="file-table-table">
        <div className="file-table-headers">
          {!disableSelect && <div className="table-header select-column"></div>}
          {tableHeaders.map((header) => (
            <div
              key={header[0]}
              className={`table-header ${header[1]}`}
            >
              <Button
                design="text"
                onClick={() => sortList(header[2])}
              >
                {header[0]}
                <Icon
                  className={sortBy !== header[2] ? 'hoverableIcon' : undefined}
                  icon={sortingIcon(header[2])}
                />
              </Button>
            </div>
          ))}
          <div className="table-header actions-column"></div>
        </div>
        {files.map((file) => (
          <TableRow
            showFileExtension={showFileExtension}
            filterValue={filterValue}
            tagFilter={tagFilter}
            activeComponentId={activeComponentId}
            visibleBookmarkLists={visibleBookmarkLists}
            onToggleBookmarkList={onToggleBookmarkList}
            disableSelect={disableSelect}
            isShiftPressed={isShiftPressed}
            onPreview={onPreview}
            onSelectFile={onSelectFile}
            file={file}
            getDropdownOptions={getDropdownOptions}
            key={file.id}
          />
        ))}
      </div>
    </div>
  );
};

export default FileTable;
