import { VALID_ORDERED_COMPONENT_KEYS } from './schemas/componentSchema';

const prepData = (formData) => {
  const keys = VALID_ORDERED_COMPONENT_KEYS;
  const data = keys
    .map((key) => ({ [key]: formData[key] }))
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...curr,
      }),
      {}
    );

  // we have to re-key source_id & options due to key conflicts in react-jsonschema
  if (data.sourceOptions) {
    data.options = data.sourceOptions;
    delete data.sourceOptions;
  }
  if (data.source) {
    data.source_id = data.source;
    delete data.source;
  } else {
    // if no source, we explicitly set null to clear and empty options
    data.source_id = null;
    data.options = {};
  }
  data.eventSources = (data.eventSources || []).filter((source) => source.source_id);
  return data;
};

export default prepData;
