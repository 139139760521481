const getComponentPropertiesPanelSchema = (categories = []) => {
  const schema = {
    title: {
      type: 'string',
      default: 'Component Properties',
    },
    options: {
      type: 'object',
      properties: {
        showComponentName: {
          title: 'Show component name',
          type: 'boolean',
          default: true,
        },
        showCategories: {
          title: 'Show categories',
          type: 'boolean',
          default: true,
        },
        showSiteIfNoActiveComponent: {
          title: 'Show site properties if no component is selected',
          type: 'boolean',
          default: true,
        },
      },
    },
  };

  if (categories.length) {
    schema.options.properties.categoriesFilter = {
      title: 'Categories filter',
      description: "Categories are collected from all the site's components' properties",
      type: 'array',
      uniqueItems: true,
      isMulti: true,
      default: [],
      items: {
        type: 'string',
        enum: categories,
      },
    };
  }

  return schema;
};

export default getComponentPropertiesPanelSchema;
