import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner } from '@avtjs/react-components';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import {
  requestAlgorithms,
  createAlgorithm,
  getSiteAlgorithms,
  algorithmsLoaded,
} from '../../../bundles/algorithms';
import { requestTasks } from '../../../bundles/tasks';
import { getUploads } from '../../../bundles/uploads';
import { getActiveSite } from '../../../bundles/sites';

import Heading from '../../Heading';
import StyledItem from '../../StyledItem';
import CreateAlgorithmModal from './components/CreateAlgorithmModal';
import { algorithmSchema, algorithmUiSchema } from './schemas/algorithmSchema';

const ManageAlgorithmView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const site = useSelector(getActiveSite);

  const siteAlgorithms = useSelector((state) => getSiteAlgorithms(state, site.id));
  const loaded = useSelector(algorithmsLoaded);
  const uploads = useSelector(getUploads);

  const [displayCreateAlgorithm, setDisplayCreateAlgorithm] = useState(false);

  useEffect(() => {
    if (site.id) {
      dispatch(requestAlgorithms({ site: site.id }));
      dispatch(requestTasks({ site: site.id }));
    }
  }, [site.id]);

  useEffect(() => {
    let pollingDelay;
    if (uploads.length) {
      pollingDelay = setTimeout(() => dispatch(requestAlgorithms({ site: site.id })), 3000);
    }
    return () => clearTimeout(pollingDelay);
  }, [uploads]);

  const onCreate = useCallback(
    (formData) => {
      formData.append('org', site.org);
      formData.append('site', site.id);
      dispatch(createAlgorithm(formData));
      setDisplayCreateAlgorithm(false);
    },
    [site]
  );

  const onClose = useCallback(() => setDisplayCreateAlgorithm(false), []);

  return (
    <>
      {loaded ? (
        <>
          <Heading
            contentLeft={<div className="title">Algorithms</div>}
            contentRight={
              <Button onClick={() => setDisplayCreateAlgorithm(true)}>Add Algorithm</Button>
            }
          />

          {siteAlgorithms.length > 0 ? (
            siteAlgorithms.map((algorithm) => (
              <StyledItem
                key={algorithm.id}
                headerContent={`${algorithm.name} - ${format(
                  parseISO(algorithm.createdAt),
                  'yyyy-MM-dd'
                )}`}
                onClick={() => history.push(`/sites/${site.id}/admin/algorithms/${algorithm.id}`)}
              />
            ))
          ) : (
            <div>No algorithms yet, create one to get started</div>
          )}
        </>
      ) : (
        <div className="loading-container">
          <Spinner
            size="m"
            className="spinner"
          />
        </div>
      )}

      {displayCreateAlgorithm && (
        <CreateAlgorithmModal
          schema={algorithmSchema}
          uiSchema={algorithmUiSchema}
          onSubmit={onCreate}
          onCloseModal={onClose}
        />
      )}
    </>
  );
};

export default ManageAlgorithmView;
