import React, { useState } from 'react';

import ModelView from '../../../ModelView';
import StepNavigator from '../StepNavigator';

const STEPS = [
  {
    component: ModelView,
    name: 'create',
    label: 'Create models',
  },
];

const ModelStep = ({ onNext, onBack, wizardType }) => {
  const [step, setStep] = useState(0);

  const StepComponent = STEPS[step].component;

  const onAdvance = () => (step === STEPS.length - 1 ? onNext() : setStep(step + 1));
  const onPrevious = () => (step === 0 ? onBack() : setStep(step - 1));

  const getNextLabel = () => {
    if (wizardType === 'basic') {
      return 'Done';
    }
    return null;
  };

  return (
    <>
      <div className="wizard-step-top">
        <div className="wizard-step-top__header">
          <h1>{'Upload 3D & 2D models'}</h1>
        </div>
      </div>
      <div
        className={`wizard-step-main__body--framed custom-thin-scrollbar model-step ${wizardType}`}
      >
        <StepComponent
          wizardType={wizardType}
          withMappingButtons
        />
      </div>
      <StepNavigator
        onBack={onPrevious}
        nextLabel={getNextLabel()}
        onNext={onAdvance}
      />
    </>
  );
};

export default ModelStep;
